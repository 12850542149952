import { getFontSize, getSpacing } from '@viz/design-system';
import styled from 'styled-components';

import { InstitutionCheckbox } from '../InstitutionCheckbox';

export const StyledInstitutionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${getSpacing(8)};
`;

export const StyledInstitutionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInstitutionCheckbox = styled(InstitutionCheckbox)`
  margin-bottom: ${getSpacing(4)};
  ${getFontSize('sm')}
`;
