import { InstitutionSetting } from '@viz/api';

import { getSubInstitutions } from '../../utils';
import {
  StyledInstitutionCheckbox,
  StyledInstitutionContainer,
  StyledInstitutionsContainer
} from './styles';

type SubInstitutionsProps = {
  institutions: InstitutionSetting[];
  searchText: string;
  onChange: (isSelected: boolean, institution: InstitutionSetting) => void;
  className?: string;
};

const SubInstitutions = ({
  institutions,
  searchText,
  onChange,
  className
}: SubInstitutionsProps): JSX.Element => {
  return (
    <StyledInstitutionsContainer className={className}>
      {institutions.map((subInstitution) => {
        const isSelectable =
          subInstitution.selectable ||
          subInstitution.subInstitutions.length > 0;

        return (
          <StyledInstitutionContainer key={subInstitution.id}>
            {isSelectable && (
              <StyledInstitutionCheckbox
                institution={subInstitution}
                onChange={onChange}
              />
            )}
            {subInstitution.subInstitutions.length > 0 && (
              <SubInstitutions
                institutions={getSubInstitutions(subInstitution, searchText)}
                searchText={searchText}
                onChange={onChange}
              />
            )}
          </StyledInstitutionContainer>
        );
      })}
    </StyledInstitutionsContainer>
  );
};

export { SubInstitutions };
