import { IconLogo, IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { ReactComponent as AppStoreButton } from './assets/appStoreButton.svg';
import { ReactComponent as GoogleStoreButton } from './assets/googleStoreButton.svg';
import {
  BadgesContainer,
  Container,
  LogoContainer,
  LogoText,
  MainMessage,
  StyledPhoneImage,
  StyledWaveImage
} from './styles';

const MobileMessage = () => {
  return (
    <Container>
      <LogoContainer>
        <IconLogo size={IconSizes.X_LARGE} />
        <LogoText>Viz.ai</LogoText>
      </LogoContainer>
      <MainMessage>{localize('downloadOurApp')}</MainMessage>
      <BadgesContainer>
        <a href="https://play.google.com/store/apps/details?id=ai.viz.notifier">
          <GoogleStoreButton />
        </a>
        <a href="https://apps.apple.com/us/app/viz-ai/id1222399372">
          <AppStoreButton />
        </a>
      </BadgesContainer>
      <StyledPhoneImage />
      <StyledWaveImage />
    </Container>
  );
};

export default MobileMessage;
