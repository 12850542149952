import {
  EmptyContent,
  IconConnectionError,
  IconLogo,
  IconSizes
} from '@viz/design-system';
import { localize } from '@viz/i18n';
import { capitalize } from '@viz/utils';

import { useAppTheme, useRouter } from '../../hooks';
import { Paths } from '../../navigation';
import { ConnectionErrorContainer, IconLogoContainer } from './styles';

const SsoConnectionError = (): JSX.Element => {
  const router = useRouter();
  const { theme } = useAppTheme();

  return (
    <ConnectionErrorContainer>
      <EmptyContent
        title={capitalize(localize('connectionError'))}
        subTitle={localize('invalidSsoCode')}
        icon={<IconConnectionError />}
        buttonDetails={{
          testId: 'try-again-button',
          text: capitalize(localize('tryAgain')),
          onClick: () => router.navigate({ path: Paths.LOGIN })
        }}
      />
      <IconLogoContainer>
        {/* @ts-ignore */}
        <IconLogo fill={theme.baseColors['blue']} size={IconSizes.X_MEDIUM} />
      </IconLogoContainer>
    </ConnectionErrorContainer>
  );
};

export default SsoConnectionError;
