import { UserBasePayload, useCustomerAccounts } from '@viz/api';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  MultipleSelection
} from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { FormLabel } from '../../../../../components';
import {
  FieldSectionInput,
  AccountInputContainer,
  LabelContainer
} from '../../styles';

interface MainCustomerAccountInputInterface {
  form: UseFormReturn<UserBasePayload>;
  className?: string;
}

const MainCustomerAccountInput = ({
  form: { control, setValue },
  className
}: MainCustomerAccountInputInterface) => {
  const { data: customerAccountList, isLoading } = useCustomerAccounts();

  const formattedCustomerAccountsList = useMemo(
    () =>
      customerAccountList?.map((customerAccount) => ({
        label: customerAccount.name,
        value: customerAccount.id
      })),
    [customerAccountList]
  );

  if (isLoading) {
    // Note: added to avoid UI flickering
    return (
      <AccountInputContainer>
        <LabelContainer>
          <FormLabel translationKey="mainCustomerAccount" required />
        </LabelContainer>
        <Autocomplete
          renderInput={(params: AutocompleteRenderInputParams) => (
            <FieldSectionInput {...params} />
          )}
        />
      </AccountInputContainer>
    );
  }

  return (
    <Controller
      name="mainCustomerAccount"
      control={control}
      rules={{
        required: {
          value: true,
          message: localize('mainAccountRequired')
        }
      }}
      render={({
        field: mainCustomerAccountsField,
        fieldState: mainCustomerAccountsFieldState
      }) => (
        <Controller
          name="customerAccounts"
          control={control}
          rules={{
            required: {
              value: true,
              message: localize('customerAccountsRequired')
            }
          }}
          render={({
            field: customerAccountsField,
            fieldState: customerAccountsFieldState
          }) => (
            <MultipleSelection
              label={<FormLabel translationKey="customerAccounts" required />}
              placeholder={localize('multipleSelectionPlaceholder')}
              selectedOptions={customerAccountsField.value ?? []}
              onChange={(value: string[]) => {
                if (
                  mainCustomerAccountsField.value &&
                  !value.includes(mainCustomerAccountsField.value)
                ) {
                  setValue('mainCustomerAccount', '');
                }
                return customerAccountsField.onChange(value);
              }}
              options={formattedCustomerAccountsList ?? []}
              selectionStatusOptions={{
                content: `${customerAccountsField.value?.length} Selected`,
                onClear: () => setValue('customerAccounts', [])
              }}
              helperText={
                customerAccountsFieldState.error?.message ||
                mainCustomerAccountsFieldState.error?.message
              }
              showSelectedOnTop={true}
              className={className}
              tagOptions={{
                setText: localize('selectedMainAccount'),
                unsetText: localize('selectMainAccount'),
                setOption: mainCustomerAccountsField.value,
                handleSetTagStatus: mainCustomerAccountsField.onChange
              }}
            />
          )}
        />
      )}
    />
  );
};

export default MainCustomerAccountInput;
