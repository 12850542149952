import { getUserByEmail, isVizUserExist, User } from '@viz/api';
import { localize } from '@viz/i18n';
import { isValidEmailAddress } from '@viz/utils';

export const emailAlreadyExists =
  (setExistingUser: (user?: User) => void) => async (value?: string) => {
    if (!value) {
      setExistingUser(undefined);
      return;
    }

    const user = await getUserByEmail(value);

    if (user) {
      setExistingUser(user);
      return localize('existingEmail');
    }

    setExistingUser(undefined);
  };

export const vizEmailAlreadyExist =
  (setVizExistEmail: (email?: string) => void) => async (value?: string) => {
    if (!value) {
      setVizExistEmail(undefined);
      return;
    }

    const existUser = await isVizUserExist(value);
    if (existUser.exists) {
      setVizExistEmail(value);
      return localize('existingEmail');
    }

    setVizExistEmail(undefined);
  };

export const validEmail = (value?: string) =>
  (value && isValidEmailAddress(value)) || localize('invalidEmail');
