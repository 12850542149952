import type { ClientConfigTabStatus } from '@viz/api';
import { ClientConfigTabFilter } from '@viz/api';
import { IconListFilter } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { PatientsFilter } from './PatientsFilter/PatientsFilter';
import { StatusFilter } from './StatusFilter';
import {
  StyledClearButton,
  StyledCounter,
  StyledFiltersSectionContainer,
  StyledSectionTitle
} from './styles';
import { Filters, FilterSelection } from './types';

interface FiltersSectionProps {
  onChangeStatusFilter: (newStatus: any) => void;
  onChangeFilters: (filters: Filters, selection?: FilterSelection) => void;
  selectedStatus?: number;
  statuses: Array<ClientConfigTabStatus>;
  filtersConfig: Array<ClientConfigTabFilter>;
  selectedFilters: Filters;
  totalPatients: number | undefined;
  showStatuses: boolean;
}

const FiltersSection = ({
  onChangeStatusFilter,
  onChangeFilters,
  selectedStatus,
  statuses,
  showStatuses,
  filtersConfig,
  selectedFilters,

  totalPatients
}: FiltersSectionProps) => {
  const showTotalPatients = totalPatients !== undefined && totalPatients > -1;

  const showClearButton = false; //disabled for now.
  //const showClearButton = Boolean(
  //   selectedFilters.some((filter) => filter.option)
  // );

  const onChangeFilter = (selection: FilterSelection) => {
    const updatedFilters = selectedFilters.filter(
      (filter) => filter.name !== selection.name
    ); //remove the existing filter, if present
    updatedFilters.push(selection); //replace wth new version of the filter

    onChangeFilters(updatedFilters, selection);
  };

  const handleClear = () => {
    onChangeFilters([]);
    onChangeStatusFilter(undefined);
  };

  // @ts-ignore
  return (
    <StyledFiltersSectionContainer data-testid="filters-section">
      <StyledSectionTitle>
        <IconListFilter />
        <span>{localize('filters')}</span>
      </StyledSectionTitle>
      {showStatuses && (
        <StatusFilter
          onChangeStatusFilter={onChangeStatusFilter}
          selected={selectedStatus}
          statuses={statuses}
        />
      )}
      {filtersConfig.map(
        (
          filterConfig: ClientConfigTabFilter // Add type annotation for filter
        ) => {
          const filterValue =
            selectedFilters.find((filter) => filterConfig.name === filter.name)
              ?.option || undefined;
          return (
            <PatientsFilter
              key={filterConfig.name}
              filterConfig={filterConfig}
              filterValue={filterValue}
              onChange={onChangeFilter}
            />
          );
        }
      )}
      {showClearButton && (
        <StyledClearButton onClick={handleClear}>{'Clear'}</StyledClearButton>
      )}
      {showTotalPatients && (
        <StyledCounter>{totalPatients} Patients</StyledCounter>
      )}
    </StyledFiltersSectionContainer>
  );
};

export { FiltersSection };
