import {
  getBaseColor,
  getBorderRadius,
  getSize,
  getSpacing,
  IconButton,
  IconEdit,
  Input
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledPhoneContainer = styled.div`
  display: flex;
  gap: ${getSpacing(2)};
  align-items: center;
  margin-top: ${getSpacing(1)};
`;

export const StyledPhoneInput = styled(Input)`
  .MuiInputBase-root {
    padding: ${getSpacing(1)};
    padding-right: ${getSpacing(1.5)};
    width: ${getSize(60)};
  }

  .MuiInputBase-input {
    padding: ${getSpacing(1)};
  }
`;

export const StyledEditIcon = styled(IconEdit)`
  pointer-events: none;
`;

export const StyledCheckMarkIcon = styled(IconButton)`
  && {
    padding: ${getSize(1.5)};
    border-radius: ${getBorderRadius('SMALL')};
    background-color: ${getBaseColor('blue')};

    :hover {
      background-color: ${getBaseColor('blue-main')};
    }
  }
`;
