import {
  getBaseColor,
  getSize,
  getSpacing,
  IconButton
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledIconButton = styled(IconButton)`
  && {
    padding: 0;

    svg path {
      fill: ${getBaseColor('french-gray')};
    }

    :hover {
      background-color: transparent;

      svg path {
        fill: ${getBaseColor('porcelain')};
      }
    }
  }
`;
export const StyledPatientIconsContainer = styled.div`
  display: flex;
  gap: ${getSpacing(2)};
  padding-top: ${getSpacing(1)};
`;

export const StyledIconContainer = styled.div`
  line-height: ${getSize(4)};
`;
