import { getBaseColor } from '@viz/design-system';
import styled from 'styled-components';

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const FieldContainer = styled.div`
  margin-left: 9px;
  border-bottom: 1px solid ${getBaseColor('waterloo')};
  padding-bottom: 8px;
`;
