import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type UnsupportedViewportOverlayType = {
  isOverlayEnabled: boolean;
  setIsOverlayEnabled: Dispatch<SetStateAction<boolean>>;
};

export const UnsupportedViewportOverlayContext =
  createContext<UnsupportedViewportOverlayType | null>(null);

export const useUnsupportedViewportOverlay = () => {
  const context = useContext(UnsupportedViewportOverlayContext);

  if (!context) {
    throw new Error(
      'useUnsupportedViewportOverlay has to be used within <UnsupportedViewportOverlayContext.Provider>'
    );
  }

  return context;
};
