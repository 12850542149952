import { FormControl } from '@material-ui/core';
import { ClientConfigTabStatus } from '@viz/api';
import { Select, SelectMenuItem } from '@viz/design-system/src/ui/Select';

interface PatientStatusProps {
  statuses: ClientConfigTabStatus[];
  selectedStatus?: string;
  onChange: (status: ClientConfigTabStatus) => void;
  testId?: string;
}

export const PatientStatus = (props: PatientStatusProps) => {
  const { statuses, selectedStatus } = props;

  const onChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newStatus = statuses.find((s) => s.value === event.target.value);

    if (newStatus) {
      props.onChange(newStatus);
    }
  };

  return (
    <FormControl>
      <Select
        onChange={onChangeStatus}
        value={selectedStatus}
        dataTestId={props.testId}
      >
        {statuses.map((status) => (
          <SelectMenuItem key={status.id} value={status.value}>
            {status.value}
          </SelectMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
