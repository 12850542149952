/* eslint-disable react-hooks/exhaustive-deps */
import { useNotificationMessageData } from '@viz/api';
import { useEffect } from 'react';

import { VizLoading } from '../../components';
import { useRouter } from '../../hooks';
import { Paths } from '../paths';

export const NaviagteToPatientDataByNotification = (props: {
  notificationId: string;
}) => {
  const router = useRouter();
  const { notificationId } = props;
  const getPatientDataByNotification = useNotificationMessageData();

  useEffect(() => {
    getPatientDataByNotification.mutate(
      {
        notification_uid: notificationId
      },
      {
        onSuccess: (data) => {
          router.navigate({
            path: Paths.PATIENT_DATA,
            queryParams: { id: data.viz_patient_id }
          });
        }
      }
    );
  }, []);

  return <VizLoading />;
};
