import { Grid, getSpacing } from '@viz/design-system';
import styled, { css } from 'styled-components';

interface StyledVizWidget {
  $vizWidget: boolean;
  [x: string]: any;
}

export const StyledGridContainer = styled(Grid)<StyledVizWidget>`
  ${(props) =>
    props.$vizWidget &&
    css`
      &.MuiGrid-container {
        flex-wrap: nowrap;
        overflow: hidden;
        & > .MuiGrid-grid-xs-6 {
          &:first-child {
            flex-basis: auto;
          }
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    `}
`;
export const StyledGrid = styled(Grid)`
  height: 100%;
  padding: ${getSpacing(4)} ${getSpacing(4)} 0 ${getSpacing(4)};
`;

export const StyledPatientFolderGrid = styled(Grid)`
  height: 100%;
  padding-top: ${getSpacing(4)};
  overflow: hidden;
`;
