import {
  useUpdateProfileSendMfaCode,
  useUpdateProfileVerifyMfaCode
} from '@viz/api';
import { localize, TranslationKey } from '@viz/i18n';
import { getDeviceUid } from '@viz/utils';
import { useEffect, useState } from 'react';

import { useUserProfile } from '../../../../hooks';
import { AuthenticatePhone } from './AuthenticatePhone';
import { VerifyPhone } from './VerifyPhone';
import { StyledVerifyPhoneNumberDialog } from './styles';

type VerifyPhoneNumberDialogProps = {
  number: string;
  isOpen: boolean;
  onUpdate: () => void;
  onClose: () => void;
};

type AuthenticationStep = {
  title: string;
  buttonText: string;
  component: typeof VerifyPhone | typeof AuthenticatePhone;
};

const verificationSteps = {
  verification: {
    title: 'Verify your number',
    buttonText: 'Yes, Send Code',
    component: VerifyPhone
  },
  authentication: {
    title: 'Authenticate your mobile phone',
    buttonText: 'Done',
    component: AuthenticatePhone
  }
} as const;

const VerifyPhoneNumberDialog = ({
  number,
  isOpen,
  onUpdate,
  onClose
}: VerifyPhoneNumberDialogProps): JSX.Element => {
  const [verificationStep, setVerificationStep] = useState<AuthenticationStep>(
    verificationSteps.verification
  );
  const [code, setCode] = useState('');
  const [isError, setIsError] = useState(false);
  const { user } = useUserProfile();
  const deviceUid = getDeviceUid();

  const { mutate: sendMfaCode } = useUpdateProfileSendMfaCode();
  const { mutate: verifyMfaCode } = useUpdateProfileVerifyMfaCode({
    onSuccess: (data) => {
      if (data.success === 'true') {
        onUpdate();
        onClose();
      } else {
        setIsError(true);
      }
    }
  });

  useEffect(() => {
    if (isOpen) {
      setVerificationStep(verificationSteps.verification);
      setIsError(false);
      setCode('');
    }
  }, [isOpen]);

  const handleNextStep = () => {
    if (verificationStep === verificationSteps.verification) {
      setVerificationStep(verificationSteps.authentication);
      sendCode();
    } else if (verificationStep === verificationSteps.authentication) {
      verifyCode();
    }
  };

  const sendCode = () => {
    if (user) {
      sendMfaCode({
        username: user?.username,
        device_token: deviceUid,
        phone_number: number
      });
    }
  };

  const verifyCode = () => {
    if (user) {
      verifyMfaCode({
        code,
        username: user?.username,
        device_uid: deviceUid
      });
    }
  };

  const onCodeChange = (code: string) => {
    setCode(code);
    setIsError(false);
  };

  const StepComponent = verificationStep.component;

  return (
    <StyledVerifyPhoneNumberDialog
      withCloseIcon
      open={isOpen}
      onClose={onClose}
      title={localize(verificationStep.title as TranslationKey)}
      primaryButtonProps={{
        content: localize(verificationStep.buttonText as TranslationKey),
        disabled:
          verificationStep === verificationSteps.authentication &&
          (code.length < 6 || isError),
        onClick: handleNextStep
      }}
      secondaryButtonProps={{
        content: localize('cancel'),
        onClick: onClose
      }}
    >
      <StepComponent
        number={number}
        code={code}
        isError={isError}
        onCodeChange={onCodeChange}
        onVerifyMfaCode={verifyCode}
        onResendMfaCode={sendCode}
      />
    </StyledVerifyPhoneNumberDialog>
  );
};

export { VerifyPhoneNumberDialog };
