import { Lead } from '@viz/api/src/ecg';
import React from 'react';

import { plotECGPoints } from '../utils';

export type LeadParams = {
  lead: Lead;
  includeSeparator: boolean;
  scale: { x: number; y: number };
};

const LeadLabel = ({
  title,
  x,
  y
}: {
  title: string;
  x: number;
  y: number;
}) => (
  <>
    <text
      x={x}
      y={y}
      fill="#138bfb"
      fontSize={20}
      textAnchor="start"
      fontWeight="bold"
    >
      {title}
    </text>
  </>
);

const leadColor = '#808080';
const leadWidth = 1.5;
const separatorColor = '#138bfb';
const separatorWidth = 3;

const ECGLead = ({ lead, includeSeparator, scale }: LeadParams) => {
  const points = plotECGPoints(lead.voltages, scale);
  const [startX] = points[0]; // Extracting coordinates of the first point

  return (
    <>
      <polyline
        fill="none"
        stroke={leadColor}
        strokeWidth={leadWidth}
        points={points.map((point) => point.join(',')).join(' ')}
      />
      {includeSeparator && (
        <line
          stroke={separatorColor}
          strokeWidth={separatorWidth}
          x1={points[0][0]}
          y1={-30}
          x2={points[0][0]}
          y2={30}
        />
      )}
      <LeadLabel title={lead.channel_display_name} x={startX + 10} y={30} />
    </>
  );
};

export default ECGLead;
