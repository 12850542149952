import { NotificationSettingAction } from '@viz/api';
import { Link } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useState } from 'react';

import { NotificationActionDialog } from './NotificationActionDialog';
import {
  StyledActionContainer,
  StyledActionLabel,
  StyledActionValue,
  StyledNotificationActions
} from './styles';

type NotificationActionsProps = {
  notificationSettingName: string;
  actions: NotificationSettingAction[];
  onChange: (criteriaId: string, value: boolean | number) => void;
};

const NotificationActions = ({
  notificationSettingName,
  actions,
  onChange
}: NotificationActionsProps): JSX.Element => {
  const [activeActionDialog, setActiveActionDialog] =
    useState<NotificationSettingAction | null>(null);

  return (
    <>
      <StyledNotificationActions>
        {actions.map((action) => {
          return (
            <StyledActionContainer>
              <StyledActionLabel>{action.label}:</StyledActionLabel>
              {action.actionType === 'bool' ? (
                <StyledActionValue>
                  {localize(action.currentValue ? 'yes' : 'no')}
                </StyledActionValue>
              ) : (
                <StyledActionValue>{action.currentValue}</StyledActionValue>
              )}
              <Link
                color="secondary"
                underline="always"
                onClick={() => setActiveActionDialog(action)}
              >
                {localize('change')}
              </Link>
            </StyledActionContainer>
          );
        })}
      </StyledNotificationActions>
      {activeActionDialog && (
        <NotificationActionDialog
          title={`${localize('edit')} ${notificationSettingName}`}
          action={activeActionDialog}
          onClose={() => setActiveActionDialog(null)}
          onSave={(value) => {
            onChange(activeActionDialog.criteriaId, value);
          }}
        />
      )}
    </>
  );
};

export { NotificationActions };
