import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GlobalStyle, Theme } from '@viz/design-system';
import { EventBusProvider } from '@viz/events';
import { History } from 'history';
import { BrowserView, MobileView } from 'react-device-detect';

import { MobileMessage } from '../components';
import { eventbus } from '../events';
import { useAppTheme } from '../hooks';
import { App } from './Application';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'offlineFirst'
    },
    mutations: {
      networkMode: 'offlineFirst'
    }
  }
});

interface RootProps {
  history: History;
}

export const Root = ({ history }: RootProps): JSX.Element => {
  const { theme } = useAppTheme();

  return (
    <Theme.ThemeProvider theme={theme}>
      <GlobalStyle />
      <MobileView renderWithFragment>
        <MobileMessage />
      </MobileView>
      <BrowserView renderWithFragment>
        <QueryClientProvider client={queryClient}>
          <EventBusProvider eventbus={eventbus}>
            <App history={history} />
            <ReactQueryDevtools initialIsOpen={false} />
          </EventBusProvider>
        </QueryClientProvider>
      </BrowserView>
    </Theme.ThemeProvider>
  );
};
