import { EllipsisTextTooltip } from '@viz/design-system';
import { localize } from '@viz/i18n';
import React from 'react';

import { StyledSubHeader } from './styles';

export interface SubHeaderProps {
  data: {
    [x: string]: any;
  };
}
export const SubHeader = ({ data }: SubHeaderProps) => {
  const formatPatientInfoOrUseDefault = (item?: string) => {
    return item ?? localize('notAvailableShortcut');
  };
  const keys = Object.keys(data);
  const values = Object.values(data);
  return (
    <EllipsisTextTooltip>
      <StyledSubHeader>
        {values.map((item, index) => (
          <span data-testid={keys[index]} key={keys[index]}>
            {formatPatientInfoOrUseDefault(item)}
          </span>
        ))}
      </StyledSubHeader>
    </EllipsisTextTooltip>
  );
};
