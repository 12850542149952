import { useEffect } from 'react';

import { useAnalyticsEvent } from '../useAnalytics';
import { DesktopAppEvents } from './consts';
import { isCustomEvent } from './utils/customEvents';

export const useWidgetAnalyticsEvent = () => {
  const { sendEvent } = useAnalyticsEvent();

  useEffect(() => {
    const handleSendAnalyticsEvent = (e: CustomEvent) => {
      sendEvent(e.detail.eventName, e.detail.eventData);
    };

    window.addEventListener(
      DesktopAppEvents.SendWidgetAnalytics,
      (event) => isCustomEvent(event) && handleSendAnalyticsEvent(event)
    );
    return () => {
      window.removeEventListener(
        DesktopAppEvents.SendWidgetAnalytics,
        (event) => isCustomEvent(event) && handleSendAnalyticsEvent(event)
      );
    };
  }, [sendEvent]);
};
