import { EmptyContent } from '@viz/design-system';
import styled from 'styled-components';

export const StyledExternalPatientContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const StyledPatientNotFound = styled(EmptyContent)`
  margin: auto;
`;
