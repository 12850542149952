import { UserInstitution } from '@viz/api';
import { Tooltip, EllipsisTextTooltip } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { StyledInstitutionContainer, StyledBadge } from './styles';

interface CollapsedInstitutionProps {
  institutions: UserInstitution[];
  testId?: string;
  tooltipTestId?: string;
}

const renderTooltipText = (institutions: UserInstitution[]) =>
  institutions.map((institution) => (
    <div key={institution.id}>{institution.name}</div>
  ));

const CollapsedInstitutions = ({
  institutions,
  testId,
  tooltipTestId
}: CollapsedInstitutionProps) => {
  return (
    <StyledInstitutionContainer data-testid={testId}>
      <EllipsisTextTooltip>
        {institutions.length > 0 ? institutions[0].name : localize('none')}
      </EllipsisTextTooltip>
      {institutions.length > 1 && (
        <Tooltip
          placement="top-start"
          title={<div>{renderTooltipText(institutions.slice(1))}</div>}
          testId={tooltipTestId}
        >
          <StyledBadge>+{institutions.length - 1}</StyledBadge>
        </Tooltip>
      )}
    </StyledInstitutionContainer>
  );
};

export default CollapsedInstitutions;
