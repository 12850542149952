import { localize } from '@viz/i18n';

import {
  StyledDialogContent,
  StyledRestoreNotificationSettingsDialog,
  StyledSetting,
  StyledSubtitle,
  StyledTitle
} from './styles';

type RestoreNotificationSettingsDialogProps = {
  isOpen: boolean;
  onRestore: () => void;
  onClose: () => void;
};

// Use this when the default settings will be received from the backend
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RestoreNotificationDialogContent = () => {
  const restoredSettings = [
    { title: localize('LVOSuspected') },
    { title: localize('CTPProcessed'), subtitle: 'Threshold (0)' },
    { title: localize('ICHSuspected') },
    { title: localize('pagerAlert') }
  ];

  return (
    <>
      <div>The following notifications will be enabled:</div>
      {restoredSettings.map(({ title, subtitle }) => {
        return (
          <StyledSetting key={title}>
            <StyledTitle key={title}>• {title}</StyledTitle>
            {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
          </StyledSetting>
        );
      })}
      <div>{localize('restoreSettingsQuestion')}</div>
    </>
  );
};

const RestoreNotificationSettingsDialog = ({
  isOpen,
  onRestore,
  onClose
}: RestoreNotificationSettingsDialogProps): JSX.Element => {
  return (
    <StyledRestoreNotificationSettingsDialog
      withCloseIcon
      open={isOpen}
      onClose={onClose}
      title={localize('restoreDefault')}
      primaryButtonProps={{
        content: localize('restore'),
        onClick: () => {
          onClose();
          onRestore();
        }
      }}
      secondaryButtonProps={{
        content: localize('cancel'),
        onClick: onClose
      }}
    >
      <StyledDialogContent>
        <div>{localize('restoreSettingsQuestion')}</div>
      </StyledDialogContent>
    </StyledRestoreNotificationSettingsDialog>
  );
};

export { RestoreNotificationSettingsDialog };
