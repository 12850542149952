import {
  Button,
  Dialog,
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSize,
  getSpacing,
  screens
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledWebglDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 90vw;
    max-height: 95vh;
    height: ${getSize(180)};
    width: ${getSize(240)};
    ${getFontSize('base')};

    @media ${screens.medium} {
      height: ${getSize(165)};
      width: ${getSize(220)};
    }
  }

  .MuiDialogContent-root {
    overflow: visible;
    padding: ${getSpacing(2)} ${getSpacing(2)} 0;
  }
`;

export const StyledDialogContent = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
`;

export const StyledWebglContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${getBaseColor('porcelain')};
  padding: ${getSpacing(4)};
  overflow-y: auto;
  position: relative;
`;

export const StyledWebglHeader = styled.div`
  ${getFontSize('lg')};
  font-weight: ${getFontWeight('font-weight-bold-600')};
  padding-bottom: ${getSpacing(4)};
`;

export const StyledOrderedList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const StyledDialogStep = styled.li`
  margin: 0 0 ${getSpacing(8)} 0;
`;

export const StyledDialogStepTitle = styled.span`
  color: ${getBaseColor('blue')};
  font-weight: ${getFontWeight('font-weight-bold-600')};
  ${getFontSize('lg')};
`;

export const StyledDialogStepDescription = styled.p`
  margin: 0;
`;

export const StyledDialogStepImage = styled.img`
  margin: ${getSpacing(4)};
  vertical-align: top;
`;

export const IconArrowRightContainer = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: ${getSize(11.25)};
  margin: ${getSpacing(4)};
`;

export const StyledCopyButton = styled(Button)`
  &&& {
    margin-left: ${getSpacing(4)};
    width: ${getSize(25)};
  }
`;
