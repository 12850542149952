import { TranslationKey, localize } from '@viz/i18n';

interface CoolDownErrorProps {
  accountLockedKey: TranslationKey;
  accountLockedTryAgainKey: TranslationKey;
  minutes?: number;
}

export const CoolDownError = (props: CoolDownErrorProps) => {
  const { accountLockedKey, accountLockedTryAgainKey, minutes } = props;

  const getCoolDownMinutes = (minutes?: number) =>
    minutes ? Math.ceil(minutes) : localize('few');

  return (
    <>
      <div>{localize(accountLockedKey)}</div>
      <div>
        {localize(accountLockedTryAgainKey, getCoolDownMinutes(minutes))}
      </div>
    </>
  );
};
