import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getSize,
  IconClose
} from '@viz/design-system';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageContainer = styled.div`
  margin-top: ${getSize(16)};
  display: flex;
  width: 100%;
  flex-grow: 1;
  min-height: 0;
`;

export const StyledToastContainer = styled(ToastContainer)`
  &.Toastify__toast-container--top-center {
    top: 5rem;
    display: flex;
    justify-content: center;
  }
  & .Toastify__toast {
    border-radius: ${getBorderRadius('SMALL')};
    width: max-content;
    padding: ${getSize(2)} ${getSize(4)};
    min-height: 20px;
  }

  & .Toastify__toast-body {
    padding: 0;
  }

  & .Toastify__toast--info {
    background-color: ${getBaseColor('blue')};
  }

  & .Toastify__toast--error {
    background-color: ${getBaseColor('brick-red')};
  }

  & .Toastify__toast--success {
    background-color: ${getBaseColor('blue')};
  }
`;

export const StyledToast = styled.div`
  color: ${getBaseColor('porcelain')};
  ${getFontSize('base')};
  text-align: center;
`;

export const StyledIconClose = styled(IconClose)`
  align-self: center;
  margin-left: ${getSize(5)};

  path {
    fill: ${getBaseColor('porcelain')};
    fill-opacity: 1;
  }
`;
