// DO NOT shorten the import as it causes a circular dependency error
import { useAuth } from '../../hooks/useAuth';

const withAuth = <P extends object>(InnerComponent: React.FC<P>) => {
  return (props: P) => {
    const { isAuth } = useAuth();
    if (!isAuth) {
      return null;
    }

    return <InnerComponent {...props} />;
  };
};

export { withAuth };
