import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { SsoConnectionError, VizLoading } from '../../components';
import { useAuth, useLoginUser, useRouter } from '../../hooks';
import { Paths } from '../../navigation';
import { loginStepState } from '../../store';
import { PostSSOContainer } from './styles';

const PostSSO = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const { isAuth } = useAuth();
  const loginStep = useRecoilValue(loginStepState);
  const router = useRouter();

  const { verifySsoCode, authenticationError } = useLoginUser();

  useEffect(() => {
    if (!window.vizWidget) {
      const code = router.getUrlParam('code', false) as string;
      verifySsoCode(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authenticationError) {
      setIsLoading(false);
    }
  }, [authenticationError]);

  useEffect(() => {
    if (isAuth && loginStep === 'policies') {
      router.navigate({ path: Paths.LOGIN });
    }
  }, [isAuth, loginStep, router]);

  return (
    <PostSSOContainer>
      {isLoading ? <VizLoading /> : <SsoConnectionError />}
    </PostSSOContainer>
  );
};

export default PostSSO;
