import { ServerPatient } from '@viz/api';
import { IconSizes } from '@viz/design-system';

import { IconContainer } from '../../../../IconContainer';
import { StyledSection, StyledSectionLabel } from './styles';

export const PatientEhrSectionHeader = (
  props: Pick<ServerPatient.EhrDataResponse, 'section_name' | 'section_icon'>
) => {
  const { section_name, section_icon } = props;

  return (
    <StyledSection data-testid={`ehr-info-section-${section_name}`}>
      <StyledSectionLabel>
        <IconContainer
          data-testid={`ehr-info-section-${section_name}-icon`}
          iconUrl={section_icon}
          size={IconSizes.SMALL}
        />
        {section_name && (
          <h3 data-testid={`ehr-info-section-${section_name}-title`}>
            {section_name}
          </h3>
        )}
      </StyledSectionLabel>
    </StyledSection>
  );
};
