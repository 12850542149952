import { DialogProps } from '@viz/design-system';
import { localize, Trans } from '@viz/i18n';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { usePatientTabs } from '../../hooks';
import { patientTabDialogState } from '../../store';
import { StyledOpenNewTabDialog } from './styles';

const PatientTabDialog = (): JSX.Element => {
  const { selectTab, closePatientTab } = usePatientTabs();
  const [patientTabDialogData, setPatientTabDialogData] = useRecoilState(
    patientTabDialogState
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!patientTabDialogData) return;

    const { tabUrlData } = patientTabDialogData;

    const canOpenTab = selectTab({ tabUrlData });

    if (canOpenTab) {
      setPatientTabDialogData(null);
    } else {
      setIsDialogOpen(true);
    }
  }, [patientTabDialogData, selectTab, setPatientTabDialogData]);

  const closePatientTabDialog = (): void => {
    setIsDialogOpen(false);
    setPatientTabDialogData(null);
  };

  const openNewStudyTab = async (): Promise<void> => {
    closePatientTabDialog();
    closePatientTab(undefined, patientTabDialogData?.tabUrlData);
  };

  const primaryButtonProps: DialogProps['primaryButtonProps'] = {
    content: localize('openStudy'),
    onClick: openNewStudyTab
  };

  const secondaryButtonProps: DialogProps['secondaryButtonProps'] = {
    content: localize('cancel'),
    onClick: closePatientTabDialog
  };

  return (
    <StyledOpenNewTabDialog
      open={isDialogOpen}
      onClose={closePatientTabDialog}
      title={localize('closeCurrentStudy')}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
      disableBackdropClick
      withCloseIcon
    >
      <p>{localize('openNewStudyDesc1')}</p>
      {patientTabDialogData?.patientName ? (
        <Trans
          i18nKey="openNewStudyDesc2"
          values={{ patientName: patientTabDialogData?.patientName }}
          components={{ bold: <b /> }}
        />
      ) : (
        <span>{localize('openNewStudyAreYouSure')}</span>
      )}
    </StyledOpenNewTabDialog>
  );
};

export { PatientTabDialog };
