import { Switch } from '@viz/design-system';

type NotificationSettingCheckboxProps = {
  state: boolean;
  onChange: (value: boolean) => void;
};

const NotificationSettingSwitch = ({
  state,
  onChange
}: NotificationSettingCheckboxProps) => (
  <Switch
    testId="notification-setting-switch"
    checked={state}
    onChange={(e) => onChange(e.target.checked)}
  />
);

export { NotificationSettingSwitch };
