import { useQueryClient } from '@tanstack/react-query';
import {
  HERMES_TRIAL_TAB_ID,
  PatientFilters,
  PatientListPatient,
  QueryKeys,
  TabSortingState,
  usePatients,
  useUpdatePatientsListPatient,
  useUpdatePatientsStatus,
  UpdatePatientsStatus
} from '@viz/api';
import {
  EmptyContent,
  IconNoSearchResults,
  IconMoveToFolder,
  IconSizes
} from '@viz/design-system';
import { PushNotificationType, VizFirebaseNotification } from '@viz/hooks';
import { localize } from '@viz/i18n';
import { MONITORING_ACTION_ATTRIBUTE } from '@viz/monitoring';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil';

import { BulkActions } from '../../components/BulkActions';
import { AnalyticsEventName, useAnalyticsEvent } from '../../hooks';
import { ChatNotification } from '../../hooks/useChatConversation/types';
import { useNotificationSubscriber } from '../../hooks/useNotificationSubscriber';
import { browserStorage, Subscriber } from '../../managers';
import {
  isPatientFolderVisibleState,
  patientFolderSelectedTabStateFamily,
  PatientFolderTabs,
  patientTabDialogState,
  selectedDistributionListTabIdState,
  selectedPatientIdState,
  selectedPatientStatusInfoState,
  toastStatusState
} from '../../store';
import { FiltersSection } from './FiltersSection';
import { Filters, FilterSelection } from './FiltersSection/types';
import { PatientsCards } from './PatientsCards';
import { SortingSection } from './SortingSection';
import { getDefaultHeaders, getHermesTrialHeaders } from './headers';
import {
  StyledPatientsHeaderContainer,
  StyledTable,
  StyledTableAnimation
} from './styles';
import { PatientsTableProps, PatientTableRowData } from './types';
import {
  convertPatientsToRows,
  formatFiltersForAnalytics,
  getDefaultSortingOption,
  getSortingOptions,
  getSortValueFromId,
  getStatuses,
  getStatusFromRowData,
  getTabHeader,
  getTableHeaders
} from './utils';

const PatientsTable = ({
  query,
  urlStatusId
}: PatientsTableProps): JSX.Element => {
  const [checkedPatients, setCheckedPatients] = useState<string[] | []>([]);

  const { mutate: updatePatientsStatus } = useUpdatePatientsStatus();
  const setToastStatusState = useSetRecoilState(toastStatusState);

  const clientConfig = browserStorage.clientConfig.get();
  const selectedDistributionListTabId = useRecoilValue(
    selectedDistributionListTabIdState
  );
  const tabSortingState = browserStorage.tabSortingState.get();

  const { updatePatientDetails } = useUpdatePatientsListPatient();
  const [selectedPatientId, setSelectedPatientId] = useRecoilState(
    selectedPatientIdState
  );
  const setIsPatientFolderVisibleState = useSetRecoilState(
    isPatientFolderVisibleState
  );
  const patientFolderSelectedTab = useRecoilValue(
    patientFolderSelectedTabStateFamily(selectedPatientId)
  );
  const setPatientTabDialogData = useSetRecoilState(patientTabDialogState);

  const [selectedStatusFilter, setSelectedStatusFilter] = useState(
    (browserStorage.statusFilterState.get() || new Map()).get(
      selectedDistributionListTabId
    )
  );

  const [selectedFilters, setSelectedFilters] = useState(
    (browserStorage.filtersState.get() || new Map()).get(
      selectedDistributionListTabId
    ) || []
  );

  const [selectedSortingOption, setSelectedSortingOption] = useState<
    number | undefined
  >(() => getDefaultSortingOption(clientConfig, selectedDistributionListTabId));

  const [selectedPatientStatusInfo] = useRecoilState(
    selectedPatientStatusInfoState
  );

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    refetch: refetchPatients,
    isRefetching
  } = usePatients(
    {
      tab: selectedDistributionListTabId,
      query,
      specStatusFilter: urlStatusId || selectedStatusFilter,
      filters: selectedFilters?.reduce(
        (filters: PatientFilters, filter: FilterSelection) => {
          if (filter.option) {
            filters[filter.name] = [filter.option];
          }
          return filters;
        },
        {}
      ),
      specSortBy: selectedSortingOption
    },
    {
      onWindowFocusRefetch: () => {
        /*
          NOTE: this is a workarount that should be removed once ServerPatient.PatientResponse will contain the selected status
          when working with mutliple tabs we need to keep the selected patient status updated
          thus, if status was changed on tab A we want to ensure the state is updated on tab B
          to do so, we refetch the patients list when the status is changed and onWindowFocus
          additionaly, we want to ensure the selectedPatientStatusInfo matches the updated status
        */
        syncSelectedPatientStatusInfoWithPatients();
      }
    }
  );

  const queryClient = useQueryClient();
  const cancelActiveRefetchingOnFetchMore = () => {
    queryClient.cancelQueries({
      queryKey: [QueryKeys.GET_PATIENTS]
    });
  };

  const infiniteScrollProps = {
    hasNext: Boolean(hasNextPage || isLoading),
    fetchMore: fetchNextPage,
    isFetching: isFetching || isLoading,
    isRefetching,
    cancelActiveRefetchingOnFetchMore
  };

  const patients = useMemo(() => {
    return data
      ? data.pages.reduce<PatientListPatient[]>(
          (patients, page) => [...patients, ...page.patients],
          []
        )
      : [];
  }, [data]);

  // use totalPatients from the latest page
  const totalPatients = useMemo(() => {
    if (!data) return undefined;
    return data.pages[data.pages.length - 1].totalPatients ?? 0;
  }, [data]);

  const tabConfig = useMemo(() => {
    const tabs = clientConfig?.tabs || [];
    return tabs.find(
      (tabConfig) => tabConfig.id === selectedDistributionListTabId
    );
  }, [selectedDistributionListTabId, clientConfig]);

  const headers = useMemo(() => {
    const showStatusColumn = Boolean(tabConfig?.statuses?.length);
    const isHermesTrialHeaders = tabConfig?.id === HERMES_TRIAL_TAB_ID;
    const headers = isHermesTrialHeaders
      ? getHermesTrialHeaders()
      : getDefaultHeaders();
    return getTableHeaders(headers, showStatusColumn);
  }, [tabConfig]);

  const onMessageIconClick = useRecoilCallback(
    ({ set }) =>
      async (patientId: string) => {
        set(
          patientFolderSelectedTabStateFamily(patientId),
          PatientFolderTabs.CHAT
        );
      },
    []
  );

  const tabStatuses = useMemo(
    () => getStatuses(clientConfig, selectedDistributionListTabId),
    [clientConfig, selectedDistributionListTabId]
  );

  const tabSortingOptions = useMemo(
    () => getSortingOptions(clientConfig, selectedDistributionListTabId),
    [clientConfig, selectedDistributionListTabId]
  );

  const rows = useMemo(() => {
    return convertPatientsToRows(
      selectedDistributionListTabId,
      tabStatuses,
      patients,
      onMessageIconClick
    );
  }, [
    onMessageIconClick,
    patients,
    selectedDistributionListTabId,
    tabStatuses
  ]);

  const { sendEvent } = useAnalyticsEvent();
  const shouldSendViewedEvent = useRef(true);
  const clearCheckedPatients = () => setCheckedPatients([]);

  useEffect(() => {
    clearCheckedPatients();
  }, [
    selectedStatusFilter,
    selectedFilters,
    selectedDistributionListTabId,
    refetchPatients
  ]);
  useEffect(() => {
    shouldSendViewedEvent.current = true;

    setSelectedFilters(
      (browserStorage.filtersState.get() || new Map()).get(
        selectedDistributionListTabId
      ) || []
    );
    setSelectedStatusFilter(
      (browserStorage.statusFilterState.get() || new Map()).get(
        selectedDistributionListTabId
      )
    );
    refetchPatients().catch((e) => {});
  }, [selectedDistributionListTabId, refetchPatients]);

  useEffect(() => {
    const tabItem = (tabSortingState || []).find(
      (item) => item.tab_id === selectedDistributionListTabId
    );

    if (tabItem) {
      setSelectedSortingOption(tabItem.selected_sort_id);
    } else {
      setSelectedSortingOption(
        getDefaultSortingOption(clientConfig, selectedDistributionListTabId)
      );
    }
  }, [selectedDistributionListTabId, tabSortingState, clientConfig]);

  useEffect(() => {
    const isSelectedPatientInRows = rows.some(
      (row) => row.id === selectedPatientId
    );
    if (!isSelectedPatientInRows) {
      setIsPatientFolderVisibleState(false);
    }
  }, [rows, selectedPatientId, setIsPatientFolderVisibleState]);

  useEffect(() => {
    if (shouldSendViewedEvent.current && data) {
      sendEvent(AnalyticsEventName.PATIENT_LIST_VIEWED, {
        patient_list_type: getTabHeader(selectedDistributionListTabId),
        has_patients: patients.length > 0,
        filtered_on_categories: formatFiltersForAnalytics(
          selectedFilters,
          tabConfig,
          selectedDistributionListTabId,
          selectedStatusFilter
        ),
        sort_on: getSortValueFromId(
          clientConfig,
          selectedDistributionListTabId,
          selectedSortingOption
        )
      });
      shouldSendViewedEvent.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const syncSelectedPatientStatusInfoWithPatients = () => {
    const selectedPatient = patients?.find((p) => p.id === selectedPatientId);
    if (!selectedPatient) return;

    const selectedPatientStatusId = selectedPatient.patientStatusesDict?.find(
      (s) => s.spec_id === selectedDistributionListTabId
    )?.status;

    if (!selectedPatientStatusId) return;

    const selectedPatientStatus = tabStatuses.find(
      (s) => s.id === selectedPatientStatusId
    )?.value;

    const currentSelectedStatus =
      selectedPatientStatusInfo?.patientSelectedStatus;
    const isStatusChanged = currentSelectedStatus !== selectedPatientStatus;
    if (isStatusChanged) {
      updatePatientListSelectedPatient(
        selectedDistributionListTabId,
        selectedPatientStatus
      );
    }
  };

  const updatePatientListSelectedPatient = useRecoilCallback(
    ({ set }) =>
      (tab?: number, patientStatus?: string) => {
        set(selectedPatientStatusInfoState, {
          selectedTabId: selectedDistributionListTabId,
          patientSelectedStatus: patientStatus,
          onChangePatientStatus: () => {
            refetchPatients();
          },
          patientStatuses: tabStatuses
        });
      },
    [tabStatuses, refetchPatients]
  );

  const handleRowClick = useCallback(
    (patientId: string, patientRowData?: PatientTableRowData): void => {
      setSelectedPatientId(patientId);
      setIsPatientFolderVisibleState(true);
      const patientStatus = getStatusFromRowData(patientRowData);
      updatePatientListSelectedPatient(
        selectedDistributionListTabId,
        patientStatus
      );
      window.vizWidget?.expandWindow();
    },
    [
      setSelectedPatientId,
      setIsPatientFolderVisibleState,
      updatePatientListSelectedPatient,
      selectedDistributionListTabId
    ]
  );

  const onChatNotification: Subscriber<VizFirebaseNotification> = (
    notification
  ) => {
    const { conversation_id: notificationPatientId } =
      notification as ChatNotification;
    const isPatientInList = patients.some(
      (patient) => patient.id === notificationPatientId
    );

    const isChatTabOpenForNotificationPatient =
      selectedPatientId === notificationPatientId &&
      patientFolderSelectedTab === PatientFolderTabs.CHAT;

    if (isPatientInList && !isChatTabOpenForNotificationPatient) {
      updatePatientDetails(notificationPatientId, [
        {
          field: 'comments_count',
          value: (prevVal: number) => prevVal + 1
        },
        {
          field: 'unread_comments_count',
          value: (prevVal: number) => prevVal + 1
        }
      ]);
    }
  };

  useNotificationSubscriber({
    subject: PushNotificationType.MESSAGE,
    onNotification: onChatNotification
  });

  const handleRowDoubleClick = (patientId: string) => {
    const patient = patients.find((patient) => patient.id === patientId);

    if (!patient?.studies.length) return;

    setPatientTabDialogData({
      tabUrlData: { id: patientId },
      patientName: patient.name
    });
  };

  useEffect(() => {
    return () => {
      setSelectedPatientId('');
      setIsPatientFolderVisibleState(false);
    };
    // reset selected patient id when component unmounts (i.e when navigating to all-cases)
  }, [setSelectedPatientId, setIsPatientFolderVisibleState]);

  if (data && query && rows.length === 0) {
    return (
      <EmptyContent
        title={localize('NoSearchResults')}
        icon={<IconNoSearchResults />}
        subTitle={localize('redefineSearch')}
      />
    );
  }

  const onChangeStatusFilter = (status: number | undefined) => {
    if (status) {
      const currentFilterEventProperty = formatFiltersForAnalytics(
        selectedFilters,
        tabConfig,
        selectedDistributionListTabId,
        selectedStatusFilter
      );
      const newFilterEventProperty = formatFiltersForAnalytics(
        selectedFilters,
        tabConfig,
        selectedDistributionListTabId,
        status
      );
      sendEvent(AnalyticsEventName.PATIENT_LIST_FILTERED, {
        current_filter_json: currentFilterEventProperty,
        filter_category: 'patient_status',
        new_filter_json: newFilterEventProperty,
        patient_list_type: getTabHeader(selectedDistributionListTabId)
      });
    }
    setSelectedStatusFilter(status);
    persistStatusFilter(status);
  };

  function onChangeFilters(filters: Filters, selectedFilter?: FilterSelection) {
    const currentFilters = selectedFilters;
    setSelectedFilters(filters);
    persistFilters(filters);

    sendEvent(AnalyticsEventName.PATIENT_LIST_FILTERED, {
      current_filter_json: formatFiltersForAnalytics(
        currentFilters || [],
        tabConfig,
        selectedDistributionListTabId,
        selectedStatusFilter
      ),
      filter_category: selectedFilter?.name ?? 'N/A',
      new_filter_json: formatFiltersForAnalytics(
        filters,
        tabConfig,
        selectedDistributionListTabId,
        selectedStatusFilter
      ),
      patient_list_type: getTabHeader(selectedDistributionListTabId)
    });
  }

  function persistSortingOption(sort_by: number) {
    let newTabSortingState = tabSortingState || [];
    const tabItem = newTabSortingState.find(
      (item) => item.tab_id === selectedDistributionListTabId
    );

    if (tabItem) {
      tabItem.selected_sort_id = sort_by;
    } else {
      newTabSortingState.push({
        tab_id: selectedDistributionListTabId,
        selected_sort_id: sort_by
      } as TabSortingState);
    }
    browserStorage.tabSortingState.set(newTabSortingState);
  }

  function persistFilters(filters: Filters) {
    const newFiltersByTabIdMap = new Map(
      browserStorage.filtersState.get() || new Map()
    );
    newFiltersByTabIdMap.set(selectedDistributionListTabId, filters);
    browserStorage.filtersState.set(newFiltersByTabIdMap);
  }

  function persistStatusFilter(status: number | undefined) {
    const newStatusFilterByTabIdMap = new Map(
      browserStorage.statusFilterState.get() || new Map()
    );
    newStatusFilterByTabIdMap.set(selectedDistributionListTabId, status);
    browserStorage.statusFilterState.set(newStatusFilterByTabIdMap);
  }

  const onChangeSortingOption = (sort_by: number) => {
    sendEvent(AnalyticsEventName.PATIENT_LIST_SORT, {
      current_sort: getSortValueFromId(
        clientConfig,
        selectedDistributionListTabId,
        selectedSortingOption
      ),
      new_sort: getSortValueFromId(
        clientConfig,
        selectedDistributionListTabId,
        sort_by
      ),
      patient_list_type: getTabHeader(selectedDistributionListTabId)
    });

    persistSortingOption(sort_by);
    setSelectedSortingOption(sort_by);
  };

  const showStatusFilter = !urlStatusId && tabStatuses.length > 0;
  const showDynamicFilters = (tabConfig?.filters?.length ?? 0) > 0;
  const showFiltersSection = showStatusFilter || showDynamicFilters;
  const enableMultiSelection = tabStatuses.length > 0;
  const showSortingOptions = tabSortingOptions.length > 0;

  const handelBulkChangeStatus = async (
    payload: Partial<
      UpdatePatientsStatus.ServerRequestPayload & { value: unknown }
    >,
    successMsg: string
  ) => {
    payload.patient_ids?.forEach((patientId) => {
      const current_patient_status = rows.find((row) => row.id === patientId)
        ?.data?.status;
      sendEvent(AnalyticsEventName.PATIENT_STATUS_UPDATED, {
        current_status: current_patient_status,
        new_status: payload.status,
        patient_id: patientId,
        patient_list_type: getTabHeader(selectedDistributionListTabId),
        source_location: 'Patient Table',
        is_bulk_update: true
      });
    });

    const sendPayload = {
      patient_ids: payload.patient_ids,
      status: payload.value,
      spec_id: selectedDistributionListTabId
    } as UpdatePatientsStatus.ServerRequestPayload;
    updatePatientsStatus(sendPayload, {
      onSuccess: async () => {
        sendEvent(AnalyticsEventName.PATIENT_LIST_BULK_UPDATE, {
          patient_count: payload.patient_ids?.length,
          action_type: 'status_update',
          action_value: payload?.value ?? 'N/A'
        });
        setToastStatusState({
          type: 'success',
          text: successMsg
        });
        await refetchPatients();
      },
      onError: (error) => {
        console.error('error', error);
        setToastStatusState({
          type: 'error',
          text: localize('somethingWentWrong')
        });
      }
    });
  };

  const actions = [
    {
      name: 'MOVE',
      onAction: handelBulkChangeStatus,
      actionProps: {
        options: tabStatuses,
        icon: <IconMoveToFolder size={IconSizes.X_SMALL} />,
        label: localize('moveTo')
      },
      dialogContent: {
        title: 'movePatient',
        action: 'move',
        i18Key: 'movePatientDesc',
        successMsgKey: 'successMove'
      }
    }
  ];

  return (
    <>
      <StyledPatientsHeaderContainer>
        {showFiltersSection ? (
          <FiltersSection
            filtersConfig={tabConfig?.filters || []}
            selectedFilters={selectedFilters || []}
            onChangeFilters={onChangeFilters}
            onChangeStatusFilter={onChangeStatusFilter}
            selectedStatus={selectedStatusFilter}
            statuses={tabStatuses}
            showStatuses={showStatusFilter}
            totalPatients={totalPatients}
          />
        ) : (
          <div />
        )}
        {showSortingOptions ? (
          <SortingSection
            onChangeSorting={onChangeSortingOption}
            selected={selectedSortingOption}
            sorting_options={tabSortingOptions}
          />
        ) : (
          <div />
        )}
      </StyledPatientsHeaderContainer>

      {window.vizWidget ? (
        <PatientsCards
          patients={patients}
          infiniteScrollProps={infiniteScrollProps}
          selectedPatientId={selectedPatientId}
          onCardClick={handleRowClick}
          onCardDoubleClick={handleRowDoubleClick}
          onMessageIconClick={onMessageIconClick}
        ></PatientsCards>
      ) : (
        <StyledTableAnimation
          isShown={enableMultiSelection && checkedPatients.length > 0}
        >
          <div>
            {checkedPatients.length > 0 && enableMultiSelection && (
              <BulkActions actions={actions} selectedRows={checkedPatients} />
            )}
          </div>
          <div>
            <StyledTable
              headers={headers}
              rows={rows}
              selectedRowId={selectedPatientId}
              onRowClick={handleRowClick}
              onRowDoubleClick={handleRowDoubleClick}
              stickyHeader
              monitoringActionAttribute={MONITORING_ACTION_ATTRIBUTE}
              infiniteScrollProps={infiniteScrollProps}
              enableSelection={enableMultiSelection}
              selectedRows={checkedPatients}
              onSelectedChanged={(rowsIds) => setCheckedPatients(rowsIds)}
            />
          </div>
        </StyledTableAnimation>
      )}
    </>
  );
};

export default PatientsTable;
