import { ClientConfigTab } from '@viz/api';

import { browserStorage } from '../../managers';
import {
  createMainTab,
  createTabWithSubItems,
  defaultTabs,
  MenuTab
} from './menuTabs';

const processClientConfigTabs = (tabs: ClientConfigTab[]) =>
  tabs.map((tab) =>
    tab.statuses && tab.status_as_menu_item
      ? createTabWithSubItems(tab)
      : createMainTab(tab)
  );

const getMenuRoutes = () => {
  const clientConfig = browserStorage.clientConfig.get();
  if (!clientConfig.tabs) return defaultTabs;

  const clientConfigTabs: MenuTab[] = processClientConfigTabs(
    clientConfig.tabs
  );
  return [...defaultTabs, ...clientConfigTabs];
};

export { getMenuRoutes };
