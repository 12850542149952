import { getBaseColor, getFontSize, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledVerifyPhone = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSize(10)};
  color: ${getBaseColor('porcelain')};
  ${getFontSize('base')};
  margin-bottom: ${getSize(8)};
`;
