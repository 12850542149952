import { IconCheckMark, IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useContext } from 'react';

import { PhoneNumberContext } from '../../ProfileSettings';
import {
  StyledCheckMarkIcon,
  StyledEditIcon,
  StyledPhoneContainer,
  StyledPhoneInput
} from './styles';

type PhoneSettingProps = {
  initialPhone: string;
  onVerifyPhoneNumber: (phoneNumber: string) => void;
};

const PhoneSettingInput = ({
  initialPhone,
  onVerifyPhoneNumber
}: PhoneSettingProps): JSX.Element => {
  const { phoneNumber, setPhoneNumber } = useContext(PhoneNumberContext);
  const isValidNumber = isValidPhoneNumber(phoneNumber);

  return (
    <StyledPhoneContainer>
      <StyledPhoneInput
        value={phoneNumber}
        error={!isValidNumber}
        helperText={!isValidNumber ? localize('insertValidPhoneNumber') : ''}
        onChange={(e) => setPhoneNumber(e.target.value)}
        InputProps={{
          endAdornment: <StyledEditIcon size={IconSizes.MEDIUM} />
        }}
        testId="profile-settings-phone-input"
      />
      {phoneNumber !== initialPhone && isValidNumber && (
        <StyledCheckMarkIcon
          onClick={() => onVerifyPhoneNumber(phoneNumber)}
          testId="phone-input-edit-icon"
        >
          <IconCheckMark size={IconSizes.MEDIUM} />
        </StyledCheckMarkIcon>
      )}
    </StyledPhoneContainer>
  );
};

export { PhoneSettingInput };
