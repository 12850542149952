import {
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledSettingContainer = styled.div`
  display: flex;
  padding: ${getSpacing(3)};
  gap: ${getSpacing(3)};

  :not(:last-child) {
    border-bottom: 1px solid ${getBaseColor('waterloo', 0.2)};
  }
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  margin-top: ${getSpacing(1.5)};
  gap: ${getSpacing(2)};
`;

export const SwitchLabel = styled.div`
  ${getFontSize('base')};
  font-weight: ${getFontWeight('font-weight-bold-600')};
`;
