import { Dialog, getFontSize, getSize } from '@viz/design-system';
import styled from 'styled-components';

const FeedbackDialog = styled(Dialog)`
  & .MuiDialog-paper {
    min-width: ${getSize(110)};
    min-height: ${getSize(60)};
    ${getFontSize('lg')}
  }

  .MuiDialogActions-root button {
    ${getFontSize('lg')};
  }
`;

export default FeedbackDialog;
