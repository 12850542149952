import {
  Alert,
  Button,
  getFontSize,
  getFontWeight,
  getSize,
  getSpacing,
  Grid,
  Typography
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

export const StyledGridBox = styled(Grid)`
  && {
    width: 100%;
    max-width: ${getSize(85)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${getSpacing(8)};
  }
`;

export const StyledTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledBtn = styled(Button)`
  && {
    ${getFontSize('lg')};
    width: 100%;
    font-weight: ${getFontWeight('font-weight-bold-500')};
    padding: ${getSpacing(2)};
  }
`;

export const StyledSubmitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(2)};
`;

export const StyledTitle = styled(Typography)`
  && {
    margin: ${getSpacing(0)} ${getSpacing(0)} ${getSpacing(2)};
    font-weight: ${getFontWeight('font-weight-bold-600')};
    width: 100%;
  }
`;

export const StyledAlert = styled(Alert)<{ isVisible: boolean }>`
  margin: ${getSpacing(2)} 0;
  text-align: start;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
`;

export const StyledResendMfaCodeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
