export const InlineBulletsObjectSummary: React.FC<{
  testidPrefix: string;
  data: Partial<Record<string, string>>;
}> = (props) => {
  const properties = Object.entries(props.data).filter(
    ([_, value]) => value !== undefined
  );

  return (
    <div>
      {properties.map(([key, value], index) => (
        <>
          <span key={key} data-testid={`${props.testidPrefix}${key}`}>
            {value}
          </span>
          <text>{index < properties.length - 1 && ' • '}</text>
        </>
      ))}
    </div>
  );
};
