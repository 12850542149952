import {
  Input,
  getSize,
  getFontWeight,
  getBaseColor
} from '@viz/design-system';
import styled from 'styled-components';

import { InstitutionsInput, UserGroupsInput } from './components';

export const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldSectionTitle = styled.h4`
  margin-bottom: ${getSize(15)};
  font-weight: ${getFontWeight('font-weight-bold-600')};
  color: ${getBaseColor('french-gray')};
`;

export const FieldSectionInputs = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: ${getSize(10)};
  row-gap: ${getSize(16)};
  padding-right: ${getSize(20)};
`;

export const FieldSectionInput = styled(Input)`
  width: ${getSize(55)};
`;

export const LabelContainer = styled.span`
  position: relative;
  left: ${getSize(2)};
  margin-bottom: ${getSize(2)};
`;

export const AccountInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: ${getSize(-7)};
  width: ${getSize(55)};
`;

export const StyledInstitutionsInput = styled(InstitutionsInput)`
  flex-grow: 1;
`;

export const StyledUserGroupsInput = styled(UserGroupsInput)`
  flex-grow: 1;
`;

export const MultipleSelectionInputGroup = styled.div`
  display: flex;
  width: 100%;
  column-gap: ${getSize(20)};
`;

export const SelectGroup = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;
