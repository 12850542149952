import { Tabs } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { usePatient, useStreamChat } from '../../../context';
import { useRouter } from '../../../hooks';
import { browserStorage } from '../../../managers';
import {
  patientFolderSelectedTabStateFamily,
  PatientFolderTabs
} from '../../../store';
import { ClinicalInfo } from './ClinicalInfo';
import { PatientChannels } from './PatientChannels';
import { ChannelTab } from './PatientChannels/ChannelTab';
import { PatientChat } from './PatientChat';
import { PatientStudies } from './PatientStudies';
import { PreScreening } from './PreScreening';
import { StyledClinicalDetails, StyledTabContainer } from './styles';
import {
  filterStudies,
  getActiveStudyUid,
  getClinicalDetailsDefaultActiveTab,
  getClinicalDetailsDefaultTabs,
  usePatientFolderTabClickedAnalyticsEvent
} from './utils';

export type ClinicalDetailsProps = {
  enableEditClinicalInfo: boolean;
  onChangeClinicalInfo: () => void;
  patientTabId?: number;
};

const ClinicalDetails = (props: ClinicalDetailsProps): JSX.Element => {
  const { patient } = usePatient();
  const { id, studies, ecgs } = patient;

  const router = useRouter();
  const [selectedTab, setSelectedTab] = useRecoilState(
    patientFolderSelectedTabStateFamily(id)
  );
  const sendPatientFolderTabClickedEvent =
    usePatientFolderTabClickedAnalyticsEvent();
  const { activeStudyUid, activeSeriesUid } = getActiveStudyUid(router);
  const { enable_novo_trial_state } = browserStorage.clientConfig.get();
  const { client: StreamChatClient } = useStreamChat();
  const tabs = getClinicalDetailsDefaultTabs({
    isUserHasAccessToStreamChat: !!StreamChatClient,
    enableNovoTrial: enable_novo_trial_state
  });

  const exams = useMemo(
    () =>
      [...studies, ...ecgs].sort((a, b) => a.lastArrivalTs - b.lastArrivalTs),
    [studies, ecgs]
  );
  const [currentStudies, priorStudies] = useMemo(
    () => [filterStudies(exams, false), filterStudies(exams, true)],
    [exams]
  );

  useEffect(() => {
    if (selectedTab) return;

    setSelectedTab(
      getClinicalDetailsDefaultActiveTab(
        enable_novo_trial_state,
        activeStudyUid,
        exams
      )
    );
  }, [
    activeStudyUid,
    selectedTab,
    setSelectedTab,
    exams,
    enable_novo_trial_state
  ]);

  const displayedExams =
    selectedTab === PatientFolderTabs.PRIOR_EXAMS
      ? priorStudies
      : currentStudies;

  const handleTabClick = useCallback(
    (tab: PatientFolderTabs) => {
      sendPatientFolderTabClickedEvent(localize(tab), patient.id);
      setSelectedTab(tab);
    },
    [sendPatientFolderTabClickedEvent, setSelectedTab, patient]
  );

  return (
    <StyledClinicalDetails>
      <Tabs
        tabs={tabs.map((tab) => ({
          label:
            tab === PatientFolderTabs.CHANNELS ? (
              <ChannelTab patientId={patient.id} />
            ) : (
              localize(tab)
            ),
          value: tab
        }))}
        activeTabId={selectedTab}
        testId="patient-folder-tabs"
        onChangeActiveTab={(tab) => handleTabClick(tab as PatientFolderTabs)}
      />
      <StyledTabContainer>
        <PatientStudies
          exams={displayedExams}
          patient={patient}
          activeStudyUid={
            displayedExams.find((exam) => exam.uid === activeStudyUid)?.uid
          }
          activeSeriesUid={activeSeriesUid}
          isVisible={
            selectedTab === PatientFolderTabs.CURRENT_IMAGING ||
            selectedTab === PatientFolderTabs.PRIOR_EXAMS
          }
        />
        <ClinicalInfo
          isVisible={selectedTab === PatientFolderTabs.CLINICAL_INFO}
          onChangePatientStaffNotes={props.onChangeClinicalInfo}
          enableEdit={props.enableEditClinicalInfo}
          patientTabId={props.patientTabId}
          patient={patient}
        />
        {StreamChatClient ? (
          <PatientChannels patientId={id} />
        ) : (
          <PatientChat
            patient={patient}
            key={id}
            isVisible={selectedTab === PatientFolderTabs.CHAT}
            shouldRefetch={selectedTab === PatientFolderTabs.CHAT}
          />
        )}
        {enable_novo_trial_state && (
          <PreScreening
            patient={patient}
            isVisible={selectedTab === PatientFolderTabs.PRE_SCREENING}
          />
        )}
      </StyledTabContainer>
    </StyledClinicalDetails>
  );
};

export { ClinicalDetails };
