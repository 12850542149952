import { BulkPanel, Action } from '@viz/design-system';
import { localize, Trans, TranslationKey } from '@viz/i18n';
import React, { useState } from 'react';

import { StyledBulkContainer, StyledDialog } from './styles';
import { ActionValuesProps, BulkActionsProps, ActionsProps } from './types';

const BulkActions = ({
  selectedRows,
  actions
}: BulkActionsProps): JSX.Element => {
  const PanelActions: Action[] = actions.map((action) => ({
    name: action.name,
    ...action.actionProps
  }));
  const [action, setAction] = useState<ActionsProps | null>(null);
  const [actionValues, setActionValues] = useState<ActionValuesProps | null>(
    null
  );

  const handelCloseDialog = () => {
    setAction(null);
    setActionValues(null);
  };

  const handelConfirmAction = () => {
    if (action && action?.dialogContent) {
      const params = actionValues ? Object.values(actionValues) : [];
      const key = action.dialogContent.successMsgKey as TranslationKey;
      const successMsg = localize(key, params);
      action.onAction &&
        action.onAction(
          {
            patient_ids: selectedRows,
            value: action.actionProps?.options?.find(
              (option: { value: unknown }) => option.value === actionValues?.to
            )?.id
          },
          successMsg
        );
    }

    handelCloseDialog();
  };

  if (!actions || !actions.length) return <></>;
  return (
    <StyledBulkContainer data-testid={'bulk-actions'}>
      <BulkPanel
        label={`${selectedRows.length} ${localize('selected')}`}
        actions={PanelActions}
        onChangeAction={(actionName: string, value?: unknown) => {
          const actionItem =
            actions.find((action) => action.name === actionName) ?? null;
          setAction(actionItem);
          setActionValues({
            selectedItemsLength: selectedRows.length,
            ...(value ? { to: value } : {})
          });
        }}
      />
      {action && action?.dialogContent && selectedRows.length > 0 && (
        <StyledDialog
          open={!!action}
          onClose={handelCloseDialog}
          title={localize(action.dialogContent.title) ?? ''}
          withCloseIcon
          secondaryButtonProps={{
            content: <span>{localize('close')}</span>,
            onClick: handelCloseDialog
          }}
          primaryButtonProps={{
            content: <span>{localize(action.dialogContent.action)}</span>,
            onClick: handelConfirmAction
          }}
        >
          <Trans
            i18nKey={action.dialogContent.i18Key}
            values={actionValues ?? {}}
            components={{ span: <span /> }}
          />
        </StyledDialog>
      )}
    </StyledBulkContainer>
  );
};

export default BulkActions;
