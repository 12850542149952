import { MenuDrawer, breakpointsDefinition } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { menuRoutes } from '../../navigation';

const bp1 = parseInt(breakpointsDefinition.bp1) * 16;

const PORTRAIT_ORIENTATIONS: OrientationType[] = [
  'portrait-primary',
  'portrait-secondary'
];

const shouldDrawerStartOpen = (): boolean => {
  const isPortraitOrientation = PORTRAIT_ORIENTATIONS.includes(
    window.screen.orientation.type
  );
  const shouldStartClosedByBreakpoints = window.innerWidth < bp1;

  return !isPortraitOrientation && !shouldStartClosedByBreakpoints;
};

const SideMenu = (): JSX.Element => {
  return (
    <MenuDrawer
      items={menuRoutes}
      loc={localize}
      isDrawerOpen={shouldDrawerStartOpen()}
      testId="menu-drawer"
    />
  );
};

export default SideMenu;
