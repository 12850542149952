import {
  Dialog,
  getFontSize,
  getFontWeight,
  getSize,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledRestoreNotificationSettingsDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: ${getSize(110)};
  }
`;

export const StyledDialogContent = styled.div`
  ${getFontSize('base')};
  padding: ${getSpacing(2)} 0;
  display: flex;
  flex-direction: column;
  gap: ${getSize(1)};
`;

export const StyledSetting = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSize(1)};
  padding: ${getSize(1)};
`;

export const StyledTitle = styled.div`
  font-weight: ${getFontWeight('font-weight-bold-600')};
`;

export const StyledSubtitle = styled.div`
  ${getFontSize('sm')};
  padding-left: ${getSize(3)};
`;
