import {
  getBaseColor,
  getSize,
  getSpacing,
  Grid,
  IconButton,
  Table
} from '@viz/design-system';
import styled, { css } from 'styled-components';

interface StyledTableAnimationProps {
  isShown: boolean;
}

export const StyledTableAnimation = styled('div')<StyledTableAnimationProps>(
  ({ isShown }) => css`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;

    & > div:first-child {
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.5s ease;
      transition-delay: 0.25s;
      opacity: ${isShown ? 1 : 0};
      transform: translateX(${isShown ? 0 : -20}%);
    }

    & > div:last-child {
      transition: padding-top 0.5s ease-in-out;
      padding-top: ${isShown ? 50 : 0}px;
      height: 100%;
    }
  `
);
export const StyledTable = styled(Table)`
  &.MuiTableContainer-root {
    height: 100%;
    transition: transform 2s ease-in-out;
  }

  & .MuiTableCell-root {
    height: auto;
    line-height: ${getSize(8)};
    padding: ${getSpacing(2)};
  }

  & .MuiTableCell-root:first-of-type {
    width: 0;
    max-width: 20vw;
    padding-right: ${getSpacing(4)};
    vertical-align: middle;
  }

  & .MuiTableCell-root:nth-of-type(2) {
    width: 0;
    vertical-align: middle;
  }
`;
//first-of-type - is icon column cells in parients table
//nth-of-type(2) - is name column cells in patients table

export const StyledIconCellContainer = styled.div`
  display: flex;
  gap: ${getSpacing(2)};
  margin-left: ${getSpacing(2)};
  padding-top: ${getSpacing(1)};
`;

export const StyledIconButton = styled(IconButton)`
  && {
    padding: 0;

    svg path {
      fill: ${getBaseColor('french-gray')};
    }

    :hover {
      background-color: transparent;

      svg path {
        fill: ${getBaseColor('porcelain')};
      }
    }
  }
`;

export const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledIconContainer = styled.div`
  line-height: ${getSize(4)};
`;

export const StyledPatientsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
