import { getBaseColor, getFontWeight } from '@viz/design-system';
import styled from 'styled-components';

const animationStyle = '0.3s ease-in-out';

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  span {
    font-size: 16px;
    font-weight: ${getFontWeight('font-weight-bold-600')};
  }
`;

export const TriangleRight = styled.div`
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 5px solid ${getBaseColor('french-gray')};
  transition: transform ${animationStyle};
`;

export const SectionContent = styled.div`
  padding: 0 4px;
  overflow: hidden;
  transition: max-height ${animationStyle}, visibility ${animationStyle};
`;

export const ExpendableSectionContainer = styled.div<{ extended: boolean }>`
  width: 100%;
  border-radius: 8px;
  padding: 8px 4px;
  color: ${getBaseColor('porcelain')};

  &:hover {
    background-color: ${getBaseColor('trout')};
  }

  ${SectionContent} {
    max-height: ${(props) => (props.extended ? '1000px' : '0')};
    visibility: ${(props) => (props.extended ? 'visible' : 'hidden')};
  }

  ${TriangleRight} {
    transform: ${(props) => (props.extended ? 'rotate(90deg)' : 'none')};
  }
`;
