import { Trans } from '@viz/i18n';

interface ResultCounterProps {
  count: number;
  testId?: string;
  className?: string;
}

const ResultCounter = ({ count, testId, className }: ResultCounterProps) => {
  return (
    <div className={className} data-testid={testId}>
      <Trans
        i18nKey="showingResults"
        values={{ count }}
        components={{ bold: <b /> }}
      />
    </div>
  );
};

export default ResultCounter;
