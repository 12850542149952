import { logger } from '@viz/logging';
import { createContext, useContext } from 'react';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import { useStreamChatClient } from '../hooks/useStreamChatClient';

interface StreamChatContextData {
  client: StreamChat<DefaultStreamChatGenerics> | null;
}

const StreamChatContext = createContext<StreamChatContextData | null>(null);

export const StreamChatContextProvider: React.FC = (props) => {
  const { chatClient: client } = useStreamChatClient();
  const { children } = props;

  const getStreamChatContext = (client: StreamChat<DefaultGenerics> | null) => {
    if (client) {
      return <Chat client={client!}>{children}</Chat>;
    }
    return children;
  };

  return (
    <StreamChatContext.Provider
      value={{
        client
      }}
    >
      {getStreamChatContext(client)}
    </StreamChatContext.Provider>
  );
};

export function useStreamChat(): StreamChatContextData {
  const context = useContext(StreamChatContext);
  if (!context) {
    logger.error(
      'useStreamChat must be used within a StreamChatContextProvider'
    );
    return { client: null };
  }
  return context;
}
