import { User } from '@viz/api';
import { EllipsisTextTooltip, TableProps } from '@viz/design-system';
import { useMemo } from 'react';

import CollapsedInstitutions from '../CollapsedInstitutions';
import ActionsCell from './ActionsCell/ActionsCell';
import { getUsersTableHeaders } from './constants';
import {
  StyledCenteredCell,
  StyledFailIcon,
  StyledSuccessIcon,
  StyledUsersTable
} from './styles';

interface UsersTableProps extends Partial<TableProps> {
  users?: User[];
  onUserOpen: (userUid: string) => void;
  onOpenAuditLog: (user: User) => void;
}

const transformUserToRow = (
  user: User,
  onOpenAuditLog: (user: User) => void
) => ({
  id: user.userUid,
  data: {
    name: (
      <EllipsisTextTooltip>
        {`${user.firstName} ${user.lastName}`}
      </EllipsisTextTooltip>
    ),
    email: <EllipsisTextTooltip>{user.email}</EllipsisTextTooltip>,
    institution: <CollapsedInstitutions institutions={user.institutions} />,
    position: user.position,
    phone: user.phone,
    isActive: (
      <StyledCenteredCell>
        {user.institutions.length > 0 ? (
          <StyledSuccessIcon />
        ) : (
          <StyledFailIcon />
        )}
      </StyledCenteredCell>
    ),
    menuCell: <ActionsCell onOpenAuditLog={() => onOpenAuditLog(user)} />
  }
});

const UsersTable = ({
  users,
  onUserOpen,
  onOpenAuditLog,
  ...tableProps
}: UsersTableProps) => {
  const usersToDisplay = useMemo(
    () => users?.map((user) => transformUserToRow(user, onOpenAuditLog)) ?? [],
    [users, onOpenAuditLog]
  );

  return (
    <StyledUsersTable
      {...tableProps}
      rows={usersToDisplay}
      headers={getUsersTableHeaders()}
      onRowClick={onUserOpen}
      stickyHeader
    />
  );
};

export default UsersTable;
