import { Button, getSize, screens } from '@viz/design-system';
import styled from 'styled-components';

export const StyledSaveButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: ${getSize(4)};
  right: ${getSize(6)};
  gap: ${getSize(2)};

  @media ${screens.small} {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const StyledButton = styled(Button)`
  width: ${getSize(20)};
`;
