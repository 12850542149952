import { usePolicies, useSignPolicy, useUserPolicyData } from '@viz/api';
import { Button, IconSizes, Loading } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { getDeviceUid } from '@viz/utils';
import { useState } from 'react';

import { useUserProfile } from '../../hooks';
import { useLoginWatcher } from '../../hooks/useLoginWatcher';
import { browserStorage } from '../../managers';
import { PoliciesDialog } from '../PoliciesDialog';
import {
  StyledPoliciesHeader,
  StyledPoliciesSubtitle,
  StyledPoliciesTitle,
  StyledUserPoliciesContainer
} from './styles';

const UserPolicies = (): JSX.Element => {
  const device_uid = getDeviceUid();
  const { user } = useUserProfile();
  useLoginWatcher();
  const [policiesDialogOpen, setPoliciesDialogOpen] = useState(true);

  const { data: userPolicyData } = useUserPolicyData(
    { device_uid },
    { refetchOnWindowFocus: false }
  );

  const { mutate: signPolicyMutation } = useSignPolicy();
  const documentUrls = (userPolicyData?.policy_urls || []).map(
    ({ url }) => url
  );

  const policiesContent = usePolicies(
    { documentUrls },
    {
      enabled: documentUrls.length > 0,
      refetchOnWindowFocus: false
    }
  );

  const signPolicies = () => {
    if (userPolicyData) {
      signPolicyMutation(
        { policy_token: userPolicyData.policy_token },
        {
          onSuccess: (data) => {
            if (data.success === 'true') {
              browserStorage.signedPolicies.set('true');
              setPoliciesDialogOpen(false);
            }
          }
        }
      );
    }
  };

  const isPoliciesLoading = policiesContent?.some(({ isLoading }) => isLoading);

  if (!user || isPoliciesLoading)
    return (
      <StyledUserPoliciesContainer>
        <Loading size={IconSizes.MEDIUM} />
      </StyledUserPoliciesContainer>
    );

  const policies = policiesContent.map((policy, index) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(policy.data!, 'text/html');
    const content = htmlDoc.children?.[0].children?.[1].innerHTML;

    return {
      title: userPolicyData!.policy_urls[index].title,
      content
    };
  });

  return (
    <>
      <StyledUserPoliciesContainer data-testid="user-policies">
        <StyledPoliciesHeader>
          <StyledPoliciesTitle data-testid="user-policies-hello-message">
            {localize('hello')} {user.name}
          </StyledPoliciesTitle>
          <StyledPoliciesSubtitle data-testid="user-policies-terms-disclamer">
            {localize('termsDisclaimer')}:
          </StyledPoliciesSubtitle>
        </StyledPoliciesHeader>
        <Button
          color="primary"
          variant="contained"
          data-testid="user-policies-open-dialog-button"
          onClick={() => setPoliciesDialogOpen(true)}
        >
          {localize('termsOfUse')}
        </Button>
      </StyledUserPoliciesContainer>
      {policies.length > 0 && (
        <PoliciesDialog
          policies={policies}
          open={policiesDialogOpen}
          onSign={signPolicies}
          onClose={() => setPoliciesDialogOpen(false)}
        />
      )}
    </>
  );
};

export default UserPolicies;
