import {
  AutocompleteRenderInputParams,
  breakpointsDefinition,
  IconSearch,
  IconSizes,
  Input
} from '@viz/design-system';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { StyledSearchBox } from './styles';

const bp1 = parseInt(breakpointsDefinition.bp1) * 16;
const ENTER_KEY = 'Enter';

interface SearchProps {
  onSubmit: (value: string) => void;
  testId?: string;
  inputTestId?: string;
  placeholder: string;
  initialSearchText: string;
}

const Search = ({
  onSubmit,
  testId,
  inputTestId,
  placeholder,
  initialSearchText
}: SearchProps): JSX.Element => {
  const [searchText, setSearchText] = useState(initialSearchText);
  const [isUnderBp1, setIsUnderBp1] = useState(window.innerWidth < bp1);
  const [isFocused, setIsFocused] = useState(false);

  const isCollapsed = isUnderBp1 && !isFocused && !searchText;

  useEffect(() => {
    setSearchText(initialSearchText);
  }, [initialSearchText]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < bp1) {
        setIsUnderBp1(true);
      } else {
        setIsUnderBp1(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();

      if (initialSearchText !== searchText) {
        onSubmit(searchText);
      }
    }
  };

  return (
    <StyledSearchBox
      $isCollapsed={isCollapsed}
      freeSolo
      testId={testId}
      options={[]}
      onKeyDown={handleSubmit}
      inputValue={searchText}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onInputChange={(event: ChangeEvent<InputEvent>, value: string) => {
        if (event?.type === 'click' && !value) {
          onSubmit(''); //clear search on click of clear button
        }
        setSearchText(value);
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <Input
          {...params}
          placeholder={isCollapsed ? '' : placeholder}
          inputProps={{
            ...params.inputProps,
            'data-testid': inputTestId
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: <IconSearch size={IconSizes.SMALL} />
          }}
        />
      )}
    />
  );
};
export default Search;
