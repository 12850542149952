import { IconInfoError } from '@viz/design-system';
import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getFontWeight,
  getSize
} from '@viz/design-system/src/ui';
import styled from 'styled-components';

export const StyledGroupContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledTabContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  position: relative;
  height: 100%;
`;

export const StyledGroup = styled.div`
  margin: ${getSize(6)} ${getSize(4)} ${getSize(2)} ${getSize(4)};
`;

export const StyledGroupName = styled.div`
  font-size: ${getFontSize('lg')};
  font-weight: ${getFontWeight('font-weight-bold-600')};
  margin-bottom: ${getSize(3)};
`;

export const StyledCriteriaGroup = styled.div`
  padding: ${getSize(3)} ${getSize(3)} 0px ${getSize(3)};
  background: #363a44;
  border-radius: ${getBorderRadius('SMALL')};
`;

export const StyledDividerHeader = styled.div`
  height: ${getSize(0.25)};
  background: ${getBaseColor('waterloo')};
`;

export const StyledDateInfo = styled.div`
  font-size: ${getSize(4)};
  color: ${getBaseColor('santas-gray')};
`;

export const StyledDateInfoNotes = styled.div`
  font-size: ${getFontSize('xs')};
  color: ${getBaseColor('santas-gray')};
  margin-top: ${getSize(2)};
  margin-bottom: ${getSize(2)};
`;

export const StyledDisclaimer = styled.div`
  background: ${getBaseColor('pure-black')};
  opacity: 0.8;
  position: absolute;
  bottom: ${getSize(2)};
  padding: ${getSize(2)};
  margin-right: ${getSize(6)};
  left: ${getSize(4)};
  right: ${getSize(0)};
  display: flex;
  border-radius: ${getSize(2)};
`;

export const StyledDisclaimerText = styled.div`
  margin-left: ${getSize(2)};
`;

export const StyledOpenDisclaimer = styled.div`
  cursor: pointer;
  text-decoration: underline;
  display: inline;
`;

export const StyledIconInfoError = styled(IconInfoError)`
  path {
    stroke: ${getBaseColor('white')};
  }
`;

export const StyledFakeSpace = styled.div`
  height: ${getSize(14)};
`;
