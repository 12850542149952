import { Badge, BadgeSize } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { usePatientChannels } from '../../../../hooks';
import { PatientFolderTabs } from '../../../../store';
import { StyledTabWithBadge, StyledBadge } from './styles';

export const ChannelTab = ({
  patientId
}: {
  patientId: string;
}): JSX.Element => {
  const { totalUnreadMessages } = usePatientChannels({ patientId });
  return (
    <StyledTabWithBadge>
      <span>{localize(PatientFolderTabs.CHANNELS)}</span>
      <StyledBadge>
        {totalUnreadMessages > 0 && (
          <Badge
            testId="patient-messages-badge"
            size={BadgeSize.MEDIUM}
            badgeContent={totalUnreadMessages}
            color="primary"
          />
        )}
      </StyledBadge>
    </StyledTabWithBadge>
  );
};
