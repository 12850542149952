import { IconSizes, Loading } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useTimer } from 'use-timer';

import {
  StyledLoadingContainer,
  StyledResendCode,
  StyledResendCodeContainer
} from './styles';

const RESEND_TIMEOUT = 15;

type ResendMfaCodeProps = {
  onSendMfaCode: () => void;
  className?: string;
  testId?: string;
};

const ResendMfaCode = ({
  onSendMfaCode,
  className,
  testId
}: ResendMfaCodeProps) => {
  const timer = useTimer({
    autostart: true,
    initialTime: RESEND_TIMEOUT,
    timerType: 'DECREMENTAL',
    endTime: 0
  });

  const resendCode = () => {
    onSendMfaCode();
    timer.reset();
    timer.start();
  };

  return (
    <StyledResendCodeContainer className={className} data-testid={testId}>
      {localize('unreceivedMFACode')}
      {timer.time > 0 ? (
        <StyledLoadingContainer>
          <Loading size={IconSizes.X_SMALL} />
        </StyledLoadingContainer>
      ) : (
        <StyledResendCode underline="always" onClick={resendCode}>
          {localize('resendCode')}
        </StyledResendCode>
      )}
    </StyledResendCodeContainer>
  );
};

export { ResendMfaCode };
