import {
  Dialog,
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSize,
  getSpacing,
  screens,
  Stepper
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledPoliciesDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 90vw;
    max-height: 90vh;
    height: ${getSize(260)};
    width: ${getSize(420)};
    ${getFontSize('base')};

    @media ${screens.medium} {
      height: ${getSize(200)};
      width: ${getSize(320)};
    }
  }

  .MuiDialogContent-root {
    padding: ${getSpacing(2)} ${getSpacing(2)} 0;
  }
`;

export const StyledStepper = styled(Stepper)`
  && {
    min-width: max-content;
    padding: ${getSize(4)};
    @media ${screens.small} {
      min-width: ${getSize(40)};
    }
  }
`;

export const StyledDialogContent = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;

  @media ${screens.small} {
    flex-direction: column;
  }
`;

export const StyledPolicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  color: ${getBaseColor('porcelain')};
  padding: ${getSpacing(4)} ${getSpacing(16)};
  overflow-y: auto;
  position: relative;
`;

export const StyledPolicesHeader = styled.div`
  ${getFontSize('xl')};
  font-weight: ${getFontWeight('font-weight-bold-600')};
  padding-bottom: ${getSpacing(4)};
`;

export const StyledReadMoreContainer = styled.div`
  position: absolute;
  bottom: ${getSpacing(3)};
  left: 50%;
  transform: translateX(-100%);
`;
