import { UserBasePayload, useUserGroups } from '@viz/api';
import { MultipleSelection } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { FormLabel } from '../../../../../components';
import UserGroupsContent from '../userGroupsContent';
import {
  filterAvailableUserGroupsFromSelected,
  getGroupSearchLabel
} from './utils';

interface UserGroupsInputProps {
  form: UseFormReturn<UserBasePayload>;
  className?: string;
}

const UserGroupsInput = ({
  form: { control, setValue, watch },
  className
}: UserGroupsInputProps) => {
  const { data: userGroups } = useUserGroups({ extended: true });
  const institutionsValue = watch('institutions');
  const currentUserGroupsSelection = watch('userGroups');

  const formattedUserGroups = useMemo(
    () =>
      userGroups
        ?.filter((userGroup) =>
          institutionsValue?.some((institutionId) =>
            userGroup.associatedInstitutions.includes(institutionId)
          )
        )
        .map((userGroup) => ({
          ...userGroup,
          label: getGroupSearchLabel(userGroup),
          value: userGroup.id
        })),
    [institutionsValue, userGroups]
  );

  const selectedCount = useMemo(() => {
    const filteredUserGroupSelection = filterAvailableUserGroupsFromSelected(
      currentUserGroupsSelection,
      formattedUserGroups
    );
    return filteredUserGroupSelection?.length || 0;
  }, [formattedUserGroups, currentUserGroupsSelection]);

  return (
    <Controller
      name="userGroups"
      control={control}
      render={({ field, fieldState }) => {
        return (
          <MultipleSelection
            label={<FormLabel translationKey="userGroups" />}
            placeholder={localize('multipleSelectionPlaceholder')}
            onChange={field.onChange}
            options={formattedUserGroups ?? []}
            selectedOptions={field.value ?? []}
            selectionStatusOptions={{
              content: `${selectedCount} Selected`,
              onClear: () => setValue('userGroups', [])
            }}
            helperText={fieldState.error?.message}
            showSelectedOnTop={true}
            className={className}
            renderItem={(element) => <UserGroupsContent userGroup={element} />}
          />
        );
      }}
    />
  );
};

export default UserGroupsInput;
