import {
  StyledBody,
  StyledContent,
  StyledImage,
  StyledTextContent,
  StyledTitle,
  StyledTrademark
} from './styles';
import { VizNotificationProps } from './types';

const VizNotification = ({
  title,
  body,
  imgSrc,
  onClick
}: VizNotificationProps): JSX.Element => {
  return (
    <div data-testid="viz-notification" onClick={onClick}>
      {title && (
        <StyledTitle data-testid="notification-title">{title}</StyledTitle>
      )}
      <StyledContent data-testid="notification-content">
        {imgSrc && <StyledImage src={imgSrc} size={82} />}
        <StyledTextContent>
          <StyledBody>{body}</StyledBody>
          <StyledTrademark>via Viz.ai</StyledTrademark>
        </StyledTextContent>
      </StyledContent>
    </div>
  );
};

export { VizNotification };
