import {
  Button,
  getBaseColor,
  getFontSize,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: ${getSpacing(5)};
  color: ${getBaseColor('french-gray')};
`;

export const CenteredText = styled.div`
  margin: auto 0;
  overflow: hidden;
  word-wrap: break-word;
  text-align: right;
`;

export const BackButton = styled(Button)`
  && {
    padding-left: 0;
    ${getFontSize('sm')};
  }
`;
