import { Ecg, Study } from '@viz/api';
import { sortBy } from '@viz/utils';

import { PatientViewerData } from '../../store';

export const getViewerDefaultData = (
  studies: Study[],
  ecgs: Ecg[]
): PatientViewerData => {
  const combinedStudies = [...studies, ...ecgs];
  if (combinedStudies.length === 0) return {};

  const sortedStudies = sortBy(combinedStudies, 'lastArrivalTs', 'desc');
  const detectedStudies = sortedStudies.filter(
    ({ algoDetection }) => algoDetection.length > 0
  );

  if (detectedStudies.length > 0) {
    const latestDetectedStudy = detectedStudies[0];
    return getDetectedViewerData(latestDetectedStudy);
  }

  const latestStudy = sortedStudies[0];
  return getLatestViewerData(latestStudy);
};

const getDetectedViewerData = (latestDetectedStudy: Study | Ecg) => {
  if ('series' in latestDetectedStudy) {
    const latestDetectedSeries = sortBy(
      latestDetectedStudy.series,
      'firstAcquiredTs',
      'desc'
    ).find(({ algoDetection }) => algoDetection.length > 0)!;

    return {
      studyUid: latestDetectedStudy.uid,
      seriesUid: latestDetectedSeries.uid
    };
  }
  return {
    ecgId: latestDetectedStudy.ecgId
  };
};

const getLatestViewerData = (latestStudy: Study | Ecg) => {
  if ('series' in latestStudy) {
    const seriesWithMaxDicoms = sortBy(
      latestStudy.series,
      'dicomCount',
      'desc'
    )[0];

    return {
      studyUid: latestStudy.uid,
      seriesUid: seriesWithMaxDicoms.uid
    };
  }
  return {
    ecgId: latestStudy.ecgId
  };
};
