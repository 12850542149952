import { IconSizes } from '@viz/design-system';
import type { MouseEventHandler, ReactText } from 'react';
import React, { useEffect } from 'react';
import { Slide, toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import {
  ContactSupportDialog,
  PatientTabDialog,
  SearchPatient,
  SettingsDialog,
  TopBar,
  WebglDialog
} from '../../../components';
import { LogoutDialog } from '../../../components/LogoutDialog';
import { TroubleshootingDialog } from '../../../components/TroubleshootingDialog';
import { useDesktopNotificationClickedEvent } from '../../../hooks';
import { Paths } from '../../../navigation';
import { toastStatusState } from '../../../store';
import { LayoutProps } from '../types';
import {
  Layout,
  PageContainer,
  StyledIconClose,
  StyledToast,
  StyledToastContainer
} from './styles';

const TOAST_DURATION = 5000;
const TOAST_CONTAINER_ID = 'topBarToast';

type BasicLayoutProps = LayoutProps & {
  searchComponent?: React.ReactNode;
  topBarWithTabs?: boolean;
  logoHref?: string;
};

type CloseButtonProps = {
  closeToast: MouseEventHandler<SVGElement>;
};

const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <StyledIconClose size={IconSizes.SMALL} onClick={closeToast} />
);

const Toast = ({ text }: { text: string }) => (
  <StyledToast data-testid="top-bar-toast">{text}</StyledToast>
);

const BasicLayout = ({
  topBarWithTabs,
  searchComponent = <SearchPatient />,
  logoHref = Paths.CASES,
  children
}: BasicLayoutProps): JSX.Element => {
  const [toastStatus, setToastStatus] = useRecoilState(toastStatusState);
  const toastId = React.useRef<ReactText | null>(null);
  useDesktopNotificationClickedEvent();

  useEffect(() => {
    if (toastStatus) {
      const toastFn = toast[toastStatus.type];

      toastId.current = toastFn(<Toast text={toastStatus.text} />, {
        autoClose: toastStatus.preventClose ? false : TOAST_DURATION,
        closeButton: toastStatus.preventClose ? <></> : CloseButton,
        closeOnClick: !toastStatus.preventClose,
        icon: false,
        position: 'top-center',
        containerId: TOAST_CONTAINER_ID,
        onClose: () => setToastStatus(null)
      });
    } else if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = null;
    }
  }, [toastStatus, setToastStatus]);

  return (
    <Layout>
      <TopBar
        withTabs={topBarWithTabs}
        searchComponent={searchComponent}
        logoHref={logoHref}
      />
      <PageContainer>{children}</PageContainer>
      <PatientTabDialog />
      <SettingsDialog />
      <WebglDialog />
      <TroubleshootingDialog />
      <StyledToastContainer
        containerId={TOAST_CONTAINER_ID}
        draggable={false}
        enableMultiContainer
        hideProgressBar
        transition={Slide}
      />
      <LogoutDialog />
      <ContactSupportDialog />
    </Layout>
  );
};

export default BasicLayout;
