import { localize } from '@viz/i18n';
import { useRecoilState } from 'recoil';

import { isTroubleshootingDialogOpenState } from '../../store';
import ContactSupportLink from '../ContactSupportLink/ContactSupportLink';
import { StatusHeader } from './StatusHeader';
import { StatusIndicator } from './StatusIndicator';
import { getTroubleshootingSections } from './constants';
import {
  StyledDialogContent,
  StyledTroubleShootingDescription,
  StyledTroubleShootingDialog,
  StyledTroubleshootingSection
} from './styles';

const TroubleshootingDialog = (): JSX.Element => {
  const [isOpen, setIsOpen] = useRecoilState(isTroubleshootingDialogOpenState);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <StyledTroubleShootingDialog
      open={isOpen}
      onClose={onClose}
      title={localize('troubleshooting')}
      withCloseIcon
    >
      {isOpen && (
        <StyledDialogContent>
          <StyledTroubleShootingDescription>
            {localize('troubleshootingDescription')}
          </StyledTroubleShootingDescription>
          {getTroubleshootingSections().map(({ title, checks }) => (
            <div key={title}>
              <StatusHeader name={title} />
              <StyledTroubleshootingSection>
                {checks.map(({ testId, name, check }) => (
                  <li key={testId}>
                    <StatusIndicator
                      testId={testId}
                      name={name}
                      check={check}
                    />
                  </li>
                ))}
              </StyledTroubleshootingSection>
            </div>
          ))}
          <ContactSupportLink onClick={onClose} />
        </StyledDialogContent>
      )}
    </StyledTroubleShootingDialog>
  );
};

export default TroubleshootingDialog;
