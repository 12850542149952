import { useEndShift, useShiftStatus, useStartShift } from '@viz/api';
import {
  IconDocument,
  IconEmail,
  IconLogout,
  IconSettings,
  IconWrench,
  Switch
} from '@viz/design-system';
import { localize } from '@viz/i18n';
import { MouseEvent, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useTheme } from 'styled-components';

import {
  AnalyticsEventMethod,
  AnalyticsEventName,
  getEventNameByPath,
  useAnalyticsEvent,
  useRouter
} from '../../hooks';
import { Paths } from '../../navigation';
import {
  isContactSupportDialogOpenState,
  isSettingsDialogOpenState,
  isTroubleshootingDialogOpenState,
  isLogoutDialogOpenState
} from '../../store';
import { APP_VERSION } from '../../types';
import { UserDetails } from './UserDetails';
import { UserMenuItem, UserMenuItemProps } from './UserMenuItem';
import {
  AlertContainer,
  OnCallContainer,
  StyledAlert,
  StyledMenu,
  StyledMenuItemsContainer,
  StyledVersionNumber,
  SwitchContainer,
  SwitchLabel
} from './styles';

const UserProfileMenu = () => {
  const router = useRouter();
  const { sendEvent } = useAnalyticsEvent();
  const theme = useTheme();
  const setIsSettingsDialogOpen = useSetRecoilState(isSettingsDialogOpenState);
  const setIsTroubleshootingDialogOpen = useSetRecoilState(
    isTroubleshootingDialogOpenState
  );
  const setIsContactSupportDialogOpen = useSetRecoilState(
    isContactSupportDialogOpenState
  );
  const setIsLogoutDialogOpen = useSetRecoilState(isLogoutDialogOpenState);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(menuAnchorEl);
  const shiftStatusQuery = useShiftStatus({
    refetchOnWindowFocus: false,
    retry: false
  });

  const source = getEventNameByPath(router.pathname);

  const startShiftMutation = useStartShift({
    enabled: false,
    onSuccess: async () => {
      await shiftStatusQuery.refetch();
      sendEvent(AnalyticsEventName.ON_CALL_STATUS_UPDATED, {
        method: AnalyticsEventMethod.START_SHIFT,
        source
      });
    }
  });

  const endShiftMutation = useEndShift({
    enabled: false,
    onSuccess: async () => {
      await shiftStatusQuery.refetch();
      sendEvent(AnalyticsEventName.ON_CALL_STATUS_UPDATED, {
        method: AnalyticsEventMethod.END_SHIFT,
        source
      });
    }
  });

  const isOnCall = shiftStatusQuery?.data?.on_shift;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
    sendEvent(AnalyticsEventName.USER_DROP_MENU_VIEWED);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOnChange = () => {
    if (isOnCall) {
      endShiftMutation.refetch();
    } else {
      startShiftMutation.refetch();
    }
  };

  const handleSettingsClick = () => {
    setIsSettingsDialogOpen(true);
    handleClose();
  };

  const handleTroubleshootingClick = () => {
    setIsTroubleshootingDialogOpen(true);
    handleClose();
  };

  const menuLinks: UserMenuItemProps[] = [
    {
      text: localize('settings'),
      icon: <IconSettings />,
      onClick: handleSettingsClick,
      leadingDivider: true,
      testId: 'menu-item-setting'
    },
    {
      text: localize('documentation'),
      icon: <IconDocument />,
      onClick: () => router.navigate({ path: Paths.DOCUMENTATION }),
      testId: 'menu-item-documentation'
    },
    {
      text: localize('contactSupport'),
      icon: <IconEmail />,
      onClick: () => {
        handleClose();
        setIsContactSupportDialogOpen(true);
        sendEvent(AnalyticsEventName.APP_SETTINGS_CONTACT_SUPPORT_CLICKED);
      },
      testId: 'menu-item-contact-support'
    },
    {
      text: localize('troubleshooting'),
      icon: <IconWrench />,
      onClick: handleTroubleshootingClick,
      leadingDivider: true,
      testId: 'menu-item-troubleshooting'
    },
    {
      text: localize('logout'),
      icon: <IconLogout />,
      onClick: () => {
        setIsLogoutDialogOpen(true);
      },
      leadingDivider: true,
      testId: 'menu-item-logout'
    }
  ];

  return (
    <>
      <UserDetails
        testId="user-details"
        isConnected={isOnCall!}
        onClick={handleClick}
        isMenuOpen={isMenuOpen}
      />
      <StyledMenu
        backgroundcolor="blue-zodiac"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        testId="user-menu"
      >
        <SwitchContainer>
          <OnCallContainer>
            <Switch
              testId="menu-switch"
              checked={isOnCall}
              onChange={handleOnChange}
              // @ts-expect-error - TS thinks there's no baseColors on theme, but there is
              uncheckedTrackColor={theme.baseColors['fruit-salad']}
            />
            <SwitchLabel testId="menu-switch-oncall-text">
              {localize(isOnCall ? 'setOffCall' : 'setOnCall')}
            </SwitchLabel>
          </OnCallContainer>
          <AlertContainer>
            <StyledAlert
              testId="menu-alert"
              text={localize('alertNotification')}
              type="info"
            />
          </AlertContainer>
        </SwitchContainer>
        <StyledMenuItemsContainer date-testid="menu-links">
          {menuLinks?.map(
            ({
              icon,
              text,
              onClick,
              leadingDivider,
              testId
            }: UserMenuItemProps): JSX.Element => (
              <UserMenuItem
                key={text}
                text={text}
                icon={icon}
                onClick={onClick}
                leadingDivider={leadingDivider}
                testId={testId}
              />
            )
          )}
        </StyledMenuItemsContainer>
        <StyledVersionNumber testId="user-menu-version-number">
          {localize('version')}: {APP_VERSION}
        </StyledVersionNumber>
      </StyledMenu>
    </>
  );
};

export { UserProfileMenu };
