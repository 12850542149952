import { AxiosService, HTTPStatusCode } from '@viz/api';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { eventbus, Events } from './events';
import { browserStorage } from './managers';
import { API_GW_URL, API_URL } from './types';

const CSRF_DOUBLE_SUBMIT_ERROR = 'CSRF double submit tokens do not match';

// Create axios instance
const headers: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8'
};
const instance = axios.create({
  baseURL: API_URL,
  headers,
  withCredentials: true
});
const apiGatewayInstance = axios.create({
  baseURL: API_GW_URL,
  headers,
  withCredentials: true
});
apiGatewayInstance.defaults.headers.common['user-workspace'] = 1; //this needs to be removed and applied only in the specific endpoints that need it. apparently it's just for the getPreScreeningData endpoint

// Attach interceptors
const apiGatewayInterceptor = (config: AxiosRequestConfig) => {
  if (!config.headers) {
    config.headers = {};
  }
  // @ts-ignore
  config.headers['app-version'] = 'web';
  config.headers['os-type'] = 'web';
  config.headers['X-CSRF-TOKEN'] = browserStorage.csrfAccessToken.get();
  return config;
};

const cliniumWebHeadersInterceptor = (config: AxiosRequestConfig) => {
  if (!config.headers) {
    config.headers = {};
  }
  // @ts-ignore
  config.headers['app-version'] = 'web';
  config.headers['os-type'] = 'web';
  config.headers['X-App-Context'] = window.vizWidget ? 'desktop' : 'browser';
  config.headers['X-CSRF-TOKEN'] = browserStorage.csrfAccessToken.get();
  return config;
};

instance.interceptors.request.use(cliniumWebHeadersInterceptor);
apiGatewayInstance.interceptors.request.use(apiGatewayInterceptor);

const errorInterceptor = (error: AxiosError) => {
  if (
    error.response?.status === HTTPStatusCode.UNAUTHORIZED &&
    error.response.data.error !== CSRF_DOUBLE_SUBMIT_ERROR
  ) {
    eventbus.dispatch(Events.LOGOUT);
  }
  return Promise.reject(error);
};

instance.interceptors.response.use((response) => response, errorInterceptor);
apiGatewayInstance.interceptors.response.use(
  (response) => response,
  errorInterceptor
);

// Create an HTTP service and expose it
const httpService = new AxiosService(instance);
const httpApiGatewayService = new AxiosService(apiGatewayInstance);

export { httpService, httpApiGatewayService };
