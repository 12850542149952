import { Grid, IconInfoError, IconSizes } from '@viz/design-system';

import { AlgoDetectionIcon } from '../../../../AlgoDetectionIcon';
import { getAlgoNameFromIconUrl } from '../utils';

type StudyDetectionIconsProps = {
  isFailed: boolean;
  highlightIcons: Array<string>;
};

const StudyDetectionIcons = ({
  isFailed,
  highlightIcons
}: StudyDetectionIconsProps): JSX.Element => {
  return (
    <Grid
      container
      direction="row-reverse"
      spacing={2}
      justifyContent="flex-start"
    >
      {!highlightIcons.length && isFailed ? (
        <Grid item>
          <IconInfoError size={IconSizes.MEDIUM} testId="study-error-icon" />
        </Grid>
      ) : (
        highlightIcons.map((iconUrl) => {
          const algoName = getAlgoNameFromIconUrl(iconUrl);

          return (
            <Grid item key={algoName}>
              <AlgoDetectionIcon
                size={IconSizes.MEDIUM}
                iconUrl={iconUrl}
                testId={`study-algo-icon-${algoName}`}
              />
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export { StudyDetectionIcons };
