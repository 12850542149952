import { IconArrowRight, IconCheckMark, IconSizes } from '@viz/design-system';
import { localize, Trans } from '@viz/i18n';
import { logger } from '@viz/logging';
import { IS_RENDERER_SUPPORTED, RENDERER_FEATURE_FLAG } from '@viz/utils';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { shouldCheckRendererState } from '../../store';
import ContactSupportLink from '../ContactSupportLink/ContactSupportLink';
import DialogStep from './DialogStep';
import defaultImg from './assets/default.png';
import openglImg from './assets/opengl.png';
import relaunchImg from './assets/relaunch.png';
import {
  IconArrowRightContainer,
  StyledCopyButton,
  StyledDialogContent,
  StyledDialogStepDescription,
  StyledDialogStepImage,
  StyledOrderedList,
  StyledWebglContainer,
  StyledWebglDialog,
  StyledWebglHeader
} from './styles';

const webglLink = 'about://flags/#use-angle';

const WebglDialog = (): JSX.Element => {
  const [shouldCheckRenderer, setShouldCheckRenderer] = useRecoilState(
    shouldCheckRendererState
  );

  const [isTextCopied, setIsTextCopied] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const handleCopyButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(webglLink);
      setIsTextCopied(true);
    } catch (e) {
      const error = e as Error;
      logger.error('handleCopyButtonClick error', {
        message: error.message,
        stack: error.stack
      });
    }
  };

  useEffect(() => {
    if (!isTextCopied) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setIsTextCopied(false);
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isTextCopied]);

  useEffect(() => {
    if (shouldCheckRenderer) {
      setShouldCheckRenderer(false);
      setIsOpen(RENDERER_FEATURE_FLAG && !IS_RENDERER_SUPPORTED);
    }
  }, [shouldCheckRenderer, setShouldCheckRenderer]);

  return (
    <StyledWebglDialog
      open={isOpen}
      onClose={onClose}
      title={localize('unsupportedDriver')}
      withCloseIcon
    >
      <StyledDialogContent>
        <StyledWebglContainer>
          <StyledWebglHeader>
            {localize('adjustBrowserSettings')}
          </StyledWebglHeader>
          <StyledOrderedList>
            <DialogStep number={1}>
              <StyledDialogStepDescription>
                {localize('copyLink')} <strong>{webglLink}</strong>
                <StyledCopyButton
                  color="secondary"
                  variant="outlined"
                  onClick={handleCopyButtonClick}
                >
                  {isTextCopied && <IconCheckMark />}
                  {isTextCopied ? localize('copied') : localize('copyLink')}
                </StyledCopyButton>
              </StyledDialogStepDescription>
              <p>{localize('pasteInBrowser')}</p>
            </DialogStep>
            <DialogStep number={2}>
              <StyledDialogStepDescription>
                <Trans i18nKey="chooseAngle" components={{ bold: <b /> }} />
              </StyledDialogStepDescription>
              <StyledDialogStepImage
                alt="default"
                src={defaultImg}
              ></StyledDialogStepImage>
              <IconArrowRightContainer>
                <IconArrowRight size={IconSizes.MEDIUM} />
              </IconArrowRightContainer>
              <StyledDialogStepImage
                alt="opengl driver option"
                src={openglImg}
              ></StyledDialogStepImage>
            </DialogStep>
            <DialogStep number={3}>
              <StyledDialogStepDescription>
                <Trans i18nKey="relaunch" components={{ bold: <b /> }} />
              </StyledDialogStepDescription>
              <StyledDialogStepImage
                alt="relaunch the browser"
                src={relaunchImg}
              ></StyledDialogStepImage>
            </DialogStep>
          </StyledOrderedList>
          <p>
            <ContactSupportLink onClick={onClose} />
          </p>
        </StyledWebglContainer>
      </StyledDialogContent>
    </StyledWebglDialog>
  );
};

export { WebglDialog };
