import { useQueryClient } from '@tanstack/react-query';
import {
  Patient,
  QueryKeys,
  ServerPatients,
  SubmitSharePatient
} from '@viz/api';
import { IconActivate, IconRefer, IconSizes } from '@viz/design-system';
import { logger } from '@viz/logging';
import { getDateAndTime } from '@viz/utils';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { AnalyticsEventName, useAnalyticsEvent } from '../../../hooks';
import { selectedDistributionListTabIdState } from '../../../store';
import { ActivateDialog } from './ActivateDialog';
import { ReferDialog } from './ReferDialog';
import { SelectDistributionList } from './SelectDistributionList';
import {
  StyledReferralDetails,
  StyledSelectors,
  StyledSharePatient
} from './styles';

export interface SharePatientProps {
  patient: Patient;
}

const SharePatient = ({ patient }: SharePatientProps): JSX.Element => {
  const [selectedDistributionList, setSelectedDistributionList] = useState<
    ServerPatients.DistributionList | undefined
  >(undefined);

  const selectedDistributionListTabId = useRecoilValue(
    selectedDistributionListTabIdState
  );
  const { sendEvent } = useAnalyticsEvent();

  const isActivate =
    patient?.shareDetails?.share_button_text.indexOf('activate') !== -1;

  const queryClient = useQueryClient();

  const onShare = (payload: SubmitSharePatient.Payload) => {
    sendEvent(AnalyticsEventName.PATIENT_SHARED, {
      context_provided: payload.message?.length > 0,
      context_character_length: payload.message?.length,
      content_type: 'patient',
      patient_id: patient?.id,
      share_target_id: selectedDistributionList?.id,
      share_target_type: 'provider_group',
      institution: patient.institutionName,
      source_tab: selectedDistributionListTabId
    });

    setSelectedDistributionList(undefined);
    queryClient
      .invalidateQueries({
        queryKey: [QueryKeys.GET_PATIENT]
      })
      .catch((e) => logger.error(e));
    queryClient
      .invalidateQueries({
        queryKey: ['Conversation']
      })
      .catch((e) => logger.error(e));
  };

  const openDialog = (distributionList: ServerPatients.DistributionList) => {
    sendEvent(AnalyticsEventName.SHARE_PATIENT_DIALOG_VIEWED, {
      di_patient_name: patient?.diName,
      di_patient_mrn: patient?.diMedicalRecordNumber,
      patient_id: patient?.id
    });
    setSelectedDistributionList(distributionList);
  };

  return (
    <StyledSharePatient>
      {patient?.shareDetails && (
        <StyledReferralDetails
          data-testid={`${isActivate ? 'activated' : 'Referred'}`}
        >
          {isActivate ? (
            <IconActivate size={IconSizes.SMALL}></IconActivate>
          ) : (
            <IconRefer size={IconSizes.SMALL}></IconRefer>
          )}
          {patient.shareDetails.share_button_text}{' '}
          {patient.shareDetails.distribution_list_name} •{' '}
          {getDateAndTime(patient.shareDetails.last_patient_shared_ts, {
            formatting: 'MMM d'
          })}
        </StyledReferralDetails>
      )}
      {patient?.shareableDistributionLists && (
        <StyledSelectors>
          <SelectDistributionList
            distributionLists={patient?.shareableDistributionLists}
            shareType="REFER"
            onChange={(distributionList) => {
              openDialog(distributionList);
            }}
          />
          <SelectDistributionList
            distributionLists={patient.shareableDistributionLists}
            shareType="ACTIVATE"
            onChange={(distributionList) => {
              openDialog(distributionList);
            }}
          />
        </StyledSelectors>
      )}
      {selectedDistributionList?.share_type === 'REFER' && (
        <ReferDialog
          distributionList={selectedDistributionList}
          patient={patient}
          onClose={() => setSelectedDistributionList(undefined)}
          onSuccess={onShare}
        />
      )}
      {selectedDistributionList?.share_type === 'ACTIVATE' && (
        <ActivateDialog
          distributionList={selectedDistributionList}
          patient={patient}
          onClose={() => setSelectedDistributionList(undefined)}
          onSuccess={onShare}
        />
      )}
    </StyledSharePatient>
  );
};

export { SharePatient };
