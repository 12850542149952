import { InstitutionSetting } from '@viz/api';

export const getSubInstitutionsData = <T>(
  institutions: InstitutionSetting[],
  selectedInstitutionsOnly = false,
  property: keyof InstitutionSetting = 'id'
): T[] => {
  return institutions.reduce<T[]>((agg, institution) => {
    if (
      !selectedInstitutionsOnly ||
      (selectedInstitutionsOnly && institution.selected)
    ) {
      agg.push(institution[property] as unknown as T);
    }

    return agg.concat(
      getSubInstitutionsData(
        institution.subInstitutions,
        selectedInstitutionsOnly,
        property
      )
    );
  }, []);
};

type getSubInstitutionSelectableOptions = {
  selectedCount: number;
  selectableCount: number;
};

export const getSubInstitutionsCount = (
  institutions: InstitutionSetting[],
  parentInstitution?: InstitutionSetting
) => {
  return institutions.reduce<getSubInstitutionSelectableOptions>(
    (agg, institution) => {
      const { selectedCount: currSelected, selectableCount: currSelectable } =
        getSubInstitutionsCount(institution.subInstitutions);

      agg.selectableCount += currSelectable + (institution.selectable ? 1 : 0);
      agg.selectedCount += currSelected + (institution.selected ? 1 : 0);

      return agg;
    },
    {
      selectedCount: parentInstitution?.selected ? 1 : 0,
      selectableCount: parentInstitution?.selectable ? 1 : 0
    }
  );
};

export const selectInstitutions = (
  institutions: InstitutionSetting[],
  isSelected: boolean
): InstitutionSetting[] => {
  return institutions.map((institution) => {
    return {
      ...institution,
      selected: institution.selectable && isSelected,
      subInstitutions: selectInstitutions(
        institution.subInstitutions,
        isSelected
      )
    };
  });
};

export const updateInstitution = (
  allInstitutions: InstitutionSetting[],
  isSelected: boolean,
  institution: InstitutionSetting
): InstitutionSetting[] => {
  const updatedInstitutions = [...allInstitutions];

  for (let i = 0; i < allInstitutions.length; i++) {
    const { updatedInstitution, updated } = updateInstitutionById(
      allInstitutions[i],
      institution,
      isSelected
    );

    if (updated) {
      updatedInstitutions[i] = updatedInstitution;
      break;
    }
  }

  return updatedInstitutions;
};

export const updateInstitutionById = (
  currInstitution: InstitutionSetting,
  institutionToUpdate: InstitutionSetting,
  isSelected: boolean
): { updatedInstitution: InstitutionSetting; updated: boolean } => {
  if (currInstitution.id === institutionToUpdate.id) {
    // We copy the original institution (since the institutionToUpdate may not have subInstitutions)
    const updatedInstitution = {
      ...currInstitution,
      selected: isSelected && institutionToUpdate.selectable
    };

    // We check if the institutionToUpdate has sub institutions, if so - update all the sub institutions
    if (institutionToUpdate.subInstitutions.length > 0) {
      updatedInstitution.subInstitutions = selectInstitutions(
        updatedInstitution.subInstitutions,
        isSelected
      );
    }

    return { updatedInstitution, updated: true };
  }

  if (currInstitution.subInstitutions.length > 0) {
    for (let i = 0; i < currInstitution.subInstitutions.length; i++) {
      const { updatedInstitution, updated } = updateInstitutionById(
        currInstitution.subInstitutions[i],
        institutionToUpdate,
        isSelected
      );

      if (updated) {
        return {
          updatedInstitution: {
            ...currInstitution,
            subInstitutions: [
              ...currInstitution.subInstitutions.slice(0, i),
              updatedInstitution,
              ...currInstitution.subInstitutions.slice(i + 1)
            ]
          },
          updated: true
        };
      }
    }
  }

  return { updatedInstitution: currInstitution, updated: false };
};
