import {
  Dialog,
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSize,
  getSpacing,
  IconFail,
  IconOk
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledTroubleShootingDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 90vw;
    max-height: 90vh;
    width: ${getSize(180)};
    ${getFontSize('base')};
  }

  .MuiDialogContent-root {
    padding: 0 ${getSpacing(6)};
  }
`;

export const StyledTroubleshootingSection = styled.ol`
  padding-left: ${getSpacing(4)};
  margin-bottom: ${getSpacing(12)};
  margin-top: ${getSpacing(2)};
`;

export const StyledStatusIndicator = styled.div<{ status: boolean | null }>`
  margin-bottom: ${getSpacing(2)};
  color: ${(props) =>
    props.status === false ? getBaseColor('error') : 'inherit'};
  justify-content: space-between;
  display: flex;
`;

export const StyledTroubleShootingDescription = styled.p`
  margin-bottom: ${getSpacing(8)};
`;

export const StyledDialogContent = styled.div`
  width: 80%;
  padding-bottom: ${getSpacing(6)};
`;

export const StyledStatusHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${getSpacing(0)};
  font-weight: ${getFontWeight('font-weight-bold-700')};
`;

export const StyledIconFail = styled(IconFail)`
  margin-right: ${getSpacing(3)};
  & path {
    fill: ${getBaseColor('error')};
  }
`;

export const StyledIconOk = styled(IconOk)`
  margin-right: ${getSpacing(3)};
  & path {
    fill: ${getBaseColor('success')};
  }
`;
