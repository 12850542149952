export const Paths = {
  LOGIN: '/login',
  LVO_SUSPECTED: '/lvo-suspected',
  ICH_SUSPECTED: '/ich-suspected',
  CASES: '/cases',
  SEARCH: '/search',
  PATIENT_DATA: '/patient-data',
  DOCUMENTATION: '/documentation',
  POST_SSO: '/sso-callback',
  ADMIN: '/admin',
  EXTERNAL_PATIENT: '/external-patient',
  CHAT: '/chat/:patientId',
  INVITATION: '/invitation',
  DESKTOP_PATIENT_DATA_BY_NOTIFICATION:
    '/desktop/notifications/:id/patient-data'
} as const;

export type Pathname = typeof Paths[keyof typeof Paths];
