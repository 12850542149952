import { localize } from '@viz/i18n';
import { IS_RENDERER_SUPPORTED, RENDERER_FEATURE_FLAG } from '@viz/utils';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { isViewerVisibleState, shouldCheckRendererState } from '../../store';
import { StyledLink, StyledSnackbar, StyledSnackbarContent } from './styles';

const UnsupportedRendererSnackbar = () => {
  const setShouldCheckRenderer = useSetRecoilState(shouldCheckRendererState);
  const isViewerVisible = useRecoilValue(isViewerVisibleState);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isViewerVisible) {
      setOpen(RENDERER_FEATURE_FLAG && !IS_RENDERER_SUPPORTED);
    }
  }, [isViewerVisible]);

  const showDialog = function () {
    setShouldCheckRenderer(true);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const message = (
    <StyledSnackbarContent>
      <span> {localize('graphicsSettings')}</span>
      <StyledLink
        data-testid="change-driver-link"
        underline="always"
        onClick={showDialog}
      >
        {localize('changeDriver')}
      </StyledLink>
    </StyledSnackbarContent>
  );

  return (
    <StyledSnackbar
      data-testid="unsupported-renderer-snackbar"
      open={open}
      message={message}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      withCloseIcon
      disableClickaway
    />
  );
};

export default UnsupportedRendererSnackbar;
