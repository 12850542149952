import {
  getBaseColor,
  getSize,
  getSpacing,
  Grid,
  IconLogo,
  Typography
} from '@viz/design-system';
import styled from 'styled-components';

export const AppLogo = styled(IconLogo)`
  position: absolute;
  margin-top: ${getSpacing(20)};
  left: ${getSpacing(20)};
  width: ${getSize(21.25)};
  height: ${getSize(17)};
`;
export const LoginPage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const LoginGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginSideBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => `
    linear-gradient(300deg, ${theme.baseColors['light-gradient-blue']}, ${theme.baseColors['dark-gradient-blue']}, transparent),
    linear-gradient(350deg, ${theme.baseColors['dark-gradient-blue']}, ${theme.baseColors['light-gradient-blue']}, transparent);
  `};
`;

export const StyledVersionNumber = styled(Typography)`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: ${getSpacing(5)};
  color: ${getBaseColor('porcelain')};
`;
