import { Badge, IconInfoError, IconMessage } from '@viz/design-system';
import React from 'react';

import { AlgoDetectionIcon } from '../../../components';
import { usePatientChannels } from '../../../hooks';
import {
  StyledIconButton,
  StyledPatientIconsContainer,
  StyledIconContainer
} from './styles';

export interface PatientIconsProps {
  patientId: string;
  highlightIcons: string[];
  unreadMessagesCount: number;
  isFailed: boolean;
  onMessageIconClick: () => void;
}

const extractTextFromUrl = (url: string) => {
  // Match text between last slash and .svg
  const regex = /\/([^/]+?)\.svg/;
  const match = url.match(regex);
  return match ? match[1] : '';
};

export const PatientIcons = ({
  patientId,
  highlightIcons,
  unreadMessagesCount,
  isFailed,
  onMessageIconClick
}: PatientIconsProps): JSX.Element => {
  const { totalUnreadMessages } = usePatientChannels({
    patientId,
    defaultCounter: unreadMessagesCount
  });
  return (
    <StyledPatientIconsContainer>
      {highlightIcons.map((iconUrl, index) => (
        <StyledIconContainer data-testid="test-icon" key={index}>
          <AlgoDetectionIcon
            iconUrl={iconUrl}
            testId={extractTextFromUrl(iconUrl)}
          />
        </StyledIconContainer>
      ))}
      {/*if there are highlightIcons and failure, there will already be an error icon*/}
      {!highlightIcons.length && isFailed && (
        <IconInfoError data-testid="error" />
      )}
      {totalUnreadMessages > 0 && (
        <StyledIconContainer data-testid="test-icon">
          <Badge
            testId="patient-messages-badge"
            badgeContent={totalUnreadMessages}
            color="primary"
          >
            <StyledIconButton
              onClick={onMessageIconClick}
              data-testid={`chat-btn`}
              role={unreadMessagesCount ? 'chat-badge-unread' : 'chat-badge'}
            >
              <IconMessage />
            </StyledIconButton>
          </Badge>
        </StyledIconContainer>
      )}
    </StyledPatientIconsContainer>
  );
};
