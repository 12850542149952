import { IconMessages, IconSizes, Loading, IconPlus } from '@viz/design-system';
import { localize } from '@viz/i18n';
import React, { useState, useEffect } from 'react';
import {
  Channel,
  ChannelList,
  MessageInput,
  MessageList
} from 'stream-chat-react';

import { useStreamChat } from '../../../../context';
import { withVisibility } from '../../../../hocs';
import { usePatientChannels } from '../../../../hooks';
import CustomChannelPreview from './CustomChannelPreview';
import {
  StyledPatientChannelsContainer,
  StyledLoaderContainer,
  StyledEmptyContent
} from './styles';

export type PatientChannelsProps = {
  patientId: string;
};

export enum ChannelTypes {
  PATIENT_CONVERSATION = 'patient_conversation',
  TEAM = 'team_conversation'
}

const PatientChannels = ({ patientId }: PatientChannelsProps): JSX.Element => {
  const { client } = useStreamChat();
  const { filters, sort } = usePatientChannels({ patientId });
  const [activeChannel, setActiveChannel] = useState<string | null>(null);
  const channel =
    client &&
    activeChannel &&
    client.channel(ChannelTypes.PATIENT_CONVERSATION, activeChannel);

  const options = {
    watch: true,
    state: true,
    message_limit: 1
  };
  useEffect(() => {
    setActiveChannel(null);
  }, [filters]);

  return (
    <StyledPatientChannelsContainer>
      <ChannelList
        filters={filters}
        sort={sort}
        Preview={(props) => (
          <CustomChannelPreview
            {...props}
            onSelectChannel={(id) => setActiveChannel(id)}
          />
        )}
        options={options}
        LoadingIndicator={() => (
          <StyledLoaderContainer>
            <Loading size={IconSizes.LARGE} />
          </StyledLoaderContainer>
        )}
        EmptyStateIndicator={() => (
          <StyledEmptyContent
            title={localize('emptyChannelsTitle')}
            subTitle={localize('emptyChannelsDescription')}
            icon={<IconMessages />}
            buttonDetails={{
              text: localize('createChannel'),
              icon: <IconPlus size={IconSizes.X_SMALL} />,
              onClick: () => {}
            }}
          />
        )}
      />
      {channel && (
        <Channel channel={channel}>
          <MessageList />
          <MessageInput />
        </Channel>
      )}
    </StyledPatientChannelsContainer>
  );
};

export default withVisibility(PatientChannels);
