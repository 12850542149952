import { Ecg, Study } from '@viz/api';
import { getActiveLanguage } from '@viz/i18n';
import { getDateAndTime, isCurrentYear } from '@viz/utils';

import { StyledStudySubtitle } from './styles';

type StudySubtitleProps = {
  exam: Study | Ecg;
};

export const getDateSubtitle = (date: Date) => {
  const year = isCurrentYear(date) ? '' : 'yyyy ';
  return getDateAndTime(Number(date), {
    formatting: `HH:mm • MMM dd ${year}`,
    language: getActiveLanguage()
  });
};

const StudySubtitle = ({ exam }: StudySubtitleProps): JSX.Element => {
  const dateSubtitle = getDateSubtitle(new Date(exam.lastArrivalTs));
  const accText =
    'accessionNumber' in exam ? `• ACC ${exam.accessionNumber}` : '';
  const referringPhysicianText =
    'referringPhysicianName' in exam && exam.referringPhysicianName
      ? ` • ${exam.referringPhysicianName}`
      : '';

  return (
    <>
      <div data-testid="study-date-subtitle">{`${dateSubtitle}${accText}${referringPhysicianText}`}</div>
      <StyledStudySubtitle data-testid="study-institution">
        {exam.institutionName}
      </StyledStudySubtitle>
    </>
  );
};

export { StudySubtitle };
