import {
  Button,
  getFontSize,
  getFontWeight,
  getSize
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledInvitationContainer = styled.div`
  padding: ${getSize(5)};
  font-size: ${getFontSize('xl')};
  font-weight: ${getFontWeight('font-weight-bold-600')};
`;

export const ReturnToHomePageButton = styled(Button)`
  && {
    ${getFontSize('sm')};
    background-color: transparent;
    text-decoration: underline;
    border: none;
    cursor: pointer;
  }
`;
