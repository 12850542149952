/* eslint-disable react-hooks/exhaustive-deps */
import { useNotificationMessageData } from '@viz/api';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { patientTabDialogState } from '../../store';
import { DesktopAppEvents } from './consts';
import { isCustomEvent } from './utils';

export const useDesktopNotificationClickedEvent = () => {
  const getPatientByNotification = useNotificationMessageData();
  const setPatientTabDialogData = useSetRecoilState(patientTabDialogState);

  useEffect(() => {
    const handleNotificationClickedEvent = (e: CustomEvent) => {
      const notificationId = e.detail.notificationId;
      getPatientByNotification.mutate(
        { notification_uid: notificationId },
        {
          onSuccess: (data) => {
            setPatientTabDialogData({
              tabUrlData: { id: data.viz_patient_id }
            });
          }
        }
      );
    };

    window.addEventListener(
      DesktopAppEvents.OnNotificationClicked,
      (event) => isCustomEvent(event) && handleNotificationClickedEvent(event)
    );
    return () => {
      window.removeEventListener(
        DesktopAppEvents.OnNotificationClicked,
        (event) => isCustomEvent(event) && handleNotificationClickedEvent(event)
      );
    };
  }, []);
};
