import styled from 'styled-components';

export const ConnectionErrorContainer = styled.div`
  display: flex;
  text-align: center;
  height: 100vh;
  position: relative;
`;

export const IconLogoContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 4%;
`;
