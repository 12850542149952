import { IconBack, IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { capitalize } from '@viz/utils';

import { BackButton, CenteredText, Container } from './styles';

type PasswordStepHeaderProps = {
  username: string;
  onBackClick: () => void;
};

const PasswordStepHeader = (props: PasswordStepHeaderProps): JSX.Element => {
  return (
    <Container>
      <BackButton
        color="inherit"
        variant="text"
        onClick={props.onBackClick}
        testId={'back-button'}
      >
        <IconBack size={IconSizes.SMALL} />
        {capitalize(localize('back'))}
      </BackButton>
      <CenteredText data-testid={'password-header-username'}>
        {props.username}
      </CenteredText>
    </Container>
  );
};

export default PasswordStepHeader;
