import { localize, TranslationKey } from '@viz/i18n';

import { StyledLabel } from './styles';

interface FormLabelProps {
  translationKey: TranslationKey;
  required?: boolean;
}

const FormLabel = ({ translationKey, required }: FormLabelProps) => (
  <StyledLabel>
    {localize(translationKey)}
    {required && '*'}
  </StyledLabel>
);

export default FormLabel;
