import { analyticsService } from '@viz/analytics';
import { ChatWorkspace, useShiftStatus } from '@viz/api';
import { getDeviceUid } from '@viz/utils';
import { useCallback, useEffect } from 'react';

import { useUserProfile } from '../useUserProfile';
import { AnalyticsEventName } from './constants';
import { isVizUser } from './utils';

export type UseAnalyticsEvent = {
  sendEvent: (
    eventName: AnalyticsEventName,
    eventData?: Record<string, unknown>
  ) => void;
};

const useAnalyticsEvent = (): UseAnalyticsEvent => {
  const { user } = useUserProfile();
  const { data: onShiftData } = useShiftStatus({
    enabled: Boolean(user),
    refetchOnWindowFocus: false
  });

  const sendEvent = useCallback(
    (
      eventName: AnalyticsEventName,
      eventData: Record<string, unknown> = {}
    ): void => {
      analyticsService.sendEvent(eventName, {
        user_uid: user?.user_uid,
        user_full_name: user?.name,
        email: user?.email,
        role_id: user?.role_id,
        is_on_call: onShiftData?.on_shift,
        device_uid: getDeviceUid(),
        is_internal_user: isVizUser(user?.email),
        workspace: ChatWorkspace.Commercial.toLowerCase(),
        language: 'en', // could get from i18n.language, but spec is to return 'en' not 'en-us', and language selection is not implemented or even on the roadmap, so avoiding that complexity for now
        environment: window.env.NODE_ENV,
        origin: window.vizWidget ? 'desktop-app' : 'web',
        desktop_app_version: window.vizWidget?.version,
        main_account_id: user?.main_customer_account ?? 'N/A',
        ...eventData
      });
    },
    [
      onShiftData?.on_shift,
      user?.name,
      user?.email,
      user?.role_id,
      user?.user_uid,
      user?.main_customer_account
    ]
  );

  return { sendEvent };
};

const useOnMountEvent = (
  eventName: AnalyticsEventName,
  eventData: Record<string, unknown> = {}
): void => {
  const { sendEvent } = useAnalyticsEvent();

  const comparableEventData = JSON.stringify(eventData);

  useEffect(() => {
    sendEvent(eventName, eventData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, comparableEventData]);
};

export { useAnalyticsEvent, useOnMountEvent };
