import { UserBasePayload } from '@viz/api';

type UserBasePayloadDirtyStatus = {
  [key in keyof UserBasePayload]?: true | true[];
};

export const filterByDirtyFields = (
  data: UserBasePayload,
  dirtyFields: UserBasePayloadDirtyStatus
): UserBasePayload => {
  const dirtyFieldNameList = Object.keys(
    dirtyFields
  ) as (keyof UserBasePayload)[];

  return dirtyFieldNameList.reduce(
    (result, fieldName) => ({ ...result, [fieldName]: data[fieldName] }),
    {}
  );
};
