import { localize } from '@viz/i18n';

import { StyledVerifyPhone } from './styles';

type VerifyPhoneProps = {
  number: string;
};

const VerifyPhone = ({ number }: VerifyPhoneProps) => {
  return (
    <StyledVerifyPhone>
      <div>{localize('verifyPhoneInfo')}</div>
      <div>{localize('verifyPhoneQuestion', number)}</div>
    </StyledVerifyPhone>
  );
};

export { VerifyPhone };
