import {
  Autocomplete,
  getSize,
  getSpacing,
  getFontSize
} from '@viz/design-system';
import styled, { css } from 'styled-components';

export const StyledSearchBox = styled(Autocomplete)(
  () => css`
    margin-right: ${getSpacing(5)};
    margin-left: ${getSpacing(5)};
    flex: 1;
    max-width: 260px;

    .MuiAutocomplete-input {
      padding: ${getSpacing(1)} !important;
      margin-left: ${getSpacing(1)};
      transition: min-width 0.4s;
    }

    .MuiInputBase-root {
      ${getFontSize('sm')};
      padding: ${getSize(2.25)} ${getSize(3.5)} !important;
    }
  `
);
