import { Button, getBaseColor, getFontSize, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h2`
  ${getFontSize('2xl')}
`;

export const SubmitButton = styled(Button)`
  && {
    margin-right: ${getSize(8)};
    height: ${getSize(10)};
  }
`;

export const DeactivateUserButton = styled(Button)`
  &.MuiButton-outlined {
    height: ${getSize(10)};
    border-color: ${getBaseColor('error')};
    color: ${getBaseColor('error')};
    margin-right: ${getSize(4)};
  }
`;
