import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getSize,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledProfileSettings = styled.div`
  display: flex;
  flex-direction: column;
  width: ${getSize(150)};
  height: ${getSize(65)};
  gap: ${getSpacing(7)};
  background-color: ${getBaseColor('ship-gray')};
  padding: ${getSpacing(6)};
  border-radius: ${getBorderRadius('SMALL')};
  margin-top: ${getSpacing(2)};
`;

export const StyledProfileDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(0.5)};
`;

export const StyledLabel = styled.div`
  ${getFontSize('sm')};
  color: ${getBaseColor('french-gray')};
`;

export const StyledValue = styled.div`
  ${getFontSize('base')};
  color: ${getBaseColor('porcelain')};
`;
