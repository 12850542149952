import { UserBasePayload } from '@viz/api';
import { Switch } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FieldSection,
  FieldSectionInputs,
  FieldSectionTitle,
  StyledFormControlLabel
} from './styles';

interface CreationOptionsProps {
  form: UseFormReturn<UserBasePayload>;
  className?: string;
}

const CreationOption = ({
  form: { control },
  className
}: CreationOptionsProps) => {
  return (
    <FieldSection className={className}>
      <FieldSectionTitle>{localize('options')}</FieldSectionTitle>
      <FieldSectionInputs>
        <Controller
          name={'sendWelcomeEmail'}
          control={control}
          render={({ field }) => (
            <StyledFormControlLabel
              control={
                <Switch
                  checked={Boolean(field.value)}
                  onChange={field.onChange}
                />
              }
              label={localize('sendWelcomeEmail')}
            />
          )}
        />
      </FieldSectionInputs>
    </FieldSection>
  );
};

export default CreationOption;
