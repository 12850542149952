import { Grid, Widget } from '@viz/design-system';

import { BasicLayout } from '../../../../layout';
import { LayoutProps } from '../../../../layout/layouts/types';
import { SearchUser } from '../../components';
import { Paths } from '../../navigation';
import SideMenu from '../common';
import { StyledGrid } from './styles';

const AdminLayout = ({ children }: LayoutProps) => {
  return (
    <BasicLayout
      topBarWithTabs={false}
      searchComponent={<SearchUser />}
      logoHref={Paths.USERS}
    >
      <SideMenu />
      <Grid container>
        <StyledGrid item xs={12} lg={12}>
          <Widget container fillSpace height="100%">
            {children}
          </Widget>
        </StyledGrid>
      </Grid>
    </BasicLayout>
  );
};

export default AdminLayout;
