import { getFontSize, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledSubHeader = styled.div`
  ${getFontSize('sm')};
  overflow: hidden;
  span {
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:first-child):before {
      content: '|';
      margin: 0 ${getSize(1)};
    }
  }
`;
