import {
  BackgroundSectionItemTitleKeys,
  ClinicalInfoKeys,
  HCMSectionItemTitleKeys,
  ImagingSectionItemTitleKeys,
  TimingSectionItemTitleKeys,
  TreatmentSectionItemTitleKeys
} from '@viz/api';

import './types';
import { ClinicalInfoSectionTitle } from './types';

export const BACKGROUND_SECTION_ITEMS_TITLE_KEYS: BackgroundSectionItemTitleKeys[] =
  ['nihssScore', 'gcs', 'mrs', 'clinicalPresentation'];

export const TIMING_SECTION_ITEMS_TITLE_KEYS: TimingSectionItemTitleKeys[] = [
  'lastKnownWellTime',
  'witnessedOnset',
  'doorIn',
  'doorOut',
  'cscDoorIn',
  'procedureStart'
];

export const IMAGING_SECTION_ITEMS_TITLE_KEYS: ImagingSectionItemTitleKeys[] = [
  'ichScore',
  'aspectsScore',
  'radiologicalDiagnosis'
];

export const TREATMENT_SECTION_ITEMS_TITLE_KEYS: TreatmentSectionItemTitleKeys[] =
  ['tpaGiven', 'tpaGivenTime', 'intervention'];

export const HCM_SECTION_ITEMS_TITLE_KEYS: HCMSectionItemTitleKeys[] = [
  'patientStoryAndLatestPresentation',
  'additionalEchoInformation',
  'sam',
  'mitralRegurgitation',
  'pw',
  'lvotGradient',
  'ivs',
  'ef',
  'pcp',
  'cardiologist',
  'diagnosis'
];

export const sectionTitleToItemKeys: Record<
  ClinicalInfoSectionTitle,
  ClinicalInfoKeys[]
> = {
  background: BACKGROUND_SECTION_ITEMS_TITLE_KEYS,
  imaging: IMAGING_SECTION_ITEMS_TITLE_KEYS,
  treatment: TREATMENT_SECTION_ITEMS_TITLE_KEYS,
  timing: TIMING_SECTION_ITEMS_TITLE_KEYS,
  hcmClinicalInfo: HCM_SECTION_ITEMS_TITLE_KEYS
};
