import {
  UserBasePayload,
  useInstitutions,
  UserRestrictedFields
} from '@viz/api';
import { localize } from '@viz/i18n';
import { UseFormReturn } from 'react-hook-form';

import { RestrictedFieldHelper } from '../../../components';
import { StylesRegionInput } from '../../common/panelStyles';
import { MainCustomerAccountInput } from './components';
import {
  FieldSection,
  FieldSectionTitle,
  FieldSectionInputs,
  SelectGroup,
  MultipleSelectionInputGroup,
  StyledInstitutionsInput,
  StyledUserGroupsInput
} from './styles';

interface AssignmentProps {
  form: UseFormReturn<UserBasePayload>;
  restrictedFields?: Array<UserRestrictedFields>;
  className?: string;
}

const Assignments = ({
  form,
  restrictedFields,
  className
}: AssignmentProps) => {
  const { data: institutionList } = useInstitutions();

  return (
    <FieldSection className={className}>
      <FieldSectionTitle>{localize('assignments')}</FieldSectionTitle>
      <FieldSectionInputs>
        <RestrictedFieldHelper
          restrictedFields={restrictedFields}
          fieldName={'mainCustomerAccount'}
        >
          <MainCustomerAccountInput form={form} />
        </RestrictedFieldHelper>
        <MultipleSelectionInputGroup>
          <RestrictedFieldHelper
            restrictedFields={restrictedFields}
            fieldName={'institutions'}
          >
            <StyledInstitutionsInput
              form={form}
              institutionList={institutionList}
            />
          </RestrictedFieldHelper>
          <RestrictedFieldHelper
            restrictedFields={restrictedFields}
            fieldName={'userGroups'}
          >
            <StyledUserGroupsInput form={form} />
          </RestrictedFieldHelper>
        </MultipleSelectionInputGroup>
        <SelectGroup>
          <RestrictedFieldHelper
            restrictedFields={restrictedFields}
            fieldName={'region'}
          >
            <StylesRegionInput form={form} />
          </RestrictedFieldHelper>
        </SelectGroup>
      </FieldSectionInputs>
    </FieldSection>
  );
};

export default Assignments;
