import { Dialog, getFontSize, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledAcceptReferralDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: ${getSize(110)};
    height: ${getSize(60)};
  }
`;

export const StyledDialogContent = styled.div`
  ${getFontSize('base')}
`;
