import { getSpacing } from '@viz/design-system';
import { Card } from '@viz/design-system';
import styled from 'styled-components';

export const StyledPatientCard = styled(Card)`
  margin-bottom: ${getSpacing(1)};
`;
export const StyledPatientsCards = styled('div')`
  height: 100%;
  overflow-x: hidden;
  width: 100%;
  min-width: 300px;
  padding-right: ${getSpacing(5)};
`;

export const StyledLoader = styled('div')`
  position: relative;
`;

export const StyledCardActions = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: ${getSpacing(10)};
`;
