import {
  getBaseColor,
  getFontSize,
  getSize,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

import { ResendMfaCode } from '../../../../Mfa/ResendMfaCode';

export const StyledAuthenticatePhone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${getSize(7)};
  color: ${getBaseColor('porcelain')};
  ${getFontSize('sm')};
`;

export const StyledError = styled.div`
  width: 100%;
  margin-top: ${getSpacing(-2)};
`;

export const StyledResendMfaCode = styled(ResendMfaCode)`
  width: 100%;
`;
