import { Ecg, Patient } from '@viz/api';
import { Alert, Thumbnail } from '@viz/design-system';
import { useSetRecoilState } from 'recoil';

import { patientTabDialogState } from '../../../../../store';
import { getAlgoNameFromIconUrl } from '../utils';
import {
  AlgoIcon,
  ProcessingFailedIcon,
  ProcessingFailedMessage
} from './common';
import {
  StyledAlertContainer,
  StyledStudyContainer,
  StyledThumbnailContainer,
  StyledThumbnailsContainer
} from './styles';

export type StudyContainerProps = {
  patient: Patient;
  study: Ecg;
  activeStudyUid?: string;
};

const EcgThumbnailSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      style={{ borderRadius: '8px' }}
      height="100%"
      viewBox="0 0 104 104"
      fill="none"
    >
      <rect width="104" height="104" rx="4" fill="black" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M98.9251 74.4347C98.0116 74.2817 97.2543 74.0811 96.6152 73.84C95.0941 73.2661 94.2031 72.4481 93.4562 71.3787C93.0706 70.8268 92.7216 70.2043 92.3411 69.4882C92.2405 69.2988 92.1373 69.1017 92.0306 68.8986C91.7421 68.3486 91.4291 67.7516 91.0807 67.1354C90.1121 65.4222 88.8307 63.4835 86.7382 61.3878C84.515 59.1614 82.0705 58.489 79.6301 58.9557C77.2636 59.4082 75.0159 60.9088 73.0304 62.7762C69.2671 66.3153 66.1278 71.4761 64.524 74.7782H50.0332L44.4756 19.7596C44.4274 19.281 44.0271 18.9149 43.546 18.9092C43.065 18.9036 42.6564 19.2602 42.5968 19.7377L35.0169 80.5196L33.1356 75.3977C32.9989 75.0256 32.6446 74.7782 32.2482 74.7782H24.9332C24.3525 72.609 22.9777 69.3984 21.0531 67.3025C19.9685 66.1215 18.5825 65.1584 16.949 65.1261C15.2791 65.093 13.6661 66.0341 12.1559 67.9564C9.78598 70.973 7.08728 72.7328 4.73364 73.707C4.99778 74.2813 5.27208 74.8499 5.5563 75.4126C8.16939 74.3134 11.0926 72.3705 13.6428 69.1246C14.9542 67.4553 16.0622 66.9998 16.9116 67.0166C17.7975 67.0342 18.7294 67.5676 19.6603 68.5815C21.527 70.6143 22.8795 74.0306 23.2594 75.9109C23.3485 76.352 23.7361 76.6691 24.1861 76.6691H31.5883L34.5854 84.8294C34.7359 85.2387 35.147 85.4923 35.5806 85.4426C36.014 85.393 36.3572 85.0532 36.4111 84.6202L43.4364 28.2857L48.3236 76.6691H65.1217C65.49 76.6691 65.8249 76.4553 65.9798 76.121C67.4292 72.99 70.587 67.6699 74.3259 64.1538C76.1988 62.3922 78.1294 61.1678 79.9852 60.8129C81.767 60.4722 83.5885 60.9097 85.4 62.7239C87.3419 64.6687 88.526 66.4588 89.4346 68.0659C89.7672 68.6543 90.0586 69.2097 90.3428 69.7516C90.4527 69.9611 90.5614 70.1686 90.6713 70.3753C91.0578 71.1029 91.4511 71.8103 91.906 72.4615C92.8403 73.7991 94.0225 74.8828 95.9477 75.6091C96.5681 75.8432 97.2605 76.0381 98.0405 76.1945C98.3461 75.6142 98.6411 75.0275 98.9251 74.4347Z"
        fill="white"
      />
    </svg>
  );
};

const EcgStudyContainer = ({
  patient,
  study,
  activeStudyUid
}: StudyContainerProps): JSX.Element => {
  const setPatientTabDialogData = useSetRecoilState(patientTabDialogState);

  const handleThumbnailClick = (ecg_id: string) => {
    setPatientTabDialogData({
      tabUrlData: { id: patient.id, ecg_id: ecg_id },
      patientName: patient.name
    });
  };

  const { highlightIcons, isFailed } = study;
  const uniqueIcons = [...new Set(highlightIcons)];
  const header = (
    <>
      {!uniqueIcons.length && isFailed ? (
        <ProcessingFailedIcon />
      ) : (
        uniqueIcons.map((iconUrl) => {
          const algoName = getAlgoNameFromIconUrl(iconUrl);
          return (
            <AlgoIcon key={algoName} algoName={algoName} iconUrl={iconUrl} />
          );
        })
      )}
    </>
  );
  return (
    <StyledStudyContainer>
      {study.isFailed && (
        <StyledAlertContainer>
          <Alert
            testId="menu-alert"
            text={<ProcessingFailedMessage />}
            type="error"
          />
        </StyledAlertContainer>
      )}
      <StyledThumbnailsContainer container>
        <StyledThumbnailContainer item key={study.uid}>
          {
            <Thumbnail
              key={study.uid}
              svg={EcgThumbnailSvg()}
              isActive={activeStudyUid === study.uid}
              size="100%"
              testId={`series-id-${study.uid}`}
              onClick={() => handleThumbnailClick(study.uid)}
              header={header}
            />
          }
        </StyledThumbnailContainer>
      </StyledThumbnailsContainer>
    </StyledStudyContainer>
  );
};

export { EcgStudyContainer };
