import {
  Accordion,
  getBaseColor,
  getBorderRadius,
  getSize,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

import { InstitutionCheckbox } from './InstitutionCheckbox';
import { SubInstitutions } from './SubInstitutions';

export const StyledInstitutionFilter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${getSize(5)};
  padding-bottom: ${getSpacing(4)};
`;

export const StyledSingleInstitutionContainer = styled.div`
  width: 100%;
  border-radius: ${getBorderRadius('SMALL')};
  background-color: ${getBaseColor('ship-gray')};
  padding: ${getSize(2)};
`;

export const StyledInstitutionAccordion = styled(Accordion)`
  width: 100%;

  &&.MuiAccordion-root {
    margin: 0;
    border-radius: ${getBorderRadius('SMALL')};
    background-color: ${getBaseColor('ship-gray')};
  }

  && {
    .MuiAccordionSummary-root {
      min-height: unset;
      padding: ${getSpacing(1)} ${getSpacing(3)};
      height: auto;

      :hover {
        background-color: ${getBaseColor('arsenic')};
      }

      &.Mui-expanded {
        border-bottom: 1px solid ${getBaseColor('waterloo', 0.2)};
      }
    }
  }
`;

export const StyledInstitutionCheckbox = styled(InstitutionCheckbox)`
  padding: ${getSpacing(2)};
`;

export const StyledSubInstitutions = styled(SubInstitutions)`
  padding-top: ${getSpacing(1)};
`;
