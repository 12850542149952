import { IconSizes } from '@viz/design-system';
import { useEffect, useState } from 'react';

import { TEST_IDS } from './constants';
import { StyledIconFail, StyledIconOk, StyledStatusIndicator } from './styles';

export type Status = boolean | null;

type StatusIndicatorProps = {
  name: string;
  check: () => Promise<boolean>;
  testId?: string;
};

type IndicatorIconProps = {
  status: Status;
};

const IndicatorIcon = ({ status }: IndicatorIconProps): JSX.Element => {
  if (status === null) return <></>;

  return status ? (
    <StyledIconOk size={IconSizes.SMALL} data-testid={TEST_IDS.ok} />
  ) : (
    <StyledIconFail size={IconSizes.SMALL} data-testid={TEST_IDS.fail} />
  );
};

export const StatusIndicator = ({
  name,
  testId,
  check
}: StatusIndicatorProps) => {
  const [status, setStatus] = useState<Status>(null);

  useEffect(() => {
    (async () => {
      setStatus(await check());
    })();
  }, [check]);

  return (
    <StyledStatusIndicator status={status} data-testid={testId}>
      {name}
      <IndicatorIcon status={status} />
    </StyledStatusIndicator>
  );
};
