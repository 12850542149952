import { useCallback, useEffect, useState } from 'react';

const MIN_LOADING_THRESHOLD = 400; // The minimum duration that must elapse for the request to be considered fast, in milliseconds.
const CHECK_LOADING_INTERVAL = 50;

type MutationResults = { isLoading: boolean; isIdle: boolean };
type QueryResults = { isInitialLoading: boolean };

export const useQueryWithLoadingThreshold = <
  T extends MutationResults | QueryResults
>(
  queryResults: T
) => {
  const isLoading = getIsLoading(queryResults);
  const [showLoader, setShowLoader] = useState(false);

  const isMinLoadingTimePassed = (startTime: number) => {
    const elapsedTime = Date.now() - startTime;
    return elapsedTime >= MIN_LOADING_THRESHOLD;
  };

  const startLoadingCheckInterval = useCallback((startTime: number) => {
    const intervalId = setInterval(() => {
      if (isMinLoadingTimePassed(startTime)) {
        setShowLoader(true);
        clearInterval(intervalId);
      }
    }, CHECK_LOADING_INTERVAL);

    return intervalId;
  }, []);

  useEffect(() => {
    let queryStartTime = Date.now();
    let loadingCheckInterval: NodeJS.Timer;

    if (!isLoading) {
      setShowLoader(false);
    } else {
      loadingCheckInterval = startLoadingCheckInterval(queryStartTime);
    }

    return () => {
      if (loadingCheckInterval) {
        clearInterval(loadingCheckInterval);
      }
    };
  }, [isLoading, startLoadingCheckInterval]);

  return { ...queryResults, isLoading: showLoader };
};

function isQueryResultsLoading(results: unknown): results is QueryResults {
  return (results as QueryResults).isInitialLoading !== undefined;
}

function getIsLoading(results: QueryResults | MutationResults) {
  // As React Query V4 modified the way it manages loading and idle states in queries, we had to identify whether a query is in a loading or pending state.
  // see https://tanstack.com/query/v4/docs/react/guides/disabling-queries#isinitialloading for more information
  if (isQueryResultsLoading(results)) {
    return results.isInitialLoading;
  }

  return results.isIdle ? false : results.isLoading;
}
