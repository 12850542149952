import { getSpacing, Grid, Widget } from '@viz/design-system';
import styled from 'styled-components';

import { PageTitle } from '../../components';

export const DocumentationLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SettingsGrid = styled(Grid)`
  width: 100%;
  padding: ${getSpacing(0)};
`;

export const StyledWidget = styled(Widget)`
  margin: ${getSpacing(4)};
`;

export const StyledDocumentsContainer = styled(Widget)`
  margin: ${getSpacing(4)};
  height: 100%;
`;

export const StyledPageTitle = styled(PageTitle)`
  margin: ${getSpacing(0)};
`;
