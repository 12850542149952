import { getFontSize } from '@viz/design-system';
import styled from 'styled-components';

export const UserGroupContainer = styled.span`
  ${getFontSize('base')};
`;

export const InstitutionMetadata = styled.div`
  ${getFontSize('base')};
`;

export const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0 0 16px;
  ${getFontSize('sm')};
`;

export const SpecContent = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 0 8px 8px;
`;

export const CategoryLabel = styled.span`
  display: inline-block;
  width: 80px;
`;

export const CategoryValue = styled.span`
  display: inline-block;
  width: 180px;
`;
