import { withAuth } from '../../hocs';
import { useLogoutWatcher, useTokenWatcher } from '../../hooks';

const SessionWatcher = () => {
  useLogoutWatcher();
  useTokenWatcher();

  return null;
};

export default withAuth(SessionWatcher);
