import type { MouseEventHandler, ReactElement } from 'react';

import {
  StyledDivider,
  StyledMenuItem,
  StyledTypography,
  StyledWrapper
} from './styles';

export type UserMenuItemProps = {
  onClick?: MouseEventHandler<HTMLDivElement>;
  text: string;
  icon: ReactElement<SVGElement>;
  testId?: string;
  leadingDivider?: boolean;
};

export const UserMenuItem = ({
  text,
  icon,
  onClick,
  testId,
  leadingDivider = false
}: UserMenuItemProps) => {
  return (
    <StyledWrapper>
      {leadingDivider && <StyledDivider />}
      <StyledMenuItem data-testid={testId} onClick={onClick}>
        {icon ? icon : null}
        {text && <StyledTypography>{text}</StyledTypography>}
      </StyledMenuItem>
    </StyledWrapper>
  );
};
