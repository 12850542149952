import { Menu, MenuItem } from '@material-ui/core';
import { Button } from '@viz/design-system';
import { changeLanguage, Language } from '@viz/i18n';
import { MouseEvent, useState } from 'react';

export const languageMenuOptions = [
  { label: 'English', value: Language.EN },
  { label: 'Français', value: Language.FR }
];

const LanguageSelect = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChangeLangClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const selectLanguage = (lang: string, index: number) => {
    setSelectedIndex(index);
    changeLanguage(Language[lang.toUpperCase() as keyof typeof Language]);
    setAnchorEl(null);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        aria-controls="language-selection-menu"
        aria-haspopup="true"
        onClick={handleChangeLangClick}
      >
        {languageMenuOptions[selectedIndex].label}
      </Button>
      <Menu
        id="language-selection-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {Object.keys(languageMenuOptions).map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={() =>
              selectLanguage(languageMenuOptions[index].value, index)
            }
          >
            {languageMenuOptions[index].label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelect;
