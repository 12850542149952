import {
  InstitutionSetting,
  institutionSettingsTransform,
  usePatients,
  useUpdateUserInstitutionSettings,
  useUserInstitutionSettings
} from '@viz/api';
import { localize } from '@viz/i18n';
import { getDeviceUid } from '@viz/utils';
import { useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { toastStatusState } from '../../store';
import { AnalyticsEventName, useAnalyticsEvent } from '../useAnalytics';
import {
  getSubInstitutionsCount,
  getSubInstitutionsData,
  selectInstitutions,
  updateInstitution
} from './utils';

type UseInstitutionSettingsProps = {
  enabled: boolean;
};

export type UseInstitutionSettingsOptions = {
  institutionsData: {
    institutions: InstitutionSetting[];
    selectedCount: number;
    selectableCount: number;
  };
  isSettingsChanged: boolean;
  isSavingChanges: boolean;
  isError: boolean;
  onInstitutionToggle: (
    isSelected: boolean,
    institution?: InstitutionSetting
  ) => void;
  onSave: () => void;
  onDiscard: () => void;
};

export const useInstitutionSettings = ({
  enabled
}: UseInstitutionSettingsProps): UseInstitutionSettingsOptions => {
  const deviceUid = getDeviceUid();
  const { sendEvent } = useAnalyticsEvent();
  const setToastStatus = useSetRecoilState(toastStatusState);
  const { refetch: refetchPatientsList } = usePatients({ enabled: false });

  const [isSettingsChanged, setIsSettingsChanged] = useState(false);
  const [localInstitutions, setLocalInstitutions] = useState<
    InstitutionSetting[]
  >([]);

  const { data: userInstitutionSettings } = useUserInstitutionSettings(
    { device_uid: deviceUid },
    {
      enabled,
      select: institutionSettingsTransform,
      refetchOnWindowFocus: false,
      onSuccess: ({ institutions }) => {
        setLocalInstitutions(institutions);
      }
    }
  );

  const { mutate: updateInstitutionSettings, isLoading: isSavingChanges } =
    useUpdateUserInstitutionSettings({
      onSuccess: () => {
        setToastStatus({
          type: 'info',
          text: localize('changesSavedSuccessfully')
        });
        setIsSettingsChanged(false);
        refetchPatientsList();
      },
      onError: () => {
        setToastStatus({
          type: 'error',
          text: localize('failedSavingChanges')
        });
      }
    });

  const handleSelectAll = (isSelected: boolean) => {
    sendEvent(
      isSelected
        ? AnalyticsEventName.INSTITUTION_FILTER_CHECK_ALL_CLICKED
        : AnalyticsEventName.INSTITUTION_FILTER_UNCHECK_ALL_CLICKED,
      {
        currently_checked_institutions_count: selectedCount,
        currently_unchecked_institutions_count:
          selectableCount && selectedCount && selectableCount - selectedCount
      }
    );

    setLocalInstitutions((currInstitutions) =>
      selectInstitutions(currInstitutions, isSelected)
    );
  };

  const handleInstitutionToggle = (
    institution: InstitutionSetting,
    isSelected: boolean
  ) => {
    if (isSelected) {
      sendEvent(AnalyticsEventName.INSTITUTION_FILTER_ITEM_MANUALLY_CHECKED, {
        is_root_node: institution.subInstitutions.length > 0,
        checked_institution: institution.name
      });
    } else {
      sendEvent(AnalyticsEventName.INSTITUTION_FILTER_ITEM_MANUALLY_UNCHECKED, {
        is_root_node: institution.subInstitutions.length > 0,
        unchecked_institution: institution.name
      });
    }

    setLocalInstitutions((currInstitutions) =>
      updateInstitution(currInstitutions, isSelected, institution)
    );
  };

  const onInstitutionToggle = (
    isSelected: boolean,
    institution?: InstitutionSetting
  ) => {
    if (institution) {
      handleInstitutionToggle(institution, isSelected);
    } else {
      handleSelectAll(isSelected);
    }

    setIsSettingsChanged(true);
  };

  const onSave = () => {
    const selectedInstitutions = getSubInstitutionsData<number>(
      localInstitutions,
      true
    );

    updateInstitutionSettings({
      selected_institutions: selectedInstitutions
    });
  };

  const onDiscard = () => {
    if (userInstitutionSettings) {
      setLocalInstitutions(userInstitutionSettings.institutions);
      setIsSettingsChanged(false);
    }
  };

  const { selectedCount, selectableCount } = useMemo(() => {
    return getSubInstitutionsCount(localInstitutions);
  }, [localInstitutions]);

  const isError = useMemo(() => {
    if (!localInstitutions.length) return false;

    return selectedCount === 0;
  }, [localInstitutions, selectedCount]);

  return {
    institutionsData: {
      institutions: localInstitutions,
      selectableCount,
      selectedCount
    },
    isSettingsChanged,
    isSavingChanges,
    isError,
    onInstitutionToggle,
    onSave,
    onDiscard
  };
};
