import { useUserPolicyData } from '@viz/api';
import { getDeviceUid } from '@viz/utils';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { browserStorage } from '../../managers';
import { loginStepState } from '../../store';
import { useAuth } from '../useAuth';

export const usePolicies = () => {
  const { isAuth } = useAuth();
  const device_uid = getDeviceUid();
  const setLoginStep = useSetRecoilState(loginStepState);
  const signedPolicies = browserStorage.signedPolicies.get();
  const [, setIsSuccessful] = useState(false);

  const shouldFetchPolicies = Boolean(device_uid && isAuth && !signedPolicies);

  const userPoliciesData = useUserPolicyData(
    { device_uid },
    {
      enabled: shouldFetchPolicies,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.has_documents_to_sign) {
          setLoginStep('policies');
        } else {
          browserStorage.signedPolicies.set('true');
          // This state is needed to trigger a re-render, as the usePolicies did not trigger a state change on signed policy success before,
          // only a browserStorage change which won't necessarily trigger the re-render.
          setIsSuccessful(true);
        }
      }
    }
  );

  const isLoading =
    isAuth &&
    ((!signedPolicies &&
      userPoliciesData.fetchStatus === 'idle' &&
      userPoliciesData.status === 'loading') ||
      userPoliciesData.isInitialLoading);

  return {
    signedPolicies,
    isLoading
  };
};
