import { NotificationSetting as NotificationSettingData } from '@viz/api';
import { NotificationSettingType } from '@viz/api/src/settings/types';
import { localize } from '@viz/i18n';
import { useState } from 'react';

import { UseNotificationSettingsOptions } from '../../../hooks';
import { SettingsLoading } from '../SettingsLoading';
import { notificationSettingsSavedTestId } from '../constants';
import { NotificationSetting } from './NotificationSetting';
import { RestoreNotificationSettingsDialog } from './RestoreNotificationSettingsDialog';
import {
  StyledAlert,
  StyledContainer,
  StyledNotificationSettings,
  StyledRestoreButton,
  StyledSettingChangeSaved,
  StyledSettings
} from './styles';

export type UpdateNotificationSettingsProps = {
  notification: NotificationSettingData;
  criteriaId: string;
  value: number | boolean;
  settingType: NotificationSettingType;
};

export type NotificationSettingsProps = {
  notificationSettingsData: UseNotificationSettingsOptions;
};

const NotificationSettings = ({
  notificationSettingsData: {
    notificationSettings,
    isChangesSaved,
    onUpdate,
    onRestoreToDefault,
    setIsChangeSaved
  }
}: NotificationSettingsProps): JSX.Element => {
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);

  if (!notificationSettings) return <SettingsLoading />;

  return (
    <>
      <StyledNotificationSettings data-testid="notification-settings">
        <StyledRestoreButton
          color="secondary"
          variant="outlined"
          onClick={() => setIsResetDialogOpen(true)}
        >
          {localize('restoreDefault')}
        </StyledRestoreButton>
        <StyledContainer>
          <StyledAlert
            testId="notification-alert"
            text={localize('notificationSettingsAlert')}
            type="info"
          />
          <StyledSettingChangeSaved
            isChangeSaved={isChangesSaved}
            onHide={() => setIsChangeSaved(false)}
            testId={notificationSettingsSavedTestId}
          />
          <StyledSettings>
            {notificationSettings?.map((notificationSetting) => (
              <NotificationSetting
                key={notificationSetting.id}
                notificationSetting={notificationSetting}
                onUpdate={onUpdate}
              />
            ))}
          </StyledSettings>
        </StyledContainer>
      </StyledNotificationSettings>
      <RestoreNotificationSettingsDialog
        isOpen={isResetDialogOpen}
        onRestore={onRestoreToDefault}
        onClose={() => setIsResetDialogOpen(false)}
      />
    </>
  );
};

export default NotificationSettings;
