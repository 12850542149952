import { AxiosError } from 'axios';

export const getErrorMessage = (
  errorResponse: AxiosError,
  defaultErrorMessage: string
) => {
  if (errorResponse.response?.data?.details) {
    return errorResponse.response.data.details;
  }
  return defaultErrorMessage;
};
