type FieldsType =
  | 'ableToSeeReps'
  | 'ableToSeePhi'
  | 'canViewVizAnalytics'
  | 'commercialWorkspace'
  | 'recruitWorkspace'
  | 'ctpAifRecalculateOnAppEnabled'
  | 'internalVizUserEnabled';

export const toggleFields: Array<FieldsType> = [
  'commercialWorkspace',
  'ableToSeeReps',
  'recruitWorkspace',
  'ableToSeePhi',
  'internalVizUserEnabled',
  'ctpAifRecalculateOnAppEnabled',
  'canViewVizAnalytics'
];
