import { VizFirebaseNotification } from '@viz/hooks';
import { useEffect } from 'react';

import { notificationsManager } from '../../managers';

type UseNotificationSubscriberOptions = {
  subject: string;
  onNotification: (message: VizFirebaseNotification) => void;
};

const useNotificationSubscriber = ({
  subject,
  onNotification
}: UseNotificationSubscriberOptions) => {
  useEffect(() => {
    const unsubscribe = notificationsManager.subscribe(subject, onNotification);
    return unsubscribe;
  }, [subject, onNotification]);
};

export { useNotificationSubscriber };
