import { IconInfoError, IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';

import {
  StyledAlgoDetectionIcon,
  StyledDetectedIconContainer,
  StyledFailedErrorContent,
  StyledFailedErrorTitle
} from './styles';

type AlgoIconProps = {
  algoName: string;
  iconUrl: string;
};

export const AlgoIcon = ({ iconUrl, algoName }: AlgoIconProps): JSX.Element => {
  return (
    <StyledDetectedIconContainer>
      <StyledAlgoDetectionIcon
        iconUrl={iconUrl}
        size={IconSizes.SMALL}
        testId={`series-algo-icon-${algoName}`}
      />
    </StyledDetectedIconContainer>
  );
};

export const ProcessingFailedMessage = (): JSX.Element => {
  return (
    <StyledFailedErrorContent>
      <StyledFailedErrorTitle>
        {localize('seriesProcessingFailed')}
      </StyledFailedErrorTitle>
      <div>{localize('contactTeamAdministrator')}</div>
    </StyledFailedErrorContent>
  );
};

export const ProcessingFailedIcon = (): JSX.Element => {
  return (
    <StyledDetectedIconContainer>
      <IconInfoError size={IconSizes.SMALL} testId="series-error-icon" />
    </StyledDetectedIconContainer>
  );
};
