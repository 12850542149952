import React from 'react';

import { LARGE_LINE_INTERVAL } from '../consts';

interface ECGPaperProps {
  lineSpacing: number;
  scaleFactor: number;
}

const lineColor = '#ffd7d5';

const PatternRect = ({ pattern }: { pattern: string }) => (
  <rect x="-500%" y="-500%" width="1000%" height="1000%" fill={pattern} />
);

const GraphPaper = ({ lineSpacing, scaleFactor }: ECGPaperProps) => {
  const spacings = [...Array(LARGE_LINE_INTERVAL)].map(
    (_, index) => index * lineSpacing
  );

  const minorLineWidth = Math.min(0.4 / scaleFactor, 0.5);
  const majorLineWidth = Math.min(1.8 / scaleFactor, 2);

  const getStrokeWidth = (index: number) =>
    index === LARGE_LINE_INTERVAL - 1 ? majorLineWidth : minorLineWidth;

  return (
    <>
      <defs>
        <pattern
          id="vertical"
          patternUnits="userSpaceOnUse"
          width={lineSpacing * LARGE_LINE_INTERVAL}
          height="100%"
        >
          {spacings.map((space, index) => (
            <line
              key={index}
              x1={space}
              y1="0"
              x2={space}
              y2="100%"
              stroke={lineColor}
              strokeWidth={getStrokeWidth(index)}
            />
          ))}
        </pattern>
        <pattern
          id="horizontal"
          patternUnits="userSpaceOnUse"
          width="100%"
          height={lineSpacing * LARGE_LINE_INTERVAL}
        >
          {spacings.map((space, index) => (
            <line
              key={index}
              x1="0"
              y1={space}
              x2="100%"
              y2={space}
              stroke={lineColor}
              strokeWidth={getStrokeWidth(index)}
            />
          ))}
        </pattern>
      </defs>
      <PatternRect pattern="url(#vertical)" />
      <PatternRect pattern="url(#horizontal)" />
    </>
  );
};

export default GraphPaper;
