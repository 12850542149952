import { IconUsers, IconSizes } from '@viz/design-system';

import { Paths } from './paths';

const menuRoutes = [
  {
    name: 'users',
    link: Paths.USERS,
    Icon: <IconUsers size={IconSizes.MEDIUM} />
  }
];

export default menuRoutes;
