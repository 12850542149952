import {
  getBaseColor,
  getBorderRadius,
  getFontWeight,
  getSize,
  getSpacing,
  Grid
} from '@viz/design-system';
import styled from 'styled-components';

import { AlgoDetectionIcon } from '../../../../AlgoDetectionIcon';

export const StyledStudyContainer = styled.div`
  width: 100%;
`;

export const StyledThumbnailsContainer = styled(Grid)`
  && {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${getSize(26)}, 1fr));
    gap: ${getSpacing(2)};
  }

  :after {
    content: '';
    height: 0.01em;
    grid-column-start: 1;
  }
`;

export const StyledAlertContainer = styled.div`
  margin-bottom: ${getSpacing(4)};
`;

export const StyledFailedErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${getSpacing(1)};
`;

export const StyledFailedErrorTitle = styled.div`
  font-weight: ${getFontWeight('font-weight-bold-700')};
`;

export const StyledThumbnailContainer = styled(Grid)`
  && {
    margin-bottom: ${getSpacing(1)};
    aspect-ratio: 1;
  }
`;

export const StyledDetectedIconContainer = styled.div`
  && {
    background-color: ${getBaseColor('black', 0.7)};
    border-radius: ${getBorderRadius('SMALL')};
    display: flex;
    align-items: center;
    padding: ${getSize(0.5)};
  }
`;

export const StyledAlgoDetectionIcon = styled(AlgoDetectionIcon)`
  display: flex;
`;
