import { getBaseColor, getSize, NumberInput } from '@viz/design-system';
import styled from 'styled-components';

export const StyledNumberInput = styled(NumberInput)`
  align-self: flex-end;
  gap: 0;
`;

export const StyledInputNumberError = styled.div`
  color: ${getBaseColor('error')};
  padding-top: ${getSize(2.5)};
  position: absolute;
  right: 0;
`;
