import { ClinicalInfoItem } from '@viz/api';
import { localize } from '@viz/i18n';

import { timestampToDatetime } from '../utils';
import {
  StyledTitle,
  StyledTypography,
  StyledStaffNotesSectionItemContainer
} from './styles';

interface ClinicalInfoItemProps {
  item: ClinicalInfoItem;
}

export const StaffNotesSectionItem = ({ item }: ClinicalInfoItemProps) => (
  <StyledStaffNotesSectionItemContainer>
    <StyledTitle data-tesid={`${item.title}-field`}>
      {localize(item.title)}
    </StyledTitle>
    <StyledTypography fontSize="base" data-tesid={`${item.title}-value`}>
      {item.type === 'timestamp' ? timestampToDatetime(item.value) : item.value}
    </StyledTypography>
  </StyledStaffNotesSectionItemContainer>
);
