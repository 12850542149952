import {
  Autocomplete,
  getBaseColor,
  getFontSize,
  getSize,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledInstitutionSettingsTopBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${getSpacing(3)};
`;

export const StyledSearchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSelectAllButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${getSpacing(2)};
`;

export const StyledInstitutionFilterInfo = styled.div`
  ${getFontSize('lg')};
  color: ${getBaseColor('french-gray')};
`;

export const StyledSwitchContainer = styled.div`
  display: flex;
  gap: ${getSpacing(0.5)};
  align-items: center;
`;

export const SwitchLabel = styled.div`
  ${getFontSize('sm')};
`;

export const StyledSearchBox = styled(Autocomplete)`
  margin-right: ${getSpacing(5)};
  width: ${getSize(80)};

  .MuiAutocomplete-input {
    padding: ${getSpacing(1)} !important;
    margin-left: ${getSpacing(1)};
  }

  .MuiInputBase-root {
    padding: ${getSpacing(1.25)} ${getSpacing(3.5)} !important;
  }
`;
