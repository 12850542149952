import {
  AuditAction,
  AuditEntityType,
  useCreateEventAudit,
  UserDetails,
  useRemoveUser,
  useResetPassword
} from '@viz/api';
import { IconSizes, IconTrash } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { toastStatusState } from '../../../../../../../store';
import { FeedbackDialog } from '../../../../../components';
import { getErrorMessage } from '../../../utils';
import {
  ActionsContainer,
  RemoveUserButton,
  ResetPasswordButton,
  DeleteConfirmationDialog
} from './styles';

// The version of MaterialUI we use does not accept 'error' as a color for button
// TODO: If we ever upgrade MaterialUI's version, clear the custom styles in this component and set color="error"

interface ActionsProps {
  user: UserDetails;
  hasPermissionToRemove: boolean;
  onRemoveSuccessful: () => void;
  className?: string;
}

const Actions = ({
  user,
  onRemoveSuccessful,
  className,
  hasPermissionToRemove
}: ActionsProps) => {
  const { mutate: createUserEventAudit } = useCreateEventAudit();
  const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);
  const [isResetPasswordDialogOpen, setResetPasswordDialogOpen] =
    useState(false);
  const setToastStatusState = useSetRecoilState(toastStatusState);

  const { mutate: removeUser, isLoading: isLoadingDelete } = useRemoveUser({
    onSuccess: () => {
      setRemoveDialogOpen(false);
      onRemoveSuccessful();

      setToastStatusState({
        type: 'info',
        text: localize(
          'removeUserSuccessful',
          `${user.firstName} ${user.lastName}`
        )
      });
    },
    onError: (error) => {
      setToastStatusState({
        type: 'error',
        text: getErrorMessage(
          error,
          localize('removeUserFailed', `${user.firstName} ${user.lastName}`)
        )
      });
    }
  });

  const { mutate: resetUserPassword, isLoading: isLoadingReset } =
    useResetPassword({
      retry: false,
      onSuccess: (data, variables) => {
        if (variables?.forget_password_send_email === 1) {
          setToastStatusState({
            type: 'info',
            text: localize(
              'resetUserPasswordSuccessful',
              `${user.firstName} ${user.lastName}`
            )
          });
          setResetPasswordDialogOpen(false);

          createUserEventAudit({
            entity_type: AuditEntityType.USER,
            entity_id: user.userUid,
            action: AuditAction.RESET_PASSWORD
          });
        }
      },
      onError: (data, variables) => {
        if (variables?.forget_password_send_email === 1) {
          setToastStatusState({
            type: 'error',
            text: localize(
              'resetUserPasswordFailed',
              `${user.firstName} ${user.lastName}`
            )
          });
          setResetPasswordDialogOpen(false);
        }
      }
    });

  const userName = `${user.firstName} ${user.lastName}`;

  const onRemoveClick = () => {
    removeUser(user.userUid);
  };

  const onResetClick = async () => {
    resetUserPassword({
      email: user.email,
      forget_password_send_sms: 1
    });
    resetUserPassword({
      email: user.email,
      forget_password_send_email: 1
    });
  };

  return (
    <>
      <ActionsContainer className={className}>
        <ResetPasswordButton
          type="button"
          variant="outlined"
          onClick={() => setResetPasswordDialogOpen(true)}
        >
          {localize('resetPassword')}
        </ResetPasswordButton>
        {hasPermissionToRemove && (
          <RemoveUserButton
            type="button"
            variant="outlined"
            startIcon={<IconTrash size={IconSizes.SMALL} />}
            onClick={() => setRemoveDialogOpen(true)}
          >
            {localize('removeUser')}
          </RemoveUserButton>
        )}
      </ActionsContainer>
      <FeedbackDialog
        title={localize('resetUserPassword')}
        primaryButtonProps={{
          content: localize('reset'),
          onClick: onResetClick,
          disabled: isLoadingReset
        }}
        secondaryButtonProps={{
          content: localize('cancel'),
          onClick: () => setResetPasswordDialogOpen(false)
        }}
        open={isResetPasswordDialogOpen}
        withCloseIcon={true}
        onClose={() => setResetPasswordDialogOpen(false)}
      >
        {localize('resetUserPasswordDialog', [userName])}
      </FeedbackDialog>
      <DeleteConfirmationDialog
        title={localize('deleteUser')}
        primaryButtonProps={{
          content: localize('delete'),
          onClick: onRemoveClick,
          disabled: isLoadingDelete
        }}
        secondaryButtonProps={{
          content: localize('cancel'),
          onClick: () => setRemoveDialogOpen(false)
        }}
        open={isRemoveDialogOpen}
        withCloseIcon={true}
        onClose={() => setRemoveDialogOpen(false)}
      >
        {localize('deleteUserDialog', [userName])}
      </DeleteConfirmationDialog>
    </>
  );
};

export default Actions;
