import { User } from '@viz/api';
import { IconInfoErrorSolid, IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { WarningMessage, Message, SwitchToEditButton } from './styles';

interface SwitchToEditUserProps {
  user: User;
  onSwitchToEdit: (userId: string) => void;
}

const SwitchToEditUser = ({ user, onSwitchToEdit }: SwitchToEditUserProps) => {
  const onSwitchToEditClick = () => {
    onSwitchToEdit(user.userUid);
  };

  const userName = `${user.firstName} ${user.lastName}`;

  return (
    <WarningMessage>
      <IconInfoErrorSolid size={IconSizes.SMALL} />
      <Message>{localize('existingEmailSwitchToEdit', [userName])}</Message>
      <SwitchToEditButton onClick={onSwitchToEditClick}>
        {localize('existingEmailSwitchToEditCTA')}
      </SwitchToEditButton>
    </WarningMessage>
  );
};

export default SwitchToEditUser;
