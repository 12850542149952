import { ServerPatient } from '@viz/api';
import { EllipsisTextTooltip, IconInfo, IconSizes } from '@viz/design-system';
import {
  TreeItemDetails,
  TreeItemSummary,
  TreeItem
} from '@viz/design-system/src/ui/TreeView/TreeItem';
import { TreeView } from '@viz/design-system/src/ui/TreeView/TreeView';
import { localize } from '@viz/i18n';
import { format } from 'date-fns';

import { usePatient } from '../../../../../context';
import { IconContainer } from '../../../../IconContainer';
import { InlineBulletsObjectSummary } from './InlineBulletsObjectSummary';
import { PatientEhrSectionHeader } from './PatientEhrInfoSection';
import { PatientEhrSectionField } from './PatientEhrSectionField';
import {
  StyledEhrInfo,
  StyledEhrInfoHeader,
  StyledSectionHeaderSub,
  StyledEhrInfoHeaderLayout
} from './styles';
import { useClinicalInfoEhrSectionClickedAnalyticsEvent } from './utils';

export interface EhrTreeProp {
  tree?: ServerPatient.EhrResponse;
}

export const PatientEhrInfo = ({ tree }: EhrTreeProp) => {
  const { patient } = usePatient();

  const sendClinicalInfoEhrSectionClickedEvent =
    useClinicalInfoEhrSectionClickedAnalyticsEvent();

  if (!tree || !tree.data.length) return null;

  return (
    <StyledEhrInfo>
      {tree.display_info && <PatientEhrInfoHeader {...tree.display_info} />}
      <TreeView>
        {tree.data?.map((treeField, index) => {
          return (
            <TreeItem
              key={index}
              id={`${index}`}
              isExpandable={Boolean(treeField.fields)}
              onChange={(isExpanded) =>
                sendClinicalInfoEhrSectionClickedEvent(
                  isExpanded,
                  treeField.section_name,
                  patient
                )
              }
            >
              <TreeItemSummary data-testid="ehr-info-section">
                <PatientEhrSectionHeader {...treeField} />
              </TreeItemSummary>
              <TreeItemDetails>
                {treeField?.fields?.map((field, i) => (
                  <PatientEhrSectionField key={i} index={i} {...field} />
                ))}
              </TreeItemDetails>
            </TreeItem>
          );
        })}
      </TreeView>
    </StyledEhrInfo>
  );
};

const PatientEhrInfoHeader: React.FC<ServerPatient.EhrDisplayInfoResponse> = (
  props
) => {
  const ehrTip = localize('ehrDisclaimer');
  const formattedFetchedAt = props.fetched_at
    ? format(props.fetched_at * 1000, "MMM d 'at' h:mm a")
    : undefined;

  return (
    <StyledEhrInfoHeaderLayout>
      <StyledEhrInfoHeader data-testid="ehr-info-header">
        <h2 data-testid="ehr-info-header-title">{props.title}</h2>
        <StyledSectionHeaderSub>
          <IconContainer
            data-testid="ehr-info-header-icon"
            iconUrl={props.icon_url}
            size={IconSizes.XX_SMALL}
          />
          <InlineBulletsObjectSummary
            testidPrefix="ehr-info-header-"
            data={{
              subtitle: props.subtitle,
              fetchedAt: formattedFetchedAt
            }}
          />
        </StyledSectionHeaderSub>
      </StyledEhrInfoHeader>
      <EllipsisTextTooltip
        placement="top"
        showTooltipWithoutEllipsis={true}
        tooltipTitle={ehrTip}
        testId={'ehr-info-tooltip'}
      >
        <IconInfo size={IconSizes.X_SMALL} />
      </EllipsisTextTooltip>
    </StyledEhrInfoHeaderLayout>
  );
};
