import { UserBasePayload, UserRestrictedFields } from '@viz/api';

import { isUserBasePayloadKey } from './isUserBasePayloadKey';

interface RemoveRestrictedFieldsProps {
  payload: UserBasePayload;
  restrictedFields?: UserRestrictedFields[];
}

export const removeRestrictedFields = ({
  payload,
  restrictedFields
}: RemoveRestrictedFieldsProps) => {
  if (!restrictedFields) {
    return payload;
  }

  for (const fieldName of restrictedFields) {
    if (isUserBasePayloadKey(fieldName, payload)) {
      delete payload[fieldName];
    }
  }

  return payload;
};
