import { payloadTypeAttributeMap, UserBasePayload } from '@viz/api';
import { Moment } from 'moment';

import {
  AdminAccessType,
  AnalyticsEventName,
  EditUserType
} from '../../../../../hooks';
import { UseAnalyticsEvent } from '../../../../../hooks/useAnalytics/hooks';
import { isUserBasePayloadKey } from './isUserBasePayloadKey';

const AUDIT_DATE_FORMAT: string = 'DD/MM/YYYY HH:mm';

export const sendEditUserEvents = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  editedData: UserBasePayload,
  originalData: UserBasePayload,
  userId: string
) => {
  const editedDataKeys = Object.keys(editedData);

  editedDataKeys.forEach((dataKey) => {
    if (!isUserBasePayloadKey(dataKey, editedData)) {
      return;
    }

    const fieldName = payloadTypeAttributeMap[dataKey];

    sendEvent(AnalyticsEventName.ADMIN_EDIT_USER_SUBMITTED, {
      user_edited_id: userId,
      user_edited_email: originalData.email,
      field: fieldName,
      old_value: originalData[dataKey],
      new_value: editedData[dataKey]
    });
  });
};

export const sendDeactivatedUserEvents = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  originalData: UserBasePayload,
  userId: string
) => {
  sendEvent(AnalyticsEventName.ADMIN_USER_DEACTIVATED_SUCCESSFUL, {
    user_deactivate_id: userId,
    user_deactivate_email: originalData.email,
    user_deactivate_main_account_id: originalData?.mainCustomerAccount
  });
};

export const sendStartEditUserEvents = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  userUid: string,
  get_to_edit_method: EditUserType,
  userEditedEmail: string
) => {
  sendEvent(AnalyticsEventName.ADMIN_EDIT_USER_STARTS, {
    user_edited: userUid,
    get_to_edit_method: get_to_edit_method,
    user_edited_email: userEditedEmail
  });
};

export const sendAdminAccessEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  access_type: AdminAccessType
) => {
  sendEvent(AnalyticsEventName.ADMIN_ACCESS_SUCCESSFUL, {
    access_type
  });
};

export const sendStopEditUserEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  userEdited: string,
  touchedFields: { [k: string]: true | true[] | undefined },
  userEditedEmail: string
) => {
  const fieldsArray = Object.keys(touchedFields);

  if (fieldsArray.length === 0) {
    sendEvent(AnalyticsEventName.ADMIN_EDIT_USER_STOPPED, {
      user_edited: userEdited
    });
  } else {
    fieldsArray.forEach(
      (fieldName) =>
        fieldName &&
        sendEvent(AnalyticsEventName.ADMIN_EDIT_USER_STOPPED, {
          user_edited: userEdited,
          touched_field: fieldName,
          user_edited_email: userEditedEmail
        })
    );
  }
};

export const sendDeletedUserEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  email: string,
  userId: string
) =>
  sendEvent(AnalyticsEventName.ADMIN_USER_REMOVED_SUCCESSFUL, {
    user_deleted_email: email,
    user_deleted_id: userId
  });

enum SearchCharacterType {
  Letters = 'letters',
  Numbers = 'numbers',
  Combination = 'combination'
}

interface AdminSearchRequestEventProps {
  search_value: string;
  number_of_search_results: number;
}

const getUserSearchType = (value: string): SearchCharacterType => {
  const containsLetters = /[a-zA-Z]/.test(value);
  const containsNumbers = /\d/.test(value);

  if (containsLetters && !containsNumbers) return SearchCharacterType.Letters;
  if (containsNumbers && !containsLetters) return SearchCharacterType.Numbers;

  return SearchCharacterType.Combination;
};

export const sendAdminSearchRequestSentEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  { search_value, number_of_search_results }: AdminSearchRequestEventProps
) => {
  const num_character = search_value.length;
  const search_character_type = getUserSearchType(search_value);

  sendEvent(AnalyticsEventName.ADMIN_SEARCH_REQUEST_SENT, {
    search_value,
    number_of_search_results,
    num_character,
    search_character_type
  });
};

export const sendAdminAddUserStartEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent']
) => sendEvent(AnalyticsEventName.ADMIN_ADD_USER_START);

export const sendAddUserSubmitEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  data: UserBasePayload,
  newUserId: string
) => {
  const dataKeys = Object.keys(data);

  dataKeys.forEach((dataKey) => {
    if (!isUserBasePayloadKey(dataKey, data)) {
      return;
    }

    const fieldName = payloadTypeAttributeMap[dataKey];

    sendEvent(AnalyticsEventName.ADMIN_ADD_USER_SUBMITTED, {
      user_added: data.email,
      field: fieldName,
      new_value: data[dataKey],
      user_added_id: newUserId
    });
  });
};

export const sendAddUserStoppedEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  touchedFields: { [k: string]: true | true[] | undefined }
) => {
  const fieldsArray = Object.keys(touchedFields);

  if (fieldsArray.length === 0) {
    sendEvent(AnalyticsEventName.ADMIN_ADD_USER_STOPPED);
  } else {
    fieldsArray.forEach(
      (fieldName) =>
        fieldName &&
        sendEvent(AnalyticsEventName.ADMIN_ADD_USER_STOPPED, {
          touched_field: fieldName
        })
    );
  }
};

export const sendSendInvitationEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  invitedUserEmail: string,
  invitedEmailSendStatus: string
) => {
  sendEvent(AnalyticsEventName.ADMIN_INVITATION_EMAIL_SENT, {
    invited_user_email: invitedUserEmail,
    invited_email_send_status: invitedEmailSendStatus
  });
};

export const sendInvitationResponseEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  replyAction: string
) => {
  sendEvent(AnalyticsEventName.ADMIN_INVITATION_EMAIL_RESPONSE, {
    response_action: replyAction
  });
};

export const sendAuditLogPanelOpenedEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  user_audited: string
) => {
  sendEvent(AnalyticsEventName.ADMIN_AUDIT_LOG_OPENED, {
    user_audited
  });
};

export const sendAuditLogOpenedEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  user_audited: string,
  auditLogId: number,
  date: Moment
) => {
  sendEvent(AnalyticsEventName.ADMIN_AUDIT_LOG_LOG_OPENED, {
    user_audited,
    log_date: date.format(AUDIT_DATE_FORMAT),
    auditLogId
  });
};

export const sendAuditLogFieldOpenedEvent = (
  sendEvent: UseAnalyticsEvent['sendEvent'],
  user_audited: string,
  auditLogId: number,
  fieldId: string,
  date: Moment
) => {
  sendEvent(AnalyticsEventName.ADMIN_AUDIT_LOG_FIELD_OPENED, {
    user_audited,
    fieldId,
    auditLogId,
    log_date: date.format(AUDIT_DATE_FORMAT)
  });
};
