import { UserBasePayload, Institution } from '@viz/api';
import { MultipleSelection } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { FormLabel } from '../../../../../components';

interface InstitutionsInputProps {
  form: UseFormReturn<UserBasePayload>;
  institutionList?: Institution[];
  className?: string;
}

const InstitutionsInput = ({
  institutionList,
  form: { control, setValue },
  className
}: InstitutionsInputProps) => {
  const formattedInstitutionList = useMemo(
    () =>
      institutionList?.map((institution) => ({
        label: `${institution.id} - ${institution.name}`,
        value: institution.id
      })),
    [institutionList]
  );

  return (
    <Controller
      name="institutions"
      control={control}
      render={({ field, fieldState }) => (
        <MultipleSelection
          label={<FormLabel translationKey="institutions" />}
          placeholder={localize('multipleSelectionPlaceholder')}
          onChange={field.onChange}
          options={formattedInstitutionList ?? []}
          selectedOptions={field.value ?? []}
          selectionStatusOptions={{
            content: `${field.value?.length} Selected`,
            onClear: () => setValue('institutions', [])
          }}
          helperText={fieldState.error?.message}
          showSelectedOnTop={true}
          className={className}
        />
      )}
    />
  );
};

export default InstitutionsInput;
