import { localize } from '@viz/i18n';
import { useSetRecoilState } from 'recoil';

import { isContactSupportDialogOpenState } from '../../store';
import { StyledContactSupportLink } from './styles';

interface ContactSupportLinkProps {
  onClick?: () => void;
}

const ContactSupportLink = ({
  onClick
}: ContactSupportLinkProps): JSX.Element => {
  const setIsContactSupportDialogOpen = useSetRecoilState(
    isContactSupportDialogOpenState
  );

  const handleClick = () => {
    onClick?.();
    setIsContactSupportDialogOpen(true);
  };

  return (
    <>
      {localize('needHelp')}{' '}
      <StyledContactSupportLink
        underline="always"
        onClick={handleClick}
        data-testid="contact-support-link"
      >
        {localize('contactSupport')}
      </StyledContactSupportLink>
    </>
  );
};

export default ContactSupportLink;
