import { getBaseColor, getFontSize, getSpacing } from '@viz/design-system';
import styled from 'styled-components';

export const StyledChangeSaved = styled.div`
  display: flex;
  align-items: center;
  gap: ${getSpacing(0.5)};
  color: ${getBaseColor('medium-green')};
  ${getFontSize('sm')};

  svg {
    stroke: ${getBaseColor('medium-green')};
  }
`;
