import { ServerPatient } from '@viz/api';
import { localize } from '@viz/i18n';
import { useState } from 'react';

import { getDate } from '../utils';
import {
  StyledCriteriaEhrNotes,
  StyledDateInfoNotes,
  StyledEhrNote,
  StyledGrayedText,
  StyledReadMore
} from './styles';

const EhrNotesGroup = (
  props: ServerPatient.EhrPreScreeningFieldResponse
): JSX.Element => {
  const text = props.fieldValue;
  const TEXT_LIMIT = 800;
  const [isReadMore, setIsReadMore] = useState(text.length > TEXT_LIMIT);
  const shouldHaveReadMore = text.length > TEXT_LIMIT;
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const displayEhrNotesText = () => {
    if (text !== '' && isReadMore) {
      return text.slice(0, TEXT_LIMIT);
    } else if (text !== '' && !isReadMore) {
      return text;
    }
    return <StyledGrayedText>N/A</StyledGrayedText>;
  };

  return (
    <StyledCriteriaEhrNotes>
      {props.acquisitionMilliTs && (
        <StyledDateInfoNotes>
          {getDate(props.acquisitionMilliTs)}
        </StyledDateInfoNotes>
      )}
      <StyledEhrNote>
        {displayEhrNotesText()}

        {shouldHaveReadMore && (
          <>
            {isReadMore && '...'}
            <StyledReadMore onClick={toggleReadMore}>
              {isReadMore ? (
                <div>{localize('showMore')}</div>
              ) : (
                <div>{localize('showLess')}</div>
              )}
            </StyledReadMore>
          </>
        )}
      </StyledEhrNote>
    </StyledCriteriaEhrNotes>
  );
};

export default EhrNotesGroup;
