import { TypeOptions } from 'react-toastify';

export const LOGIN_STEP = 'loginStep';
export const PATIENT_TABS = 'patientTabs';
export const PATIENT_TAB_DIALOG_DATA = 'patientTabDialogData';
export const SELECTED_PATIENT_ID = 'selectedPatientAllCases';
export const SELECTED_PATIENT_FOLDER_TAB = 'selectedPatientFolderTab';
export const PATIENT_VIEWER_DATA = 'patientViewerData';
export const GET_PATIENT_TAB = 'getPatientTab';
export const IS_SETTINGS_DIALOG_OPEN = 'isSettingsDialogOpen';
export const SHOULD_CHECK_RENDERER = 'shouldCheckRenderer';
export const IS_TROUBLESHOOTING_DIALOG_OPEN = 'isTroubleshootingDialogOpen';
export const IS_LOGOUT_DIALOG_OPEN = 'isLogOutDialogOpen';
export const TOAST_STATUS = 'toastStatusState';
export const IS_CONTACT_SUPPORT_DIALOG_OPEN = 'isContactSupportDialogOpen';
export const IS_VIEWER_VISIBLE = 'isViewerVisible';
export const IS_PATIENT_FOLDER_VISIBLE = 'isPatientFolderVisible';
export const SELECTED_PATIENT_STATUS_INFO = 'selectedPatientStatusInfo';
export const SPEC_STATUS_FILTER = 'specStatusFilter';
export const SELECTED_DISTRIBUTION_LIST_TAB_ID =
  'selectedDistributionListTabId';
export const CURRENT_PATIENT_LIST_URL_RELATIVE_PATH =
  'current_patient_list_url_relative_path';

export type LoginStep =
  | 'username'
  | 'password'
  | 'mfa'
  | 'policies'
  | 'continueAuthInExternalBrowser';

export enum PatientFolderTabs {
  CURRENT_IMAGING = 'currentImaging',
  PRIOR_EXAMS = 'priorExams',
  CHAT = 'chat',
  CLINICAL_INFO = 'clinicalInfo',
  PRE_SCREENING = 'preScreening',
  CHANNELS = 'channels'
}

export interface PatientViewerData {
  studyUid?: string;
  seriesUid?: string;
  ecgId?: string;
}

/**
 * This has to be a type alias, otherwise TS won't let us infer the index signature at
 * https://github.com/Viz-AI/viz_web/blob/b1787ff69a9858b1689bd1aab230c09c57ffe69a/platform/web-app/src/hooks/usePatientTabs/usePatientTabs.tsx#L124
 **/
export type TabUrlData = {
  id: string;
  study_id?: string;
  series_id?: string;
  ecg_id?: string;
};

export interface PatientTabDialogData {
  tabUrlData: TabUrlData;
  patientName?: string;
}

export interface Toast {
  text: string;
  type: Exclude<TypeOptions, 'default'>;
  preventClose?: boolean;
}
