import {
  NotificationData,
  useNotificationMessageData,
  useNotificationPatientData,
  usePatients
} from '@viz/api';
import { PushNotificationType } from '@viz/hooks';
import { useEffect, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { AnalyticsEventName, useAnalyticsEvent, useAuth, useRouter } from '../';
import { Paths } from '../../navigation';
import {
  getPatientTabState,
  isPatientFolderVisibleState,
  patientFolderSelectedTabStateFamily,
  PatientFolderTabs,
  selectedPatientIdState
} from '../../store';
import { getNotificationType } from '../useAnalytics';
import { usePatientTabs } from '../usePatientTabs';

export type VizNotificationData = {
  notificationId: string;
  notificationType: PushNotificationType;
};

const useDeepLinkNotification = () => {
  const { isAuth } = useAuth();
  const { isMaxTabsOpen, selectTab } = usePatientTabs();
  const router = useRouter();
  const { sendEvent } = useAnalyticsEvent();

  const [notificationData, setNotificationData] =
    useState<VizNotificationData | null>(null);

  const patientsQuery = usePatients({ enabled: false });
  const getNotificationPatient = useNotificationPatientData();
  const getNotificationData = useNotificationMessageData();

  useEffect(() => {
    if (notificationData?.notificationId && isAuth) {
      switch (notificationData.notificationType) {
        case PushNotificationType.PATIENT_SHARED:
        case PushNotificationType.MESSAGE:
        case PushNotificationType.PAGER:
          getNotificationData.mutate(
            {
              notification_uid: notificationData?.notificationId ?? ''
            },
            {
              onSuccess: (data) =>
                updateMessageNotificationData(data.viz_patient_id)
            }
          );
          break;

        case PushNotificationType.STUDY:
        case PushNotificationType.ECG:
          getNotificationPatient.mutate(
            { notification_uid: notificationData?.notificationId ?? '' },
            {
              onSuccess: (data) => navigateToPatientTab(data)
            }
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData, isAuth]);

  const openPatientTab = (patientId: string) => {
    const currentOpenPatientTab = router.getUrlParam('id');

    if (currentOpenPatientTab !== patientId) {
      const tabUrlData = { id: patientId };
      selectTab({ tabUrlData });
    }
  };

  const updateMessageNotificationData = useRecoilCallback(
    ({ set, snapshot }) =>
      async (patientId: string) => {
        const patientTab = await snapshot.getPromise(
          getPatientTabState(patientId)
        );
        const isPatientTabExists = Boolean(patientTab);

        const shouldOpenPatientTab =
          notificationData?.notificationType !== PushNotificationType.PAGER &&
          (!isMaxTabsOpen || isPatientTabExists);

        if (shouldOpenPatientTab) {
          openPatientTab(patientId);
        } else {
          router.navigate({ path: Paths.CASES });
          set(selectedPatientIdState, patientId);
          if (patientId) {
            set(isPatientFolderVisibleState, true);
          }
        }

        set(
          patientFolderSelectedTabStateFamily(patientId),
          PatientFolderTabs.CHAT
        );
      },
    [isMaxTabsOpen, openPatientTab, router, notificationData?.notificationType]
  );

  const navigateToPatientTab = ({
    study_uid,
    viz_patient_id,
    series_uid
  }: NotificationData.PatientDataResponse) => {
    if (study_uid && viz_patient_id) {
      const tabUrlData = {
        id: viz_patient_id,
        study_id: study_uid,
        series_id: series_uid
      };
      selectTab({ tabUrlData });
    } else {
      router.navigate({ path: Paths.CASES });
    }
  };

  const handleNotificationClick = (data: VizNotificationData) => {
    const { notificationId, notificationType } = data;

    sendEvent(AnalyticsEventName.PUSH_NOTIFICATION_CLICKED, {
      notification_uid: notificationId,
      notification_type: getNotificationType(notificationType),
      timestamp: Date.now()
    });

    patientsQuery.refetch();
    setNotificationData(data);
  };

  return { handleNotificationClick };
};

export { useDeepLinkNotification };
