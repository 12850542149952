import { BusCallback, useEventListener } from '@viz/events';

import { Events } from '../../events';
import { useLogoutUser } from '../useLogoutUser';

const useLogoutWatcher = (): void => {
  const { logout } = useLogoutUser();
  useEventListener(Events.LOGOUT, logout as BusCallback);
};

export { useLogoutWatcher };
