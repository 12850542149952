import { Accordion } from '@viz/design-system';
import React, { useContext, useState } from 'react';

import { AuditLogViewItem } from '../types';
import AuditContent from './AuditContent/AuditContent';
import AuditLogSubtitle from './AuditLogSubtitle/AuditLogSubtitle';
import { EventsContext } from './events-context';
import { AccordionTitle } from './styles';

interface AuditLogItemProps {
  auditLog: AuditLogViewItem;
}

const AuditLogItem = ({ auditLog }: AuditLogItemProps) => {
  const [isExtended, setIsExtended] = useState(false);
  const { onAuditOpened } = useContext(EventsContext);

  return (
    <Accordion
      title={
        <AccordionTitle>
          {auditLog.log.createdAt.format('MMMM DD, YYYY • h:mm A')}
        </AccordionTitle>
      }
      subTitle={<AuditLogSubtitle auditLog={auditLog} extended={isExtended} />}
      onChange={(state) => {
        setIsExtended(state);
        state && onAuditOpened(auditLog.log.id, auditLog.log.createdAt);
      }}
    >
      <AuditContent auditLog={auditLog} />
    </Accordion>
  );
};

export default AuditLogItem;
