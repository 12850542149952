import { UserGroup, UserGroupCategory } from '@viz/api';

import {
  Category,
  getUserGroupContent
} from '../../../../../utils/userGroupCategories';

export const filterAvailableUserGroupsFromSelected = (
  selectedUGs?: number[],
  availableUGs?: UserGroup[]
) =>
  selectedUGs?.filter((userGroupId) =>
    availableUGs?.some(
      (formattedUserGroup) => formattedUserGroup.id === userGroupId
    )
  );

export const getGroupSearchLabel = (userGroup: UserGroup): string =>
  `${userGroup.name} ${userGroup.institutionName} (${userGroup.institutionId}) 
    ${Object.entries(Category)
      .map(([key, value]) =>
        getUserGroupContent(
          value,
          userGroup[key as keyof typeof userGroup] as UserGroupCategory
        )
      )
      .join()}`;
