import { GetProfileData, useProfile, useUpdateUserProfile } from '@viz/api';
import { localize } from '@viz/i18n';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { toastStatusState } from '../../store';
import { AnalyticsEventName, useAnalyticsEvent } from '../useAnalytics';

type UseProfileSettingsProps = {
  enabled: boolean;
};

export type UseProfileSettingsOptions = {
  user?: GetProfileData;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  onUpdatePhone: () => void;
};

export const useProfileSettings = ({
  enabled
}: UseProfileSettingsProps): UseProfileSettingsOptions => {
  const { sendEvent } = useAnalyticsEvent();
  const setToastStatus = useSetRecoilState(toastStatusState);
  const { data: user } = useProfile({
    enabled,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setPhoneNumber(data.phone);
    }
  });
  const [phoneNumber, setPhoneNumber] = useState(user?.phone ?? '');
  const { mutate: updatePhoneNumber } = useUpdateUserProfile();

  const onUpdatePhone = () => {
    updatePhoneNumber(
      { phone: phoneNumber },
      {
        onSuccess: () => {
          sendEvent(AnalyticsEventName.PHONE_NUMBER_SETTINGS_UPDATED);
          setToastStatus({
            type: 'info',
            text: localize('phoneUpdatedSuccessfully')
          });
        }
      }
    );
  };

  return {
    user,
    phoneNumber,
    setPhoneNumber,
    onUpdatePhone
  };
};
