import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { Paths } from '../';
import { VizLoading } from '../../components';
import { useAuth, useLogoutUser, usePolicies, useRouter } from '../../hooks';
import { browserStorage } from '../../managers';
import { AccessType } from '../constants';
import { StyledVizLoadingContainer } from './styles';

type ProtectedRouteProps = RouteProps & {
  accessType?: AccessType;
};

const ProtectedRoute = ({
  accessType,
  ...props
}: ProtectedRouteProps): JSX.Element => {
  const { isAuth, isLoading: authLoading } = useAuth();
  const { logout } = useLogoutUser();
  const router = useRouter();
  const { signedPolicies, isLoading: userPoliciesLoading } = usePolicies();

  const { web_access, admin_console_access } =
    browserStorage.userAccessEnabled.get();

  if (authLoading || userPoliciesLoading) {
    return (
      <StyledVizLoadingContainer>
        <VizLoading />
      </StyledVizLoadingContainer>
    );
  }

  if (!isAuth || !signedPolicies) {
    return (
      <Redirect
        to={{
          pathname: Paths.LOGIN,
          state: {
            referer: router.getRelativePath()
          }
        }}
      />
    );
  }

  if (!web_access && !admin_console_access) {
    logout();
  }

  if (
    (accessType === AccessType.WEB && !web_access) ||
    (accessType === AccessType.ADMIN && !admin_console_access)
  ) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: {
            referer: router.getRelativePath()
          }
        }}
      />
    );
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
