import { Patient, Study, useAckStudy, useSeriesThumbnails } from '@viz/api';
import { Alert, Thumbnail } from '@viz/design-system';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { patientTabDialogState } from '../../../../../store';
import { getAlgoNameFromIconUrl } from '../utils';
import SeriesDetails from './SeriesDetails';
import {
  AlgoIcon,
  ProcessingFailedIcon,
  ProcessingFailedMessage
} from './common';
import {
  StyledAlertContainer,
  StyledStudyContainer,
  StyledThumbnailContainer,
  StyledThumbnailsContainer
} from './styles';

export type StudyContainerProps = {
  patient: Patient;
  study: Study;
  activeSeriesUid?: string;
};

const StudyContainer = ({
  patient,
  study,
  activeSeriesUid
}: StudyContainerProps): JSX.Element => {
  const setPatientTabDialogData = useSetRecoilState(patientTabDialogState);

  const thumbnails = useSeriesThumbnails(
    { retry: false, refetchOnWindowFocus: false },
    {
      institutionId: study.institutionId,
      studyId: study.uid,
      seriesIds: study.series.map((series) => series.uid)
    }
  );
  const { mutate: ackStudy } = useAckStudy();

  useEffect(() => {
    ackStudy({ study_id: study.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study.uid]);

  const handleThumbnailClick = (studyUid: string, seriesUid: string) => {
    setPatientTabDialogData({
      tabUrlData: { id: patient.id, study_id: studyUid, series_id: seriesUid },
      patientName: patient.name
    });
  };

  return (
    <StyledStudyContainer>
      {study.isFailed && (
        <StyledAlertContainer>
          <Alert
            testId="menu-alert"
            text={<ProcessingFailedMessage />}
            type="error"
          />
        </StyledAlertContainer>
      )}
      <StyledThumbnailsContainer container>
        {study.series.map((series, index) => {
          const { highlightIcons, isFailed } = series;
          const uniqueIcons = [...new Set(highlightIcons)];

          const header = (
            <>
              {!uniqueIcons.length && isFailed ? (
                <ProcessingFailedIcon />
              ) : (
                uniqueIcons.map((iconUrl) => {
                  const algoName = getAlgoNameFromIconUrl(iconUrl);
                  return (
                    <AlgoIcon
                      key={algoName}
                      algoName={algoName}
                      iconUrl={iconUrl}
                    />
                  );
                })
              )}
            </>
          );

          return (
            <StyledThumbnailContainer item key={series.uid}>
              {
                <Thumbnail
                  key={series.uid}
                  src={thumbnails[index].data}
                  isActive={activeSeriesUid === series.uid}
                  size="100%"
                  testId={`series-id-${series.uid}`}
                  onClick={() => handleThumbnailClick(study.uid, series.uid)}
                  header={header}
                  details={
                    <SeriesDetails
                      description={series.description}
                      count={series.dicomCount}
                    />
                  }
                />
              }
            </StyledThumbnailContainer>
          );
        })}
      </StyledThumbnailsContainer>
    </StyledStudyContainer>
  );
};

export { StudyContainer };
