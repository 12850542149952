import { UserAuthInfo, useLogout, useUserAuthInfo } from '@viz/api';
import { useEventDispatch } from '@viz/events';
import { logger } from '@viz/logging';
import { getDeviceUid } from '@viz/utils';
import { useCallback } from 'react';

import { Events } from '../../events';
import { browserStorage } from '../../managers';
import { useUserProfile } from '../useUserProfile';

const findActiveSsoProvider = (userAuth: UserAuthInfo.Response) => {
  const accounts = Object.values(userAuth);
  const activeAccount = accounts.find((account) => account.sso.active);
  return activeAccount?.sso;
};

export const useLogoutUser = () => {
  const { user, removeCache: removeUserProfileCache } = useUserProfile();
  const dispatchEvent = useEventDispatch();
  const getUserAuthInfo = useUserAuthInfo();
  const { refetch: logout } = useLogout(
    { device_uid: getDeviceUid() },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const clearUserData = useCallback(async () => {
    browserStorage.cleanUserData();
    dispatchEvent(Events.RESET_STATE);
    logger.clearUser();
    removeUserProfileCache();
  }, [dispatchEvent, removeUserProfileCache]);

  const navigateToSsoLogout = useCallback((url: string) => {
    if (window.vizWidget) {
      window.vizWidget.openInExternalBrowser(url);
    } else {
      window.location.replace(url);
    }
  }, []);

  const logoutUser = useCallback(
    async (shouldHardLogout = false) => {
      const userAuthInfo =
        user?.username &&
        (await getUserAuthInfo.mutateAsync({
          username: user.username
        }));
      if (shouldHardLogout) {
        await logout();
        browserStorage.tabSortingState.remove();
      }
      clearUserData();
      if (userAuthInfo) {
        const ssoProvider = findActiveSsoProvider(userAuthInfo);
        if (ssoProvider) {
          navigateToSsoLogout(ssoProvider.logout_url);
        }
      }
    },
    [
      getUserAuthInfo,
      user?.username,
      clearUserData,
      navigateToSsoLogout,
      logout
    ]
  );

  return {
    logout: logoutUser
  };
};
