import { IconInfoErrorSolid, IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { WarningMessage, Message, SendAnInvitationButton } from './styles';

interface SendUserInvitationProps {
  userEmail: string;
  onSendInvitation: (userEmail: string) => void;
}

const SendUserInvitation = ({
  userEmail,
  onSendInvitation
}: SendUserInvitationProps) => {
  const onSendInvitationClick = () => {
    onSendInvitation(userEmail);
  };

  return (
    <WarningMessage>
      <IconInfoErrorSolid size={IconSizes.SMALL} />
      <Message>{localize('existingEmailSendAnInvitation')}</Message>
      <SendAnInvitationButton onClick={onSendInvitationClick}>
        {localize('existingEmailSendAnInvitationCTA')}
      </SendAnInvitationButton>
    </WarningMessage>
  );
};
export default SendUserInvitation;
