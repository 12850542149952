import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getFontWeight,
  getSize,
  getSpacing,
  IconArrowDown
} from '@viz/design-system';
import styled from 'styled-components';

import { StyledButton } from '../../../components/SettingsDialog/InstitutionSettings/InstitutionSettingsSaveChanges/styles';

export const StyledFiltersSectionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${getSize(5)};
  padding-bottom: ${getSize(4)};
`;

export const StyledSectionTitle = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: ${getSize(1)};
  }

  & span {
    font-size: ${getFontSize('sm')};
    font-weight: ${getFontWeight('font-weight-bold-600')};
  }
`;

export const StyledCounter = styled.div`
  color: ${getBaseColor('french-gray')};
`;

export const StyledDateRangePickerContainer = styled.div`
  position: absolute;
  z-index: 10000;
  top: 120%;
  margin-left: ${getSpacing(2)};
  border: 1px solid ${getBaseColor('blue-mako')};
  border-radius: ${getBorderRadius('SMALL')};
  border-top-left-radius: 0;
`;

export const StyledFilterContainer = styled.div`
  position: relative;
`;

export const StyledIconArrow = styled(IconArrowDown)`
  transform: rotate(-90deg);
  margin-left: ${getSize(2)};
  pointer-events: none;
  z-index: -1;
`;

export const StyledClearButton = styled(StyledButton)`
  &.MuiButton-root {
    color: ${getBaseColor('blue')};
    font-weight: ${getFontWeight('font-weight-bold-400')};
    cursor: pointer;
  }
`;
