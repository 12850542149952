import { FormControl } from '@material-ui/core';
import {
  Alert,
  Dialog,
  IconSizes,
  Input,
  Typography,
  getBaseColor,
  getFontWeight,
  getIconSize,
  getSize,
  getSpacing
} from '@viz/design-system';
import styled, { css } from 'styled-components';

export const StyledClinicalInfoForm = styled(FormControl)`
  width: 100%;
`;

export const StyledClinicalInfoFormHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${getSize(2)};
`;

export const StyledClinicalInfoDialog = styled(Dialog)`
  & .MuiDialog-paper {
    height: 80vh;
    width: ${getSize(150)};
  }
`;

export const StyledTypography = styled(Typography)`
  && {
    font-weight: ${getFontWeight('font-weight-bold-600')};
  }
`;

export const StyledClinicalInfoFormSectionRowContainer = styled.div<{
  fullWidth: boolean;
}>`
  padding: ${getSpacing(4)} 0;

  ${(props) =>
    props.fullWidth &&
    css`
      grid-column-start: 1;
      grid-column-end: -1;
    `}
`;

export const StyledClinicalInfoFormFieldLabel = styled.label`
  display: block;
  padding-bottom: ${getSpacing(2)};
`;

export const StyledClinicalInfoFormFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${getSpacing(4)};
`;

export const StyledInput = styled(Input)`
  .MuiOutlinedInput-multiline {
    padding: ${getSpacing(4)};
  }
`;

export const StyledAlert = styled(Alert)`
  margin: ${getSpacing(4)} 0 ${getSpacing(1)} 0;
`;

export const StyledInfoAlert = styled(StyledAlert)`
  border-left: solid ${getBaseColor('french-gray')};
`;

export const IconContainer = styled.div<{ $size: IconSizes }>`
  & svg {
    ${(props) => getIconSize(props.$size)};
  }
`;

export const StyledSubmitLoadingButtonContainer = styled.div`
  min-width: ${getSize(30)};

  justify-content: center;
  display: flex;
`;
