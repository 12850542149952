import { Endpoints, HTTPStatusCode } from '@viz/api';
import { getCookieValue, getCurrentTime, parseJwt } from '@viz/utils';
import { RequestHandler } from 'axios-mock-adapter';

import { mockAdapter } from './adapter';

const validateToken = (token: string) => {
  try {
    const payload = parseJwt(token);
    const expiration = payload.exp * 1000;
    return expiration > getCurrentTime();
  } catch (e) {
    return false;
  }
};

export const protectRequest = <T>(
  request: RequestHandler,
  data?: T,
  statusCode = HTTPStatusCode.OK
): void => {
  request.reply((config) => {
    const cookie = getCookieValue('access_token_cookie');
    const isValid = validateToken(cookie);

    if (isValid) {
      const response = typeof data === 'function' ? data(config) : data;
      return [statusCode, response];
    }
    return [HTTPStatusCode.UNAUTHORIZED];
  });
};

export const mockGraphQL = <TData>(
  endPoint: Endpoints,
  queryDocument: string,
  response: TData,
  requireAuth: boolean = true,
  statusCode = HTTPStatusCode.OK
) => {
  const mockRequest = mockAdapter.instance.onPost(new RegExp(endPoint), {
    asymmetricMatch: (request: { query: string } | FormData) => {
      const query =
        request instanceof FormData
          ? JSON.parse(request.get('operations') as string).query
          : request.query;
      return query.includes(queryDocument);
    }
  });

  return requireAuth
    ? protectRequest<TData>(mockRequest, response, statusCode)
    : mockRequest.reply(statusCode, response);
};
