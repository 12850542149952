import { getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledClinicalInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSize(4)};
  width: 100%;
  height: 100%;
  overflow: auto;
`;
