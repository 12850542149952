import { ClinicalInfo } from '@viz/api';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

import { StyledInput } from './styles';

type FormFieldRenderFn = (
  field: ControllerRenderProps<FieldValues, string>,
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
) => React.ReactElement;

export function createRenderFunctionForInput(
  type: ClinicalInfo.ClinicalInfoFormFieldType
): FormFieldRenderFn {
  if (type === 'free_text') {
    return (field, onChange) => {
      const { value, ...rest } = field;

      return (
        <StyledInput
          value={value}
          type="text"
          minRows={1}
          maxRows={10}
          fullWidth
          multiline
          {...rest}
          data-testid={`input-field-${field.name}`}
          onChange={(e) => {
            onChange?.(e);
          }}
        />
      );
    };
  }

  return (field, onChange) => <></>;
}
