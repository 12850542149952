import {
  getSize,
  PhoneInput,
  getFontWeight,
  getBaseColor
} from '@viz/design-system';
import styled from 'styled-components';

export const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldSectionTitle = styled.h4`
  margin-bottom: ${getSize(12.5)};
  font-weight: ${getFontWeight('font-weight-bold-600')};
  color: ${getBaseColor('french-gray')};
`;

export const FieldSectionInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${getSize(55)});
  column-gap: ${getSize(5)};
  row-gap: ${getSize(14)};
`;

export const StyledPhoneInput = styled(PhoneInput)`
  & .form-control {
    width: ${getSize(55)};
    height: ${getSize(8)};
  }
`;
