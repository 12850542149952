import { getCurrentTime } from '@viz/utils';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { browserStorage } from '../../managers';
import { useAuth, useLogoutUser } from '../index';
import { TOKEN_REFRESH_INTERVAL } from './utils';

type UseTokenWatcherProps = {
  refreshInterval?: number;
};

const getAccessTokenExpiration = () => {
  const accessTokenExpiration = browserStorage.accessTokenExpiration.get();

  return Math.round(accessTokenExpiration * 1000 - getCurrentTime());
};

const useTokenWatcher = ({
  refreshInterval = TOKEN_REFRESH_INTERVAL
}: UseTokenWatcherProps = {}): void => {
  const { isAuth, refreshAccessToken } = useAuth();
  const { logout } = useLogoutUser();
  const accessTokenExpiration = browserStorage.accessTokenExpiration.get();
  const [isUserActive, setIsUserActive] = useState(isAuth);

  useEffect(() => {
    setIsUserActive(isAuth);
  }, [isAuth]);

  useEffect(() => {
    let timerId: NodeJS.Timer;

    if (accessTokenExpiration) {
      const timeInMilliseconds = getAccessTokenExpiration();

      timerId = setTimeout(() => {
        // Checking again because the token could have been refreshed by the time the timeout has reached
        if (getAccessTokenExpiration() <= 0) {
          logout();
        }
      }, timeInMilliseconds);
    }

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenExpiration]);

  useEffect(() => {
    let timerId: NodeJS.Timer;

    if (isAuth && isUserActive) {
      timerId = setInterval(() => {
        refreshAccessToken();
      }, refreshInterval);
    }

    // Cleanup
    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, isUserActive]);

  const idleTimer = useIdleTimer({
    timeout: refreshInterval,
    onActive: () => {
      refreshAccessToken();
      setIsUserActive(true);
    },
    onIdle: () => {
      setIsUserActive(false);
    },
    startOnMount: false,
    startManually: true
  });

  useEffect(() => {
    if (isAuth) {
      idleTimer.start();
    } else {
      idleTimer.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const accessTokenExpiration = browserStorage.accessTokenExpiration.get();
      const timeInMilliseconds = Math.round(
        accessTokenExpiration * 1000 - getCurrentTime()
      );

      // If the accessTokenExpiration is less than TOKEN_REFRESH_INTERVAL on mounting, we should refresh the token so the user will not be logged-out
      if (
        accessTokenExpiration &&
        timeInMilliseconds > 0 &&
        timeInMilliseconds < TOKEN_REFRESH_INTERVAL
      ) {
        await refreshAccessToken();
      }
    };

    checkTokenValidity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useTokenWatcher };
