interface RestrictedFieldHelperProps<Payload> {
  restrictedFields?: Array<keyof Payload>;
  fieldName: keyof Payload;
  children: React.ReactNode;
}

const RestrictedFieldHelper = <Payload,>({
  restrictedFields,
  fieldName,
  children
}: RestrictedFieldHelperProps<Payload>) => {
  if (restrictedFields?.includes(fieldName)) return <></>;

  return <>{children}</>;
};

export default RestrictedFieldHelper;
