import {
  useEditUser,
  UserBasePayload,
  useUser,
  useLoggedUserProfile,
  useUserGroups
} from '@viz/api';
import { IconSizes, Loading, SidePanel } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

import { useAnalyticsEvent } from '../../../../../../hooks';
import { toastStatusState } from '../../../../../../store';
import { UnsavedChangesDialog } from '../../components';
import TitleBar from '../../components/TitleBar';
import {
  filterByDirtyFields,
  getErrorMessage,
  preformatPayload,
  sendEditUserEvents,
  sendStopEditUserEvent,
  sendDeletedUserEvent,
  sendDeactivatedUserEvents
} from '../../utils';
import {
  DeactivateConfirmationDialog,
  LoadingContainer,
  StyledActions,
  StyledForm,
  StyledPersonalDetails,
  StyledUserLogs
} from '../common/panelStyles';
import { Assignments, Capabilities } from '../sections';

export const formId = 'edit-user-form';

interface EditUserProps {
  userId: string;
  onPanelTransitionEnd: () => void;
}

const EditUser = ({ userId, onPanelTransitionEnd }: EditUserProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const { data: loggedUserData } = useLoggedUserProfile();
  const { data: userData, isLoading } = useUser({ userId });
  const { mutate: editUser, isLoading: isSubmitting } = useEditUser(userId);
  const { data: userGroups } = useUserGroups();
  const { sendEvent } = useAnalyticsEvent();
  const [isUnsavedDialogOpen, setUnsavedDialogOpen] = useState(false);
  const setToastStatusState = useSetRecoilState(toastStatusState);
  const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);

  const form = useForm<UserBasePayload>({
    defaultValues: userData,
    reValidateMode: 'onBlur'
  });

  const { reset, handleSubmit, formState } = form;

  useEffect(() => {
    reset(userData);
  }, [reset, userData]);

  const onSubmitDirty = useCallback(
    (data: UserBasePayload) => {
      const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;

      if (hasDirtyFields) {
        const filteredData = filterByDirtyFields(data, formState.dirtyFields);

        const preformattedData = preformatPayload(
          filteredData,
          data,
          userGroups
        );

        editUser(preformattedData, {
          onSuccess: () => {
            if (userData) {
              sendEditUserEvents(sendEvent, preformattedData, userData, userId);
            }
            setIsOpen(false);

            setToastStatusState({
              type: 'info',
              text: localize(
                'editUserSuccessful',
                `${data.firstName} ${data.lastName}`
              )
            });
          },
          onError: (error) => {
            setToastStatusState({
              type: 'error',
              text: getErrorMessage(
                error,
                localize('editUserFailed', `${data.firstName} ${data.lastName}`)
              )
            });
          }
        });
      }
    },
    [
      formState,
      editUser,
      userGroups,
      sendEvent,
      userData,
      userId,
      setToastStatusState
    ]
  );

  const onDeactivateUser = () => {
    const deactivateUserData = { institutions: [] };
    editUser(deactivateUserData, {
      onSuccess: () => {
        if (userData) {
          sendDeactivatedUserEvents(sendEvent, userData, userId);
        }
        setIsOpen(false);

        setToastStatusState({
          type: 'info',
          text: localize('userDeactivated')
        });
      },
      onError: (error) => {
        setToastStatusState({
          type: 'error',
          text: getErrorMessage(error, localize('deactivateUserFailed'))
        });
      }
    });
  };

  return (
    <SidePanel
      open={isOpen}
      onClose={() => {
        if (Object.keys(formState.dirtyFields).length === 0) {
          setIsOpen(false);
          sendStopEditUserEvent(
            sendEvent,
            userId,
            formState.touchedFields,
            userData?.email ?? ''
          );
        } else {
          setUnsavedDialogOpen(true);
        }
      }}
      titleBar={
        <TitleBar
          title={localize('editUser')}
          buttonText={isSubmitting ? localize('updating') : localize('update')}
          buttonDisabled={isSubmitting}
          formId={formId}
          removeOptionEnabled={
            userData?.institutions && userData.institutions.length > 0
          }
          handleOnRemoveClick={() => setRemoveDialogOpen(true)}
        />
      }
      SlideProps={{
        appear: true,
        onExited: () => onPanelTransitionEnd()
      }}
    >
      {isLoading || !Boolean(form.getValues('email')) ? (
        <LoadingContainer>
          <Loading size={IconSizes.XX_LARGE} />
        </LoadingContainer>
      ) : (
        <>
          <StyledForm onSubmit={handleSubmit(onSubmitDirty)} id={formId}>
            <StyledPersonalDetails
              form={form}
              user={userData}
              restrictedFields={userData?.viewOnlyFields}
            />
            <Assignments
              form={form}
              restrictedFields={loggedUserData?.restrictedUserFields}
            />
            <Capabilities
              form={form}
              restrictedFields={loggedUserData?.restrictedUserFields}
            />
            {loggedUserData && userData && (
              <StyledUserLogs
                user={userData}
                restrictedFields={loggedUserData?.restrictedUserFields}
              />
            )}
            {loggedUserData && userData && (
              <StyledActions
                user={userData}
                hasPermissionToRemove={
                  !(
                    loggedUserData.restrictedUserFields &&
                    loggedUserData.restrictedUserFields.length > 0
                  )
                }
                onRemoveSuccessful={() => {
                  setIsOpen(false);
                  sendDeletedUserEvent(
                    sendEvent,
                    userData.email,
                    userData.userUid
                  );
                }}
              />
            )}
          </StyledForm>
          <UnsavedChangesDialog
            isOpen={isUnsavedDialogOpen}
            onCloseDialog={() => setUnsavedDialogOpen(false)}
            onClosePanel={() => {
              setUnsavedDialogOpen(false);
              setIsOpen(false);
            }}
          />
          <DeactivateConfirmationDialog
            title={localize('deleteUser')}
            primaryButtonProps={{
              content: localize('deactivateUserTitle'),
              onClick: onDeactivateUser
            }}
            secondaryButtonProps={{
              content: localize('cancel'),
              onClick: () => setRemoveDialogOpen(false)
            }}
            open={isRemoveDialogOpen}
            withCloseIcon={true}
            onClose={() => setRemoveDialogOpen(false)}
          >
            {localize('deactivateUserDialog', [
              userData?.firstName + ' ' + userData?.lastName
            ])}
          </DeactivateConfirmationDialog>
        </>
      )}
    </SidePanel>
  );
};

export default EditUser;
