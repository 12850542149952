import { FormControl } from '@material-ui/core';
import {
  Patient,
  ServerPatients,
  SubmitSharePatient,
  useSubmitSharePatientForm
} from '@viz/api';
import { IconSizes, Loading } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { toastStatusState } from '../../../../store';
import PatientInfo from '../../PatientInfo';
import {
  LoadingContainer,
  StyledDescription,
  StyledDialogContent,
  StyledPatientInfoContainer,
  StyledTextContainer,
  StyledTextInput
} from '../styles';
import { StyledSharePatientDialog } from './styles';

export type SharePatientProps = {
  distributionList: ServerPatients.DistributionList;
  patient: Patient;
  onClose: () => void;
  onSuccess: (payload: SubmitSharePatient.Payload) => void;
};

const ReferDialog = ({
  distributionList,
  patient,
  onClose,
  onSuccess
}: SharePatientProps): JSX.Element => {
  const [message, setMessage] = useState<string>('');
  const setToastStatus = useSetRecoilState(toastStatusState);

  const { mutate: submit, isLoading } = useSubmitSharePatientForm();

  const { name, id } = distributionList;

  const title = `${localize('referTo')} ${name}`;

  const acceptButton = isLoading ? (
    <LoadingContainer>
      <Loading size={IconSizes.SMALL} color="secondary" />
    </LoadingContainer>
  ) : (
    localize('sendReferral')
  );

  return (
    <StyledSharePatientDialog
      data-testid="refer-dialog"
      title={title}
      open={true}
      onClose={onClose}
      withCloseIcon
      primaryButtonProps={{
        content: acceptButton,
        onClick: async () => {
          const payload: SubmitSharePatient.Payload = {
            message,
            distribution_list_id: id,
            patient_id: patient?.id
          };
          submit(payload, {
            onSuccess: () => {
              setToastStatus({
                type: 'info',
                text: localize('referredSuccessfully', name)
              });
              onSuccess(payload);
            },
            onError: () => {
              setToastStatus({
                type: 'error',
                text: localize('referFailed', name)
              });
            }
          });
        }
      }}
      secondaryButtonProps={{
        content: localize('cancel'),
        onClick: onClose
      }}
    >
      <form>
        <StyledDialogContent>
          <StyledPatientInfoContainer>
            {patient && <PatientInfo patient={patient} variant="share" />}
          </StyledPatientInfoContainer>
          <StyledDescription data-testid={'desc'}>
            {localize('referDescription', [name, name])}
          </StyledDescription>
          <StyledTextContainer>
            <FormControl>
              <StyledTextInput
                testId="refer"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline={true}
                minRows={5}
                maxRows={5}
                placeholder={localize('addComment')}
              ></StyledTextInput>
            </FormControl>
          </StyledTextContainer>
        </StyledDialogContent>
      </form>
    </StyledSharePatientDialog>
  );
};

export default ReferDialog;
