import { Patient, Series, Study } from '@viz/api';
import { useEventListener } from '@viz/events';
import { logger } from '@viz/logging';
import { useRef } from 'react';

import {
  AnalyticsEventName,
  useAnalyticsEvent,
  ViewerType
} from '../useAnalytics';
import { ViewerToAppEvent } from './events';

export type SeriesLoadingTime = {
  time: number;
  diSeriesId: string;
};

export type UseViewerEventsProps = {
  patient?: Patient | null;
  study?: Study;
  series?: Series;
  onIframeLoaded: () => void;
  onViewerLoaded: () => void;
  onViewerLoading: () => void;
  onError: () => void;
  onSignificantError: () => void;
};

type ViewerEventMap = {
  [key in ViewerToAppEvent]: (data: any) => void;
};

type SendViewerEvent = (
  eventName: AnalyticsEventName,
  eventDetails?: Record<string, unknown>
) => void;

type MipSettingsUpdatedData = { currentMip: number; newMip: number };
type SliceScrollData = { currentSlice: number; newSlice: number; mip: number };
type WindowPresetClickedData = { currentPreset: string; newPreset: string };
type WindowManuallyUpdatedData = {
  newWindowWidth: number;
  newWindowLength: number;
};
type OrientationButtonClickedData = { currentOrientation: string };
type OrientationUpdatedData = {
  currentOrientation: string;
  newOrientation: string;
};

const isViewerEvent = (value: string): value is ViewerToAppEvent => {
  return value in ViewerToAppEvent;
};

const useViewerEvents = ({
  patient,
  study,
  series,
  onIframeLoaded,
  onViewerLoaded,
  onViewerLoading,
  onError,
  onSignificantError
}: UseViewerEventsProps): void => {
  const { sendEvent } = useAnalyticsEvent();
  const seriesLoadingTime = useRef<number | null>(null);

  const viewerEventData =
    patient && series
      ? {
          patient_id: patient.id,
          di_patient_name: patient.diName,
          di_patient_mrn: patient.diMedicalRecordNumber,
          institution: patient.institutionName,
          di_series_uid: series.diSeriesId,
          series_type: series.shortDescription,
          viewer_type: ViewerType.CT
        }
      : null;

  const sendViewerEvent: SendViewerEvent = (eventName, eventDetails) => {
    if (viewerEventData) {
      sendEvent(eventName, { ...viewerEventData, ...eventDetails });
    }
  };

  const onSeriesLoadingStart = () => {
    seriesLoadingTime.current = performance.now();
    sendViewerEvent(AnalyticsEventName.PATIENT_SERIES_VIEWED, {
      ai_detections: patient?.algoDetection?.[0] ?? null
    });
  };

  const onSwitchDimensionClicked = (data: {
    prevValue: boolean;
    newValue: boolean;
  }) => {
    sendViewerEvent(AnalyticsEventName.VIEWER_SWITCH_DIMENSION_CLICKED, {
      viewer_d_old_status: data.prevValue,
      viewer_d_new_status: data.newValue,
      patient_id: patient?.id
    });
  };

  const onSeriesLoaded = (data: { isSeriesLoadedFromCache: boolean }) => {
    if (seriesLoadingTime.current) {
      sendViewerEvent(AnalyticsEventName.PATIENT_SERIES_LOADING_COMPLETED, {
        series_chunk_count: series?.dicomCount,
        is_key_image_loaded_from_cache: false,
        is_series_loaded_from_cache: data.isSeriesLoadedFromCache,
        number_of_slices_in_chunk: 1,
        total_loading_time_in_seconds:
          (performance.now() - seriesLoadingTime.current) / 1000
      });
      seriesLoadingTime.current = null;
    }
  };

  const onMipSettingsUpdated = (data: MipSettingsUpdatedData) => {
    sendViewerEvent(AnalyticsEventName.VIEWER_MIP_SETTINGS_UPDATED, {
      current_mip: data.currentMip,
      new_mip: data.newMip
    });
  };

  const onSliceScroll = (data: SliceScrollData) => {
    sendViewerEvent(
      AnalyticsEventName.VIEWER_SLICE_SCROLL_BAR_POSITION_UPDATED,
      {
        current_slice: data.currentSlice,
        new_slice: data.newSlice,
        mip: data.mip
      }
    );
  };

  const onWindowPresetClicked = (data: WindowPresetClickedData) => {
    sendViewerEvent(AnalyticsEventName.VIEWER_WINDOW_PRESET_CLICKED, {
      current_preset: data.currentPreset,
      new_preset: data.newPreset
    });
  };

  const onWindowManuallyUpdated = (data: WindowManuallyUpdatedData) => {
    sendViewerEvent(AnalyticsEventName.VIEWER_WINDOW_MANUALLY_UPDATED, {
      new_window_width: data.newWindowWidth,
      new_window_length: data.newWindowLength
    });
  };

  const onOrientationButtonClicked = (data: OrientationButtonClickedData) => {
    sendViewerEvent(AnalyticsEventName.VIEWER_ORIENTATION_BUTTON_CLICKED, {
      current_orientation: data.currentOrientation
    });
  };

  const onOrientationUpdated = (data: OrientationUpdatedData) => {
    sendViewerEvent(AnalyticsEventName.VIEWER_ORIENTATION_UPDATED, {
      current_orientation: data.currentOrientation,
      new_orientation: data.newOrientation
    });
  };

  const getViewerLogDetails = (details?: Record<string, unknown>) => {
    return {
      ...details,
      patient_id: patient?.id,
      di_patient_name: patient?.diName,
      di_patient_mrn: patient?.diMedicalRecordNumber,
      institution: patient?.institutionName,
      di_series_uid: series?.diSeriesId,
      study_uid: study?.uid,
      series_uid: series?.uid,
      study_id: study?.id,
      series_id: series?.id,
      source: 'web-viewer'
    };
  };

  const onViewerError = (data: {
    message: string;
    details: Record<string, unknown>;
  }) => {
    logger.error(data.message, getViewerLogDetails(data.details));
    onError();
  };

  const onViewerWarning = (data: {
    message: string;
    details: Record<string, unknown>;
  }) => {
    logger.warn(data.message, getViewerLogDetails(data.details));
  };

  const onViewerSignificantError = () => {
    logger.warn(
      'Viewer Significant error -> remounting viewer component',
      getViewerLogDetails()
    );
    onSignificantError();
  };

  const EVENT_MAP: ViewerEventMap = {
    [ViewerToAppEvent.IFRAME_LOADED]: onIframeLoaded,
    [ViewerToAppEvent.VIEWER_LOADING]: onViewerLoading,
    [ViewerToAppEvent.VIEWER_LOADED]: onViewerLoaded,
    [ViewerToAppEvent.SERIES_LOADING_START]: onSeriesLoadingStart,
    [ViewerToAppEvent.SWITCH_DIMENSION_CLICKED]: onSwitchDimensionClicked,
    [ViewerToAppEvent.SERIES_LOADED]: onSeriesLoaded,
    [ViewerToAppEvent.MIP_SETTINGS_VIEWED]: () =>
      sendViewerEvent(AnalyticsEventName.VIEWER_MIP_SETTINGS_VIEWED),
    [ViewerToAppEvent.MIP_SETTINGS_UPDATED]: onMipSettingsUpdated,
    [ViewerToAppEvent.SLICE_SCROLL_UPDATED]: onSliceScroll,
    [ViewerToAppEvent.WINDOW_PRESET_CLICKED]: onWindowPresetClicked,
    [ViewerToAppEvent.WINDOW_MANUALLY_UPDATED]: onWindowManuallyUpdated,
    [ViewerToAppEvent.ORIENTATION_BUTTON_CLICKED]: onOrientationButtonClicked,
    [ViewerToAppEvent.ORIENTATION_UPDATED]: onOrientationUpdated,
    [ViewerToAppEvent.ROTATE_BUTTON_CLICKED]: () =>
      sendViewerEvent(AnalyticsEventName.VIEWER_ROTATE_BUTTON_CLICKED),
    [ViewerToAppEvent.VIEWER_ERROR]: onViewerError,
    [ViewerToAppEvent.VIEWER_WARNING]: onViewerWarning,
    [ViewerToAppEvent.SIGNIFICANT_ERROR]: onViewerSignificantError
  };

  const onMessage = (data: any) => {
    const { type } = data;

    if (type && isViewerEvent(type)) {
      EVENT_MAP[type](data);
    }
  };

  useEventListener('message', onMessage);
};

export default useViewerEvents;
