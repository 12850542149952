import { localize } from '@viz/i18n';
import { useRecoilState } from 'recoil';

import { useLogoutUser } from '../../hooks';
import { isLogoutDialogOpenState } from '../../store';
import { StyledLabel } from '../ContactSupportDialog/styles';
import { StyledDialog } from './styles';

const LogoutDialog = () => {
  const { logout } = useLogoutUser();
  const [isOpen, setIsOpen] = useRecoilState(isLogoutDialogOpenState);

  const onContinueDialog = () => {
    logout(true);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <StyledDialog
      data-testid="logout-support-dialog"
      testName="logout"
      open={isOpen}
      onClose={closeDialog}
      primaryButtonProps={{
        testId: 'logout-dialog-button-continue',
        content: localize('continue'),
        onClick: onContinueDialog
      }}
      secondaryButtonProps={{
        testId: 'logout-dialog-button-cancel',
        content: localize('cancel'),
        onClick: closeDialog
      }}
      title={localize('logout')}
      withCloseIcon
    >
      <StyledLabel data-testid={'logout-content-label'}>
        {localize('logoutFeedback')}
      </StyledLabel>
    </StyledDialog>
  );
};

export { LogoutDialog };
