import {
  Button,
  IconMessage,
  IconSizes,
  InteractiveTooltipButton,
  MessageContent,
  MessageInput
} from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  ChatModeType,
  ChatSourceLocation,
  ChatType,
  useChatConversation,
  usePatientTabs
} from '../../../hooks';
import {
  patientFolderSelectedTabStateFamily,
  PatientFolderTabs
} from '../../../store';
import { StyledFooter } from './styles';

const TOOLTIP_WIDTH = 420;

interface PatientDataFooterProps {
  patientId: string;
}

const PatientDataFooter = ({
  patientId
}: PatientDataFooterProps): JSX.Element => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { closePatientTab } = usePatientTabs();
  const setSelectedPatientFolderTab = useSetRecoilState(
    patientFolderSelectedTabStateFamily(patientId)
  );

  const { sendNewMessage } = useChatConversation({
    conversationId: patientId,
    chatType: ChatType.PATIENT_CHAT,
    chatModeType: ChatModeType.QUICK_CHAT_BUTTON,
    chatSourceLocation: ChatSourceLocation.VIEWER
  });

  const handleSend = (messageContent: MessageContent) => {
    sendNewMessage(messageContent);
    setIsTooltipOpen(false);
    setSelectedPatientFolderTab(PatientFolderTabs.CHAT);
  };

  return (
    <StyledFooter>
      <Button
        testId="close-viewer"
        variant="text"
        size="large"
        onClick={() => closePatientTab(patientId)}
      >
        {localize('close')}
      </Button>
      <InteractiveTooltipButton
        isTooltipOpen={isTooltipOpen}
        buttonText={localize('notifyInChat')}
        icon={<IconMessage size={IconSizes.SMALL} />}
        placement="top-end"
        width={TOOLTIP_WIDTH}
        onButtonClick={() => setIsTooltipOpen(true)}
        onTooltipClose={() => setIsTooltipOpen(false)}
        testId="viewer-notify-in-chat"
        tooltipContent={
          <MessageInput
            autoFocus
            allowFiles={false}
            multiline={false}
            onSend={handleSend}
            testId="notify-in-chat-input"
          />
        }
      />
    </StyledFooter>
  );
};

export default PatientDataFooter;
