import { Dialog, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledVerifyPhoneNumberDialog = styled(Dialog)`
  && .MuiPaper-root {
    width: ${getSize(110)};
    height: max-content;
    margin: auto;

    .MuiDialogContent-root {
      padding: ${getSize(6)};
    }
  }
`;
