import { Dialog, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledSharePatientDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: ${getSize(200)};
  }

  & .MuiButton-containedPrimary {
    width: ${getSize(35)};
  }
`;
