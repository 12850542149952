import { localize } from '@viz/i18n';
import { IS_RENDERER_SUPPORTED } from '@viz/utils';
import axios from 'axios';

import { TroubleshootingSection } from './types';
import { isChromeOrEdge, isInCognito } from './utils';

const checkDomainStatus = async (url: string) => {
  try {
    const response = await axios.get(url);
    return response.status === 200;
  } catch {
    return false;
  }
};

export const getTroubleshootingSections = (): TroubleshootingSection[] => [
  {
    title: localize('networkConnectivity'),
    checks: [
      {
        testId: 'troubleshooting-mixpanel',
        name: 'mixpanel.com',
        check: () => checkDomainStatus('https://mixpanel.com')
      },
      {
        testId: 'troubleshooting-datadoghq',
        name: 'datadoghq.com',
        check: () =>
          checkDomainStatus(
            'https://static.datadoghq.com/static/images/logos/javascript_avatar.svg'
          )
      },
      {
        testId: 'troubleshooting-googleapis',
        name: 'googleapis.com',
        check: () =>
          checkDomainStatus('https://fonts.googleapis.com/css?family=Lato')
      },
      {
        testId: 'troubleshooting-gstatic',
        name: 'gstatic.com',
        check: () =>
          checkDomainStatus(
            'https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wWw.ttf'
          )
      }
    ]
  },
  {
    title: localize('browserSettings'),
    checks: [
      {
        testId: 'troubleshooting-browser',
        name: localize('browserTypeCompatibility'),
        check: async () => isChromeOrEdge()
      },
      {
        testId: 'troubleshooting-renderer',
        name: localize('rendererCompatibility'),
        check: async () => IS_RENDERER_SUPPORTED
      },
      {
        testId: 'troubleshooting-notifications',
        name: localize('notificationsEnabled'),
        check: async () => Notification.permission === 'granted'
      },
      {
        testId: 'troubleshooting-incognito',
        name: localize('notIncognitoMode'),
        check: async () => !(await isInCognito())
      }
    ]
  }
];

export const TEST_IDS = {
  fail: 'status-indicator-fail',
  ok: 'status-indicator-ok'
};

export const allChecks = getTroubleshootingSections();
