import { localize } from '@viz/i18n';
import { PropsWithChildren } from 'react';

import { StyledDialogStep, StyledDialogStepTitle } from './styles';

export type DialogStepProps = PropsWithChildren<{
  number: Number;
}>;

const DialogStep = ({ number, children }: DialogStepProps): JSX.Element => {
  return (
    <StyledDialogStep>
      <StyledDialogStepTitle>
        {localize('step')} {number}
      </StyledDialogStepTitle>
      {children}
    </StyledDialogStep>
  );
};

export default DialogStep;
