import {
  Dialog,
  getFontSize,
  getSize,
  getSpacing,
  Grid,
  TabPanel
} from '@viz/design-system';
import styled, { css } from 'styled-components';

type StyledTabPanelProps = {
  $isDisplayed: boolean;
};

export const StyledTabPanel = styled(TabPanel)<StyledTabPanelProps>(
  ({ $isDisplayed }) => css`
    ${!$isDisplayed &&
    css`
      visibility: hidden;
      z-index: -1000;
      width: calc(100% - ${getSpacing(8)});
    `}
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: ${getSize(20)} 0;
    padding: 0 ${getSize(4)};
    width: 100%;
    height: calc(100% - ${getSize(20)});
  `
);

export const StyledLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Content = styled(Grid)`
  flex-grow: 1;
  min-height: 0;
`;

export const StyledPatientFolderGrid = styled(Grid)`
  height: 100%;
  overflow: hidden;
`;

export const StyledViewerGrid = styled(Grid)`
  height: 100%;
`;

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    height: ${getSize(60)};
    width: ${getSize(120)};
  }

  & .MuiDialogContent-root {
    ${getFontSize('lg')};
    line-height: 1.12;
  }

  & .MuiButton-label {
    text-transform: uppercase;
  }
`;
