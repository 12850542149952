import { Patient } from '@viz/api';
import { getActiveLanguage, localize } from '@viz/i18n';
import { getDateAndTime, isCurrentYear } from '@viz/utils';

export function timestampToDatetime(value: string): string {
  const date = Number(value);
  return getDateAndTime(date, {
    language: getActiveLanguage(),
    formatting: isCurrentYear(date) ? 'HH:mm MMM dd' : 'HH:mm MMM dd yyyy'
  });
}

export function createSubmitStaffNotesEventData(
  patient: Patient,
  formId: 'hcm_form'
) {
  return {
    viz_patient_id: patient.id,
    di_patient_mrn: patient.medicalRecordNumber,
    institution: patient.institutionName,
    action_text: localize('submitAndShare'),
    di_patient_name: patient.name,
    form_type: formId
  };
}
