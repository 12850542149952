import { localize } from '@viz/i18n';

import {
  StyledTitleBar,
  Title,
  SubmitButton,
  DeactivateUserButton
} from './styles';

interface TitleBarProps {
  title: string;
  buttonText: string;
  buttonDisabled?: boolean;
  formId?: string;
  removeOptionEnabled?: boolean;
  handleOnRemoveClick?: () => void;
}

const TitleBar = ({
  title,
  buttonText,
  buttonDisabled,
  formId,
  removeOptionEnabled,
  handleOnRemoveClick
}: TitleBarProps) => (
  <StyledTitleBar>
    <Title>{title}</Title>
    <span>
      {removeOptionEnabled && (
        <DeactivateUserButton
          type="button"
          variant="outlined"
          onClick={handleOnRemoveClick}
        >
          {localize('deactivateUser')}
        </DeactivateUserButton>
      )}
      <SubmitButton
        type="submit"
        size="medium"
        variant="contained"
        color="primary"
        form={formId}
        disabled={buttonDisabled}
      >
        {buttonText}
      </SubmitButton>
    </span>
  </StyledTitleBar>
);
export default TitleBar;
