import {
  Dialog,
  getFontSize,
  getSize,
  getSpacing,
  IconSuccess
} from '@viz/design-system';
import styled, { css } from 'styled-components';

export const StyledResetPasswordDialog = styled(Dialog)`
  & .MuiDialog-paper {
    height: ${getSize(102)};
    width: ${getSize(150)};
    ${getFontSize('base')};
  }
`;

export const IdentificationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UsernameInputContainer = styled.div`
  margin-top: ${getSpacing(6)};
  width: ${getSize(103)};
`;

export const StyledSuccessIcon = styled(IconSuccess)`
  margin-right: ${getSpacing(2)};
`;

export const StyledText = styled.div<{
  fontWeight?: number;
  marginTop?: number;
  marginBottom?: number;
  flexDirection?: 'row' | 'column';
  align?: string;
  justify?: string;
}>(
  ({
    theme,
    fontWeight = 400,
    marginTop = 0,
    marginBottom = 0,
    flexDirection = 'row',
    align = 'flex-start',
    justify = 'flex-start'
  }) => css`
    font-weight: ${theme.fontWeights[`font-weight-bold-${fontWeight}`]};

    margin-top: ${getSpacing(marginTop)};
    margin-bottom: ${getSpacing(marginBottom)};

    display: flex;
    flex-direction: ${flexDirection};
    align-items: ${align};
    justify-content: ${justify};
  `
);
