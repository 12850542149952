import {
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledViewer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${getSpacing(30)} ${getSpacing(8)} ${getSpacing(20)} ${getSpacing(8)};
`;

export const StyledDisclaimer = styled.span`
  color: ${getBaseColor('error')};
  position: absolute;
  bottom: ${getSpacing(6)};
`;

export const StyledEcgInfo = styled.div`
  position: absolute;
  top: ${getSpacing(8)};
  left: ${getSpacing(8)};
`;

export const StyledPatientName = styled.div`
  ${getFontSize('xl')};
  font-weight: ${getFontWeight('font-weight-bold-500')};
`;
