import {
  BasicAuditField,
  BooleanField,
  NameIdAuditField,
  UserGroupsAuditField
} from '@viz/api';

import { FieldItem, FieldValue } from '../types';

export function stringFieldMapper(
  title: string,
  field: BasicAuditField
): FieldItem {
  return {
    id: field.fieldId,
    title,
    values: field.addedValues
      .map(
        (value) =>
          ({
            title: value,
            type: 'added'
          } as FieldValue)
      )
      .concat(
        field.deletedValues.map(
          (value) =>
            ({
              title: value,
              type: 'removed'
            } as FieldValue)
        )
      )
  };
}

export function nameIdFieldMapper(
  title: string,
  field: NameIdAuditField
): FieldItem {
  return {
    id: field.fieldId,
    title,
    values: field.addedValues
      .map(
        (value) =>
          ({
            title: `${value.name} (${value.id})`,
            type: 'added'
          } as FieldValue)
      )
      .concat(
        field.deletedValues.map(
          (value) =>
            ({
              title: `${value.name} (${value.id})`,
              type: 'removed'
            } as FieldValue)
        )
      )
  };
}

export function userGroupFieldMapper(
  title: string,
  field: UserGroupsAuditField
): FieldItem {
  return {
    id: field.fieldId,
    title,
    values: field.addedValues
      .map(
        (value) =>
          ({
            title: `${value.name} (${value.id})`,
            subtitle: `${value.institution.name} ${value.institution.id}`,
            type: 'added'
          } as FieldValue)
      )
      .concat(
        field.deletedValues.map(
          (value) =>
            ({
              title: `${value.name} (${value.id})`,
              subtitle: `${value.institution.name} ${value.institution.id}`,
              type: 'removed'
            } as FieldValue)
        )
      )
  };
}

export function booleanFieldValueMapper(
  title: string,
  field: BooleanField
): FieldValue {
  return {
    title,
    type: field.addedValues[0] === 'True' ? 'added' : 'removed'
  };
}

export function sortFieldByOrder(
  fields: FieldItem[],
  order: string[]
): FieldItem[] {
  const orderMap = new Map(order.map((field, index) => [field, index]));

  return [...fields].sort((a, b) => {
    const indexA = orderMap.get(a.id) ?? order.length;
    const indexB = orderMap.get(b.id) ?? order.length;
    return indexA - indexB;
  });
}
