import { PAGER_NOTIFICATION_TITLE, PushNotificationType } from '@viz/hooks';

import { ServiceWorkerEvents } from '../../events';
import { useDeepLinkNotification } from '../useDeepLinkNotification';

const useServiceWorkerWatcher = () => {
  const { handleNotificationClick } = useDeepLinkNotification();

  navigator.serviceWorker.onmessage = ({ data }) => {
    if (data.messageType === ServiceWorkerEvents.NOTIFICATION) {
      const {
        title,
        push_notification_type,
        push_notification_id: notificationId
      } = data.data;

      const notificationType =
        title === PAGER_NOTIFICATION_TITLE
          ? PushNotificationType.PAGER
          : push_notification_type;

      handleNotificationClick({ notificationId, notificationType });
    }
  };
};

export { useServiceWorkerWatcher };
