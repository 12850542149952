import { IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';

import {
  StyledIconCircledCheck,
  StyledIconCircledCross,
  IsAdmin
} from './styles';

interface IsAdminValueProps {
  isAdmin: boolean;
}

const IsAdminValue = ({ isAdmin }: IsAdminValueProps) => (
  <IsAdmin>
    {isAdmin ? (
      <StyledIconCircledCheck size={IconSizes.X_SMALL} />
    ) : (
      <StyledIconCircledCross size={IconSizes.X_SMALL} />
    )}
    {localize(isAdmin ? 'yes' : 'no')}
  </IsAdmin>
);

export default IsAdminValue;
