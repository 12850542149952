import {
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSize,
  getSpacing,
  screens
} from '@viz/design-system';
import styled, { css } from 'styled-components';

const getVariantStyles = (variant: string) => {
  switch (variant) {
    case 'share':
      return css`
        background-color: ${getBaseColor('blue-deep')};
        border-radius: ${getSize(2)};
      `;
    default:
      return css``;
  }
};

export const StyledPatientInfoContainer = styled.div<{ variant?: string }>`
  padding: ${getSize(3)} ${getSize(5)};
  background-color: ${getBaseColor('gray-main')};
  width: 100%;

  ${({ variant }) => variant && getVariantStyles(variant)};
`;

export const StyledPatientInfoHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr auto;
  gap: ${getSpacing(8)};

  @media ${screens.small} {
    display: flex;
    flex-direction: column-reverse;
    gap: ${getSpacing(4)};
  }
`;

export const StyledPatientName = styled.span`
  padding-bottom: ${getSize(1)};
  ${getFontSize('2xl')};
  font-weight: ${getFontWeight('font-weight-bold-600')};

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPatientInfoData = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:first-child):before {
      content: '|';
      margin: 0 ${getSize(1)};
    }
  }
`;

export const StyledInstitutionName = styled.span`
  color: ${getBaseColor('french-gray')};
  padding-top: ${getSize(1)};
`;
