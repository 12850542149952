import { getFontSize, getFontWeight, getSize } from '@viz/design-system';
import styled from 'styled-components';

import BackgroundImage from './assets/background.svg';
import { ReactComponent as PhoneImage } from './assets/phone.svg';
import { ReactComponent as WaveImage } from './assets/wave.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url(${BackgroundImage});
  background-size: cover;
  height: 90vh;
  width: 100vw;
  padding: ${getSize(8)} ${getSize(5)} 0 ${getSize(5)};
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoText = styled.div`
  ${getFontSize('xl')};
  font-weight: ${getFontWeight('font-weight-bold-500')};
`;

export const MainMessage = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: ${getFontWeight('font-weight-bold-100')};
`;

export const BadgesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${getSize(10)};

  a svg {
    pointer-events: none;
  }
`;

export const StyledPhoneImage = styled(PhoneImage)`
  z-index: 1;
`;

export const StyledWaveImage = styled(WaveImage)`
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
`;
