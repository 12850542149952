import { AppToViewerEvent } from '../events';

class BaseViewerMessage<T> {
  private readonly type: AppToViewerEvent;
  private readonly payload: T;

  constructor(type: AppToViewerEvent, payload: T) {
    this.type = type;
    this.payload = payload;
  }

  toJSON() {
    return { type: this.type, payload: this.payload };
  }
}

export { BaseViewerMessage };
