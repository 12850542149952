import { LoginPayload } from '@viz/api';
import { Form } from '@viz/design-system';

import { StyledAlert } from '../Mfa/styles';
import { LoginLoading, StyledLoginButton } from './styles';

export type LoginFormProps = {
  onSubmit: (payload: LoginPayload) => void;
  isLoading: boolean;
  errorMessage?: JSX.Element | string;
  buttonText: string;
  children: JSX.Element;
};

const LoginForm = ({
  onSubmit,
  errorMessage,
  isLoading,
  buttonText,
  children
}: LoginFormProps): JSX.Element => {
  const loginErrorExists = Boolean(errorMessage);

  return (
    <Form
      onSubmit={onSubmit}
      id="login-form"
      testId="login-form"
      formOptions={{ mode: 'onBlur', reValidateMode: 'onBlur' }}
    >
      {children}
      <StyledLoginButton
        type="submit"
        size="large"
        variant="contained"
        color="primary"
        testId="submit-login"
        form="login-form"
        disabled={loginErrorExists || isLoading}
      >
        {isLoading ? (
          <LoginLoading testId="login-loader" color="inherit" />
        ) : (
          buttonText
        )}
      </StyledLoginButton>
      <StyledAlert
        testId="login-alert"
        type="error"
        isVisible={loginErrorExists}
        text={errorMessage}
      />
    </Form>
  );
};

export default LoginForm;
