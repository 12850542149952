import {
  Alert,
  getBaseColor,
  getBorderRadius,
  getFontSizeValue,
  getSize,
  Menu,
  Typography
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledMenuItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const AlertContainer = styled.div`
  padding: ${getSize(4)};
`;

export const StyledAlert = styled(Alert)`
  padding: ${getSize(2)};

  & > div {
    margin-right: ${getSize(2)};

    & svg {
      width: ${getSize(6)};
      height: ${getSize(6)};
    }
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledVersionNumber = styled(Typography)`
  && {
    color: ${getBaseColor('santas-gray')};
    padding: ${getSize(1)} ${getSize(4.5)};
    font-size: ${getFontSizeValue('small')};
  }
`;

export const OnCallContainer = styled.div`
  width: 100%;
  padding: ${getSize(2)} ${getSize(1)};
  padding-bottom: 0;
  display: flex;
  align-items: center;
`;

const StyledTopTriangle = `
  &:before {
    content: '';
    position: absolute;
    top: ${getSize(-2.25)};
    right: ${getSize(6.25)};
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 18px solid ${getBaseColor('blue-zodiac')}
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    width: ${getSize(75)};
    border-radius: ${getBorderRadius('MEDIUM')};
    margin-top: ${getSize(1.5)};
    color: ${getBaseColor('french-gray')};
    overflow: visible;

    ${StyledTopTriangle}
  }
`;

export const SwitchLabel = styled(Typography)`
  && {
    font-size: ${getFontSizeValue('medium')};
  }
`;
