import {
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSize,
  getSpacing,
  Typography
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledStaffNotesSectionItemContainer = styled.div`
  padding: ${getSpacing(2)} 0;
  display: flex;
  gap: ${getSize(1)};
  flex-direction: column;
`;

export const StyledTypography = styled(Typography)`
  color: ${getBaseColor('porcelain')};
`;

export const StyledTitle = styled.span`
  font-weight: ${getFontWeight('font-weight-bold-600')};
  color: ${getBaseColor('santas-gray')};
  font-size: ${getFontSize('sm')};
`;
