import { useIcon } from '@viz/api';
import { IconSizes } from '@viz/design-system';

import { StyledIconContainer } from './styles';

interface IconContainerProps {
  iconUrl: string | undefined;
  testId?: string;
  role?: string;
  size?: IconSizes;
}

const IconContainer = (props: IconContainerProps) => {
  const { iconUrl = null, size = IconSizes.X_MEDIUM } = props;
  const { data } = useIcon({ iconUrl });
  return (
    <>
      {data ? (
        <StyledIconContainer
          $size={size}
          dangerouslySetInnerHTML={{ __html: data as string }}
          {...props}
        />
      ) : null}
    </>
  );
};
export default IconContainer;
