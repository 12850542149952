import { UserGroupCategory } from '@viz/api';

export enum Category {
  algorithms = 'Algorithms',
  filters = 'Filters',
  referrals = 'Referrals',
  activations = 'Activations',
  researchAlgorithms = 'Research Algorithms',
  researchTrials = 'Research Trials'
}

export enum SpecAction {
  SEND = 'Send',
  RECEIVE = 'Receive'
}

export const getUserGroupContent = (
  category: Category,
  userGroupCategory: UserGroupCategory
): string | undefined => {
  if (!userGroupCategory) {
    return '';
  }

  const sendSpec = userGroupCategory.send;
  const receiveSpec = userGroupCategory.receive;
  const specialCategory =
    category === Category.activations || category === Category.referrals;

  if (specialCategory) {
    const isReceiveSpecExist = receiveSpec?.length;

    return (
      (sendSpec?.length
        ? `${userGroupCategory.send?.join(', ')} (${SpecAction.SEND})${
            isReceiveSpecExist ? '; ' : ''
          }`
        : '') +
      (isReceiveSpecExist
        ? `${userGroupCategory.receive?.join(', ')} (${SpecAction.RECEIVE})`
        : '')
    );
  } else {
    return receiveSpec?.join(', ');
  }
};
