import { EventBus } from '@viz/events';

export enum Events {
  LOGOUT = 'LOGOUT',
  RESET_STATE = 'RESET_STATE'
}

export enum ServiceWorkerEvents {
  NOTIFICATION = 'NOTIFICATION'
}

const eventbus = new EventBus();

export { eventbus };
