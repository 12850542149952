import {
  ClientConfig,
  ClientConfigTab,
  ClientConfigTabStatus,
  PatientListPatient,
  PatientListStudy
} from '@viz/api';
import {
  EllipsisTextTooltip,
  IconAllCases,
  IconSearchResults,
  IconSizes,
  TableHeader,
  TableRow
} from '@viz/design-system';
import { localize } from '@viz/i18n';
import { en } from '@viz/i18n/src/translations/en';
import { capitalize, getDateAndTime, sortBy } from '@viz/utils';
import { isAfter, subHours } from 'date-fns';
import { useMemo } from 'react';

import { AlgoDetectionIcon } from '../../components';
import { CopyMRN } from '../../components/CopyMRN';
import { browserStorage } from '../../managers';
import { PRIOR_HOURS } from '../../types';
import { Filters } from './FiltersSection/types';
import { PatientIcons } from './PatientIcons';

interface DescriptionCellProps {
  studies: PatientListStudy[];
}

const DEFAULT_ANALYTICS_FILTER_VALUE = 'all';

const getPatientDescription = (studies: PatientListStudy[]) => {
  const sortedStudies = sortBy(studies, 'firstAcquiredTs', 'desc');
  const latestStudies = sortedStudies.filter((study) =>
    isAfter(study.firstAcquiredTs, subHours(new Date(), PRIOR_HOURS))
  );

  if (latestStudies.length > 0) {
    return latestStudies.slice(0, 3);
  }

  return sortedStudies.slice(0, 1);
};

const DescriptionCell = ({ studies }: DescriptionCellProps): JSX.Element => {
  const displayedStudies = useMemo(
    () => getPatientDescription(studies),
    [studies]
  );

  const descriptionCellBody: string[] = [];
  const descriptionCellTooltipTitle: JSX.Element[] = [];

  displayedStudies.forEach((study, index) => {
    descriptionCellBody.push(study.description);
    descriptionCellTooltipTitle.push(
      <span key={index}>
        {study.description} <br />
      </span>
    );
  });

  return (
    <EllipsisTextTooltip
      tooltipTitle={<> {descriptionCellTooltipTitle} </>}
      testId={`description-studies`}
    >
      {descriptionCellBody.join(', ')}
    </EllipsisTextTooltip>
  );
};

export const getTableHeaders = (
  headers: TableHeader[],
  showStatusColumn = false
): TableHeader[] => {
  if (showStatusColumn) {
    headers.splice(headers.length - 1, 0, {
      id: 'status',
      value: localize('status')
    });
  }
  return headers;
};

export const getStatuses = (
  clientConfig: ClientConfig,
  spec_id: number | undefined
) => {
  return (
    (clientConfig?.tabs &&
      clientConfig.tabs.find((tabConfig) => tabConfig.id === spec_id)
        ?.statuses) ||
    []
  );
};

export const getSortingOptions = (
  clientConfig: ClientConfig,
  spec_id: number | undefined
) => {
  return (
    (clientConfig?.tabs &&
      clientConfig.tabs.find((tabConfig) => tabConfig.id === spec_id)
        ?.sorting_options) ||
    []
  );
};

export const getDefaultSortingOption = (
  clientConfig: ClientConfig,
  spec_id: number | undefined
) => {
  return (
    (clientConfig?.tabs &&
      clientConfig.tabs.find((tabConfig) => tabConfig.id === spec_id)
        ?.default_sorting) ||
    undefined
  );
};

export const getSortValueFromId = (
  clientConfig: ClientConfig,
  tabId: number | undefined,
  sortingOptionId: number | undefined
) => {
  return getSortingOptions(clientConfig, tabId).find(
    (s) => s.id === sortingOptionId
  )?.value;
};

export const getStatusValueFromId = (
  tabId: number | undefined,
  statusId: number | undefined
) => {
  const clientConfig = browserStorage.clientConfig.get();
  return getStatuses(clientConfig, tabId).find((s) => s.id === statusId)?.value;
};

export const getPatientStatus = (
  patient: PatientListPatient,
  spec_id: number | undefined,
  statuses: Array<ClientConfigTabStatus>
): ClientConfigTabStatus | null => {
  const statusId =
    spec_id &&
    patient.patientStatusesDict &&
    patient.patientStatusesDict.find((status) => status.spec_id === spec_id)
      ?.status;
  return statuses.find((status) => status.id === statusId) || null;
};

export const convertPatientsToRows = (
  spec_id: number | undefined,
  statuses: Array<ClientConfigTabStatus>,
  patients: PatientListPatient[],
  onMessageIconClick: (patientId: string) => void
): TableRow[] => {
  return patients.map((patient) => {
    const sortedStudies = sortBy(patient.studies, 'firstAcquiredTs', 'desc');
    const latestStudyTs = sortedStudies[0]?.firstAcquiredTs;
    const isUnread = false; //this is placeholder pending implementation
    const studyDate = getDateAndTime(latestStudyTs, {
      formatting: 'HH:mm | MM/dd/yy'
    });
    const status = getPatientStatus(patient, spec_id, statuses);
    return {
      id: patient.id,
      data: {
        isUnread: isUnread,
        icon: (
          <PatientIcons
            patientId={patient.id}
            highlightIcons={patient.highlightIcons}
            unreadMessagesCount={patient.unreadMessagesCount}
            isFailed={patient.studies.some((study) => study.isFailed)}
            onMessageIconClick={() => onMessageIconClick(patient.id)}
          />
        ),
        name: <EllipsisTextTooltip>{patient.name}</EllipsisTextTooltip>,
        age: patient.age,
        gender: patient.sex && capitalize(patient.sex),
        appointmentDate: patient.appointmentDate || 'N/A',
        mrn: (
          <CopyMRN
            patient={patient}
            className={'table-row-elem-opacity-togle-on-hover'}
            location={'patient_list'}
            iconSize={IconSizes.X_SMALL}
            testId="table-row-mrn-copy-btn"
          >
            <EllipsisTextTooltip>
              {patient.medicalRecordNumber}
            </EllipsisTextTooltip>
          </CopyMRN>
        ),
        desc: <DescriptionCell studies={sortedStudies} />,
        studyDate: <EllipsisTextTooltip>{studyDate} </EllipsisTextTooltip>,
        institution: (
          <EllipsisTextTooltip>{patient.institutionName}</EllipsisTextTooltip>
        ),
        status: status && (
          <EllipsisTextTooltip>{status.value}</EllipsisTextTooltip>
        )
      }
    };
  });
};

export const getTabById = (tabId?: number): ClientConfigTab | undefined => {
  const clientConfig = browserStorage.clientConfig.get();
  if (!tabId || !clientConfig.tabs) return;

  const tab = clientConfig.tabs.find((tab) => tab.id === tabId);
  if (!tab?.header) return;

  return tab;
};

export const getTabHeader = (
  distributionListTabId: number | undefined
): string | undefined => {
  return isAllCasesDistributionListTab(distributionListTabId)
    ? en.allCases
    : getTabById(distributionListTabId)?.header;
};

export const getPatientNameFromRowData = (rowData: TableRow['data']) => {
  return rowData['name']?.props.children;
};

export const getStatusFromRowData = (
  rowData?: TableRow['data']
): string | undefined => {
  return rowData?.['status']?.props.children;
};

export const isAllCasesDistributionListTab = (
  distributionListTabId: number | undefined
) => {
  return distributionListTabId === undefined;
};

export const getPageIcon = (
  searchQuery: string | undefined,
  tab: ClientConfigTab | undefined
) => {
  if (searchQuery) {
    return <IconSearchResults />;
  }
  if (tab) {
    return (
      <AlgoDetectionIcon iconUrl={tab?.icon_url} size={IconSizes.MEDIUM} />
    );
  }
  return <IconAllCases />;
};

export const getStatusTitle = (
  tabId: number | undefined,
  statusId: number | undefined
) => {
  const statusValue = getStatusValueFromId(tabId, statusId);
  return statusValue ? ` - ${statusValue}` : '';
};

export const getPageTitle = (
  searchQuery: string | undefined,
  tab: ClientConfigTab | undefined
) => {
  if (searchQuery) {
    const caseType = tab?.header ?? localize('allCases');
    return `${localize('searchResults')} '${caseType}': `;
  }
  if (tab) {
    return tab.header;
  }
  return localize('allCases');
};

function getStatusFilterValue(
  distributionListTabId: number | undefined,
  status: number | undefined
) {
  if (status === undefined) {
    return DEFAULT_ANALYTICS_FILTER_VALUE;
  }
  return (
    getStatusValueFromId(distributionListTabId, status) ||
    DEFAULT_ANALYTICS_FILTER_VALUE
  );
}

export function formatFiltersForAnalytics(
  filters: Filters | undefined,
  tabConfig: ClientConfigTab | undefined,
  distributionListTabId: number | undefined,
  status: number | undefined
): Record<string, string[]> {
  const result: Record<string, string[]> = {};

  if (tabConfig?.statuses?.length) {
    result['patient_status'] = [
      getStatusFilterValue(distributionListTabId, status)
    ];
  }

  (tabConfig?.filters || []).forEach((config) => {
    const value =
      filters?.find((f) => f.name === config.name)?.option?.name ||
      DEFAULT_ANALYTICS_FILTER_VALUE;
    result[config.name] = [value];
  });

  return result;
}
