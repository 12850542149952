import { analyticsService } from '@viz/analytics';
import { apiGatewayService, apiService } from '@viz/api';
import { initializeI18N } from '@viz/i18n';
import { monitoringService } from '@viz/monitoring';
import { getConfigValue } from '@viz/utils';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { Root } from './application';
import { httpApiGatewayService, httpService } from './axios';
import './index.css';
import { history } from './navigation';
import reportWebVitals from './reportWebVitals';
import { mockAdapter, mockAllRequests } from './tests/mocks';

// Register our own HTTP service
apiService.initialize(httpService);
apiGatewayService.initialize(httpApiGatewayService);

const shouldMock = getConfigValue('REACT_APP_MOCK_API');
if (shouldMock) {
  mockAdapter.initialize(httpService, httpApiGatewayService);
  mockAllRequests();
}

monitoringService.initialize({ service: 'vizwebapp' });
analyticsService.initialize();
initializeI18N();
// Render application
ReactDOM.render(
  <StrictMode>
    <Root history={history} />
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
