import { User, UserDetails, UserBasePayload, useRoles } from '@viz/api';
import { MenuItem, Input } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useState } from 'react';
import { UseFormReturn, Controller } from 'react-hook-form';

import { FormLabel } from '../../../components';
import { SwitchToEditUser, SendUserInvitation } from './components';
import {
  FieldSection,
  FieldSectionTitle,
  FieldSectionInputs,
  StyledPhoneInput
} from './styles';
import {
  emailAlreadyExists,
  validEmail,
  vizEmailAlreadyExist
} from './validators';

interface PersonalDetailProps {
  form: UseFormReturn<UserBasePayload>;
  user?: UserDetails;
  isCreateUser?: boolean;
  onSwitchToEdit?: (userId?: string) => void;
  onSendInvitation?: (userEmail: string) => void;
  restrictedFields?: Array<keyof UserBasePayload>;
  className?: string;
}

const PersonalDetails = ({
  form: { control },
  user,
  isCreateUser,
  onSwitchToEdit,
  onSendInvitation,
  restrictedFields,
  className
}: PersonalDetailProps) => {
  const { data: roleList } = useRoles();
  const [existingUser, setExistingUser] = useState<User | undefined>();
  const [existVizEmail, setExistVizEmail] = useState<string | undefined>();

  if (user && !roleList?.find((role) => role.id === user.roleId)) {
    const currentRole = { id: user.roleId, name: user.position };
    roleList?.push(currentRole);
  }

  return (
    <FieldSection className={className}>
      <FieldSectionTitle>{localize('personalDetails')}</FieldSectionTitle>
      <FieldSectionInputs>
        <Controller
          name="email"
          control={control}
          rules={{
            required: {
              value: true,
              message: localize('fieldRequired')
            },
            validate: isCreateUser
              ? {
                  validEmail,
                  existingEmail: emailAlreadyExists(setExistingUser),
                  existVizEmail: vizEmailAlreadyExist(setExistVizEmail)
                }
              : {}
          }}
          render={({ field, fieldState }) => (
            <Input
              disabled={!isCreateUser || restrictedFields?.includes('email')}
              label={<FormLabel translationKey="email" required />}
              onChange={(e) => {
                existingUser && setExistingUser(undefined);
                existVizEmail && setExistVizEmail(undefined);
                field.onChange(e);
              }}
              onBlur={field.onBlur}
              value={field.value}
              type="text"
              size="small"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: {
              value: true,
              message: localize('fieldRequired')
            }
          }}
          render={({ field, fieldState }) => (
            <Input
              label={<FormLabel translationKey="firstName" required />}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              type="text"
              size="small"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              disabled={
                !isCreateUser && restrictedFields?.includes('firstName')
              }
            />
          )}
        />
        <Controller
          name="middleName"
          control={control}
          render={({ field }) => (
            <Input
              label={<FormLabel translationKey="middleName" />}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              type="text"
              size="small"
              disabled={
                !isCreateUser && restrictedFields?.includes('middleName')
              }
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{
            required: {
              value: true,
              message: localize('fieldRequired')
            }
          }}
          render={({ field, fieldState }) => (
            <Input
              label={<FormLabel translationKey="lastName" required />}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              type="text"
              size="small"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              disabled={!isCreateUser && restrictedFields?.includes('lastName')}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          rules={{
            required: {
              value: true,
              message: localize('fieldRequired')
            }
          }}
          render={({ field, fieldState }) => (
            <StyledPhoneInput
              specialLabel={`${localize('phone')}*`}
              preferredCountries={['us']}
              country="us"
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              isValid={!fieldState.invalid}
              helperText={fieldState.error?.message}
              disabled={!isCreateUser && restrictedFields?.includes('phone')}
            />
          )}
        />
        <Controller
          name="roleId"
          control={control}
          rules={{
            required: {
              value: true,
              message: localize('fieldRequired')
            }
          }}
          render={({ field, fieldState }) => (
            <Input
              label={<FormLabel translationKey="position" required />}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={roleList && field.value}
              variant="outlined"
              size="small"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              select
              disabled={!isCreateUser && restrictedFields?.includes('roleId')}
            >
              {roleList?.map(({ id, name }) => (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              )) ?? []}
            </Input>
          )}
        />
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Input
              label={<FormLabel translationKey="title" />}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              type="text"
              size="small"
              disabled={!isCreateUser && restrictedFields?.includes('title')}
            />
          )}
        />
        <Controller
          name="suffix"
          control={control}
          render={({ field }) => (
            <Input
              label={<FormLabel translationKey="suffix" />}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              type="text"
              size="small"
              disabled={!isCreateUser && restrictedFields?.includes('suffix')}
            />
          )}
        />
      </FieldSectionInputs>
      {isCreateUser && onSwitchToEdit && existingUser && (
        <SwitchToEditUser user={existingUser} onSwitchToEdit={onSwitchToEdit} />
      )}
      {isCreateUser && !existingUser && onSendInvitation && existVizEmail && (
        <SendUserInvitation
          userEmail={existVizEmail}
          onSendInvitation={onSendInvitation}
        />
      )}
    </FieldSection>
  );
};

export default PersonalDetails;
