import { NotificationSetting as NotificationSettingData } from '@viz/api';
import { Switch } from '@viz/design-system';

import { UpdateNotificationSettingsProps } from '../NotificationSettings';
import { NotificationActions } from './NotificationActions';
import {
  StyledActionsContainer,
  StyledSettingContainer,
  SwitchLabel
} from './styles';

type NotificationSettingProps = {
  notificationSetting: NotificationSettingData;
  onUpdate: (notificationData: UpdateNotificationSettingsProps) => void;
};

const NotificationSetting = ({
  notificationSetting,
  onUpdate
}: NotificationSettingProps): JSX.Element => {
  const { id, name, state, actions, channelId } = notificationSetting;
  return (
    <StyledSettingContainer key={id} data-testid={`notification-setting-${id}`}>
      <Switch
        checked={state}
        testId={`notification-switch-${id}`}
        onChange={(e) =>
          onUpdate({
            notification: notificationSetting,
            criteriaId: channelId,
            value: e.target.checked,
            settingType: 'channels'
          })
        }
      />
      <StyledActionsContainer>
        <SwitchLabel>{name}</SwitchLabel>
        {state && actions.length > 0 && (
          <NotificationActions
            notificationSettingName={name}
            actions={actions}
            onChange={(criteriaId, value) =>
              onUpdate({
                notification: notificationSetting,
                criteriaId,
                value,
                settingType: 'actions'
              })
            }
          />
        )}
      </StyledActionsContainer>
    </StyledSettingContainer>
  );
};

export { NotificationSetting };
