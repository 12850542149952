import { useEventListener } from '@viz/events';
import { useCallback } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';

import { Events } from '../../events';
import { useAuth, useLogoutUser } from '../../hooks';
import { browserStorage } from '../../managers';
import { ProtectedRoute } from '../ProtectedRoute';
import { DefaultPaths, UserAccessMap } from '../constants';
import { Paths } from '../paths';
import { LayoutRoute } from '../routes';

interface LayoutsProps {
  routes: LayoutRoute[];
}

const Default = () => {
  const { isAuth, isLoading } = useAuth();
  const { logout } = useLogoutUser();
  const userAccessEnabled = browserStorage.userAccessEnabled.get();

  if (isAuth || isLoading) {
    for (const defaultPath of DefaultPaths) {
      const userAccessType = UserAccessMap[defaultPath.accessType];

      if (userAccessEnabled[userAccessType]) {
        return <Redirect to={defaultPath.defaultPath} />;
      }
    }

    logout();
  }
  return <Redirect to={Paths.LOGIN} />;
};

const AppRouter = ({ routes }: LayoutsProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const resetState = useCallback(() => {
    // Prevent state clearing when in admin console, so the user does not log back into All Cases
    if (pathname.includes(Paths.ADMIN)) {
      return;
    }

    // We replace the current path in order to clear the state (including referer) for clean state on logout
    history.replace(Paths.CASES, undefined);
  }, [history, pathname]);

  useEventListener(Events.RESET_STATE, resetState);

  return (
    <Switch>
      {routes.map(
        (
          {
            routes,
            layout: LayoutComponent,
            isProtected,
            accessType,
            exact = true
          },
          i
        ) => {
          const paths = routes.map((route) => route.path) as string[];

          const layout = (
            <LayoutComponent>
              {routes.map((route) => (
                <Route key={route.path as string} {...route} />
              ))}
            </LayoutComponent>
          );

          return isProtected ? (
            <ProtectedRoute
              key={i}
              exact={exact}
              path={paths}
              children={layout}
              accessType={accessType}
            />
          ) : (
            <Route key={i} exact={exact} path={paths} children={layout} />
          );
        }
      )}
      <Route component={Default} />
    </Switch>
  );
};

export { AppRouter };
