import { IconCheckMark, IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useEffect } from 'react';

import { StyledChangeSaved } from './styles';

const DISPLAY_DURATION = 4000;

type SettingChangeSavedProps = {
  isChangeSaved: boolean;
  onHide: () => void;
  testId?: string;
  className?: string;
};

const SettingChangeSaved = ({
  isChangeSaved,
  onHide,
  testId,
  className
}: SettingChangeSavedProps): JSX.Element => {
  useEffect(() => {
    if (isChangeSaved) {
      setTimeout(onHide, DISPLAY_DURATION);
    }
    // Removed the `onHide` dependency because it keeps setting timeout whenever it is changed (on each render of the parent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeSaved]);

  return (
    <div className={className}>
      {isChangeSaved && (
        <StyledChangeSaved data-testid={testId}>
          <IconCheckMark size={IconSizes.SMALL} />
          <span>{localize('changeSaved')}</span>
        </StyledChangeSaved>
      )}
    </div>
  );
};

export { SettingChangeSaved };
