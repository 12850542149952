import {
  Alert,
  Button,
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getSize,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

import { SettingChangeSaved } from '../SettingChangeSaved';
import { settingsContainerSize } from '../styles';

export const StyledNotificationSettings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${getSpacing(4)};
  ${settingsContainerSize};
`;

export const StyledRestoreButton = styled(Button)`
  align-self: flex-end;
`;

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${getSize(2)};
`;

export const StyledAlert = styled(Alert)`
  .MuiTypography-root {
    ${getFontSize('xs')};
  }
`;

export const StyledSettings = styled.div`
  width: 100%;
  background-color: ${getBaseColor('ship-gray')};
  padding: 0 ${getSpacing(4)};
  border-radius: ${getBorderRadius('SMALL')};
  margin-bottom: ${getSpacing(4)};
`;

export const StyledSettingChangeSaved = styled(SettingChangeSaved)`
  height: ${getSize(4)};
  align-self: flex-end;
  display: flex;
  align-items: center;
`;
