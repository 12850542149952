import {
  ChatAttachmentNoteEntryType,
  ChatMessage,
  ChatParticipant,
  ChatPatientAttachment,
  ChatSeriesAttachment,
  ChatSharedItemMetadata
} from '@viz/api';
import { SendNewMessageOptions } from '@viz/api/src/chat/hooks';
import { getSeriesThumbnail } from '@viz/api/src/patients/api';
import {
  ChatMessageDetails,
  MessageSender,
  MessageStatus,
  ReferralMessageProps,
  ReferralNotes
} from '@viz/design-system';
import { generateUuid } from '@viz/utils';

export type PendingMessage = ChatMessageDetails & {
  files: File[];
};

export const mapServerSenderToChatMessageSender = (
  message: ChatParticipant
): MessageSender => {
  const { name, userId, fullRole, onCall, color, displayName } = message;

  return {
    name,
    color,
    displayName,
    id: userId,
    description: fullRole ?? undefined,
    online: onCall
  };
};

export const mapServerMessageToChatMessage = async (
  message: ChatMessage
): Promise<ChatMessageDetails> => {
  const { id, text, delivered, media, sender, created, sharedItem } = message;

  return {
    id,
    media,
    text,
    delivered,
    senderId: sender!.userId,
    createdTime: created,
    sharedItem: await mapServerSharedItem(sharedItem)
  };
};

export const mapServerSharedItem = async (
  sharedItem: ChatMessage['sharedItem']
): Promise<ChatMessageDetails['sharedItem'] | undefined> => {
  if (sharedItem) {
    const { id, content, description, metadata } = sharedItem;
    const { acceptedTs, notes: serverNotes, acceptAction } = metadata || {};

    const notes = mapServerSharedItemNotes(serverNotes);

    const studyDetails = instanceOfSeriesAttachment(content)
      ? await mapServerStudyAttachment(content)
      : undefined;
    const patientDetails = !instanceOfSeriesAttachment(content)
      ? content
      : undefined;

    return {
      id,
      description,
      notes,
      patientDetails,
      studyDetails,
      acceptedTs,
      acceptAction
    };
  }
};

const mapServerStudyAttachment = async ({
  patientId,
  seriesUID: seriesId,
  studyUID: studyId,
  institutionID: institutionId,
  shortDescription,
  studyDate
}: ChatSeriesAttachment): Promise<ReferralMessageProps['studyDetails']> => {
  let seriesThumbnailSrc;
  try {
    seriesThumbnailSrc = await getSeriesThumbnail({
      studyId,
      seriesId,
      institutionId
    });
  } catch {
    seriesThumbnailSrc = '';
  }

  return {
    patientId,
    studyUid: studyId,
    seriesUid: seriesId,
    shortDescription,
    studyDate,
    seriesThumbnailSrc
  };
};

const mapServerSharedItemNotes = (
  serverNotes?: ChatSharedItemMetadata['notes']
): ReferralMessageProps['notes'] => {
  if (!serverNotes) return;

  let sectionIndex = -1;

  const notes: ReferralNotes[] = [];

  serverNotes.entries.forEach((entry) => {
    const isSubtitle = entry.type === ChatAttachmentNoteEntryType.Subtitle;
    const isNewSection =
      isSubtitle || entry.type === ChatAttachmentNoteEntryType.Section;

    if (isNewSection) {
      sectionIndex++;
      notes.push({ header: entry.title, isSubtitle, subTexts: [] });
    } else {
      const subTexts = `${entry.title} ${entry.value}`.split('\n');
      if (sectionIndex >= 0) {
        notes[sectionIndex].subTexts =
          notes[sectionIndex]?.subTexts?.concat(subTexts);
      }
    }
  });

  return notes;
};

const instanceOfSeriesAttachment = (
  object: ChatSeriesAttachment | ChatPatientAttachment
): object is ChatSeriesAttachment => {
  return 'studyUID' in object && 'seriesUID' in object;
};

export const getNewMessages = (
  existingMessages: ChatMessageDetails[],
  incomingMessages: ChatMessageDetails[]
): ChatMessageDetails[] => {
  let newMessages = incomingMessages;

  if (existingMessages.length > 0) {
    let lastReceivedMessageIndex = 0;
    const lastExistingMessageId =
      existingMessages[existingMessages.length - 1].id;

    for (let i = incomingMessages.length - 1; i >= 0; i--) {
      if (incomingMessages[i].id === lastExistingMessageId) {
        lastReceivedMessageIndex = i + 1;
        break;
      }
    }

    newMessages = incomingMessages.slice(lastReceivedMessageIndex);
  }

  return newMessages;
};

export const createPendingMessage = (
  userId: string,
  text: string,
  files: File[]
): PendingMessage => ({
  id: generateUuid(),
  delivered: MessageStatus.UNSENT,
  senderId: userId,
  createdTime: new Date().getTime(),
  text,
  media: files.map((file) => ({
    id: generateUuid(),
    url: URL.createObjectURL(file)
  })),
  files
});

export const getUpdatedPendingMessages = (
  pendingMessages: PendingMessage[],
  incomingMessages: ChatMessageDetails[]
): PendingMessage[] => {
  return pendingMessages?.filter(
    (pendingMessage) =>
      !incomingMessages.some((message) => message.id === pendingMessage.id)
  );
};

export const changePendingMessageStatus = (
  pendingMessages: PendingMessage[],
  messageId: string,
  status: MessageStatus
): PendingMessage[] => {
  const failedMessageIndex = pendingMessages.findIndex(
    (message) => message.id === messageId
  );

  if (failedMessageIndex !== -1) {
    const failedMessage = {
      ...pendingMessages[failedMessageIndex],
      delivered: status
    };

    return [
      ...pendingMessages.slice(0, failedMessageIndex),
      failedMessage,
      ...pendingMessages.slice(failedMessageIndex + 1)
    ];
  } else {
    return pendingMessages;
  }
};

export const createMessagePayload = (
  message: PendingMessage,
  conversationId: string
): SendNewMessageOptions => ({
  messageDetails: { ...message, conversationId },
  mediaDetails: message.media?.map(({ id }, index) => ({
    id,
    file: message.files[index]
  }))
});

export const setAcceptedReferral = (
  messages: ChatMessageDetails[],
  referralId: string,
  acceptedTs: ReferralMessageProps['acceptedTs'],
  acceptAction: ReferralMessageProps['acceptAction']
): ChatMessageDetails[] => {
  const referredMessageIndex = messages.findIndex(
    (message) => message.sharedItem?.id === referralId
  );

  if (referredMessageIndex < 0) {
    return messages;
  }

  const updatedMessages = [...messages];
  const updatedMessage = { ...updatedMessages[referredMessageIndex] };

  if (updatedMessage.sharedItem) {
    updatedMessage.sharedItem = {
      ...updatedMessage.sharedItem,
      acceptedTs,
      acceptAction
    };
  }

  updatedMessages[referredMessageIndex] = updatedMessage;

  return updatedMessages;
};
