import { InstitutionSetting } from '@viz/api';

export const filterInstitutionsByText = (
  institutions: InstitutionSetting[],
  str: string
): InstitutionSetting[] => {
  return institutions.reduce((filteredInstitutions, institution) => {
    if (institution.name.toLowerCase().includes(str.toLowerCase())) {
      filteredInstitutions.push({ ...institution });
    } else {
      const subInstitutions = filterInstitutionsByText(
        institution.subInstitutions,
        str
      );
      if (subInstitutions.length > 0) {
        filteredInstitutions.push({ ...institution, subInstitutions });
      }
    }
    return filteredInstitutions;
  }, [] as InstitutionSetting[]);
};

export const getSubInstitutions = (
  institution: InstitutionSetting,
  searchText: string
): InstitutionSetting[] => {
  if (
    searchText &&
    !institution.name.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return institution.subInstitutions;
  } else {
    if (institution.selectable) {
      return [
        { ...institution, subInstitutions: [] },
        ...institution.subInstitutions
      ];
    } else {
      return institution.subInstitutions;
    }
  }
};
