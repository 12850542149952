import { logger } from '@viz/logging';
import { useCallback, useEffect, useState } from 'react';
import { DefaultGenerics, StreamChat } from 'stream-chat';

import { browserStorage } from '../../managers';
import { useUserProfile } from '../useUserProfile';

export interface ChatClient {
  chatClient: StreamChat<DefaultGenerics> | null;
}

const useStreamChatClient = (): ChatClient => {
  const [client, setClient] = useState<StreamChat | null>(null);

  const {
    api_key: apiKey,
    token,
    patient_enabled
  } = browserStorage.userChatAccess.get();
  const { user } = useUserProfile();
  const userId = user?.user_uid;

  const connectUser = useCallback(async () => {
    const chatClient = StreamChat.getInstance(apiKey);

    if (chatClient.userID === userId && chatClient.wsConnection?.isHealthy) {
      setClient(chatClient);
      return;
    }

    try {
      await chatClient.connectUser(
        {
          id: userId!
        },
        token
      );
      logger.info(`sucsesfuul connecting to Stream`);
      setClient(chatClient);
    } catch (error) {
      logger.error(`Error connecting to Stream: ${error}`);
      setClient(null);
    }
  }, [apiKey, userId, token]);

  const disconnectUser = useCallback(async () => {
    if (client) {
      try {
        await client.disconnectUser();
        setClient(null);
        logger.info('Successfully disconnected user from Stream');
      } catch (error) {
        logger.error(`Error disconnecting user from Stream: ${error}`, {
          error
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!userId || !apiKey || !token || !patient_enabled) {
      logger.info('Missing required data to connect to Stream');
      return;
    }

    // Connect user
    connectUser();

    return () => {
      disconnectUser();
    };
  }, [userId, apiKey, token, patient_enabled, connectUser, disconnectUser]); //excluded client

  return {
    chatClient: client
  };
};

export { useStreamChatClient };
