import {
  GetProfileData,
  LoginError,
  LoginErrorReason,
  SendMfaCodeError,
  SendMfaCodeErrorReason,
  ShiftResponseData,
  UserAuthInfo,
  VerifyMfaCodeError,
  VerifyMfaCodeErrorReason
} from '@viz/api';
import { localize } from '@viz/i18n';

import { AnalyticsEventMethod, isVizUser } from '../useAnalytics';
import { CoolDownError } from './CoolDownError';

type AuthenticationError = LoginError | SendMfaCodeError | VerifyMfaCodeError;
type AuthenticationErrorMessage = AuthenticationError['message'] | JSX.Element;

export interface NormalizedAuthenticationError {
  reason: AuthenticationError['reason'];
  message: AuthenticationErrorMessage;
}

export const normalizeAuthenticationError = (
  error: AuthenticationError
): NormalizedAuthenticationError => {
  const reason = error.reason ?? error.msg;

  let message: AuthenticationErrorMessage = error.message;
  switch (reason) {
    case LoginErrorReason.MFA_REQUIRED:
      break;
    case LoginErrorReason.INVALID_SSO_CODE:
      message = localize('invalidSsoCode');
      break;
    case LoginErrorReason.INVALID_CREDENTIALS:
      message = localize('incorrectPasswordOrEmail');
      break;
    case LoginErrorReason.NO_PERMISSIONS:
      message = localize('noPermissions');
      break;
    case LoginErrorReason.FAILED_ATTEMPTS:
      message = (
        <CoolDownError
          accountLockedKey={'accountLocked'}
          accountLockedTryAgainKey={'accountLockedTryAgain'}
          minutes={error.cooldown_in_minutes}
        />
      );
      break;
    case VerifyMfaCodeErrorReason.WRONG_CODE:
      message = localize('wrongCode');
      break;
    case SendMfaCodeErrorReason.SENT_MAX_ALLOWED:
    case VerifyMfaCodeErrorReason.AUTH_MAX_ALLOWED:
    case VerifyMfaCodeErrorReason.COOL_DOWN:
      message = (
        <CoolDownError
          accountLockedKey={'accountLockedMfa'}
          accountLockedTryAgainKey={'accountLockedTryAgain'}
          minutes={error.cooldown_in_minutes}
        />
      );
      break;
  }

  return {
    reason,
    message
  };
};

export const castLoginSuccessfulEventPayload = (
  profile: GetProfileData,
  shiftData: ShiftResponseData
) => {
  return {
    method: AnalyticsEventMethod.LOGIN,
    email: profile.email,
    user_uid: profile.user_uid,
    role_id: profile.role_id,
    user_full_name: profile.name,
    is_internal_user: isVizUser(profile.email),
    screen_resolution: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    main_account_id: profile.main_customer_account,
    is_on_call: shiftData.on_shift
  };
};

export const findUserSsoProvider = (userAuth: UserAuthInfo.Response) => {
  const userCustomerAccounts = Object.values(userAuth);
  const userActiveSsoAccount = userCustomerAccounts.find((a) => a.sso.active);

  return userActiveSsoAccount?.sso;
};
