import { UserBasePayload, Institution } from '@viz/api';
import { MenuItem } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { Controller, UseFormReturn } from 'react-hook-form';

import { FieldSectionInput } from '../../styles';
import { regions } from './constants';
import {
  RegionInputContainer,
  RegionInputLabel,
  RegionInputSubtitle
} from './styles';

interface RegionInputProps {
  form: UseFormReturn<UserBasePayload>;
  institutionList?: Institution[];
  className?: string;
}

const RegionInput = ({ form: { control }, className }: RegionInputProps) => (
  <RegionInputContainer className={className}>
    <RegionInputLabel htmlFor="region">{`${localize(
      'region'
    )}*`}</RegionInputLabel>
    <RegionInputSubtitle>{localize('regionDescription')}</RegionInputSubtitle>
    <Controller
      name="region"
      control={control}
      rules={{
        required: {
          value: true,
          message: localize('regionRequired')
        }
      }}
      render={({ field, fieldState }) => (
        <FieldSectionInput
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          variant="outlined"
          size="small"
          helperText={fieldState.error?.message}
          select
        >
          {regions.map((region) => (
            <MenuItem value={region} key={region}>
              {region}
            </MenuItem>
          ))}
        </FieldSectionInput>
      )}
    />
  </RegionInputContainer>
);

export default RegionInput;
