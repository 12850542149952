import { IconSizes, Loading } from '@viz/design-system';

import { StyledSettingsLoadingContainer } from './styles';

const SettingsLoading = (): JSX.Element => (
  <StyledSettingsLoadingContainer>
    <Loading size={IconSizes.SMALL} />
  </StyledSettingsLoadingContainer>
);

export { SettingsLoading };
