import { useContext } from 'react';

import { AuditLogViewItem } from '../../types';
import { EventsContext } from '../events-context';
import AddedRemovedValue from './AddedRemovedValue/AddedRemovedValue';
import ExpendableSection from './ExtendableSection/ExtendableSection';
import { FieldContainer, FieldsContainer } from './styles';

interface AuditContentProps {
  auditLog: AuditLogViewItem;
}

const AuditContent = ({ auditLog }: AuditContentProps) => {
  const { onFieldOpened } = useContext(EventsContext);

  return (
    <FieldsContainer>
      {auditLog.fields.map((field) => (
        <ExpendableSection
          title={field.title}
          key={field.id}
          onOpen={() =>
            onFieldOpened(auditLog.log.id, field.id, auditLog.log.createdAt)
          }
        >
          <FieldContainer>
            <AddedRemovedValue values={field.values} />
          </FieldContainer>
        </ExpendableSection>
      ))}
    </FieldsContainer>
  );
};

export default AuditContent;
