import { FormControlLabel } from '@material-ui/core';
import { getSize, getFontWeight, getBaseColor } from '@viz/design-system';
import styled from 'styled-components';

export const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldSectionTitle = styled.h4`
  margin-bottom: ${getSize(7)};
  font-weight: ${getFontWeight('font-weight-bold-600')};
  color: ${getBaseColor('french-gray')};
`;

export const FieldSectionInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${getSize(75)}, 1fr));
  row-gap: ${getSize(5)};
`;

export const StyledFormControlLabel = styled(FormControlLabel)``;
