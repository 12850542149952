import {
  getBaseColor,
  getFontSize,
  getSize,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(6)};
  padding: ${getSpacing(2)} 0;
  ${getFontSize('base')};
  color: ${getBaseColor('french-gray')};
`;

export const StyledActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${getSize(4)};
  padding-bottom: ${getSize(5)};
  position: relative;
`;
