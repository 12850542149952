import {
  useCreateUser,
  UserBasePayload,
  useLoggedUserProfile,
  useUserGroups,
  useCreateInvitation
} from '@viz/api';
import { IconSizes, Loading, SidePanel } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

import { useAnalyticsEvent } from '../../../../../../hooks';
import { toastStatusState } from '../../../../../../store';
import TitleBar from '../../components/TitleBar';
import {
  getErrorMessage,
  preformatPayload,
  sendAdminAddUserStartEvent,
  sendAddUserSubmitEvent,
  sendAddUserStoppedEvent,
  removeRestrictedFields,
  sendSendInvitationEvent
} from '../../utils';
import {
  LoadingContainer,
  StyledCreationOptions,
  StyledForm,
  StyledPersonalDetails
} from '../common/panelStyles';
import { Assignments, Capabilities } from '../sections';

export const formId = 'create-user-form';

const defaultValues: Partial<UserBasePayload> = {
  institutions: [],
  commercialWorkspace: false,
  ableToSeeReps: false,
  recruitWorkspace: false,
  ableToSeePhi: false,
  internalVizUserEnabled: false,
  ctpAifRecalculateOnAppEnabled: false,
  canViewVizAnalytics: false,
  sendWelcomeEmail: true
};

interface CreateUserProps {
  isOpen: boolean;
  onClose: () => void;
  onTransitionEnd: (userId: string) => void;
}

const CreateUser = ({ isOpen, onClose, onTransitionEnd }: CreateUserProps) => {
  const { data: loggedUserData, isLoading } = useLoggedUserProfile();
  const { mutate: createUser, isLoading: isSubmitting } = useCreateUser();
  const { data: userGroups } = useUserGroups();
  const [existingUserId, setExistingUserId] = useState<string | undefined>();
  const { sendEvent } = useAnalyticsEvent();
  const setToastStatusState = useSetRecoilState(toastStatusState);
  const { mutate: createInvitation } = useCreateInvitation();

  const form = useForm<UserBasePayload>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues
  });

  useEffect(() => {
    form.reset();
    if (isOpen) {
      sendAdminAddUserStartEvent(sendEvent);
    }
    // Ignoring exhaustive deps because we don't want a form change to trigger the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const { handleSubmit, formState } = form;

  const onSubmitDirty = useCallback(
    (data: UserBasePayload) => {
      const preformattedData = preformatPayload(data, data, userGroups);

      const allowedData = removeRestrictedFields({
        payload: preformattedData,
        restrictedFields: loggedUserData?.restrictedUserFields
      });

      createUser(allowedData, {
        onSuccess: (newUserId) => {
          sendAddUserSubmitEvent(sendEvent, data, newUserId);
          onClose();

          setToastStatusState({
            type: 'info',
            text: localize(
              'addUserSuccessful',
              `${data.firstName} ${data.lastName}`
            )
          });
        },
        onError: (error) => {
          setToastStatusState({
            type: 'error',
            text: getErrorMessage(
              error,
              localize('addUserFailed', `${data.firstName} ${data.lastName}`)
            )
          });
        }
      });
    },
    [
      createUser,
      userGroups,
      onClose,
      sendEvent,
      setToastStatusState,
      loggedUserData
    ]
  );

  return (
    <SidePanel
      open={isOpen}
      onClose={() => {
        sendAddUserStoppedEvent(sendEvent, formState.touchedFields);
        onClose();
      }}
      titleBar={
        <TitleBar
          title={localize('createUser')}
          buttonText={isSubmitting ? localize('creating') : localize('create')}
          buttonDisabled={isSubmitting}
          formId={formId}
        />
      }
      SlideProps={{
        appear: true,
        onExited: () => {
          if (existingUserId) {
            onTransitionEnd(existingUserId);
            setExistingUserId(undefined);
          }
        }
      }}
    >
      {isLoading ? (
        <LoadingContainer>
          <Loading size={IconSizes.XX_LARGE} />
        </LoadingContainer>
      ) : (
        <StyledForm onSubmit={handleSubmit(onSubmitDirty)} id={formId}>
          <StyledPersonalDetails
            form={form}
            isCreateUser
            onSwitchToEdit={(userId?: string) => {
              setExistingUserId(userId);
              onClose();
            }}
            onSendInvitation={(userEmail: string) => {
              createInvitation(
                {
                  user_email: userEmail
                },
                {
                  onSuccess: () => {
                    sendSendInvitationEvent(sendEvent, userEmail, 'success');
                    setToastStatusState({
                      type: 'info',
                      text: localize('invitationSentSuccessfully')
                    });

                    onClose();
                  },
                  onError: () => {
                    sendSendInvitationEvent(sendEvent, userEmail, 'failure');
                    setToastStatusState({
                      type: 'error',
                      text: localize('viewerTryAgain')
                    });
                  }
                }
              );
            }}
            restrictedFields={loggedUserData?.viewOnlyFields}
          />
          <Assignments
            form={form}
            restrictedFields={
              loggedUserData
                ? loggedUserData.restrictedUserFields
                : ['region', 'mainCustomerAccount']
            }
          />
          {loggedUserData && (
            <Capabilities
              form={form}
              restrictedFields={loggedUserData?.restrictedUserFields}
            />
          )}
          <StyledCreationOptions form={form} />
        </StyledForm>
      )}
    </SidePanel>
  );
};

export default CreateUser;
