import { selectorFamily } from 'recoil';

import { patientTabsState } from './atoms';
import { GET_PATIENT_TAB } from './constants';

export const getPatientTabState = selectorFamily({
  key: GET_PATIENT_TAB,
  get:
    (tabValue: string | undefined) =>
    ({ get }) => {
      return get(patientTabsState).find(({ value }) => tabValue === value);
    }
});
