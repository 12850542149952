import { ClientConfigTabStatus, Patient } from '@viz/api';
import { IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { formatAge } from '@viz/utils';
import { useRecoilValue } from 'recoil';

import { isViewerVisibleState } from '../../../store';
import { CopyMRN } from '../../CopyMRN';
import { SubHeader } from '../../SubHeader';
import { PatientStatus } from './PatientStatus';
import {
  StyledInstitutionName,
  StyledPatientInfoContainer,
  StyledPatientInfoHeader,
  StyledPatientName
} from './styles';

export interface BasePatientInfoProps {
  patient: Patient;
  testId?: string;
  variant?: 'default' | 'share';
}

interface PatientInfoWithPatientStatusProps extends BasePatientInfoProps {
  patientStatuses: ClientConfigTabStatus[];
  patientSelectedStatus?: string;
  onChangePatientStatus: (newStatus: ClientConfigTabStatus) => void;
  showPatientStatus: boolean;
}

export type PatientInfoProps =
  | BasePatientInfoProps
  | PatientInfoWithPatientStatusProps;

const PatientInfo = (props: PatientInfoProps): JSX.Element => {
  const { patient, variant } = props;
  const isViewerVisible = useRecoilValue(isViewerVisibleState);

  const formatPatientInfoOrUseDefault = (data?: string) => {
    return data ?? localize('notAvailableShortcut');
  };

  const isPatientInfoWithPatientStatusProps = (
    props: unknown
  ): props is PatientInfoWithPatientStatusProps => {
    return (
      (props as PatientInfoWithPatientStatusProps).showPatientStatus !==
      undefined
    );
  };

  return (
    <StyledPatientInfoContainer variant={variant}>
      <StyledPatientInfoHeader>
        <StyledPatientName data-testid="patient-name">
          {patient.name}
        </StyledPatientName>
        {isPatientInfoWithPatientStatusProps(props) &&
          props.showPatientStatus && (
            <PatientStatus
              selectedStatus={props.patientSelectedStatus}
              statuses={props.patientStatuses}
              onChange={props.onChangePatientStatus}
              testId="patient-status"
            />
          )}
      </StyledPatientInfoHeader>

      <CopyMRN
        patient={patient}
        iconSize={IconSizes.X_SMALL}
        testId={'mrn-copy-button-patient-info'}
        location={isViewerVisible ? 'viewer' : 'patient_folder'}
      >
        <SubHeader
          data={{
            age: formatAge(patient.age, patient.birthDate),
            sex: localize(patient.sex)[0],
            medicalRecordNumber: `MRN ${patient.medicalRecordNumber}`
          }}
        ></SubHeader>
      </CopyMRN>

      <StyledInstitutionName data-testid="patient-institutionName">
        {formatPatientInfoOrUseDefault(patient.institutionName)}
      </StyledInstitutionName>
    </StyledPatientInfoContainer>
  );
};

export { PatientInfo };
