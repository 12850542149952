import { Theme } from '@viz/design-system/src';
import { useToggle } from '@viz/hooks';
import { useMemo } from 'react';

const useAppTheme = () => {
  const [isDarkMode, toggleDarkMode] = useToggle(true);

  const theme = useMemo(() => {
    return isDarkMode
      ? Theme.createThemeByType(Theme.ThemeType.DARK)
      : Theme.createThemeByType(Theme.ThemeType.LIGHT);
  }, [isDarkMode]);

  return { theme, isDarkMode, toggleDarkMode };
};

export { useAppTheme };
