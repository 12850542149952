import type { Series, Study, Ecg } from '@viz/api';

import type { EcgStudyData, StudyData } from './types';

const DEFAULT_INSTITUTION_ID = -1;

export const getStudyData = (
  patientStudies: Array<Study> | undefined,
  studyUid: Study['uid'],
  seriesUid: Series['uid']
): StudyData => {
  if (!patientStudies || !studyUid) return {};

  const study = patientStudies.find((study) => study.uid === studyUid);
  const series = study?.series.find((series) => series.uid === seriesUid);

  return { study, series };
};

export const getEcgStudyData = (
  patientStudies: Array<Ecg> | undefined,
  ecgId: Ecg['ecgId']
): EcgStudyData => {
  if (!patientStudies || !ecgId) return {};
  const ecgStudy = patientStudies.find((ecgStudy) => ecgStudy.ecgId === ecgId);

  return { ecgStudy };
};

export const getInstitutionId = (study?: Study) =>
  study?.institutionId ?? DEFAULT_INSTITUTION_ID;

export const isInstitutionIdValid = (institutionId: Study['institutionId']) =>
  Boolean(institutionId && institutionId !== DEFAULT_INSTITUTION_ID);
