import { UserBasePayload, UserRestrictedFields } from '@viz/api';
import { Switch } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { Controller, UseFormReturn } from 'react-hook-form';

import { RestrictedFieldHelper } from '../../../components';
import { toggleFields } from './constants';
import {
  FieldSection,
  FieldSectionTitle,
  FieldSectionInputs,
  StyledFormControlLabel
} from './styles';

interface CapabilitiesProps {
  form: UseFormReturn<UserBasePayload>;
  restrictedFields?: Array<UserRestrictedFields>;
  className?: string;
}

const Capabilities = ({
  form: { control },
  restrictedFields,
  className
}: CapabilitiesProps) => {
  const isCapabilitiesRestricted = toggleFields.every((field) =>
    restrictedFields?.includes(field)
  );

  if (isCapabilitiesRestricted) {
    return null;
  }

  return (
    <FieldSection className={className}>
      <FieldSectionTitle>{localize('capabilities')}</FieldSectionTitle>
      <FieldSectionInputs>
        {toggleFields.map((name) => (
          <RestrictedFieldHelper
            restrictedFields={restrictedFields}
            key={name}
            fieldName={name}
          >
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <StyledFormControlLabel
                  control={
                    <Switch
                      checked={Boolean(field.value)}
                      onChange={field.onChange}
                    />
                  }
                  label={localize(name)}
                />
              )}
            />
          </RestrictedFieldHelper>
        ))}
      </FieldSectionInputs>
    </FieldSection>
  );
};

export default Capabilities;
