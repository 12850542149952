import { UserBasePayload, UserGroup } from '@viz/api';

import { formatPhoneNumber } from './formatPhoneNumber';
import { getValidUserGroupsBasedOnCurrentInstitutions } from './getValidUserGroups';

export const preformatPayload = (
  filteredData: UserBasePayload,
  completeData: UserBasePayload,
  userGroups: UserGroup[] | undefined
): UserBasePayload => {
  const resultingData = { ...filteredData };

  if (filteredData.phone) {
    resultingData.phone = formatPhoneNumber(filteredData.phone);
  }

  if (filteredData.institutions) {
    const newUserGroups = getValidUserGroupsBasedOnCurrentInstitutions(
      filteredData.institutions,
      completeData.userGroups,
      userGroups
    );
    if (newUserGroups) {
      resultingData.userGroups = newUserGroups;
    }
  }

  return resultingData;
};
