import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getSpacing,
  getSize
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledTag = styled.div`
  background-color: ${getBaseColor('black')};
  color: ${getBaseColor('porcelain')};
  ${getFontSize('xs')};
  opacity: 0.85;
  padding: ${getSpacing(0.25)} ${getSpacing(0.5)};
  display: flex;
  align-items: center;
  line-height: ${getSize(4)};
  border-radius: ${getBorderRadius('X_SMALL')};
`;

export const StyledDicomAmount = styled.span`
  margin-left: ${getSpacing(0.5)};
`;
