import { localize } from '@viz/i18n';
import { UrlConfig } from '@viz/utils';

import { Search } from '../../../../components';
import { useRouter } from '../../../../hooks';
import { Paths } from '../../navigation';

const SearchUser = (): JSX.Element => {
  const router = useRouter();
  const queryParam = router.getUrlParam('query') ?? '';

  const searchUser = (searchText: string) => {
    const urlConfig: UrlConfig = searchText
      ? { path: Paths.USERS, queryParams: { query: searchText } }
      : { path: Paths.USERS };
    router.navigate(urlConfig, queryParam ? 'replace' : 'push');
  };

  return (
    <Search
      initialSearchText={queryParam}
      onSubmit={searchUser}
      testId="search-users-box"
      inputTestId="search-users-input"
      placeholder={localize('searchByNameEmailOrPhone')}
    />
  );
};
export default SearchUser;
