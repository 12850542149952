import { useEffect, useState } from 'react';

export const useWidgetPostSsoAuthCode = () => {
  const [code, setCode] = useState<string>();

  useEffect(() => {
    const handlePostSsoAuthCode = (code: string) => {
      setCode(code);
    };

    if (window.vizWidget) {
      window.vizWidget.onPostSsoCompleted(handlePostSsoAuthCode);
    }
  }, []);

  return { code };
};
