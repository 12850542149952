import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getFontWeight,
  getSpacing,
  Thumbnail
} from '@viz/design-system';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const StyledToastContainer = styled(ToastContainer)`
  & .Toastify__toast {
    border-radius: 0;
  }

  & .Toastify__toast-body {
    padding: 0;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  color: ${getBaseColor('black-cat')};
  font-weight: ${getFontWeight('font-weight-bold-700')};
  margin-bottom: ${getSpacing(1)};
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledImage = styled(Thumbnail)`
  margin-right: ${getSpacing(2)};
  border-radius: ${getBorderRadius('X_SMALL')};
`;

export const StyledBody = styled.div`
  color: ${getBaseColor('black')};
  ${getFontSize('sm')};
`;

export const StyledTextContent = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledTrademark = styled.div`
  color: ${getBaseColor('argent')};
  ${getFontSize('xs')};
  margin-top: ${getSpacing(6)};
`;
