import { InstitutionSetting } from '@viz/api';
import { localize } from '@viz/i18n';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { UseInstitutionSettingsOptions } from '../../../hooks';
import { Toast, toastStatusState } from '../../../store';
import { SettingsLoading } from '../SettingsLoading';
import { InstitutionFilter } from './InstitutionFilter';
import { InstitutionSettingsSaveChanges } from './InstitutionSettingsSaveChanges';
import { InstitutionSettingsTopBar } from './InstitutionSettingsTopBar';
import {
  StyledInstitutionCount,
  StyledInstitutionSettings,
  StyledInstitutionSettingsContainer
} from './styles';
import { filterInstitutionsByText } from './utils';

type ExpandedInstitutionsMap = Map<InstitutionSetting['id'], boolean>;

export const ExpandedInstitutionsContext = createContext({
  expandedInstitutions: new Map<InstitutionSetting['id'], boolean>(),
  setExpandedInstitutions: (expandedInstitutions: ExpandedInstitutionsMap) => {}
});

type InstitutionSettingsProps = {
  institutionSettingsData: UseInstitutionSettingsOptions;
};

const InstitutionSettings = ({
  institutionSettingsData: {
    institutionsData: { institutions, selectedCount, selectableCount },
    isSettingsChanged,
    isSavingChanges,
    isError,
    onInstitutionToggle,
    onSave,
    onDiscard
  }
}: InstitutionSettingsProps): JSX.Element => {
  const setToastStatus = useSetRecoilState(toastStatusState);
  const [expandedInstitutions, setExpandedInstitutions] =
    useState<ExpandedInstitutionsMap>(new Map());
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (expandedInstitutions.size === 0) {
      setExpandedInstitutions(
        new Map(
          institutions.map((institution) => {
            return [institution.id, true];
          })
        )
      );
    }
  }, [expandedInstitutions.size, institutions]);

  useEffect(() => {
    const errorToast: Toast = {
      text: localize('selectOneInstitution'),
      type: 'error',
      preventClose: true
    };

    setToastStatus(isError ? errorToast : null);
  }, [isError, setToastStatus]);

  const displayedInstitutions = useMemo(() => {
    if (!searchText) {
      return institutions;
    } else {
      const text = searchText.trim().toLowerCase();

      return filterInstitutionsByText(institutions, text);
    }
  }, [institutions, searchText]);

  if (institutions.length === 0) return <SettingsLoading />;

  return (
    <ExpandedInstitutionsContext.Provider
      value={{ expandedInstitutions, setExpandedInstitutions }}
    >
      <StyledInstitutionSettingsContainer>
        <StyledInstitutionSettings data-testid="institution-settings">
          <InstitutionSettingsTopBar
            isAllSelected={selectableCount === selectedCount}
            searchText={searchText}
            onSearch={setSearchText}
            onSelectAll={onInstitutionToggle}
          />
          {displayedInstitutions.length > 0 && (
            <StyledInstitutionCount>
              <div data-testid="institution-settings-count">
                <strong>{selectedCount}</strong> out of {selectableCount}{' '}
                checked
              </div>
            </StyledInstitutionCount>
          )}
          <InstitutionFilter
            institutions={displayedInstitutions}
            searchText={searchText}
            onInstitutionToggle={onInstitutionToggle}
          />
        </StyledInstitutionSettings>
        <InstitutionSettingsSaveChanges
          isSettingsChanged={isSettingsChanged}
          isError={isError}
          isSavingChanges={isSavingChanges}
          onSave={onSave}
          onDiscard={onDiscard}
        />
      </StyledInstitutionSettingsContainer>
    </ExpandedInstitutionsContext.Provider>
  );
};

export default InstitutionSettings;
