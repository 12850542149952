import {
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSize
} from '@viz/design-system';
import styled from 'styled-components';

export const TopicList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${getSize(4)};
`;

export const DetailsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${getSize(75)}, 1fr));
  row-gap: ${getSize(5)};
`;

export const DetailItem = styled.div``;

export const LogsSection = styled.div`
  margin-top: ${getSize(11)};
`;

export const LogGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: ${getSize(6.5)};
`;

export const Title = styled.h4`
  color: ${getBaseColor('french-gray')};
  ${getFontSize('base')}
  font-weight: ${getFontWeight('font-weight-bold-500')};
  margin: 0;
`;

export const DetailTitle = styled(Title)`
  margin-bottom: ${getSize(3)};
`;

export const LogsTitle = styled(Title)`
  margin-bottom: ${getSize(6)};
`;

export const Value = styled.div`
  color: ${getBaseColor('porcelain')};
  ${getFontSize('base')}
  font-weight: ${getFontWeight('font-weight-bold-500')};
`;
