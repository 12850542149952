import { Pathname, Paths } from '../../navigation';
import { AnalyticsEventName } from './constants';

export const getEventNameByPath = (pathname: string): string => {
  const activePathKey =
    Object.keys(Paths)[Object.values(Paths).indexOf(pathname as Pathname)];

  return AnalyticsEventName[activePathKey as keyof typeof AnalyticsEventName];
};

export const getNotificationType = (
  notificationType: string
): string | null => {
  const notificationTypes = [
    'study',
    'chat',
    'system',
    'group',
    'patientShare',
    'chatSystemMessage',
    'ecg'
  ];
  const notificationTypeIndex = Number(notificationType);
  return notificationTypeIndex
    ? notificationTypes[notificationTypeIndex]
    : null;
};

export const isVizUser = (email?: string): boolean => {
  return Boolean(email?.endsWith('@viz.ai'));
};
