import { Dialog, getBaseColor, getFontSize, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: ${getSize(150)};
  }

  & .MuiInputBase-root {
    padding: ${getSize(3)};
    color: ${getBaseColor('porcelain')};
  }
`;

export const StyledLabel = styled.label`
  ${getFontSize('base')}
`;

export const StyledCounter = styled.span<{ hasReachedMax: boolean }>`
  display: block;
  text-align: right;
  ${getFontSize('xs')};
  color: ${({ hasReachedMax }) =>
    getBaseColor(hasReachedMax ? 'error' : 'waterloo')};
  transition: ${({ theme }) =>
    `transform ${theme.transitions.duration.shortest}ms`};
`;
