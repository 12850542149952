import { Typography } from '@viz/design-system';
import { capitalize } from '@viz/utils';

import vizLoadingGif from '../../assets/images/viz_loading.gif';
import { VizLoadingContainer } from './styles';

const VizLoading = (): JSX.Element => {
  return (
    <VizLoadingContainer>
      <img alt="img" src={vizLoadingGif} />
      <Typography>{capitalize('loading')}...</Typography>
    </VizLoadingContainer>
  );
};

export default VizLoading;
