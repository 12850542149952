import { useExternalPatientData } from '@viz/api';
import { IconError } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { logger } from '@viz/logging';
import { capitalize } from '@viz/utils';
import { useState } from 'react';

import { VizLoading } from '../../components';
import {
  AnalyticsEventName,
  useAnalyticsEvent,
  useLogoutUser,
  usePatientTabs,
  useRouter
} from '../../hooks';
import { Paths } from '../../navigation';
import {
  StyledExternalPatientContainer,
  StyledPatientNotFound
} from './styles';

type Action = 'chat' | 'patient';

const ExternalPatient = (): JSX.Element => {
  const router = useRouter();
  const { selectTab } = usePatientTabs();
  const { sendEvent } = useAnalyticsEvent();
  const [error, setError] = useState('');
  const { logout } = useLogoutUser();

  const accession = router.getUrlParam('accession', false) as string;
  const mrn = router.getUrlParam('mrn', false) as string;
  const account = router.getUrlParam('account', false) as string;
  const action = (router.getUrlParam('action', false) ?? 'patient') as Action;
  const dl = router.getUrlParam('dl', false) as string;
  const shouldFetchPatient = Boolean(dl || (accession && account));

  const { isLoading: externalPatientDataLoading } = useExternalPatientData(
    { dl, accession, mrn, account },
    {
      enabled: shouldFetchPatient,
      retry: false,
      onSuccess: (data) => {
        if (data.patient_ids.length === 0) {
          setError(localize('noPatientFound'));
        } else if (data.patient_ids.length > 1) {
          setError(localize('invalidSearch'));
        } else {
          const patientId = data.patient_ids[0];

          sendEvent(AnalyticsEventName.PATIENT_PACS_LINK_CLICKED, {
            patient_id: patientId,
            study_accession_number: accession,
            di_patient_mrn: mrn ?? 'N/A',
            action_type: action,
            account_uid: account
          });

          if (action === 'patient') {
            const tabUrlData = { id: patientId };
            selectTab({ tabUrlData });
          } else if (action === 'chat') {
            router.navigate({ path: `/chat/${patientId}` });
          } else {
            setError('Invalid action');
          }
        }
      },
      onError: (err) => {
        logger.error(`Error trying to get external patient`, { err });
        setError(localize('invalidSearch'));
      }
    }
  );

  const ACTION_TO_BUTTON_DETAILS: Record<
    Action,
    { text: string; onClick: () => void }
  > = {
    chat: {
      text: localize('logout'),
      onClick: () => {
        logout();
      }
    },
    patient: {
      text: capitalize(localize('returnToHomePage')),
      onClick: () => {
        router.navigate({ path: Paths.CASES });
      }
    }
  };

  return (
    <StyledExternalPatientContainer>
      {externalPatientDataLoading && !error && <VizLoading />}
      {(!shouldFetchPatient || error) && (
        <StyledPatientNotFound
          title="Error"
          subTitle={error}
          icon={<IconError />}
          buttonDetails={ACTION_TO_BUTTON_DETAILS[action]}
        />
      )}
    </StyledExternalPatientContainer>
  );
};

export { ExternalPatient };
