import { Patient } from '@viz/api';
import { createContext, useContext } from 'react';

interface PatientContextData {
  patient: Patient;
}

const PatientContext = createContext<PatientContextData | null>(null);

export function usePatient(): PatientContextData {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error(
      'usePatient must be used within a PatientProvider. Make sure that the component is wrapped inside a <PatientProvider> context.'
    );
  }
  return context;
}

export const PatientContextProvider: React.FC<{ patient: Patient }> = (
  props
) => {
  const { patient } = props;

  return (
    <PatientContext.Provider value={{ patient }}>
      {props.children}
    </PatientContext.Provider>
  );
};
