import {
  getSize,
  getSpacing,
  getFontSize,
  getFontWeight,
  getBaseColor
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledEhrInfo = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${getBaseColor('blue-tuna')};
  padding: ${getSize(3)};
  margin-bottom: ${getSpacing(10)};
`;

export const StyledEhrInfoHeaderLayout = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
export const StyledSectionField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${getFontSize('xs')}
  color: ${getBaseColor('porcelain')};
  padding: ${getSize(2)} 0;
  border-bottom: 1px solid ${getBaseColor('trout')};
  line-height: ${getSize(11)};

  h4 {
    font-size: 0.875rem; /* TODO -typography fix */
    ${getFontWeight('font-weight-bold-600')};
    margin: 0;
  }

  b {
    ${getFontWeight('font-weight-bold-600')};
    ${getFontSize('sm')}
  }
`;

export const StyledSectionFieldSubtitle = styled.span`
  color: ${getBaseColor('french-gray')};
`;

export const StyledSection = styled.div`
  svg {
    padding-right: ${getSpacing(2)};
  }
`;

export const StyledSectionLabel = styled.div`
  display: flex;
  align-items: center;
  h3 {
    font-size: 1rem; /* TODO -typography fix */
    ${getFontWeight('font-weight-bold-600')};
    margin: 0;
  }
`;

export const StyledEhrInfoHeader = styled.div`
  h2 {
    margin: 0;
    ${getFontSize('xl')};
  }
`;

export const StyledSectionHeaderSub = styled.div`
  display: flex;
  align-items: center;
  color: ${getBaseColor('french-gray')};
  ${getFontSize('xs')};

  & > svg {
    padding-right: ${getSpacing(2)};
  }
`;
