import { getSpacing, Grid } from '@viz/design-system';
import styled from 'styled-components';

export const StyledPatientStudies = styled(Grid)`
  && {
    display: flex;
    padding: ${getSpacing(5)} ${getSpacing(5)} ${getSpacing(0)};
    flex: 1;
    overflow: auto;
    flex-direction: column;
    flex-wrap: nowrap;

    [role='accordion-header-content'] {
      width: 30%;
    }
  }
`;

export const StyledPatientStudy = styled(Grid)`
  && {
    margin-bottom: ${getSpacing(5)};
  }
`;
