import { getSpacing, Dialog } from '@viz/design-system';
import styled from 'styled-components';

export const StyledBulkContainer = styled.div`
  margin: 0 auto ${getSpacing(1)} 0;
`;
export const StyledDialog = styled(Dialog)`
  font-size: 1rem;
  line-height: 1.625rem;

  .MuiDialogContent-dividers {
    padding: ${getSpacing(10)} ${getSpacing(6)};
  }
`;
