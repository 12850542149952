import { localize } from '@viz/i18n';
import { ReactElement } from 'react';

import { Badge, ValueContainer } from './styles';

export interface AddRemoveValue {
  title: string | ReactElement;
  type?: 'added' | 'removed';
  subtitle?: string;
}

interface AddRemoveFieldProps {
  values: AddRemoveValue[];
}

const AddedRemovedValue = ({ values }: AddRemoveFieldProps) => {
  return (
    <>
      {values.map(({ type, title, subtitle }, index) => (
        <ValueContainer type={type} key={index}>
          <div className="title">
            {type && (
              <Badge type={type}>
                {type === 'added' ? localize('added') : localize('removed')}
              </Badge>
            )}
            <span>{title}</span>
          </div>
          {subtitle && <div className="subtitle">{subtitle}</div>}
        </ValueContainer>
      ))}
    </>
  );
};

export default AddedRemovedValue;
