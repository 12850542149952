import { NotificationSettingAction } from '@viz/api';
import { Alert, Dialog } from '@viz/design-system';
import { localize, Trans } from '@viz/i18n';
import { useState } from 'react';

import { NotificationSettingSwitch } from '../NotificationSettingSwitch';
import { NotificationSettingThreshold } from '../NotificationSettingThreshold';
import { StyledActionContainer, StyledDialogContent } from './styles';

type NotificationActionDialogProps = {
  title: string;
  action: NotificationSettingAction;
  onSave: (value: number | boolean) => void;
  onClose: () => void;
};

const NotificationActionDialog = ({
  title,
  action,
  onClose,
  onSave
}: NotificationActionDialogProps) => {
  const actionInitialValue = action.currentValue;

  const [actionValue, setActionValue] = useState<number | boolean>(
    actionInitialValue!
  );

  const saveEnabled = actionValue !== actionInitialValue;

  const handleSave = () => {
    onSave(actionValue);
    onClose();
  };

  return (
    <Dialog
      open={Boolean(action)}
      title={title}
      onClose={onClose}
      withCloseIcon
      primaryButtonProps={{
        content: localize('save'),
        disabled: !saveEnabled,
        onClick: handleSave
      }}
      secondaryButtonProps={{
        content: localize('cancel'),
        onClick: onClose
      }}
    >
      <StyledDialogContent>
        <Alert
          type="warning"
          text={
            <Trans
              i18nKey="changeImpactMobileAndWeb"
              components={{ bold: <b /> }}
            />
          }
        />
        <StyledActionContainer>
          <label htmlFor="notification-setting-threshold">{action.label}</label>
          {action.actionType === 'bool' ? (
            <NotificationSettingSwitch
              state={actionValue as boolean}
              onChange={setActionValue}
            />
          ) : (
            <NotificationSettingThreshold
              action={{ ...action, currentValue: actionValue as number }}
              onChange={setActionValue}
            />
          )}
        </StyledActionContainer>
      </StyledDialogContent>
    </Dialog>
  );
};

export { NotificationActionDialog };
