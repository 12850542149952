import { Channel, MassageType } from '@viz/design-system';
import { Channel as ChannelProps, DefaultGenerics } from 'stream-chat';
import { ChannelPreviewUIComponentProps } from 'stream-chat-react';

import { useChatParticipants, useUserProfile } from '../../../../hooks';
import { StyledCustomChannel } from './styles';

export interface CustomChannelPreviewProps
  extends ChannelPreviewUIComponentProps<DefaultGenerics> {
  channel: ChannelProps<DefaultGenerics>;
  onSelectChannel: (channelId: string | null) => void;
}

const CustomChannelPreview = (props: CustomChannelPreviewProps) => {
  const { user } = useUserProfile();
  const userId = user?.user_uid as string;
  const { channel, onSelectChannel } = props;
  const patientId = channel.data?.patient_id as string;
  const { participants } = useChatParticipants({ patientId });
  console.log('participants', participants);

  const {
    state: { mutedUsers = [], messages = [], unreadCount = 0 }
  } = channel;

  const last_message = messages[0] ?? null;

  const date = new Date(last_message?.created_at);
  const created_at = date.getTime();

  const attachments = last_message?.attachments ? last_message.attachments : [];
  const lastMessageFrom =
    last_message &&
    participants.find(
      (participant) => participant.uid === last_message?.user?.id
    );
  const lastMessage = last_message && {
    ...last_message,
    type: attachments[0]?.type ?? MassageType.TEXT,
    created_at: created_at,
    from: lastMessageFrom
  };

  const title = channel.data?.id as string;
  const isMember = userId
    ? Object.keys(channel?.state?.members).includes(userId)
    : false;

  const isMuted =
    !isMember || mutedUsers?.some((mutedUser) => mutedUser.id === userId);
  const changeMuteStatus = () => {
    if (!isMember) return;
    //console.log('changeMuteStatus', channel.id);
  };
  return (
    <StyledCustomChannel onClick={() => onSelectChannel(channel.id ?? null)}>
      <Channel
        lastMessage={lastMessage}
        name={title}
        onChangeMutedStatus={changeMuteStatus}
        unreadMessages={unreadCount}
        isMuted={isMuted}
        isMember={isMember}
      />
    </StyledCustomChannel>
  );
};

export default CustomChannelPreview;
