import { CryptoProvider, URLBuilder, UrlConfig } from '@viz/utils';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../index';

type HistoryLocationState = {
  referer: string;
};
type NavigationAction = 'push' | 'replace';
export type UseRouter = {
  pathname: string;
  referer: string;
  getUrlParam(key: string, shouldDecrypt?: boolean): string | null;
  navigate(urlConfig: UrlConfig, action?: NavigationAction): void;
  getRelativePath(): string;
};

function useRouter(): UseRouter {
  const history = useHistory<HistoryLocationState>();
  const { search, pathname, state } = useLocation<HistoryLocationState>();
  const { encryptionKey, isAuth } = useAuth();
  const crypto = isAuth ? new CryptoProvider(encryptionKey) : undefined;

  const getUrlParam = (key: string, shouldDecrypt = true): string | null => {
    const value = new URLSearchParams(search).get(key);
    return shouldDecrypt && value && crypto ? crypto.decrypt(value) : value;
  };

  const getRelativePath = (): string => {
    return [pathname, search].join('');
  };

  const navigate = (
    urlConfig: UrlConfig,
    action: NavigationAction = 'push'
  ) => {
    history[action](new URLBuilder(urlConfig, crypto).build());
  };

  return {
    pathname,
    referer: state?.referer,
    getUrlParam,
    navigate,
    getRelativePath
  };
}

export { useRouter };
