import { ClientConfigTab } from '@viz/api';
import { IconAllCases, IconSizes } from '@viz/design-system';

import { AlgoDetectionIcon } from '../../components/AlgoDetectionIcon';
import { Paths } from '../paths';

export type MenuTab = {
  items?: {
    name: string;
    link: string;
    useParamsOnIsActive: boolean;
  }[];
  name: string;
  link: string;
  Icon: JSX.Element;
  useParamsOnIsActive: boolean;
};

export const defaultTabs: MenuTab[] = [
  {
    name: 'allCases',
    link: Paths.CASES,
    Icon: <IconAllCases size={IconSizes.MEDIUM} />,
    useParamsOnIsActive: true
  }
];

export const createMainTab = (tab: ClientConfigTab) => ({
  name: tab.header as string,
  link: `${Paths.CASES}?tab=${tab.id}`,
  Icon: <AlgoDetectionIcon size={IconSizes.MEDIUM} iconUrl={tab?.icon_url} />,
  useParamsOnIsActive: true
});

export const createSubItems = (tab: ClientConfigTab) =>
  tab.statuses.map((status) => ({
    name: status.value,
    link: `${Paths.CASES}?tab=${tab.id}&status=${status.id}`,
    useParamsOnIsActive: true
  }));

export const createTabWithSubItems = (tab: ClientConfigTab): MenuTab => {
  const mainTab = createMainTab(tab);
  const items = createSubItems(tab);

  items.push({
    name: 'All Patients',
    link: `${Paths.CASES}?tab=${tab.id}`,
    useParamsOnIsActive: true
  });
  return { ...mainTab, items };
};
