import {
  IconCircledCheck,
  IconCircledCross,
  getSize
} from '@viz/design-system';
import styled from 'styled-components';

import { Value } from '../../styles';

export const StyledIconCircledCheck = styled(IconCircledCheck)`
  margin-right: ${getSize(2.5)};
`;

export const StyledIconCircledCross = styled(IconCircledCross)`
  margin-right: ${getSize(2.5)};
`;

export const IsAdmin = styled(Value)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
