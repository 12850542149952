import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useGetChatUsers, ChatUsers } from '@viz/api';
import { useMemo } from 'react';

import { usePatientChannels } from '../usePatientChannels';

interface useChatUsersProps {
  patientId?: string;
}

interface useChatParticipantsResponse {
  participants: ChatUsers.User[] | [];
  isLoading: boolean;
}

const useChatParticipants = ({
  patientId
}: useChatUsersProps): useChatParticipantsResponse => {
  const queryClient = useQueryClient();
  const { mutate: fetchChatUsers } = useGetChatUsers();
  const { channels } = usePatientChannels({ patientId: patientId ?? '' });

  // Get unique user IDs from channels
  const userIdsUniq = useMemo(() => {
    const uuids = channels?.map(
      (channel) => channel.state?.messages[0]?.user?.id
    );
    return [...new Set(uuids)];
  }, [channels]);

  // Get users that are not cached
  const notCachedUsers = useMemo(() => {
    return (
      userIdsUniq
        ?.filter((id) => {
          console.log(
            'queryClient?.getQueryData',
            queryClient?.getQueryData(['participants', id])
          );
          return !queryClient?.getQueryData(['participants', id]);
        })
        .filter(Boolean) ?? []
    );
  }, [userIdsUniq, queryClient]) as string[] | [];

  // Use React Query to fetch and cache the participants
  const { data: cachedParticipants, isLoading: isQueryLoading } = useQuery(
    ['participants', userIdsUniq],
    async () => {
      const usersRes = await new Promise<ChatUsers.User[]>(
        (resolve, reject) => {
          fetchChatUsers(
            { user_uids: notCachedUsers },
            {
              onSuccess: (res) => {
                console.log('fetchChatUsers success', res);
                resolve(res.users);
              },
              onError: (error) => {
                console.error('fetchChatUsers error', error);
                reject(error);
              }
            }
          );
        }
      );

      // Cache the fetched users
      usersRes.forEach((user) => {
        queryClient.setQueryData(['participants', user.uid], user);
      });

      return usersRes;
    },
    {
      enabled: notCachedUsers.length > 0, // Only fetch if there are users that are not cached
      initialData: [] // Initial data is an empty array
    }
  );

  const participants = useMemo(() => {
    // Combine the cached participants with the fetched ones
    const cachedUsers = userIdsUniq
      ?.map((id) => queryClient.getQueryData(['participants', id]))
      .filter(Boolean) as ChatUsers.User[];
    return [...cachedUsers, ...cachedParticipants];
  }, [cachedParticipants, queryClient, userIdsUniq]);

  return {
    participants,
    isLoading: isQueryLoading
  };
};

export { useChatParticipants };
