import { UserAccess } from '@viz/api';

import { Paths as AdminPaths } from '../pages/Admin/navigation/paths';
import { Paths } from './paths';

export enum AccessType {
  WEB = 'web',
  ADMIN = 'admin'
}

type UserAccessMapType = {
  [key in AccessType]: keyof UserAccess;
};

export const UserAccessMap: UserAccessMapType = {
  [AccessType.WEB]: 'web_access',
  [AccessType.ADMIN]: 'admin_console_access'
};

export const DefaultPaths = [
  {
    accessType: AccessType.WEB,
    defaultPath: Paths.CASES
  },
  {
    accessType: AccessType.ADMIN,
    defaultPath: AdminPaths.USERS
  }
];
