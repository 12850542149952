import {
  ResetPasswordPayload,
  ResetPasswordResponseData,
  useResetPassword
} from '@viz/api';
import { DialogProps } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { isValidEmailAddress } from '@viz/utils';
import { ReactNode, useState } from 'react';

import { IdentificationContent } from './IdentificationContent';
import { SentSuccessfullyContent } from './SentSuccessfullyContent';
import { StyledResetPasswordDialog } from './styles';
import { ResetPasswordStep } from './types';

type DialogPropsByStep = {
  content: ReactNode;
  primaryButtonProps: DialogProps['primaryButtonProps'];
  secondaryButtonProps: DialogProps['secondaryButtonProps'];
};

type ResetPasswordDialogProps = {
  open: boolean;
  username: string;
  onClose: () => void;
};

const ResetPasswordDialog = (props: ResetPasswordDialogProps): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<ResetPasswordStep>(
    ResetPasswordStep.Identification
  );
  const [username, setUsername] = useState(props.username);
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [resetPasswordResponseData, setResetPasswordResponseData] =
    useState<ResetPasswordResponseData>();

  const { mutate, isLoading } = useResetPassword({
    retry: false,
    onSuccess: (response) => {
      const nextStep =
        currentStep === ResetPasswordStep.Identification
          ? ResetPasswordStep.SentToEmail
          : ResetPasswordStep.SentToMobile;

      setResetPasswordResponseData(response);
      setCurrentStep(nextStep);
    }
  });

  const handleUsernameChange = (username: string): void => {
    setIsValidUsername(true);
    setUsername(username);
  };

  const sendResetLink = (): void => {
    if (!isValidEmailAddress(username)) {
      setIsValidUsername(false);
      return;
    }

    const mutationPayload: ResetPasswordPayload = { email: username };

    if (currentStep === ResetPasswordStep.SentToEmail) {
      mutationPayload.forget_password_send_sms = 1;
    } else {
      mutationPayload.forget_password_send_email = 1;
    }

    mutate(mutationPayload);
  };

  const getDialogPropsByStep = (): DialogPropsByStep => {
    switch (currentStep) {
      case ResetPasswordStep.Identification: {
        return {
          content: (
            <IdentificationContent
              username={username}
              onUsernameChange={handleUsernameChange}
              isLoading={isLoading}
              error={!isValidUsername}
            />
          ),
          primaryButtonProps: {
            content: localize('send'),
            onClick: sendResetLink,
            disabled: !Boolean(username.trim()) || !isValidUsername || isLoading
          },
          secondaryButtonProps: {
            content: localize('cancel'),
            onClick: props.onClose
          }
        };
      }

      case ResetPasswordStep.SentToEmail:
      case ResetPasswordStep.SentToMobile:
        return {
          content: (
            <SentSuccessfullyContent
              resetPasswordStep={currentStep}
              userEmail={resetPasswordResponseData!.email}
              userPhoneNumber={resetPasswordResponseData!.phone_number}
              resendLink={sendResetLink}
              isLoading={isLoading}
            />
          ),
          primaryButtonProps: {
            content: localize('done'),
            onClick: props.onClose,
            disabled: isLoading
          },
          secondaryButtonProps: { content: null }
        };
    }
  };

  const { content, primaryButtonProps, secondaryButtonProps } =
    getDialogPropsByStep();

  return (
    <StyledResetPasswordDialog
      open={props.open}
      onClose={props.onClose}
      title={localize('forgotPassword')}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
      disableBackdropClick
      withCloseIcon
    >
      {content}
    </StyledResetPasswordDialog>
  );
};

export { ResetPasswordDialog };
