import { Waveform } from '@viz/api/src/ecg';
import React, { useMemo } from 'react';

import GraphPaper from '../GraphPaper/GraphPaper';
import { LARGE_LINE_INTERVAL, LINE_TIME, LINE_VOLTAGE } from '../consts';
import { transformLeads } from '../utils';
import ECGLead from './ECGLead';

interface ECGViewerProps {
  waveform: Waveform;
  width?: number;
  zoom?: number;
  panX?: number;
  panY?: number;
}

const lineSpacing = 4; //pixels between each minor line
const largeSquareSize = lineSpacing * LARGE_LINE_INTERVAL;
const xPadding = largeSquareSize * 5 - lineSpacing;
const yPadding = largeSquareSize * 5 - lineSpacing;

const aspectRatio = 7 / 12;
const defaultWidth = 1200;
const defaultHeight = defaultWidth * aspectRatio;
const backgroundColor = 'white';
const testId = 'ecg-chart';

const ECGChart = ({
  waveform,
  width = defaultWidth,
  zoom = 1,
  panX = 0,
  panY = 0
}: ECGViewerProps) => {
  const height = width * aspectRatio;
  const scaleFactor = width / defaultWidth; // Calculates how much the width has been scaled from the default
  const effectiveZoom = scaleFactor * zoom; // Calculate composite  zoom based on the width and scale factor
  const viewBoxWidth = width / effectiveZoom; // Adjust viewBox dimensions to accommodate effectiveZoom
  const viewBoxHeight = height / effectiveZoom;

  // Center the viewBox by adjusting for panX and panY
  const viewBoxX = panX - (defaultWidth / 2 - (defaultWidth * zoom) / 2) / zoom;
  const viewBoxY =
    panY - (defaultHeight / 2 - (defaultHeight * zoom) / 2) / zoom;

  const lines = useMemo(() => {
    const timeIncrement = 1000 / waveform.sample_rate;
    const pixelsPerTimeIncrement = (lineSpacing / LINE_TIME) * timeIncrement;
    const pixelPerVoltageUnit = lineSpacing / LINE_VOLTAGE;
    const sampleSize = waveform.leads.I.voltages.length;
    const ySpacing = (defaultHeight - yPadding * 2) / 3;
    const xSpacing = pixelsPerTimeIncrement * sampleSize;

    const leads = transformLeads(waveform, sampleSize);

    return leads.map((row, rowIndex) => {
      const yOffset = yPadding + ySpacing * rowIndex;

      const rowElements = row.map((lead, leadIndex) => (
        <g
          transform={`translate(${
            xPadding + xSpacing * leadIndex
          }, ${yOffset})`}
        >
          <ECGLead
            key={leadIndex}
            lead={lead}
            includeSeparator={leadIndex > 0}
            scale={{ x: pixelsPerTimeIncrement, y: pixelPerVoltageUnit }}
          />
        </g>
      ));
      return <>{rowElements}</>;
    });
  }, [waveform]);

  return (
    <svg
      style={{ background: backgroundColor }}
      width={width}
      height={height}
      viewBox={`${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`}
      data-testid={testId}
      xmlns="http://www.w3.org/2000/svg"
    >
      <GraphPaper lineSpacing={lineSpacing} scaleFactor={effectiveZoom} />
      {lines}
    </svg>
  );
};

export default ECGChart;
