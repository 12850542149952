import { IconNoInternet, IconSizes } from '@viz/design-system';
import { useOnlineStatus } from '@viz/hooks';
import { localize } from '@viz/i18n';

import {
  StyledSnackbar,
  StyledSnackbarContent,
  StyledSnackbarText
} from './styles';

const OnlineStatusWatcher = () => {
  const { isOnline } = useOnlineStatus();

  const message = (
    <StyledSnackbarContent>
      <IconNoInternet size={IconSizes.SMALL} />
      <StyledSnackbarText>{localize('noInternet')}</StyledSnackbarText>
    </StyledSnackbarContent>
  );

  return (
    <StyledSnackbar
      open={!isOnline}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      message={message}
    />
  );
};

export default OnlineStatusWatcher;
