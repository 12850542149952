import { getBaseColor, getFontSize, getSize, Link } from '@viz/design-system';
import styled from 'styled-components';

export const StyledResendCodeContainer = styled.div`
  color: ${getBaseColor('french-gray')};
  display: flex;
  align-items: center;
  gap: ${getSize(1)};
`;

export const StyledLoadingContainer = styled.div`
  margin-left: ${getSize(1)};
  display: flex;
  align-items: center;
`;

export const StyledResendCode = styled(Link)`
  color: ${getBaseColor('french-gray')};
  ${getFontSize('sm')};

  :hover {
    color: ${getBaseColor('porcelain')};
  }
`;
