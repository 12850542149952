import { useIcon } from '@viz/api';
import { IconDistributionList, IconSizes } from '@viz/design-system';
import { memo } from 'react';

import { IconContainer } from './styles';

export type AlgoDetectionIconProps = {
  iconUrl?: string | null;
  size?: IconSizes;
  testId?: string;
};

const AlgoDetectionIcon = ({
  iconUrl,
  size = IconSizes.MEDIUM,
  testId
}: AlgoDetectionIconProps): JSX.Element => {
  const { data } = useIcon(
    { iconUrl },
    {
      enabled: Boolean(iconUrl),
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );

  if (!iconUrl) {
    return <IconDistributionList size={size} testId={testId} />;
  }

  return (
    <IconContainer
      dangerouslySetInnerHTML={{ __html: data as string }}
      $size={size}
      data-testid={testId}
    />
  );
};

export default memo(AlgoDetectionIcon);
