import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getFontWeight,
  getSpacing,
  Typography,
  Snackbar
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledSnackbar = styled(Snackbar)`
  margin-top: ${getSpacing(-4)};
  border-radius: ${getBorderRadius('SMALL')};
  color: ${getBaseColor('black')};
  ${getFontSize('xs')};

  & div {
    background-color: ${getBaseColor('warning')};
  }

  .MuiSnackbarContent-message {
    padding: 0;
  }

  .MuiSnackbarContent-root {
    padding: ${getSpacing(1)} ${getSpacing(2)};
  }
`;

export const StyledSnackbarContent = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${getBorderRadius('SMALL')};
`;

export const StyledSnackbarText = styled(Typography)`
  && {
    font-weight: ${getFontWeight('font-weight-bold-500')};
    margin: ${getSpacing(0)};
    margin-left: ${getSpacing(2)};
    color: ${getBaseColor('black')};
  }
`;
