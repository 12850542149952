import {
  getBaseColor,
  getFontWeight,
  getSpacing,
  Grid,
  Input,
  Typography
} from '@viz/design-system';
import styled from 'styled-components';

export const FormGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const StyledInput = styled(Input)`
  && {
    margin: ${getSpacing(7)} ${getSpacing(0)};

    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;

      .MuiButtonBase-root {
        padding: ${getSpacing(3)} ${getSpacing(4)};
      }
    }
  }
`;

export const LoginLinksContainer = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${getSpacing(3)};
  color: ${getBaseColor('french-gray')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  :hover {
    color: ${getBaseColor('porcelain')};
  }
`;

export const WelcomeTitle = styled(Typography)`
  && {
    font-weight: ${getFontWeight('font-weight-bold-600')};
    text-align: center;
    padding-bottom: ${getSpacing(5)};
  }
`;
