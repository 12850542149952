import styled, { css } from 'styled-components';

interface StyledVisibilityProps {
  $isVisible: boolean;
}

export const StyledVisibilityContainer = styled.div<StyledVisibilityProps>(
  ({ $isVisible }) => css`
    height: 100%;
    display: ${$isVisible ? 'flex' : 'none'};
  `
);
