import { localize } from '@viz/i18n';
import { useMemo } from 'react';

import { StyledDialogContent, StyledViewerDisclaimerDialog } from './styles';

type ViewerDisclaimerDialogProps = {
  title?: string;
  message?: string;
  isOpen: boolean;
  onClose: () => void;
};

const ViewerDisclaimerDialog = ({
  title = '',
  message,
  isOpen,
  onClose
}: ViewerDisclaimerDialogProps) => {
  const styledMessage = useMemo(
    () => message?.split('.').filter((msg) => msg.length > 0),
    [message]
  );

  if (!styledMessage) return <></>;

  return (
    <StyledViewerDisclaimerDialog
      open={isOpen}
      withCloseIcon={false}
      title={title}
      primaryButtonProps={{ content: localize('understand'), onClick: onClose }}
    >
      <StyledDialogContent>
        {styledMessage.map((msg) => (
          <span key={msg}>{msg}.</span>
        ))}
      </StyledDialogContent>
    </StyledViewerDisclaimerDialog>
  );
};

export { ViewerDisclaimerDialog };
