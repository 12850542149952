import {
  ClinicalInfo as ClinicalInfoApi,
  ClinicalInfoItem,
  Patient,
  useGetPatientClinicalInfoForm,
  useSubmitPatientClinicalInfoForm
} from '@viz/api';
import { IconSizes, Loading } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { withVisibility } from '../../../../../hocs';
import { AnalyticsEventName, useAnalyticsEvent } from '../../../../../hooks';
import { toastStatusState } from '../../../../../store';
import { ClinicalInfoFormDialog } from '../StaffNotesFormDialog';
import { ClinicalInfoSection } from '../StaffNotesSection';
import { ClinicalInfoSectionProps } from '../StaffNotesSection/ClinicalInfoSection';
import { sectionTitleToItemKeys } from '../constants';
import { useQueryWithLoadingThreshold } from '../hooks';
import { ClinicalInfoSectionTitle } from '../types';
import { createSubmitStaffNotesEventData } from '../utils';
import { StaffNotesEmptyContent } from './StaffNotesEmptyContent';
import {
  StyledStaffNotesLayout,
  StyledLoadingWrapper,
  ClinicalInfoTitle
} from './styles';

export interface ClinicalInfoProps {
  patient: Patient;
  items: ClinicalInfoItem[];
  enableEdit: boolean;
  onSubmit: () => void;

  patientTabId?: number;
  testId?: string;
}

export const StaffNotes = withVisibility((props: ClinicalInfoProps) => {
  const [isEdittingForm, setIsEdittingForm] = useState(false);
  const [showLoadingFormError, setShowLoadingFormError] = useState(false);
  const setToastStatus = useSetRecoilState(toastStatusState);
  const { sendEvent } = useAnalyticsEvent();

  const { patient, enableEdit, items, patientTabId, testId } = props;

  const toggleIsEdittingForm = () => setIsEdittingForm(!isEdittingForm);

  const { mutate: submitPatientForm, isLoading: isSubmittingForm } =
    useQueryWithLoadingThreshold(useSubmitPatientClinicalInfoForm());

  const { data: form, isLoading: isLoadingForm } = useQueryWithLoadingThreshold(
    useGetPatientClinicalInfoForm(
      { patientId: patient.id, tabId: patientTabId },
      { enabled: isEdittingForm }
    )
  );

  const handleOnSubmit = (
    patientId: string,
    payload: ClinicalInfoApi.SubmitPatientClinicalInfoFormPayload,
    onSuccess: () => void
  ) => {
    submitPatientForm(
      {
        form_id: payload.form_id,
        timezone_offset: payload.timezone_offset,
        patientId: patientId,
        fields: payload.fields
      },
      {
        onSuccess: () => {
          toggleIsEdittingForm();
          props.onSubmit();
          setToastStatus({
            type: 'info',
            text: localize('clinicalInfoHasBeenUpdated')
          });
          sendEvent(
            AnalyticsEventName.PATIENT_CLINICAL_INFO_SUBMITTED_SUCCESS,
            createSubmitStaffNotesEventData(patient, payload.form_id)
          );
          onSuccess();
        },
        onError: () => {
          setToastStatus({
            type: 'error',
            text: localize('somethingWentWrong')
          });
          sendEvent(
            AnalyticsEventName.PATIENT_CLINICAL_INFO_SUBMITTED_FAILED,
            createSubmitStaffNotesEventData(patient, payload.form_id)
          );
        }
      }
    );
  };

  useEffect(() => {
    if (isEdittingForm && form?.formId && form?.formId !== 'hcm_form') {
      setShowLoadingFormError(true);
    }

    return () => {
      setShowLoadingFormError(false);
    };
  }, [isLoadingForm, isEdittingForm, form]);

  const allSectionsAreEmpty = items.length === 0;

  return (
    <>
      <StyledStaffNotesLayout data-testid={testId}>
        {isLoadingForm ? (
          <StyledLoadingWrapper>
            <Loading size={IconSizes.LARGE} role="loader" />
          </StyledLoadingWrapper>
        ) : (
          <>
            <ClinicalInfoTitle>{localize('staffNotes')}</ClinicalInfoTitle>
            {allSectionsAreEmpty ? (
              <StaffNotesEmptyContent
                enableEdit={enableEdit}
                showError={showLoadingFormError}
                onClickEdit={toggleIsEdittingForm}
              />
            ) : (
              <>
                {renderStaffNotesSection(
                  items.filter((item) => item.title === 'latestEditor')
                )}
                {renderStaffNotesSection(items, 'background')}
                {renderStaffNotesSection(items, 'timing')}
                {renderStaffNotesSection(items, 'imaging')}
                {renderStaffNotesSection(items, 'treatment')}
                {renderStaffNotesSection(items, 'hcmClinicalInfo', {
                  enableEdit,
                  onClickEdit: toggleIsEdittingForm
                })}
              </>
            )}
          </>
        )}
      </StyledStaffNotesLayout>
      {!isLoadingForm && form?.formId === 'hcm_form' && (
        <ClinicalInfoFormDialog
          form={form}
          open={isEdittingForm}
          title={localize('clinicalInformation')}
          subtitle={patient.name}
          onSubmit={(payload, onSuccess) =>
            handleOnSubmit(
              patient.id,
              payload as ClinicalInfoApi.SubmitPatientClinicalInfoFormPayload,
              onSuccess
            )
          }
          isEditMode={isEdittingForm && !allSectionsAreEmpty}
          isSubmitting={isSubmittingForm}
          onClose={toggleIsEdittingForm}
        />
      )}
    </>
  );
});

const renderStaffNotesSection = (
  items: ClinicalInfoItem[],
  sectionTitle?: ClinicalInfoSectionTitle,
  options?: Partial<ClinicalInfoSectionProps>
) => {
  const filterItemsByTitle = (sectionTitle: ClinicalInfoSectionTitle) => {
    const sectionItemsKeys = sectionTitleToItemKeys[sectionTitle];
    return items.filter((item) => sectionItemsKeys.includes(item.title));
  };

  const sectionItems = sectionTitle ? filterItemsByTitle(sectionTitle) : items;

  return (
    <>
      {sectionItems.length > 0 && (
        <ClinicalInfoSection
          enableFlexWrap={sectionTitle !== 'hcmClinicalInfo'}
          title={sectionTitle && localize(sectionTitle)}
          role={`${sectionTitle}-section`}
          items={sectionItems}
          {...options}
        />
      )}
    </>
  );
};
