import { useAckSeries } from '@viz/api';
import {
  EmptyContent,
  IconError,
  IconInfoError,
  IconSizes,
  Loading
} from '@viz/design-system';
import { localize } from '@viz/i18n';
import { logger } from '@viz/logging';
import { monitoringService } from '@viz/monitoring';
import { capitalize, getWebGlContext } from '@viz/utils';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useViewerEvents } from '../../hooks';
import { UnsupportedRendererSnackbar } from '../UnsupportedRendererSnackbar';
import { VIEWER_URL } from './constants';
import { InitMessage } from './messages/InitMessage';
import { BaseViewerMessage } from './messages/base';
import { LoadSeriesMessage } from './messages/loadSeries';
import {
  StyledIframe,
  StyledLoaderContainer,
  StyledReadMoreDisclaimer,
  StyledViewerDisclaimer,
  ViewerContainer
} from './styles';
import { ViewerProps } from './types';
import { createIframeUrl, getViewerMetadata } from './utils';

const Viewer = ({
  patient,
  series,
  seriesUid,
  seriesMetadata,
  study,
  studyUid,
  onDisclaimerClick
}: ViewerProps) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [isViewerLoaded, setIsViewerLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeUrl = createIframeUrl();
  const [activeViewerKey, setActiveViewerKey] = useState(1);
  const { mutate: ackSeries } = useAckSeries();

  const onViewerLoaded = () => setIsViewerLoaded(true);
  const onViewerLoading = () => setIsViewerLoaded(false);

  const onIframeLoaded = () => {
    if (!iframeLoaded) {
      setIframeLoaded(true);

      const message = new InitMessage({
        monitoringSessionInfo: {
          config: monitoringService.getSessionParams('ohif-viewer'),
          user: monitoringService.getUser(),
          webglContext: getWebGlContext()
        }
      });
      postViewerMessage(message);

      if (seriesMetadata) {
        //if the iframe has re-loaded while there is a selected series, load it in the viewer
        postLoadSeriesMessage();
      }
    }
  };

  const onError = () => {
    setIsError(true);
  };

  const reloadViewer = () => {
    setIframeLoaded(false);
    setActiveViewerKey((currKey) => currKey + 1);
  };

  useViewerEvents({
    patient,
    study,
    series,
    onIframeLoaded,
    onViewerLoaded,
    onViewerLoading,
    onError,
    onSignificantError: reloadViewer
  });

  const postViewerMessage = (message: BaseViewerMessage<unknown>) => {
    try {
      iframeRef.current?.contentWindow?.postMessage(message, VIEWER_URL);
    } catch (e: any) {
      logger.warn('failed to post viewer message', e);
    }
  };

  const refreshPage = () => {
    logger.info('reloading page due to viewer error', { studyUid, seriesUid });
    window.location.reload();
  };

  //we also react to changes in patient Id to support a switch between multiple patient tabs, should that feature be enabled
  //as there may be an edge case where different patients have the same seriesUid
  useEffect(() => {
    setIsViewerLoaded(false);
  }, [seriesUid, patient?.id]);

  const postLoadSeriesMessage = useCallback(() => {
    //if all the data is cleared, re-set the viewer to prevent memory issues.
    // reloading fixes a memory leak that causes the app freezes when closing the viewer tab (returning to all-cases) in the middle of loading a study with hundreds of dicoms.
    // the reload could be replaced with sending a "clear" message to the viewer, and implementing that functionality there.
    //however, it seems more stable to fully reload the viewer window to ensure that its in a pristine state, and that all the memory usage is properly cleared immediately.

    if (!seriesMetadata && !patient && !study && !series) {
      reloadViewer();
      return;
    }

    // Once we have all the info we need, we send an event for the viewer to fetch data
    if (seriesMetadata && patient && study && series) {
      const metadata = getViewerMetadata(seriesMetadata, series, {
        accessionNumber: study.accessionNumber,
        name: patient.name!,
        lastArrivalTs: study.lastArrivalTs!
      });

      const message = new LoadSeriesMessage({
        studyId: `${study.id}`,
        seriesId: `${series.id}`,
        customerAccountUid: study.customerAccountUid,
        metadata
      });
      postViewerMessage(message);
      ackSeries({ study_id: study.id, series_uid: seriesUid });
      setIsError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesMetadata]);

  useEffect(() => {
    postLoadSeriesMessage();
  }, [postLoadSeriesMessage]);

  const showDisclaimer = seriesMetadata?.readMoreDisclaimer?.isEnabled ?? false;

  return (
    <ViewerContainer>
      <UnsupportedRendererSnackbar />
      <StyledIframe
        key={activeViewerKey}
        $isLoaded={isViewerLoaded}
        ref={iframeRef}
        src={iframeUrl}
        // The OHIF-Viewer requires cross-origin-isolation to use a SharedArrayBuffer for building volume.
        allow="cross-origin-isolated; xr-spatial-tracking"
      />
      {isViewerLoaded ? (
        <ViewerDisclaimer
          showDisclaimer={showDisclaimer}
          onDisclaimerClick={onDisclaimerClick}
        />
      ) : (
        <StyledLoaderContainer>
          {isError ? (
            <EmptyContent
              title={localize('viewerError')}
              subTitle={localize('viewerTryAgain')}
              icon={<IconError />}
              buttonDetails={{
                testId: 'try-again-button',
                text: capitalize(localize('tryAgain')),
                onClick: refreshPage
              }}
            />
          ) : (
            <Loading size={IconSizes.X_LARGE} />
          )}
        </StyledLoaderContainer>
      )}
    </ViewerContainer>
  );
};

type ViewerDisclaimerProps = {
  showDisclaimer: boolean;
  onDisclaimerClick: () => void;
};

const ViewerDisclaimer = ({
  showDisclaimer,
  onDisclaimerClick
}: ViewerDisclaimerProps) => {
  return (
    <StyledViewerDisclaimer>
      <IconInfoError size={IconSizes.X_SMALL} />
      <span>
        {localize('viewerDisclaimer')}
        {showDisclaimer && (
          <StyledReadMoreDisclaimer
            underline="always"
            onClick={onDisclaimerClick}
          >
            {localize('readMore')}
          </StyledReadMoreDisclaimer>
        )}
      </span>
    </StyledViewerDisclaimer>
  );
};

export { Viewer };
