import { getBaseColor, getSize } from '@viz/design-system';
import styled, { css } from 'styled-components';

type StyledInstitutionCheckboxProps = {
  $isDisabled?: boolean;
};

export const StyledInstitutionCheckbox =
  styled.div<StyledInstitutionCheckboxProps>(
    ({ $isDisabled }) => css`
      display: flex;
      align-items: center;
      gap: ${getSize(3)};
      cursor: pointer;
      color: ${getBaseColor('french-gray')};
      width: max-content;
      pointer-events: ${$isDisabled ? 'none' : 'auto'};

      :hover {
        color: ${getBaseColor('porcelain')};
      }
    `
  );
