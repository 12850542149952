import { Input } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { ChangeEvent } from 'react';

import { IdentificationContainer, UsernameInputContainer } from './styles';

type IdentificationContentProps = {
  username: string;
  onUsernameChange: (username: string) => void;
  isLoading: boolean;
  error: boolean;
};

const IdentificationContent = (
  props: IdentificationContentProps
): JSX.Element => {
  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    props.onUsernameChange(event.target.value);
  };

  return (
    <IdentificationContainer>
      {localize('resetPasswordDialogDesc')}
      <UsernameInputContainer>
        <Input
          name="email"
          placeholder={localize('enterEmail')}
          value={props.username}
          onChange={handleUsernameChange}
          variant="outlined"
          testId="reset-password-username"
          disabled={props.isLoading}
          error={props.error}
          fullWidth
          helperText={props.error ? localize('invalidEmail') : ''}
        />
      </UsernameInputContainer>
    </IdentificationContainer>
  );
};

export { IdentificationContent };
