import {
  UserChatAccess,
  ClientConfig,
  TabSortingState,
  UserAccess
} from '@viz/api';
import { createStorageEntry, LocalStorage, SessionStorage } from '@viz/utils';

import {
  FiltersByTabIdMap,
  StatusFilterByTabIdMap
} from '../../pages/Patients/types';

class BrowserStorage {
  private _clientConfigEntry = createStorageEntry<ClientConfig>('clientConfig');
  private _notificationsTokenEntry = createStorageEntry('notificationsToken');
  private _signedPolicies = createStorageEntry('signedPolicies', LocalStorage);
  private _userAccessEnabledEntry = createStorageEntry<UserAccess>(
    'userAccessEnabled',
    LocalStorage
  );
  private _ssoUsernameEntry = createStorageEntry('ssoUsername', LocalStorage);
  private _tabSortingStateEntry =
    createStorageEntry<TabSortingState[]>('tabSortingState');
  private _encryptionKeyEntry = createStorageEntry(
    'encryption_key',
    LocalStorage
  );
  private _accessTokenExpirationEntry = createStorageEntry<number>(
    'access_expiration',
    LocalStorage
  );
  private _CSRFAccessTokenEntry = createStorageEntry(
    'csrf_access_token',
    LocalStorage
  );

  private _filtersByTabIdEntry =
    createStorageEntry<FiltersByTabIdMap>('filters_by_tab_id');

  private _statusFilterByTabIdEntry =
    createStorageEntry<StatusFilterByTabIdMap>('status_by_tab_id');

  private _hasAdminConsoleBeenAccessed = createStorageEntry(
    'hasAdminConsoleBeenAccessed'
  );

  private _userChatAccess = createStorageEntry<UserChatAccess>(
    'user_chat_access',
    LocalStorage
  );

  clear(): void {
    SessionStorage.clear();
    LocalStorage.clear();
  }

  cleanUserData(): void {
    this.encryptionKey.remove();
    this.accessTokenExpiration.remove();
    this.csrfAccessToken.remove();
    this.clientConfig.remove();
    this.signedPolicies.remove();
    this.userChatAccess.remove();
  }

  get clientConfig() {
    return this._clientConfigEntry;
  }

  get encryptionKey() {
    return this._encryptionKeyEntry;
  }

  get accessTokenExpiration() {
    return this._accessTokenExpirationEntry;
  }

  get csrfAccessToken() {
    return this._CSRFAccessTokenEntry;
  }

  get notificationsToken() {
    return this._notificationsTokenEntry;
  }

  get signedPolicies() {
    return this._signedPolicies;
  }

  get ssoUsername() {
    return this._ssoUsernameEntry;
  }

  get userAccessEnabled() {
    return this._userAccessEnabledEntry;
  }

  get filtersState() {
    return this._filtersByTabIdEntry;
  }

  get statusFilterState() {
    return this._statusFilterByTabIdEntry;
  }

  get hasAdminConsoleBeenAccessed() {
    return this._hasAdminConsoleBeenAccessed;
  }

  get tabSortingState() {
    return this._tabSortingStateEntry;
  }

  get userChatAccess() {
    return this._userChatAccess;
  }
}

export const browserStorage = new BrowserStorage();
