export function isChromeOrEdge() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('chrome') || userAgent.includes('edg');
}

//adapted from https://stackoverflow.com/questions/2909367/can-you-determine-if-chrome-is-in-incognito-mode-via-a-script/2909398#2909398
export async function isInCognito(): Promise<boolean> {
  const { quota } = await navigator.storage.estimate();
  return (quota || 0).toString().length < 11; //incognito quota is significantly lower than normal quota
}
