import {
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledStaffNotesLayout = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(4)};
`;

export const StyledLoadingWrapper = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClinicalInfoTitle = styled.span`
  font-weight: ${getFontWeight('font-weight-bold-600')};
  ${getFontSize('xl')};
  color: ${getBaseColor('porcelain')};
  padding: 0 0 ${getSpacing(5.3)};
`;

export const ClinicalInfoEmptyStateLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
