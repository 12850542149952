import { ReadMore } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { UIEvent, useEffect, useRef, useState } from 'react';

import {
  StyledDialogContent,
  StyledPolicesContainer,
  StyledPolicesHeader,
  StyledPoliciesDialog,
  StyledReadMoreContainer,
  StyledStepper
} from './styles';

type PoliciesDialogProps = {
  policies: Array<{ title: string; content: string }>;
  open: boolean;
  onSign: () => void;
  onClose: () => void;
};

const READ_MORE_TIMEOUT = 10000;
type ReadMoreState = 'preScroll' | 'show' | 'afterScroll';
const SCROLL_BUFFER = 10;

const PoliciesDialog = ({
  policies,
  open,
  onSign,
  onClose
}: PoliciesDialogProps): JSX.Element => {
  const steps = policies.map(({ title }) => ({ label: title }));
  const [activeStep, setActiveStep] = useState(0);
  const [agreeButtonDisabled, setAgreeButtonDisabled] = useState(true);
  const [showReadMoreBadge, setShowReadMoreBadge] =
    useState<ReadMoreState>('preScroll');
  const policyRef = useRef<HTMLDivElement>(null);

  const finalStep = steps.length - 1;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowReadMoreBadge((currState) =>
        currState === 'preScroll' ? 'show' : currState
      );
    }, READ_MORE_TIMEOUT);

    return () => clearTimeout(timer);
  }, [activeStep]);

  const agreeToTerms = () => {
    if (activeStep === finalStep) {
      onSign();
    } else {
      policyRef.current?.scrollTo({ top: 0 });
      setActiveStep((currStep) => currStep + 1);
      setAgreeButtonDisabled(true);
      setShowReadMoreBadge('preScroll');
    }
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    if (target.scrollTop > 0 && showReadMoreBadge !== 'afterScroll') {
      setShowReadMoreBadge('afterScroll');
    }

    const bottom =
      target.scrollHeight - target.scrollTop - SCROLL_BUFFER <=
      target.clientHeight;

    if (bottom) {
      setAgreeButtonDisabled(false);
    }
  };

  const close = () => {
    onClose();
    setAgreeButtonDisabled(true);
  };

  const agreeButtonText = localize(
    activeStep < finalStep ? 'agreeAndContinue' : 'agree'
  );

  return (
    <StyledPoliciesDialog
      open={open}
      onClose={close}
      title={localize('termsOfUse')}
      data-testid="user-policies-dialog"
      withCloseIcon
      primaryButtonProps={{
        testId: 'user-policies-dialog-button-agree',
        content: agreeButtonText,
        disabled: agreeButtonDisabled,
        onClick: agreeToTerms
      }}
      secondaryButtonProps={{
        testId: 'user-policies-dialog-button-decline',
        content: localize('decline'),
        onClick: close
      }}
    >
      <StyledDialogContent>
        <StyledStepper
          steps={steps}
          data-testid="user-policies-stepper"
          activeStep={activeStep}
          orientation="vertical"
        />
        <StyledPolicesContainer
          onScroll={handleScroll}
          ref={policyRef}
          data-testid="user-policies-scroll-container"
        >
          <StyledPolicesHeader>
            {localize('termsDisclaimer')}:
          </StyledPolicesHeader>
          <div
            dangerouslySetInnerHTML={{ __html: policies[activeStep].content }}
          />
          {showReadMoreBadge === 'show' && (
            <StyledReadMoreContainer>
              <ReadMore bounce />
            </StyledReadMoreContainer>
          )}
        </StyledPolicesContainer>
      </StyledDialogContent>
    </StyledPoliciesDialog>
  );
};

export { PoliciesDialog };
