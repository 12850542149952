import {
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledNotificationActions = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledActionContainer = styled.div`
  display: flex;
  gap: ${getSpacing(1)};
  ${getFontSize('sm')};
`;

export const StyledActionLabel = styled.div`
  color: ${getBaseColor('french-gray')};
`;

export const StyledActionValue = styled.div`
  font-weight: ${getFontWeight('font-weight-bold-600')};
  margin-right: ${getSpacing(1)};
`;
