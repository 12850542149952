import {
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSpacing
} from '@viz/design-system';
import styled, { css } from 'styled-components';

const container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const StyledUserPoliciesContainer = styled.div`
  ${container};
  gap: ${getSpacing(6)};
`;

export const StyledPoliciesHeader = styled.div`
  ${container};
  gap: ${getSpacing(2)};
`;

export const StyledPoliciesTitle = styled.div`
  ${getFontSize('2xl')};
  color: ${getBaseColor('porcelain')};
  font-weight: ${getFontWeight('font-weight-bold-600')};
`;

export const StyledPoliciesSubtitle = styled.div`
  ${getFontSize('base')};
  color: ${getBaseColor('porcelain')};
`;
