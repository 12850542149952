import { localize } from '@viz/i18n';

import { FeedbackDialog } from '../../../../components';

interface UnsavedChangesDialogProps {
  isOpen: boolean;
  onCloseDialog: () => void;
  onClosePanel: () => void;
}

const UnsavedChangesDialog = ({
  isOpen,
  onCloseDialog,
  onClosePanel
}: UnsavedChangesDialogProps) => {
  return (
    <FeedbackDialog
      title={localize('unsavedChanges')}
      primaryButtonProps={{
        content: localize('keepEditing'),
        onClick: onCloseDialog
      }}
      secondaryButtonProps={{
        content: localize('closeWithoutSaving'),
        onClick: onClosePanel
      }}
      open={isOpen}
      withCloseIcon={true}
      onClose={onCloseDialog}
    >
      <div>{localize('unsavedChangesDialogFirst')}</div>
      <div>{localize('unsavedChangesDialogSecond')}</div>
    </FeedbackDialog>
  );
};

export default UnsavedChangesDialog;
