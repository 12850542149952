import { Button, getBaseColor, getFontSize, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const WarningMessage = styled.div`
  margin-top: ${getSize(5)};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Message = styled.span`
  margin-left: ${getSize(2)};
`;

export const SendAnInvitationButton = styled(Button)`
  && {
    ${getFontSize('sm')};
    background-color: transparent;
    color: ${getBaseColor('white')};
    text-decoration: underline;
    border: none;
    cursor: pointer;
  }
`;
