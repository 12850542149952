import {
  IconHistory,
  IconSizes,
  IconUser,
  IconVizLogo,
  Tooltip
} from '@viz/design-system';
import { localize } from '@viz/i18n';
import React from 'react';

import { AuditLogViewItem } from '../../types';
import { SubtitleContainer, SubtitleRow, SubtitleText } from './styles';

export interface AuditLogSubtitleProps {
  auditLog: AuditLogViewItem;
  extended: boolean;
}

const AuditLogSubtitle = ({ auditLog, extended }: AuditLogSubtitleProps) => {
  const executorName = `${auditLog.log.executor.firstName} ${auditLog.log.executor.lastName}`;
  const isVizInternal = isVizUser(auditLog.log.executor.email);

  return (
    <SubtitleContainer>
      <SubtitleRow extended={extended}>
        {isVizInternal ? (
          <IconVizLogo size={IconSizes.X_SMALL} />
        ) : (
          <IconUser size={IconSizes.X_SMALL} />
        )}
        <Tooltip title={auditLog.log.executor.email} placement={'top'}>
          <SubtitleText>{`${executorName}, ${
            isVizInternal
              ? localize('vizTechnician')
              : localize('administrator')
          }`}</SubtitleText>
        </Tooltip>
      </SubtitleRow>
      <SubtitleRow extended={extended}>
        <IconHistory size={IconSizes.X_SMALL} />
        <ActionsRow auditLog={auditLog} />
      </SubtitleRow>
    </SubtitleContainer>
  );
};

const ActionsRow = ({ auditLog }: { auditLog: AuditLogViewItem }) => {
  let texts = auditLog.fields.map(({ title }) => title);

  return (
    <Tooltip
      title={texts.map((text) => (
        <div>{text}</div>
      ))}
    >
      <SubtitleText>{texts.join(', ')}</SubtitleText>
    </Tooltip>
  );
};

const isVizUser = (email: string): boolean => {
  return email.endsWith('@viz.ai');
};

export default AuditLogSubtitle;
