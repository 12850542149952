import { breakpointsDefinition } from '@viz/design-system';
import { useDebouncedCallback } from '@viz/hooks';
import { localize } from '@viz/i18n';
import { useEffect, useState } from 'react';

import { getMenuRoutes } from '../../../navigation';
import { AppSideMenu } from './styles';

const bp1 = parseInt(breakpointsDefinition.bp1) * 16;
const bp2 = parseInt(breakpointsDefinition.bp2) * 16;

const PORTRAIT_ORIENTATIONS: OrientationType[] = [
  'portrait-primary',
  'portrait-secondary'
];
const isPortraitOrientation = PORTRAIT_ORIENTATIONS.includes(
  window.screen.orientation.type
);

const LayoutSideMenu = (): JSX.Element => {
  const menuRoutes = getMenuRoutes();
  const [isOpen, setIsOpen] = useState<boolean>(
    window.innerWidth >= bp1 && !isPortraitOrientation && !window.vizWidget
  );

  const [isExpended, setIsExpended] = useState(
    window.innerWidth >= bp2 && !window.vizWidget
  );

  const handleResize = () => {
    if (
      window.innerWidth >= bp1 &&
      !isPortraitOrientation &&
      !window.vizWidget
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }

    if (window.innerWidth >= bp2 && !window.vizWidget) setIsExpended(true);
    else setIsExpended(false);
  };

  const debouncedHandleResize = useDebouncedCallback(handleResize, 300);
  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [debouncedHandleResize]);

  return (
    <AppSideMenu
      items={menuRoutes}
      loc={localize}
      isDrawerOpen={isOpen}
      testId="menu-drawer"
      isExpended={isExpended}
    />
  );
};

export default LayoutSideMenu;
