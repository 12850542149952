import { InstitutionSetting } from '@viz/api';
import { getSizeNumber } from '@viz/design-system';
import { useContext } from 'react';

import { institutionAccordionTestId } from '../../constants';
import { ExpandedInstitutionsContext } from '../InstitutionSettings';
import { getSubInstitutions } from '../utils';
import { SelectedInstitutionsSubtitle } from './SelectedInstitutionsSubtitle';
import {
  StyledInstitutionAccordion,
  StyledInstitutionCheckbox,
  StyledInstitutionFilter,
  StyledSingleInstitutionContainer,
  StyledSubInstitutions
} from './styles';

type InstitutionSettingsProps = {
  institutions: InstitutionSetting[];
  searchText: string;
  onInstitutionToggle: (
    isSelected: boolean,
    institution: InstitutionSetting
  ) => void;
};

const InstitutionFilter = ({
  institutions,
  searchText,
  onInstitutionToggle
}: InstitutionSettingsProps): JSX.Element => {
  const { expandedInstitutions, setExpandedInstitutions } = useContext(
    ExpandedInstitutionsContext
  );

  const handleToggleExpandInstitution = (
    institutionId: number,
    isExpanded: boolean
  ) => {
    const updatedExpandedInstitutions = new Map(expandedInstitutions);
    updatedExpandedInstitutions.set(institutionId, isExpanded);
    setExpandedInstitutions(updatedExpandedInstitutions);
  };

  return (
    <StyledInstitutionFilter>
      {institutions.map((institution) => {
        const isExpanded = expandedInstitutions.get(institution.id) ?? true;

        if (institution.subInstitutions.length === 0) {
          return (
            <StyledSingleInstitutionContainer
              key={institution.id}
              data-testid={`single-institution-${institution.id}`}
            >
              <StyledInstitutionCheckbox
                institution={institution}
                onChange={onInstitutionToggle}
              />
            </StyledSingleInstitutionContainer>
          );
        }

        const subInstitutions = getSubInstitutions(institution, searchText);

        return (
          <StyledInstitutionAccordion
            key={institution.id}
            maxHeight={getSizeNumber(113)}
            title={
              <StyledInstitutionCheckbox
                institution={institution}
                onChange={onInstitutionToggle}
              />
            }
            subTitle={
              !isExpanded && (
                <SelectedInstitutionsSubtitle
                  subInstitutions={subInstitutions}
                />
              )
            }
            expanded={isExpanded}
            onChange={(expanded) =>
              handleToggleExpandInstitution(institution.id, expanded)
            }
            testId={institutionAccordionTestId + institution.id}
          >
            <StyledSubInstitutions
              institutions={subInstitutions}
              searchText={searchText}
              onChange={onInstitutionToggle}
            />
          </StyledInstitutionAccordion>
        );
      })}
    </StyledInstitutionFilter>
  );
};

export { InstitutionFilter };
