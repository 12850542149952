import { localize } from '@viz/i18n';

import { PhoneSettingInput } from './PhoneSettingInput';
import {
  StyledLabel,
  StyledProfileDetail,
  StyledProfileSettings,
  StyledValue
} from './styles';

type ProfileDetailsProps = {
  name: string;
  email: string;
  initialPhone: string;
  onVerifyPhoneNumber: (phoneNumber: string) => void;
};

type ProfileDetailProps = {
  label: string;
  value: JSX.Element;
};

const ProfileDetail = ({ label, value }: ProfileDetailProps) => (
  <StyledProfileDetail>
    <StyledLabel>{label}</StyledLabel>
    {value}
  </StyledProfileDetail>
);

const ProfileDetails = ({
  name,
  email,
  initialPhone,
  onVerifyPhoneNumber
}: ProfileDetailsProps) => {
  return (
    <StyledProfileSettings>
      <ProfileDetail
        label={localize('name')}
        value={<StyledValue>{name}</StyledValue>}
      />
      <ProfileDetail
        label={localize('email')}
        value={<StyledValue>{email}</StyledValue>}
      />
      <ProfileDetail
        label={localize('phone')}
        value={
          <PhoneSettingInput
            initialPhone={initialPhone}
            onVerifyPhoneNumber={onVerifyPhoneNumber}
          />
        }
      />
    </StyledProfileSettings>
  );
};

export { ProfileDetails };
