import { Dialog, getFontSize, getSize, getSpacing } from '@viz/design-system';
import styled from 'styled-components';

export const StyledViewerDisclaimerDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: ${getSize(110)};
  }
`;

export const StyledDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(5)};
  padding: ${getSpacing(2)};
  ${getFontSize('base')}
`;
