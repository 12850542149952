import { ehrDataTransform, Patient, usePatientEhrData } from '@viz/api';
import { Dialog, IconSizes } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useState } from 'react';

import { withVisibility } from '../../../../hocs';
import { CriteriaGroup } from './CriteriaGroup';
import { EhrNotesGroup } from './EhrNotesGroup';
import {
  StyledGroupContainer,
  StyledCriteriaGroup,
  StyledTabContainer,
  StyledGroup,
  StyledDividerHeader,
  StyledGroupName,
  StyledDisclaimer,
  StyledDisclaimerText,
  StyledOpenDisclaimer,
  StyledIconInfoError,
  StyledFakeSpace
} from './styles';

export interface PreScreeningProps {
  patient: Patient;
}

const PreScreening = ({ patient }: PreScreeningProps): JSX.Element => {
  const { id } = patient;
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);
  const { data: screeningData } = usePatientEhrData(
    {
      select: ehrDataTransform
    },
    { patientId: id, catalogId: 'NOVO' }
  );

  return (
    <StyledGroupContainer>
      <StyledTabContainer>
        {screeningData?.data.map((group, i) => (
          <StyledGroup key={i}>
            <StyledCriteriaGroup>
              <StyledGroupName>{group.sectionName}</StyledGroupName>
              <StyledDividerHeader />
              {group.fields.length === 1 ? (
                <EhrNotesGroup {...group.fields[0]} />
              ) : (
                <CriteriaGroup
                  sectionName={group.sectionName}
                  fields={group.fields}
                />
              )}
            </StyledCriteriaGroup>
          </StyledGroup>
        ))}
        <StyledFakeSpace />
      </StyledTabContainer>

      <StyledDisclaimer>
        <StyledIconInfoError size={IconSizes.SMALL} />
        <StyledDisclaimerText>
          <StyledOpenDisclaimer onClick={() => setIsDisclaimerOpen(true)}>
            {localize('learnMore')}
          </StyledOpenDisclaimer>{' '}
          {localize('aboutThisData')}
        </StyledDisclaimerText>
      </StyledDisclaimer>
      <Dialog
        maxWidth="xs"
        open={isDisclaimerOpen}
        title={localize('ehrDisclaimerTitle')}
        withCloseIcon={true}
        onClose={() => setIsDisclaimerOpen(false)}
        secondaryButtonProps={{
          content: localize('close'),
          onClick: () => setIsDisclaimerOpen(false)
        }}
      >
        <p>{localize('ehrDisclaimer')}</p>
      </Dialog>
    </StyledGroupContainer>
  );
};

export default withVisibility(PreScreening);
