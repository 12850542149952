import { User, useUsers } from '@viz/api';
import { IconPlus, IconSizes, IconUsers, Loading } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useEffect, useMemo, useState } from 'react';

import { PageTitle } from '../../../../components';
import {
  AdminAccessType,
  EditUserType,
  useAnalyticsEvent,
  useRouter
} from '../../../../hooks';
import { browserStorage } from '../../../../managers';
import { UsersTable } from './components';
import { EditUser } from './panels';
import CreateUser from './panels/createUser';
import UserAuditLogPanel from './panels/userAuditLog/UserAuditLogPanel';
import { LoadingContainer, StyledGrid, StyledResultCounter } from './styles';
import {
  sendAdminAccessEvent,
  sendAdminSearchRequestSentEvent,
  sendStartEditUserEvents
} from './utils';

const Users = () => {
  const { sendEvent } = useAnalyticsEvent();
  const searchQuery = useRouter().getUrlParam('query') as string;

  const { data, hasNextPage, fetchNextPage, isFetching, isLoading } = useUsers({
    query: searchQuery || '',
    onSuccess: (data) => {
      if (searchQuery && data.pages[0] && data.pages.length === 1) {
        sendAdminSearchRequestSentEvent(sendEvent, {
          search_value: searchQuery,
          number_of_search_results: data.pages[0].total
        });
      }
    }
  });

  const users = useMemo(() => {
    return data?.pages.reduce(
      (userList, users) => [...userList, ...users.users],
      [] as User[]
    );
  }, [data]);

  const [openedUser, setOpenedUser] = useState('');
  const [isCreateUserOpen, setIsCreateUserOpen] = useState(false);
  const [openedUserAuditLog, setOpenedUserAuditLog] = useState<User | null>(
    null
  );

  useEffect(() => {
    if (!browserStorage.hasAdminConsoleBeenAccessed.get()) {
      sendAdminAccessEvent(sendEvent, AdminAccessType.URL_ACCESS);
      browserStorage.hasAdminConsoleBeenAccessed.set('true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledGrid>
      <PageTitle
        title={localize('users')}
        icon={<IconUsers />}
        buttonText={localize('addUser')}
        buttonIcon={<IconPlus size={IconSizes.XX_SMALL} />}
        onActionButtonClick={() => setIsCreateUserOpen(true)}
      />
      <StyledResultCounter
        count={users?.length ?? 0}
        testId="users-result-counter"
      />
      {isLoading ? (
        <LoadingContainer>
          <Loading size={IconSizes.XX_LARGE} />
        </LoadingContainer>
      ) : (
        <UsersTable
          users={users}
          onUserOpen={(userId) => {
            setOpenedUser(userId);
            const openedUser = users?.find((user) => user.userUid === userId);

            sendStartEditUserEvents(
              sendEvent,
              userId,
              EditUserType.DIRECT_EDIT,
              openedUser?.email ?? ''
            );
          }}
          onOpenAuditLog={setOpenedUserAuditLog}
          infiniteScrollProps={{
            hasNext: Boolean(hasNextPage),
            fetchMore: fetchNextPage,
            isFetching
          }}
        />
      )}
      {Boolean(openedUser) && (
        <EditUser
          userId={openedUser}
          onPanelTransitionEnd={() => setOpenedUser('')}
        />
      )}
      <CreateUser
        isOpen={isCreateUserOpen}
        onClose={() => setIsCreateUserOpen(false)}
        onTransitionEnd={(userId: string) => setOpenedUser(userId)}
      />
      {Boolean(openedUserAuditLog) && (
        <UserAuditLogPanel
          user={openedUserAuditLog!}
          opened={openedUserAuditLog !== null}
          onClose={() => setOpenedUserAuditLog(null)}
        />
      )}
    </StyledGrid>
  );
};

export default Users;
