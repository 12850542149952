export const notificationSettingsSavedTestId = 'notification-settings-saved';
export const institutionSettingsSavedTestId = 'institution-settings-saved';

export const institutionAccordionTestId = 'institution-accordion-';

export const parentInstitutionCheckboxTestId = 'institution-checkbox-all-';
export const institutionCheckboxTestId = 'institution-checkbox-';

export const parentInstitutionLabelTestId = 'institution-label-all-';
export const institutionLabelTestId = 'institution-label-';
