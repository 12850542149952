import { Documentation } from '../../../pages';
import { LayoutSideMenu } from '../common';

const DocumentationLayout = (): JSX.Element => {
  return (
    <>
      <LayoutSideMenu />
      <Documentation />
    </>
  );
};

export default DocumentationLayout;
