import {
  IconLogo,
  IconLogoRecruit,
  IconSizes,
  AppBar,
  BrowserTabs
} from '@viz/design-system';

import { UserProfileMenu } from '../';
import { useUserProfile, usePatientTabs, useRouter } from '../../hooks';
import { Paths } from '../../navigation';
import { AppLogo, LogoAndTabsContainer, MenuAndSearchInput } from './styles';

interface TopBarProps {
  withTabs?: boolean;
  searchComponent: React.ReactNode;
  logoHref?: string;
}

const TopBar = ({
  withTabs = true,
  searchComponent,
  logoHref = Paths.CASES
}: TopBarProps): JSX.Element => {
  const router = useRouter();
  const { tabs, selectedTab } = usePatientTabs();

  const { user } = useUserProfile();
  const isRecruit =
    JSON.stringify(user?.allowed_user_workspaces) === JSON.stringify([1]);

  return (
    <AppBar>
      <LogoAndTabsContainer>
        <AppLogo
          isRecruit={isRecruit}
          onClick={() => router.navigate({ path: logoHref })}
        >
          {isRecruit ? (
            <IconLogoRecruit size={IconSizes.MEDIUM} />
          ) : (
            <IconLogo size={IconSizes.MEDIUM} />
          )}
        </AppLogo>
        {withTabs && (
          <BrowserTabs
            tabs={tabs}
            activeTabId={selectedTab}
            transition={false}
          />
        )}
      </LogoAndTabsContainer>
      <MenuAndSearchInput>
        {searchComponent}
        <UserProfileMenu />
      </MenuAndSearchInput>
    </AppBar>
  );
};
export default TopBar;
