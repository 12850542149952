import { getBaseColor, getFontSize, getSize } from '@viz/design-system/src/ui';
import styled from 'styled-components';

export const StyledCriteriaFlex3 = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

export const StyledCriteriaFlex1 = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
`;

export const StyledCriterion = styled.div`
  display: flex;
  align-items: center;
  padding: ${getSize(3)} ${getSize(1)};
  gap: ${getSize(4)};
`;

export const StyledDateInfo = styled.div`
  font-size: ${getFontSize('xs')};
  color: ${getBaseColor('santas-gray')};
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const StyledGrayedText = styled.div`
  color: ${getBaseColor('santas-gray')};
`;

export const StyledAlignedRightText = styled.div`
  text-align: left;
`;

export const StyledCriteriaPassFail = styled.div`
  display: flex;
  justify-content: start;
  min-width: ${getSize(12.5)};
  flex: 1 1 0%;
`;
