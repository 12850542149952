import { Button, getFontSize, getSpacing, Loading } from '@viz/design-system';
import styled from 'styled-components';

export const StyledLoginButton = styled(Button)`
  && {
    padding: ${getSpacing(2.75)} ${getSpacing(5.5)};
    margin: ${getSpacing(7)} ${getSpacing(0)} ${getSpacing(0)};
    ${getFontSize('base')};
  }
`;

export const LoginLoading = styled(Loading)`
  padding: ${getSpacing(0.25)} ${getSpacing(0)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
