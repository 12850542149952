import { Study, Ecg } from '@viz/api';
import { isAfter, isBefore, subHours } from 'date-fns';

import { AnalyticsEventName, useAnalyticsEvent } from '../../../hooks';
import { UseRouter } from '../../../hooks/useRouter/useRouter';
import { PatientFolderTabs } from '../../../store';
import { PRIOR_HOURS } from '../../../types';

export const getClinicalDetailsDefaultTabs = (
  opts: Partial<{
    isUserHasAccessToStreamChat: boolean;
    enableNovoTrial: boolean;
  }>
) => {
  if (opts.enableNovoTrial) {
    return [PatientFolderTabs.PRE_SCREENING, PatientFolderTabs.CHAT];
  }

  const chatTab = opts.isUserHasAccessToStreamChat
    ? PatientFolderTabs.CHANNELS
    : PatientFolderTabs.CHAT;

  return [
    PatientFolderTabs.CURRENT_IMAGING,
    PatientFolderTabs.PRIOR_EXAMS,
    chatTab,
    PatientFolderTabs.CLINICAL_INFO
  ];
};

export const isNewStudies = (exams: (Study | Ecg)[]): boolean =>
  exams?.some((exam) =>
    isAfter(exam.lastArrivalTs, subHours(new Date(), PRIOR_HOURS))
  );

export const getClinicalDetailsDefaultActiveTab = (
  enable_novo_trial_state: boolean,
  studyUid?: string,
  exams?: (Study | Ecg)[]
): PatientFolderTabs => {
  if (enable_novo_trial_state) {
    return PatientFolderTabs.PRE_SCREENING;
  } else {
    if (!exams || exams.length === 0) return PatientFolderTabs.CURRENT_IMAGING;

    if (!studyUid) {
      if (isNewStudies(exams)) return PatientFolderTabs.CURRENT_IMAGING;
      return PatientFolderTabs.PRIOR_EXAMS;
    }

    const exam = exams?.find((exam) => exam.uid === studyUid);
    if (!!exam && isNewStudies([exam])) {
      return PatientFolderTabs.CURRENT_IMAGING;
    }

    return PatientFolderTabs.PRIOR_EXAMS;
  }
};

export const filterStudies = (exams: Array<Study | Ecg>, isPrior: boolean) => {
  return exams.filter((exam) =>
    (isPrior ? isBefore : isAfter)(
      exam.lastArrivalTs,
      subHours(new Date(), PRIOR_HOURS)
    )
  );
};

export const getActiveStudyUid = (router: UseRouter) => {
  const studyIdUrlParam = router.getUrlParam('study_id') as string;
  const ecgIdUrlParam = router.getUrlParam('ecg_id') as string;
  const activeSeriesUid = router.getUrlParam('series_id') as string;
  return { activeStudyUid: studyIdUrlParam || ecgIdUrlParam, activeSeriesUid };
};

export const usePatientFolderTabClickedAnalyticsEvent = () => {
  const { sendEvent } = useAnalyticsEvent();

  return (tab: string, patientId: string) =>
    sendEvent(AnalyticsEventName.PATIENT_FOLDER_TAB_CLICKED, {
      tab_name: tab,
      patient_id: patientId
    });
};
