export enum ViewerToAppEvent {
  IFRAME_LOADED = 'IFRAME_LOADED',
  VIEWER_LOADED = 'VIEWER_LOADED',
  VIEWER_LOADING = 'VIEWER_LOADING',
  SERIES_LOADING_START = 'SERIES_LOADING_START',
  SWITCH_DIMENSION_CLICKED = 'SWITCH_DIMENSION_CLICKED',
  SERIES_LOADED = 'SERIES_LOADED',
  MIP_SETTINGS_VIEWED = 'MIP_SETTINGS_VIEWED',
  MIP_SETTINGS_UPDATED = 'MIP_SETTINGS_UPDATED',
  ROTATE_BUTTON_CLICKED = 'ROTATE_BUTTON_CLICKED',
  // SERIES_ROTATED = 'SERIES_ROTATED', // TODO: implement when we'll have rotate on drag & drop
  SLICE_SCROLL_UPDATED = 'SLICE_SCROLL_UPDATED',
  WINDOW_MANUALLY_UPDATED = 'WINDOW_MANUALLY_UPDATED',
  WINDOW_PRESET_CLICKED = 'WINDOW_PRESET_CLICKED',
  ORIENTATION_BUTTON_CLICKED = 'ORIENTATION_BUTTON_CLICKED',
  ORIENTATION_UPDATED = 'ORIENTATION_UPDATED',
  VIEWER_ERROR = 'VIEWER_ERROR',
  VIEWER_WARNING = 'VIEWER_WARNING',
  SIGNIFICANT_ERROR = 'SIGNIFICANT_ERROR'
}
