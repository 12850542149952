import { getBaseColor, getFontSize, getSize } from '@viz/design-system';
import styled from 'styled-components';

import { settingsContainerSize } from '../styles';

export const StyledInstitutionSettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledInstitutionSettings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${getSize(3)};
  ${settingsContainerSize};
`;

export const StyledInstitutionCount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${getFontSize('sm')};
  color: ${getBaseColor('waterloo')};
  align-self: flex-start;
  height: ${getSize(4)};
`;
