import {
  getBaseColor,
  getSize,
  getFontSize,
  getSpacing
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledInstitutionContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const StyledBadge = styled.div`
  color: ${getBaseColor('porcelain')};
  background-color: ${getBaseColor('gray-dark')};
  border-radius: ${getSize(10)};
  padding-inline: ${getSize(2)};
  margin-left: ${getSpacing(2)};
  ${getFontSize('xs')}
`;
