import { InstitutionSetting } from '@viz/api';
import { Checkbox } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useMemo, useState } from 'react';

import { getSubInstitutionsCount } from '../../../../../hooks';
import {
  institutionCheckboxTestId,
  institutionLabelTestId,
  parentInstitutionCheckboxTestId,
  parentInstitutionLabelTestId
} from '../../../constants';
import { StyledInstitutionCheckbox } from './styles';

type InstitutionCheckboxProps = {
  institution: InstitutionSetting;
  onChange: (isSelected: boolean, institution: InstitutionSetting) => void;
  className?: string;
};

const InstitutionCheckbox = ({
  institution,
  onChange,
  className
}: InstitutionCheckboxProps): JSX.Element => {
  const [isCheckboxHover, setIsCheckboxHover] = useState(false);

  const containsSubInstitutions = institution.subInstitutions.length > 0;

  const { selectedCount, selectableCount } = useMemo(() => {
    return getSubInstitutionsCount(institution.subInstitutions, institution);
  }, [institution]);

  const isSelected = containsSubInstitutions
    ? selectableCount > 0 && selectedCount === selectableCount
    : institution.selected;

  const isIndeterminate =
    !isSelected && containsSubInstitutions && selectedCount > 0;

  return (
    <StyledInstitutionCheckbox
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        onChange(!isSelected, institution);
      }}
      onMouseEnter={() => setIsCheckboxHover(true)}
      onMouseLeave={() => setIsCheckboxHover(false)}
    >
      <Checkbox
        color="primary"
        isHover={isCheckboxHover}
        indeterminate={isIndeterminate}
        checked={isSelected || isIndeterminate}
        onChange={() => onChange(!isSelected, institution)}
        onClick={(e) => e.stopPropagation()}
        testId={
          containsSubInstitutions
            ? parentInstitutionCheckboxTestId + institution.id
            : institutionCheckboxTestId + institution.id
        }
      />
      <div
        data-testid={
          containsSubInstitutions
            ? parentInstitutionLabelTestId + institution.id
            : institutionLabelTestId + institution.id
        }
      >
        {institution.name}{' '}
        {institution.subInstitutions.length > 0 && (
          <span>({localize('all')})</span>
        )}
      </div>
    </StyledInstitutionCheckbox>
  );
};

export { InstitutionCheckbox };
