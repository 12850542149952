import { Link } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { capitalize, isValidEmailAddress } from '@viz/utils';
import { useState } from 'react';
import { useRecoilState } from 'recoil';

import {
  AnalyticsEventMethod,
  AnalyticsEventName,
  useAnalyticsEvent,
  UseLoginUserOptions
} from '../../hooks';
import { loginStepState } from '../../store';
import { LoginForm } from '../LoginForm';
import { ResetPasswordDialog } from '../ResetPasswordDialog';
import { PasswordStepHeader } from './PasswordStepHeader';
import {
  FormGrid,
  LoginLinksContainer,
  StyledInput,
  WelcomeTitle
} from './styles';

type LoginStepData = {
  formHeader: JSX.Element;
  formInputField: JSX.Element;
  formButtonText: string;
  areLinksVisible: boolean;
};

type LoginFormContainerProps = {
  loginUser: UseLoginUserOptions;
};

const LoginUserContainer = ({
  loginUser
}: LoginFormContainerProps): JSX.Element => {
  const [loginStep, setLoginStep] = useRecoilState(loginStepState);

  const {
    resetLoginError,
    submitLogin,
    authenticationError,
    isLoading,
    username
  } = loginUser;

  const { sendEvent } = useAnalyticsEvent();
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);

  const openResetPasswordDialog = (): void => {
    sendEvent(AnalyticsEventName.RESET_PASSWORD_BUTTON_CLICKED, {
      method: AnalyticsEventMethod.RESET_PASSWORD
    });
    setResetPasswordDialogOpen(true);
  };

  const closeResetPasswordDialog = (): void => {
    setResetPasswordDialogOpen(false);
  };

  const getLoginStepData = (): LoginStepData => {
    if (loginStep === 'username') {
      return {
        formHeader: (
          <WelcomeTitle fontSize="2xl" testId="welcome-title">
            {localize('welcomeTitle')}!
          </WelcomeTitle>
        ),
        formInputField: (
          <StyledInput
            name="username"
            label={localize('email')}
            variant="outlined"
            testId="login-username"
            rules={{ required: true, validate: isValidEmailAddress }}
            changeHandler={resetLoginError}
          />
        ),
        formButtonText: capitalize(localize('next')),
        areLinksVisible: false
      };
    }

    return {
      formHeader: (
        <PasswordStepHeader
          username={username}
          onBackClick={() => {
            setLoginStep('username');
            resetLoginError();
          }}
        />
      ),
      formInputField: (
        <StyledInput
          name="password"
          label={localize('password')}
          type="password"
          testId="login-password"
          rules={{ required: true }}
          changeHandler={resetLoginError}
          autoFocus
        />
      ),
      formButtonText: capitalize(localize('login')),
      areLinksVisible: true
    };
  };

  const { formHeader, formInputField, formButtonText, areLinksVisible } =
    getLoginStepData();

  return (
    <>
      <FormGrid container>
        <FormGrid item sm={4} lg={4} xl={4}>
          {formHeader}
          <LoginForm
            onSubmit={submitLogin}
            errorMessage={authenticationError?.message}
            isLoading={isLoading}
            buttonText={formButtonText}
          >
            {formInputField}
          </LoginForm>
          <LoginLinksContainer visible={areLinksVisible}>
            <Link underline="always" onClick={openResetPasswordDialog}>
              {localize('forgotPasswordReset')}
            </Link>
          </LoginLinksContainer>
        </FormGrid>
      </FormGrid>
      {resetPasswordDialogOpen && (
        <ResetPasswordDialog
          open
          onClose={closeResetPasswordDialog}
          username={username}
        />
      )}
    </>
  );
};

export default LoginUserContainer;
