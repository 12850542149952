import { FC } from 'react';
import { RouteProps } from 'react-router';

import {
  BasicLayout,
  DocumentationLayout,
  EmptyLayout,
  PatientsLayout
} from '../layout';
import {
  Admin,
  Chat,
  ExternalPatient,
  Login,
  Patients,
  PostSSO,
  Invitation
} from '../pages';
import { AccessType } from './constants';
import { NaviagteToPatientDataByNotification } from './desktop-app/NaviagteToPatientDataByNotification';
import { Paths } from './paths';

export type LayoutRoute = {
  layout: FC;
  isProtected: boolean;
  accessType?: AccessType;
  routes: RouteProps[];
  exact?: boolean;
};

const routes: LayoutRoute[] = [
  {
    layout: EmptyLayout,
    isProtected: false,
    routes: [
      {
        path: Paths.LOGIN,
        component: Login,
        exact: true
      },
      {
        path: Paths.POST_SSO,
        component: PostSSO,
        exact: true
      }
    ]
  },
  {
    layout: BasicLayout,
    isProtected: true,
    accessType: AccessType.WEB,
    routes: [
      {
        path: Paths.CASES,
        render: () => (
          <PatientsLayout>
            <Patients />
          </PatientsLayout>
        )
      },
      {
        path: Paths.PATIENT_DATA
      },
      {
        path: Paths.DOCUMENTATION,
        component: DocumentationLayout
      },
      {
        path: Paths.INVITATION,
        component: Invitation
      },
      {
        path: Paths.DESKTOP_PATIENT_DATA_BY_NOTIFICATION,
        exact: true,
        render: (routeParams) => {
          return (
            <NaviagteToPatientDataByNotification
              notificationId={routeParams.match.params['id'] ?? ''}
            />
          );
        }
      }
    ]
  },
  {
    layout: EmptyLayout,
    isProtected: true,
    accessType: AccessType.WEB,
    routes: [
      {
        path: Paths.EXTERNAL_PATIENT,
        component: ExternalPatient
      },
      {
        path: Paths.CHAT,
        component: Chat
      }
    ]
  },
  {
    layout: EmptyLayout,
    isProtected: true,
    exact: false,
    accessType: AccessType.ADMIN,
    routes: [
      {
        path: Paths.ADMIN,
        component: Admin
      }
    ]
  }
];

export { routes };
