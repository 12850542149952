import {
  getBaseColor,
  getFontSize,
  getFontWeight,
  getSpacing,
  Link,
  Widget
} from '@viz/design-system';
import styled from 'styled-components';

export const LinksContainer = styled(Widget)`
  width: 100%;
`;

export const Title = styled.div`
  text-align: start;
  margin: ${getSpacing(2)} 0;
  font-weight: ${getFontWeight('font-weight-bold-600')};
  ${getFontSize('xl')};
`;

export const Links = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  ${getFontSize('lg')};
  gap: ${getSpacing(1)};
`;

export const StyledLink = styled(Link)`
  :hover {
    color: ${getBaseColor('porcelain')};
  }
`;
