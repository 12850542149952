import {
  getBaseColor,
  getFontSizeValue,
  getSize,
  Typography
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;
`;

export const StyledMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${getSize(4)} ${getSize(4.5)};
  cursor: pointer;

  & svg {
    width: ${getSize(6)};
    height: ${getSize(6)};
  }

  &:hover {
    background-color: ${getBaseColor('rhino-blue')};
    color: ${getBaseColor('porcelain')};

    svg {
      path {
        fill: ${getBaseColor('porcelain')};
      }
    }
  }
`;

export const StyledTypography = styled(Typography)`
  && {
    margin-left: ${getSize(2)};
    font-size: ${getFontSizeValue('medium')};
  }
`;

export const StyledDivider = styled.hr`
  border: ${getSize(0.2)} solid ${getBaseColor('blue-tuna')};
  margin: 0;
`;
