import { Series } from '@viz/api';
import { IconLayers, IconSizes } from '@viz/design-system';

import { StyledDicomAmount, StyledTag } from './styles';

type SeriesDetailsProps = {
  description: Series['description'];
  count: Series['dicomCount'];
};

const SeriesDetails = ({
  description,
  count
}: SeriesDetailsProps): JSX.Element => {
  return (
    <>
      <StyledTag>
        <IconLayers size={IconSizes.X_SMALL} />
        <StyledDicomAmount data-testid="series-dicom-count">
          {count}
        </StyledDicomAmount>
      </StyledTag>
      <StyledTag data-testid="series-description">{description}</StyledTag>
    </>
  );
};

export { SeriesDetails };
