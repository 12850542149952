import { ClientConfigTab } from '@viz/api';
import { useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { PageTitle } from '../../components';
import { useRouter } from '../../hooks';
import {
  selectedDistributionListTabIdState,
  currentPatientListUrRelativePath
} from '../../store';
import PatientsTable from './PatientsTable';
import { StyledGrid } from './styles';
import { getPageIcon, getPageTitle, getStatusTitle, getTabById } from './utils';

const usePatientsSetRecoilStates = (
  tabId: number | undefined,
  relativePath: string
) => {
  const setTabId = useSetRecoilState(selectedDistributionListTabIdState);
  const setRelativePath = useSetRecoilState(currentPatientListUrRelativePath);
  setTabId(tabId);
  setRelativePath(relativePath);
};

const useUrlParams = () => {
  const router = useRouter();

  const searchQuery = router.getUrlParam('q') as string | undefined;
  const paramTabId = router.getUrlParam('tab', false) as string;
  const paramStatusId = router.getUrlParam('status', false) as string;
  const tabId = paramTabId ? Number(paramTabId) : undefined;
  const statusId = paramStatusId ? Number(paramStatusId) : undefined;
  const pageTitleSuffix = router.getUrlParam('q') ?? '';
  const relativePath = router.getRelativePath() as string;

  return { searchQuery, tabId, pageTitleSuffix, statusId, relativePath };
};

const Patients = (): JSX.Element => {
  const { searchQuery, tabId, pageTitleSuffix, statusId, relativePath } =
    useUrlParams();
  usePatientsSetRecoilStates(tabId, relativePath);

  const tab = useMemo<ClientConfigTab | undefined>(
    () => getTabById(tabId),
    [tabId]
  );
  const pageIcon = getPageIcon(searchQuery, tab);
  const pageTitle = getPageTitle(searchQuery, tab);
  const statusTitle = getStatusTitle(tabId, statusId);

  return (
    <StyledGrid container>
      <StyledGrid item xs={12}>
        <PageTitle
          title={pageTitle + pageTitleSuffix + statusTitle}
          icon={pageIcon}
        />
        <PatientsTable query={searchQuery} urlStatusId={statusId} />
      </StyledGrid>
    </StyledGrid>
  );
};

export default Patients;
