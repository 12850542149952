import { UserGroup, UserGroupCategory } from '@viz/api';

import {
  Category,
  getUserGroupContent
} from '../../../../../utils/userGroupCategories';
import {
  CategoryContent,
  CategoryLabel,
  CategoryValue,
  InstitutionMetadata,
  SpecContent,
  UserGroupContainer
} from './styles';

export interface UserGroupsContentProps {
  userGroup: UserGroup;
}

const categoryEntries = Object.entries(Category);

const isCategoryAvailable = (userGroup: UserGroup, key: string): boolean => {
  const category = userGroup[
    key as keyof typeof userGroup
  ] as UserGroupCategory;

  return !!(category?.receive?.length || category?.send?.length);
};

const UserGroupsContent = ({ userGroup }: UserGroupsContentProps) => {
  return (
    <UserGroupContainer data-testid="user-groups-content">
      <span>{userGroup.name}</span>
      <InstitutionMetadata>
        {userGroup.institutionName} ({userGroup.institutionId})
      </InstitutionMetadata>
      <CategoryContent>
        {categoryEntries.map(
          ([key, value]) =>
            key in userGroup &&
            isCategoryAvailable(userGroup, key) && (
              <SpecContent>
                <CategoryLabel>{value}:</CategoryLabel>
                <CategoryValue>
                  {getUserGroupContent(
                    value,
                    userGroup[
                      key as keyof typeof userGroup
                    ] as UserGroupCategory
                  )}
                </CategoryValue>
              </SpecContent>
            )
        )}
      </CategoryContent>
    </UserGroupContainer>
  );
};

export default UserGroupsContent;
