import { Button, getBaseColor, getSize } from '@viz/design-system';
import styled from 'styled-components';

import { FeedbackDialog } from '../../../../../components';

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${getSize(6)};
  gap: ${getSize(12)};
`;

export const RemoveUserButton = styled(Button)`
  &.MuiButton-outlined {
    border-color: ${getBaseColor('error')};
    color: ${getBaseColor('error')};
  }
`;

export const ResetPasswordButton = styled(Button)`
  &.MuiButton-outlined {
    border-color: ${getBaseColor('french-gray')};
    color: ${getBaseColor('french-gray')};
  }
`;

export const DeleteConfirmationDialog = styled(FeedbackDialog)`
  button[role='dialog-primary-button'] {
    background-color: ${getBaseColor('error')};
    border-color: ${getBaseColor('error')};
  }
`;
