import { getSize } from '@viz/design-system';
import styled from 'styled-components';

export const UserNameHeader = styled.h2`
  margin-bottom: 4px;
`;

export const PanelContent = styled.div`
  width: ${getSize(180)};
  padding: ${getSize(8)};
`;

export const UserCard = styled.div`
  margin-bottom: ${getSize(8)};
`;

export const AuditsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSize(5)};
`;
