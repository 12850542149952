import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDebouncedCallback } from '@viz/hooks';
import { useMemo, useEffect } from 'react';
import {
  ChannelFilters,
  ChannelSort,
  Channel as ChannelProps
} from 'stream-chat';

import { ChannelTypes } from '../../components/PatientFolder/ClinicalDetails/PatientChannels/PatientChannels';
import { useStreamChat } from '../../context/StreamChatContext';

interface patientChannelsProps {
  patientId: string;
  defaultCounter?: number;
}

export const usePatientChannels = ({
  patientId,
  defaultCounter
}: patientChannelsProps) => {
  const { client } = useStreamChat();
  const queryClient = useQueryClient();
  const filters: ChannelFilters = useMemo(
    () => ({
      patient_id: patientId,
      type: ChannelTypes.PATIENT_CONVERSATION
    }),
    [patientId]
  );
  const sort: ChannelSort = useMemo(() => [{ last_message_at: -1 }], []);

  const fetchChannels = useMemo(
    () => async () => {
      if (!patientId || !client) return;
      const options = {
        watch: true,
        state: true,
        message_limit: 1
      };
      try {
        const channels = (await client.queryChannels(
          filters,
          sort,
          options
        )) as ChannelProps[];
        return {
          channels: channels,
          totalUnreadMessages: channels.reduce(
            (sum, channel) => sum + (channel.state.unreadCount || 0),
            0
          )
        };
      } catch (error) {
        console.error('queryChannels failed', error);
      }
    },
    [patientId, client, filters, sort]
  );
  const {
    data: channels = {
      channels: [],
      totalUnreadMessages: 0
    }
  } = useQuery(
    ['channels', patientId],

    fetchChannels,
    {
      refetchOnWindowFocus: true,
      refetchInterval: false, //300000,
      enabled: !!patientId && !!client,
      keepPreviousData: true,
      retry: 3,
      staleTime: 10000
    }
  );

  const handleMessageRead = () => {
    queryClient.invalidateQueries(['channels', patientId]);
  };
  const debounceMessageRead = useDebouncedCallback(handleMessageRead, 500);

  useEffect(() => {
    if (client) {
      client.on('message.read', debounceMessageRead);
    }

    return () => {
      if (client) {
        client.off('message.read', debounceMessageRead);
      }
    };
  }, [client, queryClient, debounceMessageRead]);

  const totalUnreadMessages = useMemo(
    () => (!!client ? channels.totalUnreadMessages : defaultCounter || 0),
    [client, channels, defaultCounter]
  );

  return {
    channels: channels.channels,
    totalUnreadMessages: totalUnreadMessages,
    filters,
    sort
  };
};
