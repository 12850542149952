import { useSaveNotificationToken } from '@viz/api';
import { logger } from '@viz/logging';
import { getDeviceUid } from '@viz/utils';

import { browserStorage } from '../../managers';

export const useNotificationToken = () => {
  const { mutate: saveToken } = useSaveNotificationToken();

  const saveNotificationToken = (newToken?: string) => {
    let notificationToken;
    if (newToken) {
      browserStorage.notificationsToken.set(newToken);
      notificationToken = newToken;
    } else {
      notificationToken = browserStorage.notificationsToken.get();
    }

    if (Notification.permission !== 'granted') {
      logger.info(
        'saveNotificationToken > Notification permission not granted'
      );
      return;
    }

    const csrfAccessToken = browserStorage.csrfAccessToken.get();

    if (csrfAccessToken) {
      saveToken({
        device_uid: getDeviceUid(),
        push_token: notificationToken
      });
    }
  };

  return { saveNotificationToken };
};
