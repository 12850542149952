import {
  Dialog,
  EmptyContent,
  getFontSize,
  getSize,
  getSpacing,
  screens
} from '@viz/design-system';
import styled, { css } from 'styled-components';

export const settingsContainerSize = css`
  width: ${getSize(175)};

  @media ${screens.medium} {
    width: ${getSize(150)};
  }
`;

export const StyledSettingsDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 90vw;
    max-height: 90vh;
    height: ${getSize(200)};
    width: ${getSize(320)};
    ${getFontSize('base')};
  }

  .MuiDialogContent-root {
    padding: ${getSpacing(2)} ${getSpacing(2)} 0 0;
  }
`;

export const StyledDialogContent = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
`;

export const StyledSettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: overlay;
`;

export const StyledContainer = styled.div`
  height: 100%;
  margin-top: ${getSpacing(1)};
  margin-right: ${getSize(20)};
`;

export const StyledEmptyContent = styled(EmptyContent)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
