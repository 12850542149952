import { getBaseColor, getFontWeight } from '@viz/design-system';
import styled from 'styled-components';

export const Badge = styled.div<{ type: 'added' | 'removed' }>`
  display: inline-block;
  padding: 4px;
  ${getFontWeight('font-weight-bold-500')};
  border-radius: 4px;
  background-color: ${(props) =>
    props.type === 'added' ? getBaseColor('info') : getBaseColor('waterloo')};
  color: ${getBaseColor('porcelain')};
  font-size: 12px;
  line-break: 1;
  font-weight: ${getFontWeight('font-weight-bold-600')};
  text-transform: uppercase;
`;

export const ValueContainer = styled.div<{ type?: 'added' | 'removed' }>`
  padding: 8px 0;
  color: ${(props) =>
    props.type === 'removed'
      ? getBaseColor('french-gray')
      : getBaseColor('porcelain')};

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${getBaseColor('waterloo')};
  }

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: ${(props) =>
      props.type === 'removed'
        ? getFontWeight('font-weight-bold-600')
        : getFontWeight('font-weight-bold-700')};
    span {
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }
  }

  .subtitle {
    font-size: 12px;
    color: ${getBaseColor('french-gray')};
  }
`;
