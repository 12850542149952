import { Grid, getSpacing } from '@viz/design-system';
import styled from 'styled-components';

import { ResultCounter } from '../../components';

export const StyledGrid = styled(Grid)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const StyledResultCounter = styled(ResultCounter)`
  margin-bottom: ${getSpacing(9)};
`;

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
