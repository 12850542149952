import {
  EllipsisTextTooltip,
  IconProfileConnected,
  IconProfileNotConnected,
  IconSizes
} from '@viz/design-system';

import { useUserProfile } from '../../../hooks';
import {
  IconContainer,
  IconProfileContainer,
  MenuBarContainer,
  StyledArrowIcon,
  UserDetailsContainer,
  UserName
} from './styles';

type UserDetailsProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isConnected: boolean;
  isMenuOpen: boolean;
  testId: string;
};

const UserDetails = ({
  onClick,
  isConnected,
  isMenuOpen,
  testId
}: UserDetailsProps): JSX.Element => {
  const { user } = useUserProfile();
  const userName = user?.name;

  return (
    <MenuBarContainer data-testid={testId} onClick={onClick}>
      <IconProfileContainer>
        {isConnected ? (
          <IconProfileConnected size={IconSizes.LARGE} />
        ) : (
          <IconProfileNotConnected size={IconSizes.LARGE} />
        )}
      </IconProfileContainer>
      <UserDetailsContainer>
        <EllipsisTextTooltip>
          <UserName>{userName}</UserName>
        </EllipsisTextTooltip>
      </UserDetailsContainer>
      <IconContainer>
        <StyledArrowIcon size={IconSizes.X_SMALL} isDown={!isMenuOpen} />
      </IconContainer>
    </MenuBarContainer>
  );
};

export { UserDetails };
