import { VerifyMfaCodeErrorReason } from '@viz/api';
import { CodeInput, Typography } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { KeyboardEvent, useEffect, useState } from 'react';

import { NormalizedAuthenticationError } from '../../hooks/useLoginUser/utils';
import { ResendMfaCode } from './ResendMfaCode';
import {
  StyledAlert,
  StyledBtn,
  StyledGrid,
  StyledGridBox,
  StyledResendMfaCodeContainer,
  StyledSubmitContainer,
  StyledTitle,
  StyledTitleContainer
} from './styles';

export type MfaProps = {
  phoneNumber: string;
  error?: NormalizedAuthenticationError | null;
  onVerifyMfaCode: (code: string) => void;
  clearError: () => void;
  resendMfaCode: () => void;
};

const isSixDigitRegex = /^\d{6}$/;
const ENTER_KEY = 'Enter';

const Mfa = ({
  phoneNumber,
  error,
  onVerifyMfaCode,
  clearError,
  resendMfaCode
}: MfaProps): JSX.Element => {
  const [code, setCode] = useState('');
  const [canResendCode, setCanResendCode] = useState(true);

  const mfaErrorExists = Boolean(error);
  const isButtonDisabled = mfaErrorExists || !isSixDigitRegex.test(code);

  useEffect(() => {
    if (error) {
      const showResendCode =
        error.reason === VerifyMfaCodeErrorReason.WRONG_CODE;
      setCanResendCode(showResendCode);
    }
  }, [error]);

  const onEnter = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (!isButtonDisabled && event.key === ENTER_KEY) {
      event.preventDefault();
      onVerifyMfaCode(code);
    }
  };

  const handleMfaCodeChange = (code: string): void => {
    setCode(code);
    clearError();
  };

  return (
    <StyledGrid container onKeyDown={onEnter}>
      <StyledGridBox item sm={6} lg={6} xl={6}>
        <StyledTitleContainer>
          <StyledTitle fontSize="2xl" testId="mfa-title">
            {localize('authenticateYourAccount')}
          </StyledTitle>
          <Typography fontSize="sm" testId="mfa-message">
            {`${localize('authorizationCodeMessage')} ${phoneNumber}`}
          </Typography>
        </StyledTitleContainer>
        <CodeInput
          autoFocus
          value={code}
          onChange={handleMfaCodeChange}
          isValid={!mfaErrorExists}
          testId="mfa-input"
        />
        <StyledSubmitContainer>
          <div>
            <StyledBtn
              variant="contained"
              color="primary"
              testId="mfa-button"
              onClick={() => onVerifyMfaCode(code)}
              disabled={isButtonDisabled}
            >
              {localize('next')}
            </StyledBtn>
            <StyledAlert
              isVisible={mfaErrorExists}
              text={error?.message}
              type="error"
              testId="mfa-alert"
            />
          </div>
          {canResendCode && (
            <StyledResendMfaCodeContainer>
              <ResendMfaCode
                onSendMfaCode={resendMfaCode}
                testId="login-resend-code"
              />
            </StyledResendMfaCodeContainer>
          )}
        </StyledSubmitContainer>
      </StyledGridBox>
    </StyledGrid>
  );
};

export { Mfa };
