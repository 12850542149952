import { getIconSize, IconSizes } from '@viz/design-system';
import styled from 'styled-components';

type IconContainerProps = {
  dangerouslySetInnerHTML: { __html: string };
  $size: IconSizes;
};

export const IconContainer = styled.div<IconContainerProps>`
  & svg {
    ${(props) => getIconSize(props.$size)};
  }
`;
