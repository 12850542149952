import {
  IconCircledCheck,
  IconCircledCross,
  getBaseColor,
  Table,
  getSize
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledCenteredCell = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFailIcon = styled(IconCircledCross)`
  & path {
    fill: ${getBaseColor('error')};
  }
`;

export const StyledSuccessIcon = styled(IconCircledCheck)`
  & path {
    fill: ${getBaseColor('success')};
  }
`;

export const StyledUsersTable = styled(Table)`
  & .MuiTableCell-root {
    height: 46px;
    vertical-align: middle;
    padding: 0 ${getSize(2)};
  }
`;
