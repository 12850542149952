import { Patient, PatientListPatient } from '@viz/api';
import { IconSizes, TextCopyButton } from '@viz/design-system';
import { logger } from '@viz/logging';
import { removeLeadingZeros } from '@viz/utils';
import { ReactElement } from 'react';

import { AnalyticsEventName, useAnalyticsEvent } from '../../hooks';

export type CopyMRNProps = {
  children: ReactElement | string;
  patient: Patient | PatientListPatient;
  className?: string;
  iconSize: IconSizes;
  location: 'patient_list' | 'patient_folder' | 'viewer';
  testId: string;
};

const CopyMRN = ({
  patient,
  className,
  iconSize,
  location,
  testId,
  children
}: CopyMRNProps): JSX.Element => {
  const { sendEvent } = useAnalyticsEvent();

  if (!patient.medicalRecordNumber) return <>{children}</>;

  const eventPayload = {
    patient_id: patient.id,
    di_patient_name: patient.diName,
    di_patient_mrn: patient.diMedicalRecordNumber,
    institution: patient.institutionName,
    location
  };

  const sendCopyEvent = () => {
    sendEvent(
      AnalyticsEventName.COPY_MRN_BUTTON_CLICKED_SUCCESSFUL,
      eventPayload
    );
  };

  const sendCopyErrorEvent = (error: unknown) => {
    const loggerErrorPayload = {
      details: { error_message: (error as Error).message, ...eventPayload }
    };
    sendEvent(AnalyticsEventName.COPY_MRN_BUTTON_CLICKED_FAILED, eventPayload);
    logger.error(
      AnalyticsEventName.COPY_MRN_BUTTON_CLICKED_FAILED,
      loggerErrorPayload
    );
  };

  return (
    <TextCopyButton
      textToCopy={removeLeadingZeros(patient.medicalRecordNumber)}
      className={className}
      description={'MRN'}
      iconSize={iconSize}
      onCopy={sendCopyEvent}
      onError={(error) => sendCopyErrorEvent(error)}
      testId={testId}
    >
      {children}
    </TextCopyButton>
  );
};

export { CopyMRN };
