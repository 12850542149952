import { localize } from '@viz/i18n';

export const getDefaultHeaders = () => [
  { id: 'icon', value: '' },
  { id: 'name', value: localize('name'), isPhi: true },
  { id: 'age', value: localize('age'), isPhi: true },
  { id: 'gender', value: localize('gender'), isPhi: true },
  { id: 'mrn', value: localize('mrn'), isPhi: true },
  { id: 'desc', value: localize('description') },
  { id: 'studyDate', value: localize('studyDate') },
  { id: 'institution', value: localize('institution') }
];

export const getHermesTrialHeaders = () => [
  { id: 'icon', value: '' },
  { id: 'name', value: localize('name'), isPhi: true },
  { id: 'age', value: localize('age'), isPhi: true },
  { id: 'gender', value: localize('gender'), isPhi: true },
  { id: 'mrn', value: localize('mrn'), isPhi: true },
  { id: 'appointmentDate', value: localize('nextAppointment') },
  { id: 'institution', value: localize('institution') }
];
