import { DocumentationResponseData } from '@viz/api';
import { capitalize } from '@viz/utils';

import {
  AnalyticsEventMethod,
  AnalyticsEventName,
  useAnalyticsEvent,
  useOnMountEvent
} from '../../hooks';
import { API_URL } from '../../types';
import { Links, LinksContainer, StyledLink, Title } from './style';

enum UrlFormat {
  LegalSection,
  UserManual
}

const getDocumentHref = (
  format: number,
  dropboxUrl: string,
  htmlUrlSuffix: string
): string => {
  return format === UrlFormat.UserManual
    ? dropboxUrl
    : `${API_URL}/${htmlUrlSuffix}`;
};

type DocumentationProps = {
  documentation: DocumentationResponseData;
};

const Documents = (props: DocumentationProps): JSX.Element => {
  const { sendEvent } = useAnalyticsEvent();
  const isEmpty = props.documentation.length < 0;

  useOnMountEvent(AnalyticsEventName.DOCUMENTATION_SECTION_VIEWED);

  const onDocumentClick = (format: number): void => {
    const eventName =
      format === UrlFormat.UserManual
        ? AnalyticsEventName.PRODUCT_USER_MANUAL_VIEWED
        : AnalyticsEventName.PRODUCT_LEGAL_SECTION_VIEWED;

    sendEvent(eventName, {
      method: AnalyticsEventMethod.ON_DOCUMENT_CLICK
    });
  };

  return (
    <>
      {!isEmpty &&
        props.documentation.map(({ title, docs }) => (
          <LinksContainer key={title}>
            <Title>{capitalize(title.toLowerCase())}</Title>
            <Links>
              {docs.map(({ format, dropbox_url, name, url }) => (
                <StyledLink
                  color={'textSecondary'}
                  key={name}
                  target="_blank"
                  testId={name}
                  href={getDocumentHref(format, dropbox_url, url)}
                  onClick={() => onDocumentClick(format)}
                >
                  {name}
                </StyledLink>
              ))}
            </Links>
          </LinksContainer>
        ))}
    </>
  );
};
export default Documents;
