import { createContext, useState } from 'react';

import { UseProfileSettingsOptions } from '../../../hooks/useProfileSettings';
import { ProfileDetails } from './ProfileDetails';
import { VerifyPhoneNumberDialog } from './VerifyPhoneNumberDialog';
import { StyledProfileSettings } from './styles';

export const PhoneNumberContext = createContext({
  phoneNumber: '',
  setPhoneNumber: (phoneNumber: string) => {}
});

type ProfileSettingsProps = {
  profileSettingsData: UseProfileSettingsOptions;
};

const ProfileSettings = ({
  profileSettingsData: { user, phoneNumber, setPhoneNumber, onUpdatePhone }
}: ProfileSettingsProps): JSX.Element => {
  const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false);

  const handleUpdatePhone = () => {
    onUpdatePhone();
    setIsVerifyDialogOpen(false);
  };

  const handleCloseVerifyPhoneDialog = () => {
    if (user) setPhoneNumber(user.phone);
    setIsVerifyDialogOpen(false);
  };

  if (!user) return <></>;

  return (
    <PhoneNumberContext.Provider value={{ phoneNumber, setPhoneNumber }}>
      <StyledProfileSettings>
        <ProfileDetails
          name={user.name}
          email={user.email}
          initialPhone={user.phone}
          onVerifyPhoneNumber={() => setIsVerifyDialogOpen(true)}
          data-testid="profile-settings"
        />
        <VerifyPhoneNumberDialog
          number={phoneNumber}
          isOpen={isVerifyDialogOpen}
          onUpdate={handleUpdatePhone}
          onClose={handleCloseVerifyPhoneDialog}
        />
      </StyledProfileSettings>
    </PhoneNumberContext.Provider>
  );
};

export default ProfileSettings;
