import { UserGroup } from '@viz/api';

const getInstitutionIdFromUserGroup = (
  userGroup: number,
  userGroups: UserGroup[] | undefined
) => {
  const userGroupDetails = userGroups?.find((ug) => ug.id === userGroup);
  return userGroupDetails?.associatedInstitutions;
};

export const getValidUserGroupsBasedOnCurrentInstitutions = (
  newInstitutions: number[],
  selectedUserGroups: number[] | undefined,
  userGroups: UserGroup[] | undefined
) => {
  let newUserGroups: number[] = [];

  selectedUserGroups?.forEach((userGroup) => {
    const associatedInstitutionsIds = getInstitutionIdFromUserGroup(
      userGroup,
      userGroups
    );
    if (
      associatedInstitutionsIds &&
      newInstitutions.some((selectedInstitution) =>
        associatedInstitutionsIds?.includes(selectedInstitution)
      )
    ) {
      newUserGroups.push(userGroup);
    }
  });
  return newUserGroups;
};
