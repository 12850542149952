import { Patient, useUserInstitutionSettings } from '@viz/api';
import { getDeviceUid } from '@viz/utils';

import { AnalyticsEventName, useAnalyticsEvent } from '../../../../../hooks';

export const useClinicalInfoEhrSectionClickedAnalyticsEvent = () => {
  const deviceUid = getDeviceUid();
  const { sendEvent } = useAnalyticsEvent();
  const { data: userInstitution } = useUserInstitutionSettings({
    device_uid: deviceUid
  });

  return (isExpanded: boolean, sectionName: string, patient: Patient) => {
    sendEvent(AnalyticsEventName.CLINICAL_INFO_EHR_SECTION_CLICKED, {
      action_type: isExpanded ? 'open' : 'close',
      section_name: sectionName,
      viz_institution_id: userInstitution?.all_institutions.viz_institution_id,
      viz_institution_name: patient.institutionName,
      viz_patient_id: patient.id
    });
  };
};
