import {
  NotificationSetting,
  NotificationSetting as NotificationSettingData,
  notificationSettingsTransform,
  useResetNotificationSettings,
  useUpdateNotificationSettings,
  useUserNotificationSettings
} from '@viz/api';
import { UpdateNotificationSettingsPayload } from '@viz/api/src/settings/types';
import { getDeviceUid } from '@viz/utils';
import { useState } from 'react';

import { browserStorage } from '../../managers';
import { AnalyticsEventName, useAnalyticsEvent } from '../useAnalytics';

type UseNotificationSettingsProps = {
  enabled: boolean;
};

export type UseNotificationSettingsOptions = {
  notificationSettings?: NotificationSetting[];
  isChangesSaved: boolean;
  onUpdate: (updateProps: UpdateNotificationSettingsProps) => void;
  onRestoreToDefault: () => void;
  setIsChangeSaved: (isSaved: boolean) => void;
};

export type UpdateNotificationSettingsProps = {
  notification: NotificationSettingData;
  criteriaId: string;
  value: number | boolean;
  settingType: UpdateNotificationSettingsPayload['setting_type'];
};

export const useNotificationSettings = ({
  enabled
}: UseNotificationSettingsProps): UseNotificationSettingsOptions => {
  const { sendEvent } = useAnalyticsEvent();
  const [isChangesSaved, setIsChangeSaved] = useState(false);

  const getUserWorkspace = function () {
    return String(browserStorage.clientConfig.get().default_user_workspace);
  };

  const { data: notificationSettings, refetch: refetchNotificationSettings } =
    useUserNotificationSettings(
      { device_uid: getDeviceUid(), user_workspace: getUserWorkspace() },
      {
        enabled,
        refetchOnWindowFocus: false,
        select: notificationSettingsTransform
      }
    );

  const { mutate: updateNotificationSettings } = useUpdateNotificationSettings({
    onSuccess: () => {
      refetchNotificationSettings().then(() => setIsChangeSaved(true));
    }
  });

  const { mutate: resetNotificationSettings } = useResetNotificationSettings({
    onSuccess: () => {
      refetchNotificationSettings().then(() => setIsChangeSaved(true));
    }
  });

  const onUpdate = ({
    notification,
    criteriaId,
    settingType,
    value
  }: UpdateNotificationSettingsProps) => {
    updateNotificationSettings(
      {
        setting_type: settingType,
        category_type: notification.type,
        category_id: notification.id,
        criteria_id: criteriaId,
        device_uid: getDeviceUid(),
        value: value,
        user_workspace: getUserWorkspace()
      },
      {
        onSuccess: () => {
          sendEvent(AnalyticsEventName.NOTIFICATION_SETTINGS_UPDATED, {
            notification_types: [notification.name]
          });
        }
      }
    );
  };

  const onRestoreToDefault = () => {
    resetNotificationSettings(
      { device_uid: getDeviceUid(), user_workspace: getUserWorkspace() },
      {
        onSuccess: () => {
          sendEvent(AnalyticsEventName.NOTIFICATION_SETTINGS_RESET_CLICKED);
        }
      }
    );
  };

  return {
    notificationSettings,
    isChangesSaved,
    onUpdate,
    onRestoreToDefault,
    setIsChangeSaved
  };
};
