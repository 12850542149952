import { getBaseColor, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const AppLogo = styled.a<{ isRecruit: boolean }>`
  cursor: pointer;
  display: flex;
  padding: ${getSize(5)};
  background: ${({ isRecruit }) =>
    isRecruit ? getBaseColor('white') : 'unset'};
`;

export const LogoAndTabsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuAndSearchInput = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  min-width: max-content;
  flex: 1;
`;
