import { Series, SeriesMetadata } from '@viz/api';
import { getActiveLanguage } from '@viz/i18n';
import { getDateAndTime, URLBuilder } from '@viz/utils';

import { VIEWER_URL } from './constants';
import { MessageMetadata, ViewportOverlay } from './messages/types';
import { PatientViewerDetails } from './types';

export const createIframeUrl = () => {
  return new URLBuilder({ path: VIEWER_URL }).build();
};

const getBoundaries = (
  seriesMetadata: SeriesMetadata,
  series: Series
): { startIndex: number; endIndex: number } => {
  const { imageHighlights = [] } = series;
  const { chunksMetaData } = seriesMetadata;

  const firstHighlight = imageHighlights[0];

  let startIndex;
  let endIndex;

  if (firstHighlight?.imageEnd > 0) {
    startIndex = firstHighlight.imageStart;
    endIndex = firstHighlight.imageEnd;
  } else {
    startIndex = chunksMetaData.keyImageStart;
    endIndex = chunksMetaData.keyImageEnd;
  }

  return { startIndex, endIndex };
};

const getSlabThickness = (
  startIndex: number,
  endIndex: number,
  sliceThickness: number
): number => {
  return (endIndex - startIndex) * sliceThickness * 0.5;
};

export const getViewportOverlay = (
  patientViewerDetails: PatientViewerDetails
): ViewportOverlay => ({
  patientName: patientViewerDetails.name,
  patientId: patientViewerDetails.accessionNumber,
  studyDate: getDateAndTime(patientViewerDetails.lastArrivalTs, {
    formatting: 'MMM dd yyyy',
    language: getActiveLanguage()
  }),
  studyTime: getDateAndTime(patientViewerDetails.lastArrivalTs, {
    formatting: 'HH:mm:ss',
    language: getActiveLanguage()
  })
});

export const getViewerMetadata = (
  seriesMetadata: SeriesMetadata,
  series: Series,
  patientViewerDetails: PatientViewerDetails
): MessageMetadata => {
  const { isHighlighted, sliceThickness } = series;
  const { preset } = seriesMetadata;

  const voi = {
    windowCenter: preset.center,
    windowWidth: preset.width
  };

  const { startIndex, endIndex } = getBoundaries(seriesMetadata, series);
  const slabThickness = getSlabThickness(startIndex, endIndex, sliceThickness);

  return {
    voi,
    isHighlighted,
    slabThickness,
    sliceIndex: startIndex,
    viewportOverlay: getViewportOverlay(patientViewerDetails),
    volumeOrientation: series.orientation
  };
};
