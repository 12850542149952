import { Patient, usePatientEhrData } from '@viz/api';
import { IconSizes, Loading } from '@viz/design-system';

import { withVisibility } from '../../../../hocs';
import { browserStorage } from '../../../../managers';
import { StyledLoadingPatient } from '../../styles';
import { PatientEhrInfo } from './PatientEhrInfo';
import { StaffNotes } from './StaffNotes/StaffNotes';
import { StyledClinicalInfo } from './styles';

export interface ClinicalInfoProps {
  patient: Patient;
  enableEdit: boolean;
  onChangePatientStaffNotes: () => void;
  patientTabId?: number;
  testId?: string;
  isVisible: boolean;
}

export const ClinicalInfo = withVisibility((props: ClinicalInfoProps) => {
  const { patient, isVisible } = props;
  const clientConfig = browserStorage.clientConfig.get();

  const { clinicalInfo: staffNotesItems } = patient;
  const { data: ehrInfo, isFetching } = usePatientEhrData(
    {
      enabled: isVisible && clientConfig.ehr_viewing_state,
      refetchOnWindowFocus: false,
      initialData: {
        patient_id: patient.id,
        data: []
      }
    },
    { patientId: patient.id }
  );

  return (
    <StyledClinicalInfo>
      {!isFetching ? (
        <>
          <PatientEhrInfo tree={ehrInfo} />
          <StaffNotes
            items={staffNotesItems}
            onSubmit={props.onChangePatientStaffNotes}
            enableEdit={props.enableEdit}
            patientTabId={props.patientTabId}
            patient={props.patient}
            testId={'staff-notes'}
          />
        </>
      ) : (
        <StyledLoadingPatient>
          <Loading size={IconSizes.LARGE} testId="patient-loader" />
        </StyledLoadingPatient>
      )}
    </StyledClinicalInfo>
  );
});
