import { User, useUserAuditLogs } from '@viz/api';
import { IconSizes, Loading, SidePanel } from '@viz/design-system';
import { useEffect, useState } from 'react';

import { useAnalyticsEvent } from '../../../../../../hooks';
import { Title } from '../../components/TitleBar/styles';
import {
  sendAuditLogFieldOpenedEvent,
  sendAuditLogOpenedEvent,
  sendAuditLogPanelOpenedEvent
} from '../../utils';
import { LoadingContainer } from '../common/panelStyles';
import AuditLogItem from './AuditLogItem/AuditLogItem';
import { EventsContext } from './AuditLogItem/events-context';
import { auditLogsMapper } from './audit-log-mapper/audit-logs-mapper';
import {
  AuditsContainer,
  PanelContent,
  UserCard,
  UserNameHeader
} from './styles';
import { AuditLogViewItem } from './types';

export interface UserAuditLogPanelProps {
  user: User;
  opened: boolean;
  onClose: () => void;
}

const UserAuditLogPanel = ({
  user,
  opened,
  onClose
}: UserAuditLogPanelProps) => {
  const { sendEvent } = useAnalyticsEvent();
  const [auditLogs, setAuditLogs] = useState<AuditLogViewItem[]>([]);
  const resp = useUserAuditLogs({
    offset: 0,
    limit: 50,
    entityType: 'USER',
    entityId: user.userUid
  });

  useEffect(() => {
    sendAuditLogPanelOpenedEvent(sendEvent, user.userUid);
  }, [sendEvent, user.userUid]);

  useEffect(() => {
    if (resp.data) {
      setAuditLogs(auditLogsMapper(resp.data.items));
    }
  }, [resp.data]);

  return (
    <EventsContext.Provider
      value={{
        onAuditOpened: (logId, date) =>
          sendAuditLogOpenedEvent(sendEvent, user.userUid, logId, date),
        onFieldOpened: (logId, fieldId, date) =>
          sendAuditLogFieldOpenedEvent(
            sendEvent,
            user.userUid,
            logId,
            fieldId,
            date
          )
      }}
    >
      <SidePanel
        open={opened}
        onClose={onClose}
        titleBar={<Title>Audit Log</Title>}
      >
        <PanelContent>
          <UserCard>
            <UserNameHeader>
              {user.firstName}&nbsp;{user.lastName}
            </UserNameHeader>
            <div>email: {user.email}</div>
          </UserCard>

          {resp.isLoading && (
            <LoadingContainer width="100%">
              <Loading size={IconSizes.XX_LARGE} />
            </LoadingContainer>
          )}
          {!resp.isLoading && (
            <AuditsContainer>
              {auditLogs.map((log) => (
                <AuditLogItem key={log.log.id} auditLog={log} />
              ))}
            </AuditsContainer>
          )}
        </PanelContent>
      </SidePanel>
    </EventsContext.Provider>
  );
};

export default UserAuditLogPanel;
