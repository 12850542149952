import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getFontWeight,
  getSize,
  getSpacing,
  IconArrowDown,
  Menu
} from '@viz/design-system';
import TextInput from '@viz/design-system/src/form/TextInput/TextInput';
import styled, { css } from 'styled-components';

export const StyledSharePatient = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(4)};
  padding-left: ${getSpacing(6)};
  margin-bottom: ${getSpacing(6)};
`;

export const StyledReferralDetails = styled.div`
  display: flex;
  gap: ${getSpacing(2)};
  color: ${getBaseColor('french-gray')};

  & path {
    fill: ${getBaseColor('french-gray')} !important;
  }
`;

export const StyledSelectors = styled.div`
  display: flex;
  gap: ${getSpacing(2)};
`;

export const StyledMenuBar = styled.div`
  color: ${getBaseColor('french-gray')};
  border-radius: ${getBorderRadius('SMALL')};
  background-color: ${getBaseColor('blue-zodiac')};
  height: ${getSize(8)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: ${getSpacing(2)};

  &:hover {
    background-color: ${getBaseColor('trout')};
  }

  & label {
    padding: ${getSpacing(0)} ${getSpacing(2)};
  }
`;

const StyledTopTriangle = `
  &:before {
    content: '';
    position: absolute;
    top: ${getSize(-2.25)};
    right: ${getSize(6.25)};
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 18px solid ${getBaseColor('blue-zodiac')}
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-top: ${getSize(1)};
    border-radius: ${getBorderRadius('SMALL')};
    color: ${getBaseColor('french-gray')};
    background-color: ${getBaseColor('blue-zodiac')};
    overflow: visible;
    padding: 0;
    ${StyledTopTriangle}
  }
`;

export const StyledMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${getSize(2)} ${getSize(3)};
  cursor: pointer;
  min-width: ${getSize(26.5)};

  &:hover {
    background-color: ${getBaseColor('rhino-blue')};
    color: ${getBaseColor('porcelain')};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${getSpacing(4)} ${getSpacing(0)};
`;

export const StyledArrowIcon = styled(IconArrowDown)<{ isDown: boolean }>(
  ({ theme, isDown }) => {
    const { duration, easing } = theme.transitions;

    return css`
      transform: rotate(${isDown ? 0 : 180}deg);
      transition: transform ${duration.shortest}ms ${easing.easeInOut} 0ms;
    `;
  }
);

export const StyledDialogContent = styled.div`
  ${getFontSize('base')}
`;

export const StyledDescription = styled.div`
  padding: ${getSize(3)};
  padding-top: ${getSize(5)};
  background-color: ${getBaseColor('blue-mako')};
  border-radius: ${getBorderRadius('SMALL')};
`;

export const StyledTextInput = styled(TextInput)`
  width: ${getSize(138)};

  & .MuiInputBase-root {
    padding: ${getSize(3)};
    margin: ${getSize(2)} 0;
  }
`;

export const StyledTextContainer = styled.div`
  margin-top: ${getSize(4)};
`;

export const StyledPatientInfoContainer = styled.div`
  position: relative;
  z-index: 1;
  top: ${getSize(2)};
`;

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledName = styled.span`
  font-weight: ${getFontWeight('font-weight-bold-700')};
  margin-left: ${getSize(1)};
`;
