import {
  getFontSize,
  getFontWeight,
  getSpacing,
  Button
} from '@viz/design-system';
import styled from 'styled-components';

export const PageTitleContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${getSpacing(7)};
`;

export const PageIconContainer = styled.div`
  margin-right: ${getSpacing(3)};
`;

export const TitleText = styled.div`
  flex-grow: 1;
  ${getFontSize('xl')};
  font-weight: ${getFontWeight('font-weight-bold-600')};
`;

export const ActionButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
`;
