import { DocumentationResponseData, useDocumentation } from '@viz/api';
import { IconDocument, IconSizes, Loading } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { Documents } from '../../components';
import {
  DocumentationLoading,
  SettingsGrid,
  StyledDocumentsContainer,
  StyledPageTitle,
  StyledWidget
} from './styles';

const Documentation = (): JSX.Element => {
  const docsQuery = useDocumentation();

  if (docsQuery.isLoading)
    return (
      <DocumentationLoading>
        <Loading size={IconSizes.LARGE} />
      </DocumentationLoading>
    );

  if (docsQuery.isError) return <h2>Something went wrong</h2>;

  const documentation = docsQuery.data! as DocumentationResponseData;

  return (
    <SettingsGrid>
      <StyledWidget>
        <StyledPageTitle
          title={localize('documentation')}
          icon={<IconDocument />}
        />
      </StyledWidget>
      <StyledDocumentsContainer>
        <Documents documentation={documentation} />
      </StyledDocumentsContainer>
    </SettingsGrid>
  );
};

export default Documentation;
