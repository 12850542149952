import { BrowserTabProps } from '@viz/design-system';
import { atom, atomFamily } from 'recoil';

import {
  IS_CONTACT_SUPPORT_DIALOG_OPEN,
  IS_PATIENT_FOLDER_VISIBLE,
  IS_SETTINGS_DIALOG_OPEN,
  IS_TROUBLESHOOTING_DIALOG_OPEN,
  IS_LOGOUT_DIALOG_OPEN,
  IS_VIEWER_VISIBLE,
  LOGIN_STEP,
  LoginStep,
  PATIENT_TAB_DIALOG_DATA,
  PATIENT_TABS,
  PATIENT_VIEWER_DATA,
  PatientFolderTabs,
  PatientTabDialogData,
  PatientViewerData,
  SELECTED_PATIENT_FOLDER_TAB,
  SELECTED_PATIENT_ID,
  SELECTED_DISTRIBUTION_LIST_TAB_ID,
  SELECTED_PATIENT_STATUS_INFO,
  SHOULD_CHECK_RENDERER,
  SPEC_STATUS_FILTER,
  Toast,
  TOAST_STATUS,
  CURRENT_PATIENT_LIST_URL_RELATIVE_PATH
} from './constants';
import { SelectedPatientStatusInfoState } from './types';

export const loginStepState = atom<LoginStep | null>({
  key: LOGIN_STEP,
  default: null
});

export const patientTabsState = atom<Array<BrowserTabProps>>({
  key: PATIENT_TABS,
  default: []
});

export const patientTabDialogState = atom<PatientTabDialogData | null>({
  key: PATIENT_TAB_DIALOG_DATA,
  default: null
});

export const selectedPatientIdState = atom<string>({
  key: SELECTED_PATIENT_ID,
  default: ''
});

export const selectedDistributionListTabIdState = atom<number | undefined>({
  key: SELECTED_DISTRIBUTION_LIST_TAB_ID,
  default: undefined
});

export const currentPatientListUrRelativePath = atom<string | undefined>({
  key: CURRENT_PATIENT_LIST_URL_RELATIVE_PATH,
  default: undefined
});

export const selectedPatientStatusInfoState = atom<
  SelectedPatientStatusInfoState | undefined
>({
  key: SELECTED_PATIENT_STATUS_INFO,
  default: undefined,
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue?.isStale) {
          newValue.onChangePatientStatus();
        }
      });
    }
  ]
});

export const patientFolderSelectedTabStateFamily = atomFamily<
  PatientFolderTabs | undefined,
  string
>({
  key: SELECTED_PATIENT_FOLDER_TAB,
  default: undefined
});

export const patientViewerDataStateFamily = atomFamily<
  PatientViewerData | null,
  string
>({
  key: PATIENT_VIEWER_DATA,
  default: null
});

export const isSettingsDialogOpenState = atom({
  key: IS_SETTINGS_DIALOG_OPEN,
  default: false
});

export const shouldCheckRendererState = atom({
  key: SHOULD_CHECK_RENDERER,
  default: false
});

export const isTroubleshootingDialogOpenState = atom({
  key: IS_TROUBLESHOOTING_DIALOG_OPEN,
  default: false
});

export const isLogoutDialogOpenState = atom({
  key: IS_LOGOUT_DIALOG_OPEN,
  default: false
});

export const toastStatusState = atom<Toast | null>({
  key: TOAST_STATUS,
  default: null
});

export const isContactSupportDialogOpenState = atom({
  key: IS_CONTACT_SUPPORT_DIALOG_OPEN,
  default: false
});

export const isViewerVisibleState = atom({
  key: IS_VIEWER_VISIBLE,
  default: false
});

export const specStatusFilterState = atom<number | undefined>({
  key: SPEC_STATUS_FILTER,
  default: undefined
});

export const isPatientFolderVisibleState = atom({
  key: IS_PATIENT_FOLDER_VISIBLE,
  default: false
});
