import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getSize
} from '@viz/design-system/src/ui';
import styled from 'styled-components';

export const StyledCriteriaEhrNotes = styled.div`
  padding-bottom: ${getSize(3)};
  background: #363a44;
  border-radius: ${getBorderRadius('SMALL')};
`;

export const StyledDateInfoNotes = styled.div`
  font-size: ${getFontSize('xs')};
  color: ${getBaseColor('santas-gray')};
  margin-top: ${getSize(2)};
  margin-bottom: ${getSize(2)};
`;

export const StyledEhrNote = styled.div`
  align-items: center;
  margin-top: ${getSize(1)};
  margin-bottom: ${getSize(1)};
  word-break: break-all;
`;

export const StyledReadMore = styled.span`
  color: ${getBaseColor('info')};
  cursor: pointer;
`;

export const StyledGrayedText = styled.div`
  color: ${getBaseColor('santas-gray')};
`;
