import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getSize,
  getSpacing,
  Link,
  screens
} from '@viz/design-system';
import styled, { css } from 'styled-components';

type StyledIframeProps = {
  $isLoaded: boolean;
};

export const StyledIframe = styled.iframe<StyledIframeProps>(
  ({ $isLoaded }) => css`
    ${!$isLoaded &&
    css`
      visibility: hidden;
    `};
    width: 100%;
    height: 100%;
    border: none;
    flex-grow: 1;
  `
);

export const ViewerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
`;

export const StyledLoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledViewerDisclaimer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: ${getSpacing(1)};
  color: ${getBaseColor('error')};
  ${getFontSize('sm')};
  display: flex;
  gap: ${getSpacing(1)};
  align-items: center;
  padding: ${getSpacing(1)};
  border-radius: ${getBorderRadius('SMALL')};
  background-color: ${getBaseColor('pure-black', 0.7)};
  pointer-events: none;

  @media ${screens.small} {
    align-items: flex-start;
    max-width: ${getSize(75)};
  }
`;

export const StyledReadMoreDisclaimer = styled(Link)`
  && {
    pointer-events: auto;
    margin-left: ${getSpacing(1)};
  }
`;
