import {
  getSize,
  getFontWeight,
  getFontSize,
  getBaseColor
} from '@viz/design-system';
import styled from 'styled-components';

export const RegionInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RegionInputLabel = styled.label`
  ${getFontWeight('font-weight-bold-500')}
  margin-bottom: ${getSize(1.5)};
  padding-left: ${getSize(2)};
  color: ${getBaseColor('french-gray')};
`;

export const RegionInputSubtitle = styled.div`
  ${getFontSize('xs')}
  margin-bottom: ${getSize(3)};
  padding-left: ${getSize(2)};
  color: ${getBaseColor('french-gray')};
`;
