import { InvitationAction, useUpdateInvitation } from '@viz/api';
import { localize } from '@viz/i18n';
import { useEffect } from 'react';

import { useAnalyticsEvent, useRouter } from '../../hooks';
import { Paths } from '../../navigation';
import { sendInvitationResponseEvent } from '../Admin/pages/users/utils';
import { ReturnToHomePageButton, StyledInvitationContainer } from './styles';

const Invitation = (): JSX.Element => {
  const router = useRouter();
  const { getUrlParam } = router;
  const action = getUrlParam('action') as InvitationAction;
  const invitationUid = getUrlParam('invitation_uid') as string;
  const { sendEvent } = useAnalyticsEvent();
  const { mutate: updateInvitation } = useUpdateInvitation();

  useEffect(() => {
    sendInvitationResponseEvent(sendEvent, action);
    updateInvitation({
      invitation_uid: invitationUid,
      action: action
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToHomePage = () => {
    router.navigate({ path: Paths.CASES });
  };

  return (
    <StyledInvitationContainer>
      {localize(
        action === InvitationAction.accept
          ? 'acceptInvitation'
          : 'rejectInvitation'
      )}
      <div>
        <ReturnToHomePageButton onClick={navigateToHomePage}>
          {localize('returnToHomePage')}
        </ReturnToHomePageButton>
      </div>
    </StyledInvitationContainer>
  );
};

export default Invitation;
