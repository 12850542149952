import { IconButton } from '@material-ui/core';
import {
  getBaseColor,
  getBorderRadius,
  getFontSize,
  getFontWeight,
  getSpacing,
  Widget
} from '@viz/design-system';
import styled, { css } from 'styled-components';

export const StyledStaffNotesSectionWidget = styled(Widget)`
  background-color: ${getBaseColor('blue-tuna')};
  padding: ${getSpacing(2)} ${getSpacing(7)} ${getSpacing(2)} ${getSpacing(5)};
`;

export const StyledStaffNotesSectionHeader = styled.div`
  padding: ${getSpacing(2)} 0 ${getSpacing(2)} 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSectionTitle = styled.span`
  font-weight: ${getFontWeight('font-weight-bold-600')};
  ${getFontSize('xl')};
  color: ${getBaseColor('porcelain')};
`;

export const StyledStaffNotesSectionBody = styled.div<{
  $enableFlexWrap: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    ${(props) =>
      props.$enableFlexWrap
        ? css({
            flex: '1 0 33.33%'
          })
        : css({ justifyContent: 'space-between', flexBasis: '100%' })}
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    padding: ${getSpacing(1)} ${getSpacing(2)} ${getSpacing(1)} ${getSpacing(1)};
    border-radius: ${getBorderRadius('SMALL')};
    display: flex;
    align-items: flex-start;

    .MuiTouchRipple-root {
      display: none;
    }
  }
`;
