import { Grid } from '@viz/design-system';
import styled from 'styled-components';

export const StyledClinicalDetails = styled(Grid)`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
`;

export const StyledTabContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;
