import { Subscriber } from './types';

class PubSub<M> {
  private readonly subscribers: Record<string, Subscriber<M>[]>;

  constructor() {
    this.subscribers = {};
  }

  subscribe(subject: string, subscriber: Subscriber<M>) {
    if (!this.subscribers[subject]) {
      this.subscribers[subject] = [];
    }
    this.subscribers[subject].push(subscriber);

    // In order to unsubscribe successfully we need the same instance of the subscriber function,
    // thus we return the unsubscribe function
    return () => {
      this.subscribers[subject] = this.subscribers[subject].filter(
        (s) => subscriber !== s
      );
    };
  }

  publish(subject: string, message: M) {
    if (this.subscribers[subject]) {
      this.subscribers[subject].forEach((subscriber) => subscriber(message));
    }
  }
}

export { PubSub };
