import {
  getBaseColor,
  getBorderRadius,
  getSpacing,
  Link,
  Snackbar,
  Typography
} from '@viz/design-system';
import styled from 'styled-components';

export const StyledSnackbar = styled(Snackbar)`
  margin-top: ${getSpacing(8)};
  border-radius: ${getBorderRadius('SMALL')};

  & .MuiSnackbarContent-root {
    padding: 0 ${getSpacing(2)};
    background-color: ${getBaseColor('warning')};
  }
  .MuiSnackbarContent-message {
    padding: 0;
  }
`;

export const StyledSnackbarContent = styled(Typography)`
  display: flex;
  align-items: center;
  padding: 0;
`;

export const StyledLink = styled(Link)`
  && {
    margin: 0 ${getSpacing(1)};
  }
`;
