import React from 'react';

import {
  ActionButton,
  PageIconContainer,
  PageTitleContainer,
  TitleText
} from './styles';
import { PageTitleProps } from './types';

const PageTitle = ({
  title,
  icon,
  buttonText,
  buttonIcon,
  onActionButtonClick,
  className
}: PageTitleProps): JSX.Element => {
  return (
    <PageTitleContainer className={className}>
      <PageIconContainer>{icon}</PageIconContainer>
      <TitleText data-testid="table-page-title-text">{title}</TitleText>
      {buttonText && (
        <ActionButton
          onClick={onActionButtonClick}
          size="medium"
          variant="contained"
          color="primary"
          startIcon={buttonIcon}
        >
          {buttonText}
        </ActionButton>
      )}
    </PageTitleContainer>
  );
};

export default PageTitle;
