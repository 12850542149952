import { AppToViewerEvent } from '../events';
import { BaseViewerMessage } from './base';
import { LoadMessagePayload } from './types';

class LoadSeriesMessage extends BaseViewerMessage<LoadMessagePayload> {
  constructor(payload: LoadMessagePayload) {
    super(AppToViewerEvent.LOAD_SERIES, payload);
  }
}

export { LoadSeriesMessage };
