import {
  getBaseColor,
  getFontWeight,
  getSize,
  getSpacing,
  IconArrowDown,
  Typography
} from '@viz/design-system';
import styled, { css } from 'styled-components';

export const MenuBarContainer = styled.div`
  color: ${getBaseColor('french-gray')};
  height: ${getSize(16)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: ${getSpacing(0)} ${getSpacing(2)};
  min-width: 10vw;

  &:hover {
    background-color: ${getBaseColor('blue-tuna')};
  }
`;

export const StyledArrowIcon = styled(IconArrowDown)<{ isDown: boolean }>(
  ({ theme, isDown }) => {
    const { duration, easing } = theme.transitions;

    return css`
      transform: rotate(${isDown ? 0 : 180}deg);
      transition: transform ${duration.shortest}ms ${easing.easeInOut} 0ms;
    `;
  }
);

export const UserName = styled(Typography)`
  && {
    font-weight: ${getFontWeight('font-weight-bold-600')};
  }
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: ${getSize(25)};
  text-align: left;
  margin-right: ${getSpacing(2)};
`;

export const IconProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${getSpacing(2)};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${getSpacing(0)} ${getSpacing(1)};
`;
