import { Patient } from '@viz/api';
import { useEcgWaveform } from '@viz/api/src/ecg';
import {
  EmptyContent,
  IconError,
  IconSizes,
  Loading
} from '@viz/design-system';
import { useDebounce } from '@viz/hooks';
import { localize } from '@viz/i18n';
import { capitalize } from '@viz/utils';
import { useEffect, useRef, useState } from 'react';

import { AnalyticsEventName, useAnalyticsEvent, ViewerType } from '../../hooks';
import { getDateSubtitle } from '../PatientFolder/ClinicalDetails/PatientStudies/StudySubtitle/StudySubtitle';
import ECGChart from './ECGChart/ECGChart';
import {
  StyledDisclaimer,
  StyledEcgInfo,
  StyledPatientName,
  StyledViewer
} from './styles';

const getInternalWidth = (element: HTMLElement) => {
  const computedStyle = getComputedStyle(element);
  const paddingLeft = parseFloat(computedStyle.paddingLeft);
  const paddingRight = parseFloat(computedStyle.paddingRight);
  return element.clientWidth - paddingLeft - paddingRight;
};

interface ECGViewerProps {
  ecgId: string;
  patientName?: string;
  lastArrivalTs?: number;
  patient?: Patient;
}

export const ECGViewer = ({
  patient,
  ecgId,
  patientName,
  lastArrivalTs
}: ECGViewerProps) => {
  const [chartWidth, setChartWidth] = useState(1100);
  const [zoom] = useState(1);
  const [panX] = useState(0);
  const [panY] = useState(0);
  const debouncedZoom = useDebounce(zoom, 10);
  const containerRef = useRef(null);
  const { data, isLoading, isError, refetch } = useEcgWaveform({ ecgId });
  const previousEcgId = useRef('');

  const { sendEvent } = useAnalyticsEvent();

  useEffect(() => {
    const currentRef = containerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const internalWidth = getInternalWidth(entry.target as HTMLElement);
        setChartWidth(internalWidth);
      }
    });

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (previousEcgId.current !== ecgId) {
      const patientParams = {
        patient_id: patient?.id,
        di_patient_name: patient?.diName,
        di_patient_mrn: patient?.diMedicalRecordNumber,
        institution: patient?.institutionName
      };

      sendEvent(AnalyticsEventName.PATIENT_ECG_VIEWED, {
        ecg_id: ecgId,
        ...patientParams
      });
      sendEvent(AnalyticsEventName.PATIENT_SERIES_VIEWED, {
        viewer_type: ViewerType.ECG,
        viz_series_id: ecgId,
        ...patientParams
      });
      previousEcgId.current = ecgId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecgId, patient]);

  return (
    <StyledViewer ref={containerRef}>
      {isLoading && <Loading size={IconSizes.X_LARGE} />}
      {isError && (
        <EmptyContent
          title={localize('viewerError')}
          subTitle={localize('viewerTryAgain')}
          icon={<IconError />}
          buttonDetails={{
            testId: 'try-again-button',
            text: capitalize(localize('tryAgain')),
            onClick: () => refetch()
          }}
        />
      )}
      {data && (
        <>
          <StyledEcgInfo>
            <StyledPatientName data-testid="ecg-viewer-patient-name">
              {patientName}
            </StyledPatientName>
            <div data-testid="ecg-viewer-study-date">
              {lastArrivalTs && getDateSubtitle(new Date(lastArrivalTs))}{' '}
            </div>
          </StyledEcgInfo>
          <ECGChart
            waveform={data.waveform}
            width={chartWidth}
            zoom={debouncedZoom}
            panX={panX}
            panY={panY}
          />
          <StyledDisclaimer data-testid="ecg-viewer-disclamer">
            {localize('ecgDisclaimer')}
          </StyledDisclaimer>
        </>
      )}
    </StyledViewer>
  );
};
