import {
  PAGER_NOTIFICATION_TITLE,
  PushNotificationType,
  useNotifications,
  UseNotificationsOptions,
  VizFirebaseNotification
} from '@viz/hooks';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  AnalyticsEventName,
  getNotificationType,
  useAnalyticsEvent,
  useDeepLinkNotification,
  useNotificationToken
} from '../../hooks';
import { notificationsManager } from '../../managers';
import { VizNotification } from './VizNotification';
import { StyledToastContainer } from './styles';

type VizNotificationOptions = UseNotificationsOptions<VizFirebaseNotification>;

const NOTIFICATION_DURATION = 15000;
const TOAST_CONTAINER_ID = 'notificationCenterToastContainer';

const NotificationsCenter = (): JSX.Element => {
  const { saveNotificationToken } = useNotificationToken();
  const { handleNotificationClick } = useDeepLinkNotification();
  const { sendEvent } = useAnalyticsEvent();

  const onTokenFetched: VizNotificationOptions['onTokenFetched'] = (token) => {
    saveNotificationToken(token);
  };

  const onNotification: VizNotificationOptions['onNotification'] = (
    notification
  ) => {
    const {
      title,
      body,
      push_notification_type,
      push_notification_id: notificationId
    } = notification;

    const notificationType =
      title === PAGER_NOTIFICATION_TITLE
        ? PushNotificationType.PAGER
        : push_notification_type;

    toast(
      <VizNotification
        title={title}
        body={body}
        onClick={() =>
          handleNotificationClick({ notificationId, notificationType })
        }
      />,
      { containerId: TOAST_CONTAINER_ID }
    );

    sendEvent(AnalyticsEventName.PUSH_NOTIFICATION_RECEIVED, {
      notification_type: getNotificationType(notificationType),
      notification_uid: notificationId,
      timestamp: Date.now()
    });

    notificationsManager.publish(notificationType, notification);
  };

  const onError: VizNotificationOptions['onError'] = (error) => {
    switch (error.code) {
      case 'NoPermission':
        console.error('Please allow notifications for this website', error);
        break;
      case 'UnknownError':
        console.error(
          'An error occurred while retrieving Firebase token.',
          error
        );
        break;
    }
  };

  useNotifications<VizFirebaseNotification>({
    onTokenFetched,
    onNotification,
    onError
  });

  return (
    <StyledToastContainer
      enableMultiContainer
      containerId={TOAST_CONTAINER_ID}
      autoClose={NOTIFICATION_DURATION}
      hideProgressBar
      closeOnClick
      draggable={false}
      transition={Slide}
    />
  );
};

export { NotificationsCenter };
