import { useClientConfig, useRefreshAccessToken } from '@viz/api';

import { browserStorage } from '../../managers';
import { useUserProfile } from '../useUserProfile';

export interface AuthDetails {
  isAuth: boolean;
  isLoading: boolean;
  encryptionKey?: string;
  refreshAccessToken: () => void;
}

const useAuth = (): AuthDetails => {
  const { user, isLoading: isLoadingUserProfile } = useUserProfile();
  const isAuth = Boolean(user);

  const { refetch: refreshAccessToken } = useRefreshAccessToken({
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      browserStorage.csrfAccessToken.set(data.csrf_access_token);
      browserStorage.accessTokenExpiration.set(data.access_expiration);
      browserStorage.encryptionKey.set(data.encryption_key);
    }
  });

  const { isInitialLoading: isLoadingClientConfig } = useClientConfig({
    enabled: isAuth,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onSuccess: (data) => {
      browserStorage.clientConfig.set(data);
    }
  });

  return {
    isAuth,
    isLoading: isLoadingUserProfile || isLoadingClientConfig,
    encryptionKey: browserStorage.encryptionKey.get(),
    refreshAccessToken
  };
};

export { useAuth };
