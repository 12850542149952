import { NotificationSettingAction } from '@viz/api';
import { localize } from '@viz/i18n';

import { StyledInputNumberError, StyledNumberInput } from './styles';

type NotificationSettingThresholdProps = {
  action: NotificationSettingAction;
  onChange: (value: number) => void;
};

const NotificationSettingThreshold = ({
  action: {
    criteriaId,
    currentValue,
    minValue = Number.MIN_SAFE_INTEGER,
    maxValue = Number.MAX_SAFE_INTEGER,
    resolutionValidValue = 1
  },
  onChange
}: NotificationSettingThresholdProps) => {
  return (
    <StyledNumberInput
      value={currentValue}
      helperText={
        <StyledInputNumberError>
          {localize('inputNumberError', [minValue, maxValue])}
        </StyledInputNumberError>
      }
      min={minValue}
      max={maxValue}
      step={resolutionValidValue}
      name="notification-setting-threshold"
      onChange={onChange}
      testId={`notification-number-input-${criteriaId}`}
    />
  );
};

export { NotificationSettingThreshold };
