import { PropsWithChildren, useState } from 'react';

import {
  ExpendableSectionContainer,
  SectionContent,
  SectionTitle,
  TriangleRight
} from './styles';

export interface ExpendableSectionProps extends PropsWithChildren<any> {
  title: string;
  onOpen: () => void;
}

const ExpendableSection = ({
  title,
  onOpen,
  children
}: ExpendableSectionProps) => {
  const [isExtended, setIsExtended] = useState(false);

  return (
    <ExpendableSectionContainer extended={isExtended}>
      <SectionTitle
        onClick={() => {
          setIsExtended(!isExtended);
          !isExtended && onOpen();
        }}
      >
        <TriangleRight />
        <span>{title}</span>
      </SectionTitle>
      <SectionContent>{children}</SectionContent>
    </ExpendableSectionContainer>
  );
};

export default ExpendableSection;
