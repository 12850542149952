import { IconSizes, Loading } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useRecoilValue } from 'recoil';

import { LoginUserContainer, Mfa, UserPolicies } from '../../components';
import { AnalyticsEventName, useLoginUser, useOnMountEvent } from '../../hooks';
import { loginStepState } from '../../store';
import { APP_VERSION } from '../../types';
import {
  AppLogo,
  LoginGrid,
  LoginPage,
  LoginSideBox,
  StyledContainer,
  StyledVersionNumber
} from './styles';

const Login = (): JSX.Element => {
  useOnMountEvent(AnalyticsEventName.LOGIN_SCREEN_VIEWED);
  const loginUser = useLoginUser();
  const loginStep = useRecoilValue(loginStepState);

  const {
    phoneNumber,
    authenticationError,
    verifyMfa,
    resetLoginError,
    resendMfaCode
  } = loginUser;

  const getLoginStepComponent = () => {
    switch (loginStep) {
      case 'username':
      case 'password':
        return <LoginUserContainer loginUser={loginUser} />;
      case 'mfa':
        return (
          <Mfa
            onVerifyMfaCode={verifyMfa}
            phoneNumber={phoneNumber}
            error={authenticationError}
            clearError={resetLoginError}
            resendMfaCode={resendMfaCode}
          />
        );
      case 'policies':
        return <UserPolicies />;
      default:
        return (
          <StyledContainer>
            <Loading size={IconSizes.MEDIUM} />
          </StyledContainer>
        );
    }
  };

  return (
    <LoginPage>
      <LoginGrid container>
        <LoginGrid item md={4}>
          <LoginSideBox>
            <AppLogo data-testid="app-logo" />
          </LoginSideBox>
        </LoginGrid>
        <LoginGrid item sm={12} md={8}>
          {getLoginStepComponent()}
          <StyledVersionNumber
            testId="login-page-version-number-text"
            fontSize="sm"
          >
            {localize('version')}: {APP_VERSION}
          </StyledVersionNumber>
        </LoginGrid>
      </LoginGrid>
    </LoginPage>
  );
};

export default Login;
