import { getSize, EmptyContent, getSpacing } from '@viz/design-system';
import styled from 'styled-components';

export const StyledPatientChannelsContainer = styled.div`
  width: 100%;
  min-height: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow-y: auto;

  .str-chat,
  .str-chat__channel-list-messenger,
  .str-chat__channel-list-messenger__main {
    height: 100%;
    width: 100%;
    display: flex;
  }
`;

export const StyledCustomChannel = styled.div`
  width: 100%;
`;

export const StyledLoaderContainer = styled.div`
  margin: auto;
`;
export const StyledEmptyContent = styled(EmptyContent)`
  padding: ${getSize(4)};
  max-width: 300px;
  text-align: center;
  width: 100%;
  margin: auto;
`;

export const StyledTabWithBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    padding: ${getSpacing(3)};
  }
`;

export const StyledBadge = styled.div`
  position: relative;

  .MuiBadge-badge {
    position: relative;
    top: 0;
    right: 0;
    transform: translate(0, 0);
  }
`;
