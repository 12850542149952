import { ListItemIcon, ListItemText } from '@material-ui/core';
import {
  IconButton,
  IconSizes,
  MenuItem,
  MoreVert,
  IconHistory
} from '@viz/design-system';
import React, { useState } from 'react';

import { StyledActionsMenu } from './styles';

interface ActionsCellProps {
  onOpenAuditLog: () => void;
}

const ActionsCell = ({ onOpenAuditLog }: ActionsCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  return (
    <>
      <IconButton
        data-testid="menu-button"
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <MoreVert size={IconSizes.SMALL} />
      </IconButton>
      <StyledActionsMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          data-testid="menu-item-audit-log"
          onClick={(e) => {
            handleMenuClose(e);
            onOpenAuditLog();
          }}
        >
          <ListItemIcon>
            <IconHistory />
          </ListItemIcon>
          <ListItemText>Open Audit log</ListItemText>
        </MenuItem>
      </StyledActionsMenu>
    </>
  );
};

export default ActionsCell;
