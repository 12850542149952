import { AuditAction, AuditLog, AuditLogWithMetadata } from '@viz/api';
import { IconLock, IconRemoved } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { AuditLogViewItem, FieldItem } from '../types';
import {
  booleanFieldValueMapper,
  nameIdFieldMapper,
  sortFieldByOrder,
  stringFieldMapper,
  userGroupFieldMapper
} from './mapper-utils';

export const fieldsOrder = [
  'email',
  'firstName',
  'middleName',
  'lastName',
  'phone',
  'roleId',
  'title',
  'suffix',
  'customerAccounts',
  'mainCustomerAccount',
  'institutions',
  'userGroups',
  'region',
  'capabilities',
  'resetPassword',
  'removeUser'
];

export function auditLogsMapper(auditLogs: AuditLog[]): AuditLogViewItem[] {
  return auditLogs.map((log) => {
    const fields: FieldItem[] = [];
    const capabilitiesField: FieldItem = {
      id: 'capabilities',
      title: localize('capabilities'),
      values: []
    };

    switch (log.action) {
      case AuditAction.CREATE:
      case AuditAction.UPDATE:
        for (const field of (log as AuditLogWithMetadata).metadata.fields) {
          switch (field.fieldId) {
            case 'firstName':
            case 'middleName':
            case 'lastName':
            case 'email':
            case 'phone':
            case 'title':
            case 'suffix':
            case 'region':
              fields.push(stringFieldMapper(localize(field.fieldId), field));
              break;
            case 'roleId':
              fields.push(stringFieldMapper(localize('position'), field));
              break;
            case 'institutions':
            case 'mainCustomerAccount':
            case 'customerAccounts':
              fields.push(nameIdFieldMapper(localize(field.fieldId), field));
              break;
            case 'userGroups':
              fields.push(userGroupFieldMapper(localize(field.fieldId), field));
              break;
            case 'commercialWorkspace':
            case 'ableToSeeReps':
            case 'recruitWorkspace':
            case 'ableToSeePhi':
            case 'internalVizUserEnabled':
            case 'ctpAifRecalculateOnAppEnabled':
            case 'canViewVizAnalytics':
              capabilitiesField.values.push(
                booleanFieldValueMapper(localize(field.fieldId), field)
              );
              break;
            case 'sendWelcomeEmail':
              fields.push({
                id: field.fieldId,
                title: localize(field.fieldId),
                values: [
                  {
                    title: localize('welcomeEmailWasSentMessage')
                  }
                ]
              });
              break;
            case 'featureStatusBar':
            case 'viewerOnly':
            case 'notifyOnPatientsComment':
              console.error('unsupported field. skipping', field.fieldId);
              break;
          }
        }
        break;
      case AuditAction.RESET_PASSWORD:
        fields.push({
          id: 'resetPassword',
          title: localize('resetPassword'),
          values: [
            {
              title: (
                <>
                  <IconLock />
                  {localize('resetPasswordAuditMessage')}
                </>
              )
            }
          ]
        });
        break;
      case AuditAction.DELETE:
        fields.push({
          id: 'removeUser',
          title: localize('removeUser'),
          values: [
            {
              title: (
                <>
                  <IconRemoved />
                  {localize('userRemovedAuditMessage')}
                </>
              )
            }
          ]
        });
        break;
    }

    if (capabilitiesField.values.length) {
      fields.push(capabilitiesField);
    }

    return {
      log,

      // fields should be ordered by the way that they appear in the user form
      fields: sortFieldByOrder(fields, fieldsOrder)
    };
  });
}
