import { PushNotificationType } from '@viz/hooks';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { Paths } from '../../navigation';
import { shouldCheckRendererState, loginStepState } from '../../store';
import { useAuth } from '../useAuth';
import { useDeepLinkNotification } from '../useDeepLinkNotification';
import { useNotificationToken } from '../useNotificationToken';
import { usePolicies } from '../usePolicies';
import { useRouter } from '../useRouter';

const useLoginWatcher = (): void => {
  const { isAuth } = useAuth();
  const { signedPolicies } = usePolicies();
  const setLoginStep = useSetRecoilState(loginStepState);
  const setShouldCheckRenderer = useSetRecoilState(shouldCheckRendererState);

  const router = useRouter();
  const { saveNotificationToken } = useNotificationToken();
  const { handleNotificationClick } = useDeepLinkNotification();

  useEffect(() => {
    if (!isAuth) {
      setLoginStep('username');
    }
  }, [isAuth, setLoginStep]);

  useEffect(() => {
    if (isAuth && signedPolicies) {
      const notificationId = router.getUrlParam('notification', false);
      saveNotificationToken();

      if (notificationId) {
        const notificationType = router.getUrlParam(
          'type',
          false
        ) as PushNotificationType;
        handleNotificationClick({ notificationId, notificationType });
      } else {
        setShouldCheckRenderer(true);
        router.navigate({ path: router.referer || Paths.CASES });
      }

      setLoginStep(null);
    }

    /* This useEffect should be triggered only on two occasions:
     *  1. The user has authenticated.
     *  2. The signed policies value has changed - user signed policies.
     *  On any other occasion, this shouldn't be triggered since this handles
     * the redirection to a path that the user might have requested prior to the login process. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, signedPolicies]);
};

export default useLoginWatcher;
