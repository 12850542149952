import { localize } from '@viz/i18n';

export const getUsersTableHeaders = () => [
  { id: 'name', value: localize('name') },
  { id: 'email', value: localize('email') },
  { id: 'institution', value: localize('institution') },
  { id: 'position', value: localize('position') },
  { id: 'phone', value: localize('phone') },
  { id: 'isActive', value: localize('active') },
  { id: 'menuCell', value: '' }
];
