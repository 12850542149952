import { patientDataModification, usePatient } from '@viz/api';
import { EmptyContent, IconMessages, IconPatient } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useParams } from 'react-router-dom';

import { VizLoading } from '../../components';
import { PatientChat } from '../../components/PatientFolder/ClinicalDetails/PatientChat';
import PatientInfo from '../../components/PatientFolder/PatientInfo';
import { useUnsupportedViewportOverlay } from '../../hooks';
import { LoadingWrapper, Wrapper } from './styles';

const Chat = () => {
  const { patientId } = useParams<{ patientId: string }>();

  const { setIsOverlayEnabled } = useUnsupportedViewportOverlay();
  setIsOverlayEnabled(false);

  const { data: patient, status } = usePatient(
    {
      enabled: Boolean(patientId),
      select: patientDataModification
    },
    { patientId }
  );

  if (status === 'loading') {
    return (
      <LoadingWrapper>
        <VizLoading />
      </LoadingWrapper>
    );
  }

  if (status === 'error') {
    return (
      <EmptyContent
        title={localize('errorDisplayingChatMessages')}
        icon={<IconMessages />}
        size="large"
      />
    );
  }

  return patient ? (
    <Wrapper>
      <PatientInfo patient={patient} />
      <PatientChat patient={patient} allowSpecialMessages={false} />
    </Wrapper>
  ) : (
    <EmptyContent
      title={localize('noPatientFound')}
      icon={<IconPatient />}
      size="large"
    />
  );
};

export default Chat;
