import { Waveform } from '@viz/api/src/ecg';

import { LARGE_LINE_TIME } from './consts';

const defaultSampleRate = 1000;

export const plotECGPoints = (
  voltages: number[],
  scale: { x: number; y: number }
) => {
  const points: number[][] = [];

  voltages.forEach((volt, index) => {
    const x = Number((index * scale.x).toFixed(2));
    const y = Number((volt * scale.y).toFixed(2)) * -1; // Invert the y-axis
    points.push([x, y]);
  });

  return points;
};

const insertCalibrationData = (voltages: number[], sampleRate: number) => {
  const sampleTimeUnit = defaultSampleRate / sampleRate;
  const samplesPerLargeSquare = Math.ceil(LARGE_LINE_TIME / sampleTimeUnit);
  const calibrationData = [
    ...Array(samplesPerLargeSquare).fill(0), // Zero voltage for the first 200 microseconds
    ...Array(samplesPerLargeSquare).fill(1) // Voltage of 1 for the next 200 microseconds
  ];

  return [
    ...calibrationData, // Start with the calibration data
    ...voltages.slice(2 * samplesPerLargeSquare) // Skip the first 400 microseconds of the original data
  ];
};

export const transformLeads = (waveform: Waveform, sampleSize: number) => {
  return [
    [
      waveform.leads.I,
      waveform.leads.aVR,
      waveform.leads.V1,
      waveform.leads.V4
    ],
    [
      {
        ...waveform.leads.II,
        voltages: waveform.leads.II.voltages.slice(0, sampleSize)
      },
      waveform.leads.aVL,
      waveform.leads.V2,
      waveform.leads.V5
    ],
    [
      waveform.leads.III,
      waveform.leads.aVF,
      waveform.leads.V3,
      waveform.leads.V6
    ],
    [
      {
        ...waveform.leads.II,
        voltages: insertCalibrationData(
          waveform.leads.II.voltages,
          waveform.sample_rate
        )
      }
    ]
  ];
};
