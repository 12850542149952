import {
  AutocompleteRenderInputParams,
  Button,
  IconSearch,
  IconSizes,
  Input,
  Switch
} from '@viz/design-system';
import { localize } from '@viz/i18n';
import { ChangeEvent, useContext } from 'react';

import { ExpandedInstitutionsContext } from '../InstitutionSettings';
import {
  StyledInstitutionFilterInfo,
  StyledInstitutionSettingsTopBar,
  StyledSearchBox,
  StyledSearchContainer,
  StyledSelectAllButtonContainer,
  StyledSwitchContainer,
  SwitchLabel
} from './styles';

type InstitutionFilterTopBarProps = {
  isAllSelected?: boolean;
  searchText: string;
  onSearch: (text: string) => void;
  onSelectAll: (isSelected: boolean) => void;
};

const InstitutionSettingsTopBar = ({
  isAllSelected,
  searchText,
  onSearch,
  onSelectAll
}: InstitutionFilterTopBarProps): JSX.Element => {
  const { expandedInstitutions, setExpandedInstitutions } = useContext(
    ExpandedInstitutionsContext
  );

  const isAllExpanded = Array.from(expandedInstitutions.values()).every(
    (value) => Boolean(value)
  );

  const handleToggleExpandAll = () => {
    setExpandedInstitutions(
      new Map(
        Array.from(expandedInstitutions, ([key]) => [key, !isAllExpanded])
      )
    );
  };

  return (
    <StyledInstitutionSettingsTopBar>
      <StyledSearchContainer>
        <StyledSearchBox
          freeSolo
          options={[]}
          inputValue={searchText}
          testId="institution-settings-search"
          onInputChange={(event: ChangeEvent<InputEvent>, value: string) =>
            onSearch(value)
          }
          renderInput={(params: AutocompleteRenderInputParams) => (
            <Input
              {...params}
              placeholder="Search"
              InputProps={{
                ...params.InputProps,
                startAdornment: <IconSearch size={IconSizes.SMALL} />
              }}
            />
          )}
        />
        <StyledSelectAllButtonContainer>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleToggleExpandAll}
            testId="expand-collapse-institutions-button"
          >
            {localize(isAllExpanded ? 'collapseAll' : 'expandAll')}
          </Button>
          <StyledSwitchContainer>
            <Switch
              testId="select-all-institutions-switch"
              checked={isAllSelected}
              onChange={(e) => onSelectAll(e.target.checked)}
            />
            <SwitchLabel>{localize('selectAll')}</SwitchLabel>
          </StyledSwitchContainer>
        </StyledSelectAllButtonContainer>
      </StyledSearchContainer>
      <StyledInstitutionFilterInfo>
        {localize('institutionFilterInfo')}:
      </StyledInstitutionFilterInfo>
    </StyledInstitutionSettingsTopBar>
  );
};

export { InstitutionSettingsTopBar };
