import { AppToViewerEvent } from '../events';
import { BaseViewerMessage } from './base';
import { InitMessagePayload } from './types';

class InitMessage extends BaseViewerMessage<InitMessagePayload> {
  constructor(payload: InitMessagePayload) {
    super(AppToViewerEvent.INITIALIZE, payload);
  }
}

export { InitMessage };
