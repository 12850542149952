import { Dialog, getBaseColor, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledSharePatientDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: ${getSize(200)};
  }

  & .MuiButton-containedPrimary {
    background: ${getBaseColor('error')};
    width: ${getSize(26)};
    &:hover {
      background: ${getBaseColor('brick-red')} !important;
    }
  }
`;
