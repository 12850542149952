import styled from 'styled-components';

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubtitleRow = styled.div<{ extended: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    text-decoration: ${(props) => (props.extended ? 'underline' : 'none')};
  }
`;

export const SubtitleText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 560px;
`;
