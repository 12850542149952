import { IconSizes, Link } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { capitalize } from '@viz/utils';

import { StyledSuccessIcon, StyledText } from './styles';
import { ResetPasswordStep } from './types';

type TextByStep = {
  successText: string;
  bodyText: string;
  resendLinkHeader: string;
  resendLinkLabel: string;
};

type SentSuccessfullyContentProps = {
  resetPasswordStep:
    | typeof ResetPasswordStep.SentToEmail
    | typeof ResetPasswordStep.SentToMobile;
  userEmail: string;
  userPhoneNumber: string | null;
  resendLink: () => void;
  isLoading: boolean;
};

const SentSuccessfullyContent = (
  props: SentSuccessfullyContentProps
): JSX.Element => {
  const getTextByStep = (): TextByStep => {
    switch (props.resetPasswordStep) {
      case ResetPasswordStep.SentToEmail:
        return {
          successText: props.userEmail,
          bodyText: localize('anEmail'),
          resendLinkHeader: `${localize('phone')}: ${props.userPhoneNumber}`,
          resendLinkLabel: capitalize(localize('textMessage'))
        };

      case ResetPasswordStep.SentToMobile:
        return {
          successText: props.userPhoneNumber!,
          bodyText: localize('aTextMessage').toLocaleLowerCase(),
          resendLinkHeader: `${localize('email')}: ${props.userEmail}`,
          resendLinkLabel: localize('email')
        };
    }
  };

  const { successText, bodyText, resendLinkHeader, resendLinkLabel } =
    getTextByStep();

  return (
    <>
      <StyledText fontWeight={600} align="center">
        <StyledSuccessIcon size={IconSizes.SMALL} />
        {localize('sentSuccessfullyTo')}: {successText}
      </StyledText>

      <StyledText fontWeight={600} marginTop={6}>
        {localize('followupInstructions', bodyText)}
      </StyledText>
      <StyledText fontWeight={300}>
        {localize('validExistingPassword')}.
      </StyledText>

      <StyledText fontWeight={300} marginTop={8} marginBottom={8}>
        {localize('linkExpiration')}.
      </StyledText>

      {props.userPhoneNumber &&
        props.resetPasswordStep === ResetPasswordStep.SentToEmail && (
          <StyledText fontWeight={500} flexDirection="column">
            <div>{resendLinkHeader}</div>
            {props.isLoading ? (
              <div>{localize('sending')}...</div>
            ) : (
              <Link underline="always" onClick={props.resendLink}>
                {localize('sendVia')} {resendLinkLabel}
              </Link>
            )}
          </StyledText>
        )}
    </>
  );
};

export { SentSuccessfullyContent };
