import { useProfile } from '@viz/api';
import { logger as loggingService } from '@viz/logging';
import { useEffect } from 'react';

import { browserStorage } from '../../managers';

interface User {
  able_to_see_phi: boolean;
  allowed_user_workspaces: Array<number>;
  email: string;
  name: string;
  phone: string;
  role_id: string;
  user_uid: string;
  username: string;
  main_customer_account?: string;
}

export interface UserProfileDetails {
  user?: User;
  isLoading: boolean;
  removeCache: () => void;
}

const useUserProfile = (): UserProfileDetails => {
  const canFetchProfile = Boolean(browserStorage.csrfAccessToken.get());
  const getProfile = useProfile({
    enabled: canFetchProfile,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false
  });
  const { data: userProfile } = getProfile;

  const isLoadingUserProfile =
    getProfile.isInitialLoading ||
    (canFetchProfile &&
      getProfile.fetchStatus === 'idle' &&
      getProfile.status === 'loading');

  useEffect(() => {
    const { data } = getProfile;
    if (data) {
      const { user_uid, email, name } = data;
      loggingService.identifyUser({
        user_uid,
        user_email: email,
        user_name: name
      });
    }
  }, [getProfile, getProfile.data]);

  return {
    user: userProfile,
    removeCache: getProfile.remove,
    isLoading: isLoadingUserProfile
  };
};

export { useUserProfile };
