import { UserDetails, UserRestrictedFields } from '@viz/api';
import { localize } from '@viz/i18n';

import { RestrictedFieldHelper } from '../../../components';
import { IsAdminValue } from './components';
import {
  DetailItem,
  DetailsSection,
  DetailTitle,
  LogGrid,
  LogsSection,
  LogsTitle,
  Title,
  TopicList,
  Value
} from './styles';

interface UserLogsProps {
  user: UserDetails;
  restrictedFields?: Array<UserRestrictedFields>;
  className?: string;
}

const formatLastLoginDate = (lastLogin: string) =>
  lastLogin?.split('.')[0].replace('T', ' ');

const UserLogs = ({ user, restrictedFields, className }: UserLogsProps) => {
  const {
    userUid,
    isAdmin,
    userLogs: {
      lastLogin,
      recentFailed2fa,
      recent2faCodesSent,
      failedLoginAttemptsApp,
      failedLoginAttemptsUM
    }
  } = user;

  return (
    <TopicList className={className}>
      <DetailsSection>
        <DetailItem>
          <DetailTitle>{localize('logsUserId')}</DetailTitle>
          <Value>{userUid}</Value>
        </DetailItem>
        <RestrictedFieldHelper
          restrictedFields={restrictedFields}
          fieldName="isAdmin"
        >
          <DetailItem>
            <DetailTitle>{localize('logsAdmin')}</DetailTitle>
            <IsAdminValue isAdmin={isAdmin} />
          </DetailItem>
        </RestrictedFieldHelper>
        <DetailItem>
          <DetailTitle>{localize('logsLastLogin')}</DetailTitle>
          <Value>{formatLastLoginDate(lastLogin)}</Value>
        </DetailItem>
      </DetailsSection>
      <LogsSection>
        <LogsTitle>{localize('logs')}</LogsTitle>
        <LogGrid>
          <Title>{localize('logsRecentFailedLogins')}</Title>
          <Value>{failedLoginAttemptsApp ?? 0}</Value>
          <Title>{localize('logsRecentFailed2fas')}</Title>
          <Value>{recentFailed2fa ?? 0}</Value>
          <Title>{localize('logsRecent2faCodesSent')}</Title>
          <Value>{recent2faCodesSent ?? 0}</Value>
          <Title>{localize('logsFailedLoginAttemptsUM')}</Title>
          <Value>{failedLoginAttemptsUM ?? 0}</Value>
        </LogGrid>
      </LogsSection>
    </TopicList>
  );
};

export default UserLogs;
