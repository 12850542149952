import { Alert, CirclePlusIcon, IconSizes, Button } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { ClinicalInfoEmptyStateLayout } from './styles';

interface StaffNotesEmptyContentProps {
  enableEdit: boolean;
  showError: boolean;
  onClickEdit: () => void;
}

export const StaffNotesEmptyContent = (props: StaffNotesEmptyContentProps) => {
  return (
    <>
      <ClinicalInfoEmptyStateLayout>
        <span data-testid={'empty-staff-notes'}>
          {localize('noStaffNotes')}
        </span>
        {props.enableEdit && (
          <Button
            role={'add-staff-note'}
            color="secondary"
            variant="outlined"
            onClick={props.onClickEdit}
            startIcon={<CirclePlusIcon size={IconSizes.X_SMALL} />}
          >
            {localize('addNotes')}
          </Button>
        )}
      </ClinicalInfoEmptyStateLayout>
      {props.showError && (
        <Alert
          type="error"
          text={localize('errorDisplayingClinicalInformation')}
        />
      )}
    </>
  );
};
