import { FC } from 'react';
import { RouteProps } from 'react-router';

import { AdminLayout } from '../layout';
import { Users } from '../pages';
import { Paths } from './';

export type LayoutRoute = {
  layout: FC;
  isProtected: boolean;
  routes: RouteProps[];
};

const routes: LayoutRoute[] = [
  {
    layout: AdminLayout,
    isProtected: true,
    routes: [
      {
        path: Paths.USERS,
        component: Users
      }
    ]
  }
];

export default routes;
