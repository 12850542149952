import {
  IconInfoError,
  Item as SectionItem,
  SideNavigation
} from '@viz/design-system';
import { localize, TranslationKey } from '@viz/i18n';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import {
  AnalyticsEventName,
  useAnalyticsEvent,
  useInstitutionSettings,
  useNotificationSettings
} from '../../hooks';
import { useProfileSettings } from '../../hooks/useProfileSettings';
import { isSettingsDialogOpenState } from '../../store';
import { InstitutionSettings } from './InstitutionSettings';
import { NotificationSettings } from './NotificationSettings';
import { ProfileSettings } from './ProfileSettings';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';
import {
  StyledContainer,
  StyledDialogContent,
  StyledEmptyContent,
  StyledSettingsContainer,
  StyledSettingsDialog
} from './styles';

const SettingsDialog = (): JSX.Element => {
  const { sendEvent } = useAnalyticsEvent();
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useRecoilState(
    isSettingsDialogOpenState
  );
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] =
    useState(false);

  const institutionSettingsData = useInstitutionSettings({
    enabled: isSettingsDialogOpen
  });
  const notificationSettingsData = useNotificationSettings({
    enabled: isSettingsDialogOpen && Notification.permission === 'granted'
  });
  const profileSettingsData = useProfileSettings({
    enabled: isSettingsDialogOpen
  });

  const MenuSections: Array<SectionItem<TranslationKey>> = [
    { value: 'webNotifications', label: localize('webNotifications') },
    { value: 'institutionFilter', label: localize('institutionFilter') },
    { value: 'myProfile', label: localize('myProfile') }
  ];

  const [activeSection, setActiveSection] = useState<
    SectionItem<TranslationKey>['value']
  >(MenuSections[0].value);

  useEffect(() => {
    if (isSettingsDialogOpen) {
      sendEvent(AnalyticsEventName.APP_SETTINGS_VIEWED);
    }
  }, [isSettingsDialogOpen, sendEvent]);

  useEffect(() => {
    if (isSettingsDialogOpen) {
      switch (activeSection) {
        case 'webNotifications':
          sendEvent(AnalyticsEventName.NOTIFICATION_SETTINGS_VIEWED);
          break;
        case 'institutionFilter':
          sendEvent(AnalyticsEventName.INSTITUTION_FILTER_SETTINGS_VIEWED);
          break;
        case 'myProfile':
          sendEvent(AnalyticsEventName.PHONE_NUMBER_SETTINGS_CLICKED);
          break;
      }
    }
  }, [activeSection, isSettingsDialogOpen, sendEvent]);

  const isUnsavedSettings =
    institutionSettingsData.isSavingChanges ||
    institutionSettingsData.isSettingsChanged;

  const onClose = () => {
    if (!isUnsavedSettings) {
      setIsSettingsDialogOpen(false);
    } else if (institutionSettingsData.isSettingsChanged) {
      setIsUnsavedChangesDialogOpen(true);
    }
  };

  const handleSectionChange = (section: TranslationKey) => {
    if (!isUnsavedSettings) {
      setActiveSection(section);
    } else if (institutionSettingsData.isSettingsChanged) {
      setIsUnsavedChangesDialogOpen(true);
    }
  };

  const getActiveSectionComponent = () => {
    switch (activeSection) {
      case 'webNotifications':
        return Notification.permission === 'granted' ? (
          <NotificationSettings
            notificationSettingsData={notificationSettingsData}
          />
        ) : (
          <StyledEmptyContent
            icon={<IconInfoError />}
            title={localize('pleaseAllowBrowserNotifications')}
          />
        );
      case 'institutionFilter':
        return (
          <InstitutionSettings
            institutionSettingsData={institutionSettingsData}
          />
        );
      case 'myProfile':
        return <ProfileSettings profileSettingsData={profileSettingsData} />;
    }
  };

  return (
    <>
      <StyledSettingsDialog
        open={isSettingsDialogOpen}
        onClose={onClose}
        title={localize('settings')}
        withCloseIcon
      >
        <StyledDialogContent>
          <SideNavigation
            items={MenuSections}
            activeItem={activeSection}
            onClick={(section) =>
              handleSectionChange(section as TranslationKey)
            }
            testId="settings-side-navigation"
          />
          <StyledSettingsContainer>
            <StyledContainer>{getActiveSectionComponent()}</StyledContainer>
          </StyledSettingsContainer>
        </StyledDialogContent>
      </StyledSettingsDialog>
      <UnsavedChangesDialog
        open={isUnsavedChangesDialogOpen}
        onClose={() => {
          setIsUnsavedChangesDialogOpen(false);
        }}
      />
    </>
  );
};

export { SettingsDialog };
