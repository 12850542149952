import { InstitutionSetting } from '@viz/api';

import { getSubInstitutionsData } from '../../../../../hooks';
import { StyledSelectedSubInstitutions } from './styles';

type SelectedInstitutionsSubtitleProps = {
  subInstitutions: InstitutionSetting[];
};

const SelectedInstitutionsSubtitle = ({
  subInstitutions
}: SelectedInstitutionsSubtitleProps): JSX.Element => {
  const selectedSubInstitutions = getSubInstitutionsData<string>(
    subInstitutions,
    true,
    'name'
  );

  if (selectedSubInstitutions.length === 0) return <></>;

  return (
    <StyledSelectedSubInstitutions>
      {selectedSubInstitutions.map((subInstitution, index) => (
        <span key={subInstitution}>
          {subInstitution}
          {index !== selectedSubInstitutions.length - 1 ? ' | ' : ''}
        </span>
      ))}
    </StyledSelectedSubInstitutions>
  );
};

export { SelectedInstitutionsSubtitle };
