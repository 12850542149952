import { Dialog } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { StyledUnsavedChangesDialogContent } from './styles';

type UnsavedChangesDialogProps = {
  open: boolean;
  onClose: () => void;
};

const UnsavedChangesDialog = ({
  open,
  onClose
}: UnsavedChangesDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={localize('unsavedChanges')}
      withCloseIcon
      primaryButtonProps={{
        content: localize('ok'),
        onClick: onClose
      }}
    >
      <StyledUnsavedChangesDialogContent>
        {localize('unsavedInstitutionSettings')}
      </StyledUnsavedChangesDialogContent>
    </Dialog>
  );
};

export { UnsavedChangesDialog };
