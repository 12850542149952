import { ServerPatient } from '@viz/api';
import { format } from 'date-fns';

import { StyledSectionField } from './styles';

export const PatientEhrSectionField = (
  props: ServerPatient.EhrFieldResponse & { index: number }
) => {
  const {
    acquisition_ts,
    acquisition_unit,
    value_finding,
    field_value,
    index
  } = props;

  const formatTimestamp = (ts: number) => {
    return format(ts * 1000, 'MMM d, yyyy · h:mm a').toLowerCase();
  };

  return (
    <StyledSectionField>
      <div>
        <h4 data-testid={`ehr-info-section-field-${index}-label`}>
          {props.field_label}
        </h4>
        {acquisition_ts && (
          <span data-testid={`ehr-info-section-field-${index}-acquisition-ts`}>
            {formatTimestamp(acquisition_ts)}
          </span>
        )}
      </div>
      {!field_value ? (
        <></>
      ) : acquisition_unit ? (
        <div style={{ color: props.field_color }}>
          <b data-testid={`ehr-info-section-field-${index}-value`}>
            {field_value}
          </b>
          <span
            data-testid={`ehr-info-section-field-${index}-acquisition-unit`}
          >
            {acquisition_unit}
          </span>
          {value_finding && (
            <span data-testid={`ehr-info-section-field-${index}-value-finding`}>
              ({value_finding})
            </span>
          )}
        </div>
      ) : (
        <div>
          <span data-testid={`ehr-info-section-field-${index}-value`}>
            {field_value}
          </span>
        </div>
      )}
    </StyledSectionField>
  );
};
