import type { ClientConfigTabSortingOption } from '@viz/api';
import { Sort, SortMenuItem } from '@viz/design-system';
import { ChangeEvent, useMemo } from 'react';

import { StyledSortSectionContainer } from './styles';

interface SortingSectionProps {
  onChangeSorting: (newSortBy: any) => void;
  selected?: number;
  sorting_options: Array<ClientConfigTabSortingOption>;
}

const SortingSection = ({
  onChangeSorting,
  selected,
  sorting_options
}: SortingSectionProps) => {
  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    const newSortById = sorting_options.find(
      ({ value }) => value === newValue
    )?.id;

    onChangeSorting(newSortById);
  };

  const value = useMemo(
    () => sorting_options.find(({ id }) => id === selected)?.value,
    [selected, sorting_options]
  );

  return (
    <StyledSortSectionContainer data-testid="sort-section">
      <Sort onChange={handleChange} value={value} valueLabel={'Sort:'}>
        {sorting_options.map(({ id, value }) => (
          <SortMenuItem key={id} value={value}>
            {value}
          </SortMenuItem>
        ))}
      </Sort>
    </StyledSortSectionContainer>
  );
};

export { SortingSection };
