import { Dialog, getFontSize, getSize } from '@viz/design-system';
import styled from 'styled-components';

export const StyledOpenNewTabDialog = styled(Dialog)`
  & .MuiDialog-paper {
    height: ${getSize(70)};
    width: ${getSize(130)};
    ${getFontSize('xl')};
  }

  & .MuiDialogContent-root {
    ${getFontSize('base')};
  }
`;
