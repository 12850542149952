import { Alert, CodeInput } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { KeyboardEvent } from 'react';

import {
  StyledAuthenticatePhone,
  StyledError,
  StyledResendMfaCode
} from './styles';

const ENTER_KEY = 'Enter';

type AuthenticatePhoneProps = {
  number: string;
  code: string;
  isError: boolean;
  onCodeChange: (code: string) => void;
  onVerifyMfaCode: () => void;
  onResendMfaCode: () => void;
};

const AuthenticatePhone = ({
  number,
  code,
  isError,
  onCodeChange,
  onVerifyMfaCode,
  onResendMfaCode
}: AuthenticatePhoneProps) => {
  const onEnter = (event: KeyboardEvent<HTMLDivElement>) => {
    if (code.length === 6 && event.key === ENTER_KEY) {
      event.preventDefault();
      onVerifyMfaCode();
    }
  };

  return (
    <StyledAuthenticatePhone onKeyDown={onEnter}>
      <div>{`${localize('authorizationCodeMessage')} ${number}`}</div>
      <CodeInput
        autoFocus
        value={code}
        onChange={(value) => onCodeChange(value)}
        isValid={!isError}
        testId="authenticate-phone-number-code"
      />
      {isError && (
        <StyledError>
          <Alert
            type="error"
            text={localize('wrongCode')}
            testId="authenticate-phone-number-error"
          />
        </StyledError>
      )}
      <StyledResendMfaCode onSendMfaCode={onResendMfaCode} />
    </StyledAuthenticatePhone>
  );
};

export { AuthenticatePhone };
