import { localize } from '@viz/i18n';

import { StyledStatusHeader } from './styles';

type StatusHeaderProps = {
  name: string;
};

export const StatusHeader = ({ name }: StatusHeaderProps): JSX.Element => {
  return (
    <StyledStatusHeader>
      <span>{name}</span>
      <span>{localize('status')}</span>
    </StyledStatusHeader>
  );
};
