import { ClientConfigTabStatus } from '@viz/api';
import { Filter, FilterMenuItem } from '@viz/design-system';
import { ChangeEvent, useMemo } from 'react';

interface StatusFilterProps {
  disabled?: boolean;
  onChangeStatusFilter: (newStatus: any) => void;
  selected?: number;
  statuses: Array<ClientConfigTabStatus>;
}

const StatusFilter = ({
  disabled,
  onChangeStatusFilter,
  selected,
  statuses
}: StatusFilterProps) => {
  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    const newStatusId = statuses.find(({ value }) => value === newValue)?.id;

    onChangeStatusFilter(newStatusId);
  };

  const handleClear = () => {
    onChangeStatusFilter(undefined);
  };

  const value = useMemo(
    () => statuses.find(({ id }) => id === selected)?.value,
    [selected, statuses]
  );

  return (
    <Filter
      dataTestId="filter-status"
      disabled={disabled}
      onChange={handleChange}
      onClear={handleClear}
      value={value}
      valueLabel="Status"
    >
      {statuses.map(({ id, value }) => (
        <FilterMenuItem key={id} value={value}>
          {value}
        </FilterMenuItem>
      ))}
    </Filter>
  );
};

export { StatusFilter };
