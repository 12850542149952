import { IconSizes, Loading } from '@viz/design-system';
import { localize } from '@viz/i18n';

import { StyledButton, StyledSaveButtonContainer } from './styles';

type SaveChangesSectionProps = {
  isSettingsChanged: boolean;
  isError: boolean;
  isSavingChanges: boolean;
  onSave: () => void;
  onDiscard: () => void;
};

export const InstitutionSettingsSaveChanges = ({
  isSettingsChanged,
  isError,
  isSavingChanges,
  onSave,
  onDiscard
}: SaveChangesSectionProps) => {
  return (
    <StyledSaveButtonContainer>
      <StyledButton
        color="secondary"
        variant="outlined"
        onClick={onDiscard}
        disabled={Boolean(!isSettingsChanged)}
      >
        {localize('discard')}
      </StyledButton>
      <StyledButton
        color="primary"
        variant="contained"
        onClick={onSave}
        disabled={Boolean(!isSettingsChanged || isError)}
        testId="institution-settings-save-button"
      >
        {isSavingChanges ? (
          <Loading size={IconSizes.SMALL} color="secondary" />
        ) : (
          localize('save')
        )}
      </StyledButton>
    </StyledSaveButtonContainer>
  );
};
