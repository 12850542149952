import { localize } from '@viz/i18n';
import { UrlConfig } from '@viz/utils';
import { useSetRecoilState } from 'recoil';

import {
  AnalyticsEventName,
  getEventNameByPath,
  useAnalyticsEvent,
  useRouter
} from '../../../hooks';
import { Paths } from '../../../navigation';
import { selectedPatientIdState } from '../../../store';
import Search from '../Search';

const SearchPatient = (): JSX.Element => {
  const router = useRouter();
  const { sendEvent } = useAnalyticsEvent();
  const queryParam = router.getUrlParam('q') ?? '';
  const tab = router.getUrlParam('tab') ?? '';
  const setSelectedPatientIdState = useSetRecoilState(selectedPatientIdState);

  const searchPatient = (searchText: string) => {
    setSelectedPatientIdState('');

    sendEvent(AnalyticsEventName.PATIENT_SEARCH_CRITERIA_SUBMITTED, {
      active_patient_list: getEventNameByPath(router.pathname)
    });

    const urlConfig: UrlConfig = searchText
      ? {
          path: Paths.CASES,
          queryParams: {
            q: searchText,
            tab: { value: tab, noEncryption: true }
          }
        }
      : {
          path: Paths.CASES,
          queryParams: { tab: { value: tab, noEncryption: true } }
        };

    router.navigate(urlConfig, queryParam ? 'replace' : 'push');
  };

  return (
    <Search
      initialSearchText={queryParam}
      onSubmit={searchPatient}
      testId="search-patient-box"
      inputTestId="search-patient-input"
      placeholder={localize('searchByNameOrMRN')}
    />
  );
};
export default SearchPatient;
